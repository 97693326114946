import { canBeUndefined } from '../../utils/ts/is-defined';

interface BalyoSimulationVersion {
  /** Name of the path */
  version: string;
  /** Can be selected if the project name matches exactly the project name as filled in the version */
  projectNames?: string[];
  /** Only available in dev mode */
  devOnly?: boolean;
}

export const availableBalyoSimulationVersions: BalyoSimulationVersion[] = [
  {
    version: '4.15',
  },
  {
    version: '4.16',
  },
  {
    version: '4.16-multicore-unstable',
    devOnly: true,
  },
  {
    version: '4.17',
  },
  {
    version: '4.16_v1.2',
    devOnly: true,
  },
];

const sortBalyoSimulationVersions = (a: BalyoSimulationVersion, b: BalyoSimulationVersion): 1 | 0 | -1 => {
  const [aMajor, aMinor, aPatch] = a.version.split('_').join('').split('.');
  const [bMajor, bMinor, bPatch] = b.version.split('_').join('').split('.');
  if (aMajor > bMajor) {
    return -1;
  } else if (aMajor < bMajor) {
    return 1;
  } else if (aMinor > bMinor) {
    return -1;
  } else if (aMinor < bMinor) {
    return 1;
  } else if (aPatch > bPatch) {
    return -1;
  } else if (aPatch < bPatch) {
    return 1;
  }

  return 0;
};

export const latestBalyoSimulationVersion = availableBalyoSimulationVersions
  .sort(sortBalyoSimulationVersions)
  .filter((version) => !version.projectNames?.length)[0];

export function getMatchingSimulationVersion(version: string): BalyoSimulationVersion {
  const matchingVersion = canBeUndefined(
    availableBalyoSimulationVersions
      .filter((availableVersion) => {
        if (availableVersion.version === version) {
          return true;
        }

        // check if x.y are the same
        const [availableMajor, availableMinor] = availableVersion.version.split('_').join('').split('.');
        const [major, minor] = version.split('_').join('').split('.');

        if (availableMajor === major && availableMinor === minor) {
          return true;
        }

        return false;
      })
      .filter((version) => !version.devOnly)
      .sort((a, b) => sortBalyoSimulationVersions(a, b))[0]
  );

  if (!matchingVersion) {
    // eslint-disable-next-line no-console
    console.warn(`No matching simulation version found for ${version}`);

    // detect if version is < 4.15.0 and use 4.16 if so (4.16 is more stable than 4.15)
    const before4_15_0 = sortBalyoSimulationVersions({ version }, { version: '4.15' }) === 1;
    const version4_16 = availableBalyoSimulationVersions.find(
      (availableVersion) => availableVersion.version === '4.16'
    );
    if (before4_15_0 && version4_16) {
      // eslint-disable-next-line no-console
      console.warn(`Using 4.16 version instead`);

      return version4_16;
    }

    // eslint-disable-next-line no-console
    console.warn(`Using latest version instead (${latestBalyoSimulationVersion.version})`);

    return latestBalyoSimulationVersion;
  }

  return matchingVersion;
}
