import { addLayerAction, addLayerGroupAction, deleteLayerAction, deleteLayerGroupAction } from 'actions/circuit';
import { syncYJSLocalToRemote } from 'components/presence/utils/syncYjsDoc';
import { localDoc } from 'multiplayer/globals';
import type { LayerData, LayerGroupData, LayersDataContainer } from 'reducers/circuit/state';
import store from 'store';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import { generateShapeId } from './next-free-id';

export function initializeLegacyLayerGroups(): void {
  const dispatch = store.dispatch;
  const layerGroups = store.getState().circuit.present.layers.layerGroups;
  const newLayerGroups = PreferencesService.getModelNames();

  // we delete all the layer groups
  Object.values(layerGroups).forEach((layerGroup) => {
    dispatch(
      deleteLayerGroupAction({
        layerGroupId: layerGroup.id,
        userAction: true,
      })
    );
  });

  // we create the common layer group
  dispatch(
    addLayerGroupAction({
      name: 'Common',
      id: generateShapeId().toString(),
    })
  );

  // we create the layer groups for each robot model
  newLayerGroups.forEach((robotModel) => {
    dispatch(
      addLayerGroupAction({
        name: robotModel,
        id: generateShapeId().toString(),
      })
    );
  });
}

export function initializeLayersAndLayerGroups(): void {
  const dispatch = store.dispatch;
  const layers = store.getState().circuit.present.layers.layers;
  const layerGroups = store.getState().circuit.present.layers.layerGroups;
  const newLayerGroups = PreferencesService.getModelNames();

  // we delete all the layers
  Object.values(layers).forEach((layer) => {
    dispatch(
      deleteLayerAction({
        layerId: layer.id,
        name: layer.name,
      })
    );
  });

  // we delete all the layer groups
  Object.values(layerGroups).forEach((layerGroup) => {
    dispatch(
      deleteLayerGroupAction({
        layerGroupId: layerGroup.id,
        userAction: true,
      })
    );
  });

  // we create the layer Storage and Robots
  const storageLayerId = generateShapeId().toString();
  const robotsLayerId = generateShapeId().toString();
  dispatch(
    addLayerAction({
      name: 'Storage',
      id: storageLayerId,
      visibility: true,
      isDraft: false,
      color: theme.palette.secondary.main,
      order: 0,
    })
  );
  dispatch(
    addLayerAction({
      name: 'Robots',
      id: robotsLayerId,
      visibility: true,
      isDraft: false,
      color: theme.palette.primary.main,
      order: 1,
    })
  );

  // we create the common layer group
  dispatch(
    addLayerGroupAction({
      name: 'Common',
      id: generateShapeId().toString(),
      children: [storageLayerId],
    })
  );

  // we create the layer groups for each robot model
  newLayerGroups.forEach((robotModel) => {
    dispatch(
      addLayerGroupAction({
        name: robotModel,
        id: generateShapeId().toString(),
        children: [robotsLayerId],
      })
    );
  });

  if (!store.getState().multiplayer.multiplayer) return;

  const localCircuitMap = localDoc.getMap('circuit');
  localCircuitMap.set('circuit', store.getState().circuit.present);
  syncYJSLocalToRemote();
}

export function getLegacyLayersInitial(models?: string[]): LayersDataContainer {
  const racksAndStockzonesLayer: LayerData = {
    id: generateShapeId().toString(),
    name: 'Storage',
    visibility: true,
    isDraft: false,
    color: theme.palette.secondary.main,
    order: 0,
  };
  const robotsLayer: LayerData = {
    id: generateShapeId().toString(),
    name: 'Robots',
    visibility: true,
    isDraft: false,
    color: theme.palette.primary.main,
    order: 1,
  };

  const layerGroupCommon: LayerGroupData = {
    id: generateShapeId().toString(),
    name: 'Common',
    children: [racksAndStockzonesLayer.id],
  };

  const layerGroups = {
    [layerGroupCommon.id]: layerGroupCommon,
  };

  const newLayerGroups = models || PreferencesService.getModelNames();
  newLayerGroups.forEach((robotModel) => {
    const layerGroupRobot: LayerGroupData = {
      id: generateShapeId().toString(),
      name: robotModel,
      children: [robotsLayer.id],
    };

    layerGroups[layerGroupRobot.id] = layerGroupRobot;
  });

  return {
    selectedLayer: robotsLayer.id,
    editParametersLayer: null,
    layers: {
      [racksAndStockzonesLayer.id]: racksAndStockzonesLayer,
      [robotsLayer.id]: robotsLayer,
    },
    layerGroups: layerGroups,
  };
}
