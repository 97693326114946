import { Settings, Warning } from '@mui/icons-material';
import { Divider, FormControlLabel, InputAdornment, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { CollapseMore } from 'components/utils/collapse-more';
import { HelpIconTooltip } from 'components/utils/tooltips';
import React, { startTransition, useCallback, useEffect, useState } from 'react';
import {
  PresetSimulationEnum,
  setDisplayAllRobotsData,
  setEnableAdvancedDisplayTraffic,
  setEnableCharging,
  setEnableDisplayTraffic,
  setEnableFastRefresh,
  setEnableLibPerfo,
  setMaxDuration,
  setPresetSimulation,
  setRobotsOpacity,
  setSimulationDisplayState,
} from 'simulation/simulation';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { DisplayErrorsMonitoringCollapse } from '../display-errors-monitoring-collapse';
import { useBattPointsInTheCircuit } from './use-batt-points';

const simulationDurationMaxHoursName = 'simulation-max-duration-hours';
const simulationDurationMaxMinutesName = 'simulation-max-duration-minutes';

interface ConfigureSimulationParametersProps {
  isSimulationPaused: boolean;
  isSimulationRunning: boolean;
}
export function ConfigureSimulationParameters(props: ConfigureSimulationParametersProps): JSX.Element {
  const { isSimulationPaused, isSimulationRunning } = props;

  const dispatch = useAppDispatch();

  const maxDuration = useAppSelector((state) => state.simulation.maxDuration);
  const enableCharging = useAppSelector((state) => state.simulation.enableCharging);
  const robotsOpacity = useAppSelector((state) => state.simulation.robotsOpacity);
  const simulationEnableDisplay = useAppSelector((state) => state.simulation.enableDisplay);
  const fastRefreshEnabled = useAppSelector((state) => state.simulation.enableFastRefresh);
  const displayTrafficEnabled = useAppSelector((state) => state.simulation.enableDisplayTraffic);
  const advancedTrafficDisplayEnabled = useAppSelector((state) => state.simulation.enableAdvancedDisplayTraffic);
  const libPerfoEnabled = useAppSelector((state) => state.simulation.enableLibPerfo);
  const displayAllRobotsData = useAppSelector((state) => state.simulation.displayAllRobotsData);

  const isThereAtLeastOneBattPoint = useBattPointsInTheCircuit();

  const handleToggleCharging = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
      dispatch(setEnableCharging(newValue));
    },
    [dispatch]
  );

  const [fadeRobots, setFadeRobots] = useState(robotsOpacity === 0.2);
  const handleChangeFadeRobots = useCallback(
    (hasFadeRobots: boolean) => {
      const newRobotOpacity = hasFadeRobots ? 0.2 : 1;
      startTransition(() => {
        dispatch(setRobotsOpacity(newRobotOpacity));
      });
    },
    [dispatch]
  );
  const handleToggleFadeRobots = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, newValueIsFaded: boolean) => {
      setFadeRobots(newValueIsFaded);
      handleChangeFadeRobots(newValueIsFaded);
    },
    [handleChangeFadeRobots]
  );

  const handleToggleDisplayAllRobotsData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
      dispatch(setDisplayAllRobotsData(newValue));
      dispatch(setPresetSimulation(PresetSimulationEnum.Custom));
    },
    [dispatch]
  );

  const handleToggleEnableDisplay = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, newValue: boolean): void => {
      dispatch(setSimulationDisplayState(newValue));
      dispatch(setPresetSimulation(PresetSimulationEnum.Custom));
    },
    [dispatch]
  );

  const maxDurationFormDisabled = !isSimulationPaused && isSimulationRunning;
  const handleUpdateMaxDuration = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const currentTarget = e.currentTarget;

      const maxDurationHrsInput: HTMLInputElement | undefined | unknown = currentTarget.elements[
        simulationDurationMaxHoursName
      ] as unknown;
      const maxDurationMinInput: HTMLInputElement | undefined | unknown = currentTarget.elements[
        simulationDurationMaxMinutesName
      ] as unknown;

      if (!(maxDurationHrsInput instanceof HTMLInputElement) || !(maxDurationMinInput instanceof HTMLInputElement)) {
        // eslint-disable-next-line no-console
        console.error('Wrong type for maxDurationHrsInput or maxDurationMinInput, elements are not HTMLInputElement', {
          maxDurationHrsInput,
          maxDurationMinInput,
        });

        return;
      }

      const maxDurationHrs = parseInt(maxDurationHrsInput.value, 10);
      const maxDurationMin = parseInt(maxDurationMinInput.value, 10);

      if (isNaN(maxDurationHrs) || isNaN(maxDurationMin)) {
        // eslint-disable-next-line no-console
        console.error('maxDurationHrs or maxDurationMin is NaN', { maxDurationHrs, maxDurationMin });

        return;
      }

      const newMaxDuration = maxDurationHrs * 3600 + maxDurationMin * 60;

      dispatch(setMaxDuration(newMaxDuration));
    },
    [dispatch]
  );

  useEffect(() => {
    const maxDurationHrs = maxDuration !== undefined ? Math.floor(maxDuration / 3600) : '';
    const maxDurationMin =
      maxDuration !== undefined && maxDurationHrs !== '' ? Math.floor((maxDuration - maxDurationHrs * 3600) / 60) : '';

    const maxDurationHrsInput = document.querySelector(`[name=${simulationDurationMaxHoursName}]`);
    const maxDurationMinInput = document.querySelector(`[name=${simulationDurationMaxMinutesName}]`);

    if (
      !maxDurationHrsInput ||
      !maxDurationMinInput ||
      !(maxDurationHrsInput instanceof HTMLInputElement) ||
      !(maxDurationMinInput instanceof HTMLInputElement)
    ) {
      // eslint-disable-next-line no-console
      console.error('maxDurationHrsInput or maxDurationMinInput is null or not input elements', {
        maxDurationHrsInput,
        maxDurationMinInput,
      });

      return;
    }

    maxDurationHrsInput.value = maxDurationHrs.toString();
    maxDurationMinInput.value = maxDurationMin.toString();
  }, [dispatch, maxDuration]);

  const handleChangeFastDisplayRefresh = useCallback(() => {
    const newValue = !fastRefreshEnabled;
    dispatch(setEnableFastRefresh(newValue));
    dispatch(setPresetSimulation(PresetSimulationEnum.Custom));
  }, [dispatch, fastRefreshEnabled]);

  const handleChangeTrafficDisplay = useCallback(() => {
    const newValue = !displayTrafficEnabled;

    dispatch(setEnableDisplayTraffic(newValue));
    dispatch(setPresetSimulation(PresetSimulationEnum.Custom));
  }, [dispatch, displayTrafficEnabled]);

  const handleChangeAdvancedTrafficDisplay = useCallback(() => {
    const newValue = !advancedTrafficDisplayEnabled;

    dispatch(setEnableAdvancedDisplayTraffic(newValue));
    dispatch(setPresetSimulation(PresetSimulationEnum.Custom));
  }, [dispatch, advancedTrafficDisplayEnabled]);

  const handleChangeLibPerfo = useCallback(() => {
    const newLibPerfoEnabledState = !libPerfoEnabled;
    dispatch(setEnableLibPerfo(newLibPerfoEnabledState));
  }, [dispatch, libPerfoEnabled]);

  useEffect(() => {
    if (!isThereAtLeastOneBattPoint) {
      dispatch(setEnableCharging(false));
    }
  }, [dispatch, isThereAtLeastOneBattPoint]);

  return (
    <CollapseMore
      title={
        <Stack direction="row" spacing={2} sx={{ pt: 0.6 }}>
          <Settings fontSize="small" />
          <Typography variant="body2" sx={{ color: theme.palette.grey[700], fontWeight: 600 }}>
            Parameters
          </Typography>
        </Stack>
      }
      hasDivider={false}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pl: 2, mt: 1 }}>
        <Typography variant="subtitle2">Maximum duration</Typography>
        <HelpIconTooltip
          title={
            <>
              The simulation will stop automatically after this duration
              <br />0 = no maximum duration
            </>
          }
        />
      </Stack>
      <form onBlur={handleUpdateMaxDuration}>
        <Stack direction="row" spacing={2} justifyContent={'center'}>
          <TextField
            type="number"
            name={simulationDurationMaxHoursName}
            variant="standard"
            inputProps={{
              min: 0,
              step: 1,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
            }}
            sx={{
              background: maxDurationFormDisabled ? theme.palette.grey[200] : undefined,
            }}
            size="small"
            disabled={maxDurationFormDisabled}
          />
          <TextField
            type="number"
            name={simulationDurationMaxMinutesName}
            variant="standard"
            inputProps={{
              min: 0,
              step: 1,
              max: 59,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">mn</InputAdornment>,
            }}
            sx={{
              width: '14ch',
              background: maxDurationFormDisabled ? theme.palette.grey[200] : undefined,
            }}
            size="small"
            disabled={maxDurationFormDisabled}
          />
        </Stack>
      </form>
      {/* Enable Charging */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          control={
            <Switch checked={enableCharging} onChange={handleToggleCharging} disabled={!isThereAtLeastOneBattPoint} />
          }
          label={
            <>
              <Typography variant="subtitle2">Enable Charging</Typography>
              {!isThereAtLeastOneBattPoint && (
                <Tooltip title="There's not charger point in the circuit.">
                  <Warning
                    color="warning"
                    sx={{
                      verticalAlign: 'middle',
                      marginLeft: theme.spacing(2),
                    }}
                  />
                </Tooltip>
              )}
            </>
          }
          sx={{
            ml: 1,
            marginTop: theme.spacing(1),
          }}
        />
      </Stack>
      {/* Fade Robots */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          control={<Switch checked={fadeRobots} onChange={handleToggleFadeRobots} />}
          label={<Typography variant="subtitle2">Fade Robots</Typography>}
          sx={{
            ml: 1,
            marginTop: theme.spacing(1),
          }}
        />
        <HelpIconTooltip title="When enabled, the robots are displayed with a lower opacity." />
      </Stack>
      {/* Enable Display */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          control={<Switch checked={simulationEnableDisplay} onChange={handleToggleEnableDisplay} />}
          label={<Typography variant="subtitle2">Enable Display</Typography>}
          sx={{
            ml: 1,
            marginTop: theme.spacing(1),
          }}
        />
        <HelpIconTooltip title="When disabled, the robots as well as other elements are not displayed. This option can impact negatively the maximum simulation speed." />
      </Stack>
      {/* Fast Display Refresh */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          control={<Switch checked={fastRefreshEnabled} onChange={handleChangeFastDisplayRefresh} />}
          label={<Typography variant="subtitle2">Fast Display Refresh </Typography>}
          sx={{
            ml: 1,
            marginTop: theme.spacing(1),
          }}
        />
        <HelpIconTooltip title="When disabled, the User Interface is updated every second only (despite the simulation is running well in the background). When enabled, the UI is updated much more frequently. This option can impact negatively the maximum simulation speed." />
      </Stack>
      {/* Display All Robots Data */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          control={<Switch checked={displayAllRobotsData} onChange={handleToggleDisplayAllRobotsData} />}
          label={<Typography variant="subtitle2">Display All Robots Data</Typography>}
          sx={{
            ml: 1,
            marginTop: theme.spacing(1),
          }}
        />
        <HelpIconTooltip title="When enabled, all the robots tooltips are displayed. This option can impact negatively the maximum simulation speed." />
      </Stack>
      {/* Display Traffic */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          control={<Switch checked={displayTrafficEnabled} onChange={handleChangeTrafficDisplay} />}
          label={<Typography variant="subtitle2">Display Traffic</Typography>}
          sx={{
            ml: 1,
            marginTop: theme.spacing(1),
          }}
        />
        <HelpIconTooltip title="When enabled, some traffic data as well as robot itineraries are displayed on the map. This option can impact negatively the maximum simulation speed." />
      </Stack>
      {/* Robot Performance Monitoring */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          control={<Switch checked={libPerfoEnabled} onChange={handleChangeLibPerfo} />}
          label={<Typography variant="subtitle2">Robot Performance Monitoring</Typography>}
          sx={{
            ml: 1,
            marginTop: theme.spacing(1),
          }}
        />
        <HelpIconTooltip title="When enabled, we retrieve more data about the robots. This option can impact negatively the maximum simulation speed." />
      </Stack>
      <Divider variant="middle" sx={{ mt: 1, opacity: 0.5 }} />
      <DisplayErrorsMonitoringCollapse />
      <Divider variant="middle" sx={{ mt: 1 }} />
      {/* Advanced Traffic Display */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          control={<Switch checked={advancedTrafficDisplayEnabled} onChange={handleChangeAdvancedTrafficDisplay} />}
          label={<Typography variant="subtitle2">Advanced Traffic Display</Typography>}
          sx={{
            ml: 1,
            marginTop: theme.spacing(1),
          }}
        />
        <HelpIconTooltip title="When enabled, more traffic data are displayed on the map. This option can have a huge negative impact on the maximum simulation speed." />
      </Stack>
    </CollapseMore>
  );
}
