import { importErrorMessageAction, importMapImageTilesAction, importMapImageTilesReleaseVersionAction } from 'actions';
import {
  mapTilesSchema,
  releasesVersionSchema,
  serverErrorResponse,
  serverSuccessResponse,
} from 'models/map-tiles.model';
import type { MapImageTiles } from 'models/maps';
import { useEffect } from 'react';
import { addMapImageFilterAction } from 'reducers/local/filters.reducer';
import { AuthService } from 'services/auth.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppSelector } from 'store';
import { Container } from 'typescript-ioc';
import { isErrorMessage } from 'utils/circuit/map-tiles';

const dispatch = store.dispatch;

const mapEditorURL = import.meta.env.VITE_MAP_EDITOR_API_URL;

interface ReleaseData {
  release: string;
  layers: string[];
}

export type ErrorMessage =
  | 'Project not found'
  | 'Invalid parameter: layer'
  | 'File not found'
  | 'Bad descriptor format'
  | 'Forbidden'
  | 'Server error';

export type FetchAction = 'mapTiles' | 'preview' | 'releases';

/**
 * Function to find the good error message
 * @param window - The coordinates of the window
 * @param message - The error message from the server
 * @param action - Which fetch action is it
 * @returns The message for the user that match with the server error message or undefined
 * */
function matchServerErrorResponse(message: ErrorMessage, action: FetchAction): string | undefined {
  let errorMsg: string | undefined;

  switch (message) {
    case 'Project not found':
      errorMsg = `The server processed the request successfully, however, there ${
        action === 'preview' ? 'is no preview' : action === 'releases' ? 'are no releases version' : ' are no map tiles'
      } yet for this version of the project`;
      break;

    case 'Forbidden':
      errorMsg = `${
        action === 'preview'
          ? 'The Map Editor server denied access to the map bird view preview'
          : action === 'releases'
            ? 'The Map Editor server denied access to the releases version'
            : 'The map tiles are not displayed'
      } because you do not have the proper authorization`;
      break;

    case 'Bad descriptor format':
      errorMsg = 'The map tiles are not displayed due to an internal server error';
      break;

    case 'File not found':
      errorMsg = `The server processed the request successfully, however, there ${
        action === 'preview'
          ? 'is no map preview'
          : action === 'releases'
            ? 'are no releases version'
            : ' are no map tiles'
      } yet for this version of the project`;
      break;

    case 'Invalid parameter: layer':
      errorMsg = 'The layer is invalid';
      break;
  }

  return errorMsg;
}

/**
 * Function to request the server to get the mapTiles
 * @param release - The map tiles release version
 * @param projectName - The name of the project
 * @param userToken - The token to do the request
 * @returns The map tiles or null
 */
async function fetchMapTiles(
  release: string,
  projectName: string,
  userToken: string
): Promise<MapImageTiles | ErrorMessage> {
  const url = `${mapEditorURL}/remote/projects/${projectName}/tiles/${release}/birdview`;

  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${userToken}` },
  });

  const data: unknown = await response.json();

  if (response.ok === false) {
    if (response.status === 401) {
      SnackbarUtils.error(
        'The map tiles are not displayed due to an authorization problem. Please try to logout and login'
      );

      return 'Forbidden';
    } else if (response.status === 404) {
      return 'Project not found';
    }

    const parsed = serverErrorResponse.safeParse(data);
    if (!parsed.success) {
      // Our schema does not match the server error response type.
      SnackbarUtils.error('An error occurred and the map tiles could not be loaded');

      return 'Server error';
    }

    // Inspect the response to know what happened and inform the user.
    const errorMsg = matchServerErrorResponse(parsed.data.message, 'mapTiles');
    if (errorMsg) {
      SnackbarUtils.error(errorMsg);
    }

    return parsed.data.message;
  }

  // Server responded 200 OK
  const resSuccess = serverSuccessResponse(mapTilesSchema).safeParse(data);

  if (!resSuccess.success) {
    // Our schema does not match the server error response type.
    SnackbarUtils.error('An error occurred and the map tiles could not be loaded');

    return 'Server error';
  }

  const responseData = resSuccess.data.data;

  const mapImageTiles = { ...responseData, ...{ name: projectName } };

  return mapImageTiles;
}

/**
 * Function to request the server to get all the available releases version
 * @param projectName - The name of the project
 * @param userToken - The token to do the request
 * @returns The available releases version or null
 */
export async function fetchMapTilesReleasesVersion(
  projectName: string,
  userToken: string
): Promise<ReleaseData[] | ErrorMessage> {
  const url = `${mapEditorURL}/remote/projects/${projectName}/tiles/releases`;

  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${userToken}` },
  });

  const data: unknown = await response.json();

  if (response.ok === false) {
    if (response.status === 401) {
      SnackbarUtils.error(
        'The map tiles are not displayed due to an authorization problem. Please try to logout and login'
      );

      return 'Forbidden';
    } else if (response.status === 404) {
      return 'Project not found';
    }

    const parsed = serverErrorResponse.safeParse(data);

    if (!parsed.success) {
      SnackbarUtils.error('An error occurred and the map tiles could not be loaded');

      return 'Server error';
    }

    const errorMsg = matchServerErrorResponse(parsed.data.message, 'releases');

    if (errorMsg) {
      if (parsed.data.message === 'Project not found') {
        // eslint-disable-next-line no-console
        console.warn(errorMsg);
      } else {
        // eslint-disable-next-line no-console
        console.error(errorMsg);

        SnackbarUtils.error(errorMsg);
      }
    }

    return parsed.data.message;
  }

  const resSuccess = serverSuccessResponse(releasesVersionSchema).safeParse(data);

  if (!resSuccess.success) {
    SnackbarUtils.error('An error occurred and the map tiles could not be loaded');

    return 'Server error';
  }

  const mapReleasesVersion = resSuccess.data.data;

  return mapReleasesVersion;
}

/** Function to use the server response to store the map tiles*/
export function useFetchMapTiles(): void {
  const projectName = useAppSelector((state) => state.project.projectName);
  const projectNameMapEditor = useAppSelector((state) => state.project.projectNameMapEditor);
  const release = useAppSelector((state) => state.maps.mapImageTiles.mapImageTilesData?.release);
  const userToken = Container.get(AuthService).accessToken;
  const releaseVersion = useAppSelector((state) => state.maps.mapImageTiles.mapTilesReleaseVersion);
  const mapImageTilesFetchError = useAppSelector((state) => state.maps.mapImageTiles.mapImageTilesFetchError);

  const projectNameForMapTiles = projectNameMapEditor ?? projectName;

  useEffect(() => {
    if (!projectNameForMapTiles) return;
    if (userToken === null) return;
    if (!releaseVersion) return;

    const getTiles = async (): Promise<void> => {
      const response = await fetchMapTiles(release ? release : 'latest', projectNameForMapTiles, userToken);

      if (isErrorMessage(response)) {
        dispatch(
          importErrorMessageAction({
            errorMessage: response,
            fetchAction: 'mapTiles',
          })
        );

        return;
      }

      // If we change the projectNameMapEditor and now have a good one, we need to set the previous error message to undefined
      if (mapImageTilesFetchError) {
        dispatch(
          importErrorMessageAction({
            errorMessage: undefined,
            fetchAction: 'mapTiles',
          })
        );
      }

      dispatch(
        importMapImageTilesAction({
          name: projectNameForMapTiles,
          version: response.version,
          tiles: response.tiles,
          release: response.release,
          tilePixelSize: response.tilePixelSize,
        })
      );

      dispatch(
        addMapImageFilterAction({
          name: projectNameForMapTiles ? projectNameForMapTiles : 'map',
          isTiles: true,
        })
      );
    };

    if (!mapEditorURL) {
      // eslint-disable-next-line no-console
      console.warn('No map editor API URL env variable, feature disabled');
    }

    getTiles();
  }, [projectNameForMapTiles, projectName, release, releaseVersion, userToken, mapImageTilesFetchError]);
}

/** Function to use the server response to store the available releases version*/
export function useFetchMapTilesReleaseVersion(): void {
  const projectName = useAppSelector((state) => state.project.projectName);
  const projectNameMapEditor = useAppSelector((state) => state.project.projectNameMapEditor);
  const userToken = Container.get(AuthService).accessToken;
  const releaseVersionFetchError = useAppSelector((state) => state.maps.mapImageTiles.mapTilesReleaseVersionFetchError);

  const projectNameForMapTiles = projectNameMapEditor ?? projectName;

  useEffect(() => {
    if (!projectNameForMapTiles) return;
    if (userToken === null) return;

    const getReleasesVersion = async (): Promise<void> => {
      const response = await fetchMapTilesReleasesVersion(projectNameForMapTiles, userToken);

      if (isErrorMessage(response)) {
        dispatch(
          importErrorMessageAction({
            errorMessage: response,
            fetchAction: 'releases',
          })
        );

        return;
      }

      if (releaseVersionFetchError) {
        dispatch(
          importErrorMessageAction({
            errorMessage: undefined,
            fetchAction: 'releases',
          })
        );
      }

      dispatch(
        importMapImageTilesReleaseVersionAction({
          releaseVersion: response.map((data) => {
            return data.release;
          }),
        })
      );
    };

    if (!mapEditorURL) {
      // eslint-disable-next-line no-console
      console.warn('No map editor API URL env variable, feature disabled');
    }

    getReleasesVersion();
  }, [projectNameForMapTiles, projectName, userToken, releaseVersionFetchError]);
}
