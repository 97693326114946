import { AutoAwesome, Tune } from '@mui/icons-material';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import SpeedIcon from '@mui/icons-material/Speed';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useEffect } from 'react';
import {
  PresetSimulationEnum,
  setDisplayAllRobotsData,
  setEnableAdvancedDisplayTraffic,
  setEnableDisplayTraffic,
  setEnableFastRefresh,
  setErrorsMonitoringMode,
  setMaxDuration,
  setPresetSimulation,
  setSimulationDisplayState,
  setSpeedFactor,
} from 'simulation/simulation';
import { useAppDispatch, useAppSelector } from 'store';
import { demonstration, detailed, throughput } from './preset.config';

export function PresetSimulationToolbox(): JSX.Element {
  const dispatch = useAppDispatch();

  const currentPreconfiguration = useAppSelector((state) => state.simulation.presetSimulation);

  const setPresetParameters = useCallback(
    (preset: typeof demonstration | typeof detailed | typeof throughput) => {
      dispatch(setSpeedFactor(preset.speed));
      dispatch(setMaxDuration(preset.maxDuration));
      dispatch(setSimulationDisplayState(preset.simulationDisplay));
      dispatch(setEnableFastRefresh(preset.fastRefresh));
      dispatch(setDisplayAllRobotsData(preset.displayAllRobotData));
      dispatch(setEnableDisplayTraffic(preset.displayTraffic));
      dispatch(setEnableAdvancedDisplayTraffic(preset.advancedDisplayTraffic));
      // errors monitoring
      dispatch(setErrorsMonitoringMode(preset.robotErrorMonitoring));
      dispatch(setErrorsMonitoringMode(preset.deadlockErrorMonitoring));
    },
    [dispatch]
  );

  const applyParametersPreset = useCallback(
    (newPreconfiguration: PresetSimulationEnum) => {
      switch (newPreconfiguration) {
        case PresetSimulationEnum.Demonstration:
          setPresetParameters(demonstration);
          break;
        case PresetSimulationEnum.Detailed:
          setPresetParameters(detailed);
          break;
        case PresetSimulationEnum.Throughput:
          setPresetParameters(throughput);
          break;
      }
    },
    [setPresetParameters]
  );

  const handleChangePreconfiguration = useCallback(
    (newPreconfiguration: PresetSimulationEnum) => {
      if (newPreconfiguration === currentPreconfiguration) return;

      dispatch(setPresetSimulation(newPreconfiguration));
      applyParametersPreset(newPreconfiguration);
    },
    [applyParametersPreset, currentPreconfiguration, dispatch]
  );

  useEffect(() => {
    applyParametersPreset(currentPreconfiguration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="section" sx={{ pb: 2, textAlign: 'center' }}>
      <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
        <Tooltip title="Visual demonstration config" arrow>
          <Button onClick={() => handleChangePreconfiguration(PresetSimulationEnum.Demonstration)}>
            <AutoAwesome
              sx={{
                color: currentPreconfiguration === PresetSimulationEnum.Demonstration ? 'primary' : 'gray',
              }}
            />
          </Button>
        </Tooltip>
        <Tooltip title="Traffic analysis config" arrow>
          <Button onClick={() => handleChangePreconfiguration(PresetSimulationEnum.Detailed)}>
            <FilterCenterFocusIcon
              sx={{
                color: currentPreconfiguration === PresetSimulationEnum.Detailed ? 'primary' : 'gray',
              }}
            />
          </Button>
        </Tooltip>
        <Tooltip title="Productivity results config" arrow>
          <Button onClick={() => handleChangePreconfiguration(PresetSimulationEnum.Throughput)}>
            <SpeedIcon
              sx={{
                color: currentPreconfiguration === PresetSimulationEnum.Throughput ? 'primary' : 'gray',
              }}
            />
          </Button>
        </Tooltip>
        <Tooltip title="Custom config" arrow>
          <Button onClick={() => handleChangePreconfiguration(PresetSimulationEnum.Custom)}>
            <Tune
              sx={{
                color: currentPreconfiguration === PresetSimulationEnum.Custom ? 'primary' : 'gray',
              }}
            />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );
}
