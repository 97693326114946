import AbcIcon from '@mui/icons-material/Abc';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import FunctionsIcon from '@mui/icons-material/Functions';
import NumbersIcon from '@mui/icons-material/Numbers';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  InputAdornment,
  ListItem,
  Menu,
  Popover,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { setLoadingStateAction } from 'reducers/core/reducer';
import { useAppDispatch } from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe/assert';
import { isBlockDataPositionNames, isNumberVariableName, isVariableName } from 'utils/circuit/racks-naming.guard';
import type {
  BlockDataPositionName,
  FormatingDirectionType,
  FormatingType,
  VariableParams,
  VariablesName,
} from 'utils/circuit/racks-naming.model';
import { variablesNames } from 'utils/circuit/racks-naming.model';
import { getInputValueWidth } from 'utils/input';
import { theme } from 'utils/mui-theme';
import type { ActionsRack } from '.';
import { PositionNameAdornment } from '../variable-name-adornment';

const defaultParamsVariable: VariableParams = {
  formatting: 'number',
  fillCharacter: '0',
  formattingDirection: 'ltr',
  nbCharacters: 1,
  startAt: 1,
  step: 1,
};

/**
 * For certain variables, the text direction has to be changed or is not relevant
 */
const specialTextDirection: Partial<Record<VariablesName, [string, string] | null>> = {
  '@level!': ['Bottom to top', 'Top to bottom'],
  '@numberOfColumns!': null,
  '@numberOfLevels!': null,
};

/**
 * For certain variables, the step field is not relevant
 */
const disableStepField: Partial<Record<VariablesName, boolean>> = {
  '@numberOfColumns!': true,
  '@numberOfLevels!': true,
};

export interface ChipVariableNameProps {
  index: number;
  value: VariablesName;
  deleteVariable?: () => void;
  handleChangeVariable?: (newVariableParams: VariableParams, index: number, value: VariablesName) => void;
  forceTextDirectionText?: [string, string] | null | undefined;
  blockData: BlockDataPositionName;
}
export function ChipVariableName(props: ChipVariableNameProps): JSX.Element {
  const [openMenu, setOpenMenu] = useState(false);
  const chipElRef = useRef<HTMLDivElement | null>(null);

  const isNbVariable = useMemo(() => {
    return isNumberVariableName(props.value);
  }, [props.value]);

  const { blockData } = props;

  const [formatting, setFormatting] = useState<FormatingType>(
    blockData.params?.formatting ?? defaultParamsVariable.formatting
  );
  const [nbChars, setNbChars] = useState(blockData.params?.nbCharacters ?? defaultParamsVariable.nbCharacters);
  const [fillChars, setFillChars] = useState(
    blockData.params?.fillCharacter ?? (defaultParamsVariable.fillCharacter || '0')
  );
  const [direction, setDirection] = useState<FormatingDirectionType>(
    blockData.params?.formattingDirection ?? defaultParamsVariable.formattingDirection
  );
  const [startAt, setStartAt] = useState(blockData.params?.startAt ?? defaultParamsVariable.startAt);
  const [step, setStep] = useState(blockData.params?.step ?? defaultParamsVariable.step);
  const [errorPositiveInteger, setErrorPositiveInteger] = useState(false);

  const textDirectionText = useMemo(() => {
    if (props.forceTextDirectionText === undefined) {
      if (specialTextDirection[props.value] !== undefined) {
        return specialTextDirection[props.value]; // custom values for certain variables
      }

      return ['Left to right', 'Right to left']; // default value
    }

    return props.forceTextDirectionText;
  }, [props.forceTextDirectionText, props.value]);

  const deleted = useRef(false);

  useEffect(() => {
    if (props.handleChangeVariable && deleted.current === false) {
      props.handleChangeVariable(
        {
          formatting,
          formattingDirection: direction,
          nbCharacters: nbChars,
          fillCharacter: fillChars,
          startAt,
          step,
        },
        props.index,
        props.value
      );
    }
  }, [direction, formatting, nbChars, props, startAt, step, fillChars]);

  const label = useMemo(() => {
    return variableValueToLabel(props.value);
  }, [props.value]);

  const tooltipValue = useMemo(() => {
    return variableValueToTooltip(props.value);
  }, [props.value]);

  const handleClick = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handleDelete = useCallback(() => {
    if (props.deleteVariable) {
      deleted.current = true;
      props.deleteVariable();
    }
  }, [props]);

  const inputStartAtElRef = useRef<HTMLInputElement | null>(null);
  const handleChangeFormatting = useCallback(
    (e, v: FormatingType): void => {
      if (v) setFormatting(v);

      if (v === 'number' && formatting !== 'number') {
        setStartAt(1);
        if (inputStartAtElRef.current) {
          inputStartAtElRef.current.value = '1';
        }
      } else if (v === 'letter' && formatting !== 'letter') {
        setStartAt(0);
        if (inputStartAtElRef.current) {
          inputStartAtElRef.current.value = '0';
        }
      }
    },
    [formatting]
  );

  const isClickable = !!isNbVariable;

  return (
    <Box
      component="div"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Tooltip title={tooltipValue}>
        <Chip
          label={label}
          icon={isClickable ? <FunctionsIcon /> : <FormatQuoteIcon />}
          onClick={isClickable ? handleClick : undefined}
          onDelete={props.deleteVariable ? handleDelete : undefined}
          variant={openMenu ? 'filled' : 'outlined'}
          ref={chipElRef}
          color={isClickable ? 'primary' : undefined}
        />
      </Tooltip>
      {isNbVariable ? (
        <Menu
          anchorEl={chipElRef.current}
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          autoFocus={false}
          PaperProps={{
            sx: {
              width: '250px',
            },
          }}
        >
          <ListItem>
            <ToggleButtonGroup fullWidth value={formatting} color="primary" onChange={handleChangeFormatting} exclusive>
              <ToggleButton value="number">
                <NumbersIcon />
                <Box
                  component="div"
                  sx={{
                    textTransform: 'none',
                    marginLeft: theme.spacing(1),
                  }}
                >
                  Number
                </Box>
              </ToggleButton>
              <ToggleButton value="letter">
                <AbcIcon />
                <Box
                  component="div"
                  sx={{
                    textTransform: 'none',
                    marginLeft: theme.spacing(1),
                  }}
                >
                  Letter
                </Box>
              </ToggleButton>
            </ToggleButtonGroup>
          </ListItem>
          <ListItem sx={{ display: 'flex', gap: theme.spacing(1) }}>
            <TextField
              error={errorPositiveInteger}
              helperText={errorPositiveInteger ? 'Positive integer or minimum value is 1' : undefined}
              type="text"
              label="Minimum number of characters"
              defaultValue={nbChars}
              fullWidth
              onChange={(e) => {
                const el = e.target as HTMLInputElement;
                const value = parseInt(el.value, 10);

                const min = parseInt(el.min, 10);
                const max = parseInt(el.max, 10);
                if (!isNaN(value) && value >= min && value <= max) {
                  setNbChars(value);
                  setErrorPositiveInteger(false);
                } else {
                  setErrorPositiveInteger(true);
                }
              }}
              onBlur={(e) => {
                const el = e.target as HTMLInputElement;
                const value = parseInt(el.value, 10);

                const min = parseInt(el.min, 10);
                const max = parseInt(el.max, 10);

                if (isNaN(value)) {
                  setNbChars(defaultParamsVariable.nbCharacters);
                  el.value = defaultParamsVariable.nbCharacters.toString();
                  setErrorPositiveInteger(false);
                } else if (value < min) {
                  setNbChars(min);
                  setErrorPositiveInteger(false);
                  el.value = min.toString();
                } else if (value > max) {
                  setErrorPositiveInteger(false);
                  setNbChars(max);
                  el.value = max.toString();
                }
              }}
              onClick={(e) => {
                e.stopPropagation();

                (e.target as HTMLElement)?.focus();
              }}
              onKeyDown={(e) => e.stopPropagation()}
              inputProps={{
                min: 1,
                max: 10,
                step: 1,
                // inputMode: 'numeric',
              }}
            />
          </ListItem>
          <Box component="div" sx={{ paddingTop: '0.5rem', px: '1rem' }}>
            {nbChars.valueOf() > 1 ? (
              <TextField
                type="text"
                label="Filling Character"
                defaultValue={fillChars}
                fullWidth
                onChange={(e) => {
                  const el = e.target;
                  const value = el.value.toString();

                  setFillChars(value);
                }}
                onBlur={(e) => {
                  const el = e.target;
                  let value = el.value.toString();

                  if (value.length !== 1) {
                    value = defaultParamsVariable.fillCharacter;
                  }

                  setFillChars(value);
                }}
                onClick={(e) => {
                  e.stopPropagation();

                  (e.target as HTMLElement)?.focus();
                }}
                onKeyDown={(e) => e.stopPropagation()}
                inputProps={{ maxLength: 1 }}
                disabled={nbChars.valueOf() <= 1 || isNaN(nbChars)}
              />
            ) : null}
          </Box>

          {textDirectionText && (
            <ListItem>
              <ToggleButtonGroup
                fullWidth
                value={direction}
                color="primary"
                onChange={(e, v: FormatingDirectionType) => {
                  if (v) setDirection(v);
                }}
                exclusive
              >
                <ToggleButton value="ltr">
                  <FormatTextdirectionLToRIcon />
                  <Box
                    component="div"
                    sx={{
                      textTransform: 'none',
                      marginLeft: theme.spacing(1),
                    }}
                  >
                    {textDirectionText[0]}
                  </Box>
                </ToggleButton>
                <ToggleButton value="rtl">
                  <FormatTextdirectionRToLIcon />
                  <Box
                    component="div"
                    sx={{
                      textTransform: 'none',
                      marginLeft: theme.spacing(1),
                    }}
                  >
                    {textDirectionText[1]}
                  </Box>
                </ToggleButton>
              </ToggleButtonGroup>
            </ListItem>
          )}
          <ListItem sx={{ display: 'flex', gap: theme.spacing(1) }}>
            <TextField
              type="text"
              label="Start at"
              defaultValue={startAt}
              fullWidth
              onChange={(e) => {
                const el = e.target as HTMLInputElement;
                const value = parseInt(el.value, 10);

                const min = parseInt(el.min, 10);

                if (!isNaN(value) && value >= min) {
                  setStartAt(value);
                }
              }}
              onBlur={(e) => {
                const el = e.target as HTMLInputElement;
                const value = parseInt(el.value, 10);

                const min = parseInt(el.min, 10);

                if (isNaN(value)) {
                  setStartAt(defaultParamsVariable.startAt);
                  el.value = defaultParamsVariable.startAt.toString();
                } else if (value < min) {
                  setStartAt(min);
                  el.value = min.toString();
                }
              }}
              onClick={(e) => {
                (e.target as HTMLElement)?.focus();
              }}
              onKeyDown={(e) => e.stopPropagation()}
              inputProps={{
                min: 0,
                step: 1,
                // inputMode: 'numeric',
              }}
              inputRef={inputStartAtElRef}
            />
            {!disableStepField[props.value] && (
              <TextField
                type="text"
                label="Step"
                defaultValue={step}
                fullWidth
                onChange={(e) => {
                  const el = e.target as HTMLInputElement;
                  const value = parseInt(el.value, 10);

                  const min = parseInt(el.min, 10);
                  if (!isNaN(value) && value >= min) {
                    setStep(value);
                  }
                }}
                onBlur={(e) => {
                  const el = e.target as HTMLInputElement;
                  const value = parseInt(el.value, 10);

                  const min = parseInt(el.min, 10);

                  if (isNaN(value)) {
                    setStep(defaultParamsVariable.step);
                    el.value = defaultParamsVariable.step.toString();
                  } else if (value < min) {
                    setStep(min);
                    el.value = min.toString();
                  }
                }}
                onClick={(e) => {
                  (e.target as HTMLElement)?.focus();
                }}
                onKeyDown={(e) => e.stopPropagation()}
                inputProps={{
                  min: 1,
                  step: 1,
                  // inputMode: 'numeric',
                }}
              />
            )}
          </ListItem>
        </Menu>
      ) : (
        <></>
      )}
    </Box>
  );
}

function variableValueToLabel(value: VariablesName): string {
  switch (value) {
    case '@column!': {
      return 'column';
    }

    case '@positionInCell!': {
      return 'position in cell';
    }

    case '@rackName!': {
      return 'rack name';
    }

    case '@level!': {
      return 'level';
    }

    case '@loadPattern!': {
      return 'load pattern';
    }

    case '@numberOfColumns!': {
      return 'number of columns';
    }

    case '@numberOfLevels!': {
      return 'number of levels';
    }

    case '@position!': {
      return 'position';
    }

    case '@positionInCellMultiLoad!': {
      return 'position in cell multi load';
    }

    case '@positionMultiLoad!': {
      return 'position multi load';
    }
  }

  // ts error triggered if we add a variable and not the corresponding label
  assert<Equals<typeof value, never>>();

  // eslint-disable-next-line no-console
  console.error('Unknown variable value', value);

  return value;
}

function variableValueToTooltip(value: VariablesName): string {
  switch (value) {
    case '@column!': {
      return 'Column number of the cell';
    }

    case '@positionInCell!': {
      return 'Position number considering the selected load pattern distributed on each cell';
    }

    case '@rackName!': {
      return 'Name of the rack';
    }

    case '@level!': {
      return 'Level of the cell';
    }

    case '@loadPattern!': {
      return 'Name of the load pattern';
    }

    case '@numberOfColumns!': {
      return 'Total number of columns in the rack';
    }

    case '@numberOfLevels!': {
      return 'Total number of levels in  each column';
    }

    case '@position!': {
      return 'Position number regarding the selected load pattern distributed on each level';
    }

    case '@positionInCellMultiLoad!': {
      return 'Position number considering all load patterns distributed on each cell';
    }

    case '@positionMultiLoad!': {
      return 'Position number considering all load patterns distributed on each level';
    }
  }

  // ts error triggered if we add a variable and not the corresponding label
  assert<Equals<typeof value, never>>();

  // eslint-disable-next-line no-console
  console.error('Unknown variable value', value);

  return value;
}

interface RenamePositionsMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose?: () => void;
  /** actions functions that can be called */
  actions: ActionsRack;
  nbSelectedCells: number;
}
export function RenamePositionsMenu(props: RenamePositionsMenuProps): JSX.Element {
  const { anchorEl, open, onClose, actions, nbSelectedCells } = props;

  const [name, setName] = useState('');
  const dispatch = useAppDispatch();

  const [valuesTextField, setValuesTextField] = useState<BlockDataPositionName[]>([]);
  const [posCursor, setPosCursor] = useState(valuesTextField.length);

  const [applyToAllLoads, setApplyToAllLoads] = useState(true);

  const handleChangeName = useCallback(
    (e: React.SyntheticEvent<Element, Event>, value: unknown, reason: string) => {
      if (e && typeof value === 'string') {
        setName(value);

        const el = e.target as HTMLInputElement;
        if (el) {
          const pos = el.selectionStart;
          const pos2 = el.selectionEnd;

          if (typeof pos === 'number' && pos === pos2) {
            const lastTypedChar = value[pos - 1];
            if (lastTypedChar === '@') {
              const newValue = value.slice(0, pos - 1);
              if (newValue) {
                const nv = [...valuesTextField];
                const newBlock: BlockDataPositionName = {
                  type: 'char',
                  value: newValue,
                };

                nv.splice(posCursor, 0, newBlock);
                setValuesTextField(nv);
                setPosCursor((state) => state + 1);
                setName(value.slice(pos - 1, value.length));
              }
            }
          }
        }
      }
    },
    [posCursor, valuesTextField]
  );

  const deleteValue = useCallback(
    (cursor = posCursor) => {
      const nv = [...valuesTextField];
      nv.splice(cursor, 1);
      setValuesTextField(nv);
    },
    [posCursor, valuesTextField]
  );

  const handleChangeVariable = useCallback((newVariableParams: VariableParams, index: number, value: VariablesName) => {
    setValuesTextField((state) => {
      if (!isEqual(state[index].params, newVariableParams) && state[index].value === value) {
        const nv = [...state];
        const variable = nv[index];
        variable.params = newVariableParams;

        return nv;
      }

      return state;
    });
  }, []);

  useEffect(() => {
    if (valuesTextField && valuesTextField.length) {
      localStorage.setItem('lastRulesCellsName', JSON.stringify(valuesTextField));
    }
  }, [valuesTextField]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const lastRulesCellsName = localStorage.lastRulesCellsName;
    if (lastRulesCellsName) {
      const lastRulesCellsNameParsed = JSON.parse(lastRulesCellsName) as unknown;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (isBlockDataPositionNames(lastRulesCellsNameParsed)) {
        setValuesTextField(lastRulesCellsNameParsed);
        setPosCursor(lastRulesCellsNameParsed.length);
      } else {
        // eslint-disable-next-line no-console
        console.error(`lastRulesCellsName is not a BlockDataPositionNames`, lastRulesCellsNameParsed);
      }
    }
  }, []);

  const valuesTextFieldStrings = useMemo(() => {
    return valuesTextField.map((block) => block.value);
  }, [valuesTextField]);

  const [assigningCellNames, setAssigningCellNames] = useState(false);
  const assignNameToCells = useCallback(
    (assignTo: 'selection' | 'all') => {
      setAssigningCellNames(true);

      dispatch(setLoadingStateAction({ newLoadingState: true }));

      const values = [...valuesTextField];

      if (name && name.length) {
        values.splice(posCursor, 0, { type: 'char', value: name });
        setValuesTextField(values);
        setPosCursor((state) => state + 1);
        setName('');
      }

      actions.assignNameToCells(assignTo, values, !applyToAllLoads);

      setAssigningCellNames(false);

      dispatch(setLoadingStateAction({ newLoadingState: false }));
    },
    [actions, applyToAllLoads, dispatch, name, posCursor, valuesTextField]
  );

  const resetNameToCells = useCallback(
    (assignTo: 'selection' | 'all') => {
      actions.assignNameToCells(assignTo, null, !applyToAllLoads);
    },
    [actions, applyToAllLoads]
  );

  const onCloseHandler = useCallback(() => {
    if (assigningCellNames) return;
    if (onClose) onClose();
  }, [assigningCellNames, onClose]);

  const autoCompleteRef = useRef<HTMLInputElement | null>(null);
  const handleAddNewValue = useCallback(
    (name?: React.SyntheticEvent<Element, Event>, valuesToConsider?: BlockDataPositionName[]) => {
      let el = name?.target as HTMLInputElement | undefined | null;
      if (!el) {
        el = autoCompleteRef.current;
      }

      if (!el) {
        // eslint-disable-next-line no-console
        console.error('No input filled in in the handleAddNewValue');

        return;
      }

      const value = el.value;

      // Check if the value is empty or white space, if yes then return
      if (!value || value === '') {
        return;
      }

      const nv = [...(valuesToConsider ?? valuesTextField)];

      const isVariable = isVariableName(value);
      const isNbVariable = isNumberVariableName(value);

      const newBlock: BlockDataPositionName = {
        type: !isVariable ? 'char' : isNbVariable ? 'nbVariable' : 'strVariable',
        value: value,
        params: isNbVariable ? defaultParamsVariable : undefined,
      };

      nv.splice(posCursor, 0, newBlock);

      setValuesTextField(nv);
      setPosCursor((prev) => prev + 1);

      setName('');
      el.value = '';
    },
    [posCursor, valuesTextField]
  );

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onCloseHandler}
      sx={{
        cursor: assigningCellNames ? 'wait' : undefined,
      }}
    >
      <Card
        sx={{
          background: assigningCellNames ? theme.palette.grey[300] : undefined,
          pointerEvents: assigningCellNames ? 'none' : undefined,
        }}
      >
        <CardContent sx={{ paddingBottom: 0 }}>
          <Stack direction="row" alignItems="center">
            <Autocomplete
              options={variablesNames}
              fullWidth
              inputValue={name}
              onInputChange={handleChangeName}
              onChange={(e, newValues, reason, detail) => {
                if (reason === 'removeOption') {
                  return;
                }

                const newValue = detail?.option as string;
                if (newValue) {
                  const nv = [...valuesTextField];
                  const isVariable = isVariableName(newValue);
                  const isNbVariable = isNumberVariableName(newValue);
                  const newBlock: BlockDataPositionName = {
                    type: !isVariable ? 'char' : isNbVariable ? 'nbVariable' : 'strVariable',
                    value: newValue,
                    params: isNbVariable ? defaultParamsVariable : undefined,
                  };

                  nv.splice(posCursor, 0, newBlock);
                  setValuesTextField(nv);
                }

                setPosCursor((prev) => prev + 1);
              }}
              onKeyDown={(e) => {
                const el = e.target as HTMLInputElement;
                const pos = el.selectionStart;
                const pos2 = el.selectionEnd;
                const value = el.value;

                el.style.width = `${getInputValueWidth(el) + 10}px`;

                if (typeof pos === 'number' && pos === pos2) {
                  if (pos === 0 && e.key === 'ArrowLeft') {
                    e.preventDefault();
                    e.stopPropagation();
                    setPosCursor((prev) => (prev > 0 ? prev - 1 : prev));

                    return;
                  } else if (pos === value.length && e.key === 'ArrowRight') {
                    e.preventDefault();
                    e.stopPropagation();
                    setPosCursor((prev) => (prev < valuesTextField.length ? prev + 1 : prev));

                    return;
                  } else if (pos === value.length && e.key === 'Delete') {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteValue(posCursor);

                    return;
                  } else if (e.key === 'Backspace') {
                    // e.preventDefault();
                    e.stopPropagation();
                    if (pos === 0) {
                      const nv = [...valuesTextField];
                      nv.splice(posCursor - 1, 1);
                      setValuesTextField(nv);
                      setPosCursor((prev) => prev - 1);

                      return;
                    }
                  } else if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    handleAddNewValue(e);

                    return;
                  }
                }
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    fullWidth
                    multiline
                    sx={{ overflowX: 'auto' }}
                    variant="outlined"
                    // onChange={handleChangeName}
                    helperText={
                      <>
                        Enter the new name of the cells. If you want to use a variable, select it in the list.
                        <HelpIconTooltip title="The input above can have strings as well as variables. Once applied, the variables are replaced by their value in the cell." />
                      </>
                    }
                    inputRef={autoCompleteRef}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <PositionNameAdornment
                            valuesTextField={valuesTextField}
                            deleteValue={deleteValue}
                            posCursor={posCursor}
                            setName={setName}
                            setPosCursor={setPosCursor}
                            setValuesTextField={setValuesTextField}
                            handleChangeVariable={handleChangeVariable}
                            startOrEnd="start"
                            inputRef={autoCompleteRef.current ? autoCompleteRef.current : undefined}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <PositionNameAdornment
                            valuesTextField={valuesTextField}
                            deleteValue={deleteValue}
                            posCursor={posCursor}
                            setName={setName}
                            setPosCursor={setPosCursor}
                            setValuesTextField={setValuesTextField}
                            handleChangeVariable={handleChangeVariable}
                            startOrEnd="end"
                            inputRef={autoCompleteRef.current ? autoCompleteRef.current : undefined}
                          />
                        </InputAdornment>
                      ),
                    }}
                    onBlur={(e) => {
                      handleAddNewValue(e);
                    }}
                  />
                );
              }}
              renderTags={(values, getTagProps, ownerState) => {
                return <></>;
              }}
              // we remove the first char (@) as well as the last char (!)
              getOptionLabel={(option) => variableValueToLabel(option as VariablesName)}
              freeSolo
              value={valuesTextFieldStrings}
              multiple
            />

            <Tooltip
              title={
                <>
                  <h3>Multi-load effect ({applyToAllLoads ? 'activated' : 'deactivated'})</h3>
                  Apply the new positions names to all the load patterns of the cells.
                  <br />
                  <br />
                  If unchecked, only the selected cell pattern will be modified.
                </>
              }
              sx={{ marginTop: '-18px' }}
            >
              <Checkbox
                checkedIcon={<SelectAllIcon color="primary" />}
                icon={<SelectAllIcon />}
                checked={applyToAllLoads}
                onChange={() => setApplyToAllLoads(!applyToAllLoads)}
              />
            </Tooltip>
          </Stack>
        </CardContent>
        <CardActions sx={{ float: 'right' }}>
          <Tooltip title="Resetting the name of the cell will generate a new default name for the cells.">
            <ButtonGroup color="inherit" variant="contained" size="small" disabled={assigningCellNames}>
              <Button
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => resetNameToCells('all')}
              >
                Reset All
              </Button>
              <Button
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => resetNameToCells('selection')}
                disabled={!nbSelectedCells}
              >
                Reset Selection
              </Button>
            </ButtonGroup>
          </Tooltip>

          <ButtonGroup color="primary" variant="contained" disabled={assigningCellNames}>
            <Button
              sx={{
                textTransform: 'none',
              }}
              onClick={() => assignNameToCells('all')}
              startIcon={<ReplyAllIcon sx={{ transform: 'rotate(180deg)' }} />}
            >
              Apply to All
            </Button>
            <Button
              sx={{
                textTransform: 'none',
              }}
              onClick={() => assignNameToCells('selection')}
              startIcon={<ReplyIcon sx={{ transform: 'rotate(180deg)' }} />}
              disabled={!nbSelectedCells}
            >
              Apply to Selection
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Popover>
  );
}
