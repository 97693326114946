import DeleteIcon from '@mui/icons-material/Delete';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import UpdateIcon from '@mui/icons-material/Update';
import * as Sentry from '@sentry/react';
import { clearLidarMapAction, clearMapImageAction, importLidarAction, updateMapImagePropertiesAction } from 'actions';
import type { AddDevice } from 'actions/circuit';
import {
  addDeviceAction,
  deleteAllCellTemplatesAction,
  importCellTemplatesAction,
  importCircuitAction,
  importLayersAction,
  restoreCircuitInitialStateAction,
  updateLayerAction,
} from 'actions/circuit';
import type { DeleteDevice, SaveDevice } from 'actions/devices';
import { deleteDeviceAction, saveDeviceAction } from 'actions/devices';
import { saveSchedulerConfigurationOnDisk } from 'components/export/save-project/save-scheduler-configuration';
import { syncYJSLocalToRemote } from 'components/presence/utils/syncYjsDoc';
import { getAvailableSchedulerConfig } from 'components/toolboxes/simulation/get-available-scheduler-config';
import { setCustomSteps, setFlows, setStations } from 'flows/flows';
import { cloneDeep, isArray, isEqual } from 'lodash';
import type { CircuitDevice, CircuitShapes, DeviceType, GeoJsonCircuit } from 'models/circuit';
import { DISPLAY_UNIT_FACTOR } from 'models/drawings';
import type { CircuitImageDataToSave } from 'models/images';
import { LidarPosition } from 'models/maps';
import { MAX_EDITING_USERS, localDoc, projectHost, setProjectHost } from 'multiplayer/globals';
import {
  projectSliceInitialState,
  setDescription,
  setDevicePrefManagement,
  setOpcData,
  setProjectName,
  setVersion,
} from 'project/project';
import { getInitialStateCellTemplates } from 'reducers/circuit/reducer';
import type { LayersDataContainer } from 'reducers/circuit/state';
import { setLoadingStateAction } from 'reducers/core/reducer';
import { addCircuitImage } from 'reducers/images/images';
import type { FilterState } from 'reducers/local/filters.reducer';
import {
  addMapImageFilterAction,
  changeOpacityFilterAction,
  changeStateFilterAction,
  clearMapImageFilterAction,
  getFilterDataInitialState,
} from 'reducers/local/filters.reducer';
import { ActionCreators } from 'redux-undo';
import { clearAllRobotPaths } from 'robots/robots';
import { restoreInitialRouteState } from 'routes/routes';
import { CircuitService, isGeoJsonCircuit, resetDisplayPriority } from 'services/circuit.service';
import { createSimulationService, waitForSimulationService } from 'services/simulation.service';
import {
  getMatchingSimulationVersion,
  latestBalyoSimulationVersion,
} from 'simulation/BalyoSimulation/available-balyo-simulation-versions';
import { getOpcConfig } from 'simulation/opc';
import { checkConfigsAreTheSame } from 'simulation/scheduler/check-scheduler-config';
import {
  checkProperSchedulerConfigPath,
  schedulerConfigFilePath,
  schedulerConfigSchema,
} from 'simulation/scheduler/scheduler-config';
import {
  setBalyoSimulationVersion,
  setEnableCharging,
  setMaxDuration,
  setRobotsInitialBatteryLevel,
  setSchedulerConfiguration,
} from 'simulation/simulation';
import type { SimulationErrorResponse } from 'simulation/states';
import { setTriggers } from 'simulation/triggers';
import store from 'store';
import { setImmediateTimeoutPromise } from 'utils/browser';
import { checkStorageQuota } from 'utils/check-storage-quota';
import { checkUnicityTrucksIpsAndDisplayError } from 'utils/check-unicity-truck-ips';
import { devicePositionToCpxStr } from 'utils/circuit';
import { checkNextFreeId } from 'utils/circuit/check-next-free-id';
import { testDuplicateIds } from 'utils/circuit/heal-circuit';
import { getLegacyLayersInitial } from 'utils/circuit/initialize-layer-groups';
import { checkIfTurnsAreNotComputedCorrectly } from 'utils/circuit/turns';
import { checkCellTemplatesHaveMaximumLoadHeight, fixCellTemplatesMaximumLoadHeight } from 'utils/circuit/utils';
import { frequencyDefaultValue } from 'utils/config';
import { getDeviceSamplePeriodPrefName } from 'utils/device';
import { LFSFilesNotPulledError, NotFoundError } from 'utils/errors';
import { lfsFileHeader } from 'utils/git';
import { theme } from 'utils/mui-theme';
import { cpxStrToPosition } from 'utils/parse-cpx';
import { PreferencesService, convertBlobUrlToUInt8Array, loadFileAsUint8Array } from 'utils/preferences';
import { checkCellTemplatesIdsFixNeeded, fixCellTemplatesIds } from 'utils/upgrade-circuit/upgrade-2.7';
import { checkNeedUpgradeCircuit, upgradeCircuit } from 'utils/upgrade-circuit/upgrade-circuit';
import { testUpgradeRackSlotIdNeeded, updateCircuitSimu } from 'utils/upgrade-circuit/upgrade-rack-slot-id';
import packageJson from '../../package.json';
import { ConfirmUtil } from './confirm.service';
import { SnackbarUtils } from './snackbar.service';

let loadedCircuitName: string | undefined;

export function getLoadedCircuitName(): string {
  return loadedCircuitName || PreferencesService.getCircuitName();
}

interface LoadProjectType {
  keepDirectoryHandle?: boolean;
  circuitName?: string;
  newCircuit?: boolean;
  loadCircuit?: boolean;
  simulationVersion?: string;
}

/**
 * This function aims to load a project, it reads a geojson circuit file, restore the store to a "blank" state and then dispatch
 * actions to put the store in the state of the loaded project.
 * @param keepDirectoryHandle wether or not we keep the directory handle
 * @param circuitName tje name of the circuit to load
 * @param newCircuit wether or not the circuit is a new circuit (we need to create it if yes)
 * @returns a promise if we successfully loaded the project
 */
export async function loadProject({
  keepDirectoryHandle,
  circuitName,
  newCircuit,
  simulationVersion,
}: LoadProjectType): Promise<boolean | void> {
  delete localStorage.drawnBackgroundLidar;

  const dispatch = store.dispatch;

  dispatch(setLoadingStateAction({ newLoadingState: true }));

  // we try to load the preferences of the project
  try {
    await PreferencesService.loadDirectoryPrefs({ circuitName, keepDirectoryHandle });
  } catch (e) {
    dispatch(setLoadingStateAction({ newLoadingState: false }));
    if (e instanceof Error && e.name === 'NotAllowedError') {
      SnackbarUtils.error(
        `It looks like you prevented Road Editor to access your files. To enable it, please go to the site settings (lock icon at the left of the website URL) and allow access to your files.`
      );
    } else if (e instanceof Error && e.name === 'NotFoundError') {
      if (e instanceof NotFoundError && e.fileName) {
        SnackbarUtils.error(`The file or folder "${e.fileName}" is missing in the project folder`);
      } else {
        SnackbarUtils.error(`A file is missing in the project folder`);
      }

      // eslint-disable-next-line no-console
      console.warn(`A file is missing in the project folder`, { e });
    } else if (e instanceof Error && e.name !== 'AbortError') {
      // eslint-disable-next-line no-console
      console.error(`Error while loading project: ${e.message}`, {
        e,
      });
      SnackbarUtils.error(`The selected folder was not a project folder.`);
    }

    if (e instanceof Error) {
      throw e;
    }
  }

  /* Update the circuit name so the UI doesn't bug */
  let circuitFileToLoad: File | undefined;
  if (circuitName) {
    loadedCircuitName = circuitName;
    if (!newCircuit) {
      circuitFileToLoad = (await PreferencesService.getFileByPath(`Circuits/${circuitName}`)) as File;
    }
  } else {
    loadedCircuitName = PreferencesService.getCircuitName();

    const loadedCircuitExist = await PreferencesService.getFileByPath(`Circuits/${loadedCircuitName}`);
    if (!loadedCircuitExist) {
      const newDefaultCircuit =
        PreferencesService.getCircuitName().replace('.geojson', '') ?? PreferencesService.getAvailableCircuitsName()[0];

      loadedCircuitName = newDefaultCircuit ? `${newDefaultCircuit}.geojson` : PreferencesService.getProjectName();
      circuitFileToLoad = (await PreferencesService.getFileByPath(`Circuits/${loadedCircuitName}`)) as File;
      await PreferencesService.setPreferenceValue('general/circuitFileName', `${newDefaultCircuit}.xml`);
    }
  }

  const modelCounts = PreferencesService.getTrucks().reduce(
    (acc, truck) => {
      acc[truck.modelName] = (acc[truck.modelName] || 0) + 1;

      return acc;
    },
    {} as Record<string, number>
  );

  const sentryTransationData = {
    circuitName: circuitName || PreferencesService.getCircuitName(),
    projectName: PreferencesService.getProjectName(),
    sdkVersion: PreferencesService.getSDKVersion(),
    coreVersion: PreferencesService.getNEW4XCoreVersion(),
  };

  Sentry.withScope((scope) => {
    scope.setTags(sentryTransationData);

    Sentry.startSpan({ name: 'load project', attributes: sentryTransationData }, (span) => {
      return;
    });
  });

  if (!window.dataLayer) window.dataLayer = [];

  window?.dataLayer?.push({ ...sentryTransationData, modelCounts, event: 'project_loaded' });

  dispatch(setProjectName(PreferencesService.getProjectName()));

  const sdkVersion = PreferencesService.getSDKVersion() || latestBalyoSimulationVersion.version;
  const balyoSimulationVersion = simulationVersion ?? getMatchingSimulationVersion(sdkVersion).version;
  dispatch(setBalyoSimulationVersion(balyoSimulationVersion));

  // eslint-disable-next-line no-console
  console.log({
    sdkVersion,
    balyoSimulationVersion,
  });

  createSimulationService({
    balyoSimulationVersion,
  });
  await waitForSimulationService;

  checkStorageQuota();

  await setImmediateTimeoutPromise();

  /**
   * First the idea is to put the store in a "blank" clean state
   **/

  dispatch(clearAllRobotPaths());

  dispatch(restoreInitialRouteState());

  dispatch(restoreCircuitInitialStateAction());

  dispatch(clearMapImageAction({}));
  dispatch(clearMapImageFilterAction());
  dispatch(clearLidarMapAction({ name: LidarPosition.Background }));
  dispatch(clearLidarMapAction({ name: LidarPosition.Foreground }));

  resetDisplayPriority();

  /**
   * Then we load the circuit file
   */

  let circuitFile: string | undefined;
  let circuitGeoJSON: GeoJsonCircuit | undefined;
  if (!newCircuit) {
    try {
      try {
        circuitFile = await PreferencesService.loadCircuitFile(circuitFileToLoad);
      } catch (e) {}

      if (circuitFile) {
        if (circuitFile.startsWith(lfsFileHeader)) {
          throw new LFSFilesNotPulledError();
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const obj = JSON.parse(circuitFile);

        const circuitNeedUpgrade = checkNeedUpgradeCircuit(obj as GeoJsonCircuit);
        if (circuitNeedUpgrade) {
          const currentVersion = packageJson.version;
          const circuitVersion = obj.properties.roadEditorVersion as string;
          // eslint-disable-next-line no-alert
          const upgrade = window.confirm(
            `The circuit needs to be upgraded. It has been made with Road Editor ${circuitVersion} and you currently use Road Editor ${currentVersion}.\nDo you want to upgrade it?`
          );

          if (upgrade) {
            const upgradedCircuit = upgradeCircuit(obj as GeoJsonCircuit);
            circuitGeoJSON = upgradedCircuit;
          }
        }

        const cellTemplatesFixNeeded = checkCellTemplatesIdsFixNeeded(obj);
        if (cellTemplatesFixNeeded) {
          const upgradedCircuit = fixCellTemplatesIds(obj);
          circuitGeoJSON = upgradedCircuit;

          // eslint-disable-next-line no-console
          console.log('Cell templates ids upgraded');

          SnackbarUtils.info('Cell templates ids upgraded');
        }

        const doCellTemplateHaveMaximumLoadHeight = checkCellTemplatesHaveMaximumLoadHeight(obj);

        if (!doCellTemplateHaveMaximumLoadHeight) {
          const upgradedCircuit = fixCellTemplatesMaximumLoadHeight(obj);

          circuitGeoJSON = upgradedCircuit;

          // eslint-disable-next-line no-console
          console.log('Property maximumLoadHeight added to cell templates');
        }

        const testGeoJsonCircuit = isGeoJsonCircuit(circuitGeoJSON ?? obj);

        if (!testGeoJsonCircuit) {
          SnackbarUtils.error(
            `We detected a major issue in the circuit file. We still loaded it but unexpected behavior may occur.`,
            {
              persist: true,
            }
          );
        }

        if (!circuitGeoJSON) {
          circuitGeoJSON = obj as GeoJsonCircuit;
        }

        if (circuitGeoJSON) {
          const roadEditorVersion = packageJson.version;
          const circuitVersion = circuitGeoJSON.properties.roadEditorVersion;
          if (roadEditorVersion !== circuitVersion) {
            SnackbarUtils.info(
              `The circuit version (${circuitVersion}) has not been produced by the current Road Editor version (${roadEditorVersion}).`
            );
          }
        }
      } else {
        const circuitName = PreferencesService.getCircuitName();

        SnackbarUtils.warning(`No circuit 'Circuits\\${circuitName}' found thus no shape has been loaded.`);
      }
    } catch (e) {
      let msg = `Road Editor failed to import the circuit file. It may be corrupted.`;

      if (e instanceof LFSFilesNotPulledError) {
        msg = `The circuit file is corrupted because the LFS files have not been pulled.`;
      }

      // eslint-disable-next-line no-console
      console.error(msg, e);

      SnackbarUtils.error(msg);

      return finishLoadingProject();
    }
  }

  const loadingPreferenceToLibTrack = loadPreferencesToLibTrack();

  // we load all the shapes
  const shapes: CircuitShapes = CircuitService.convertFeatureArrayCoordinates(
    circuitGeoJSON && circuitGeoJSON.features ? circuitGeoJSON.features.flatMap((layer) => layer.features) : [],
    (value) => value * DISPLAY_UNIT_FACTOR
  ) as CircuitShapes;

  if (circuitGeoJSON) {
    const nextFreeId = circuitGeoJSON.properties.nextFreeId;
    const nextFreeIdArray = circuitGeoJSON.properties.nextFreeIdArray;

    window.nextFreeId = nextFreeId > (nextFreeIdArray?.[0] || 0) ? nextFreeId : nextFreeIdArray?.[0] || 0;
    const newNextFreeIdArray = nextFreeIdArray ?? (Array(MAX_EDITING_USERS).fill(0) as number[]);
    newNextFreeIdArray[0] = window.nextFreeId;
    window.nextFreeIdArray = newNextFreeIdArray;
  }

  // we load all the layers and layer groups
  const layers: LayersDataContainer = circuitGeoJSON?.properties?.layers || getLegacyLayersInitial();
  Object.values(layers.layers).forEach((layer, index) => {
    if (layer.order === undefined) {
      layer.order = index;
    }

    if (layer.color === 'brown') {
      // reason: #47564
      layer.color = '#8B4513';
    }
  });
  try {
    dispatch(importLayersAction(layers));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('The layers could not be imported', e);

    SnackbarUtils.error(`The layers could not be imported.`);

    return finishLoadingProject();
  }

  // we select the layer that was previsouly selected
  dispatch(
    updateLayerAction({
      layerId: layers.selectedLayer,
      selectedLayer: layers.selectedLayer,
    })
  );

  // we remove all the cell templates and import the ones of the circuit
  dispatch(deleteAllCellTemplatesAction());
  const cellTemplates = circuitGeoJSON?.properties?.cellTemplates || getInitialStateCellTemplates().entities;
  dispatch(importCellTemplatesAction({ cellTemplates }));

  // we put the filters in the previous state of the circuit
  const filters: FilterState | undefined = circuitGeoJSON?.properties?.filters || getFilterDataInitialState();
  if (filters) {
    dispatch(
      changeStateFilterAction({
        ...getFilterDataInitialState(),
        ...filters,
      })
    );

    dispatch(
      changeOpacityFilterAction({
        ...getFilterDataInitialState(),
        ...filters,
      })
    );
  }

  // Here we load circuit version and description from GeaoJSON file to be able to read them
  if (circuitGeoJSON && circuitGeoJSON.properties) {
    const versionToSet = circuitGeoJSON.properties.circuitVersion || projectSliceInitialState.circuitVersion;
    const actionSetVersion = setVersion(versionToSet);
    dispatch(actionSetVersion);
    dispatch(
      setDescription(circuitGeoJSON.properties.circuitDescription || projectSliceInitialState.circuitDescription)
    );
  }

  // Upgrade layout image support from old version
  if (circuitGeoJSON && circuitGeoJSON.properties && circuitGeoJSON.properties.mapImageData) {
    circuitGeoJSON.properties.mapImageArray = [circuitGeoJSON.properties.mapImageData];
    delete circuitGeoJSON.properties.mapImageData;
  }

  // we load the layout image (map image)
  if (circuitGeoJSON && circuitGeoJSON.properties && circuitGeoJSON.properties.mapImageArray) {
    const mapImages = circuitGeoJSON.properties.mapImageArray;

    mapImages?.forEach(async (mapImage) => {
      if (mapImage.name && mapImage.height) {
        const mapImageFile = await PreferencesService.getFileByPath(`MAP/${mapImage.name}`);

        if (mapImageFile) {
          const mapImageUrl = window.URL.createObjectURL(mapImageFile);
          if (mapImage.originalHeight && mapImage.originalWidth) {
            dispatch(
              updateMapImagePropertiesAction({
                properties: {
                  URL: mapImageUrl,
                  name: mapImage.name,
                  height: mapImage.height,
                  x: mapImage.x,
                  y: mapImage.y,
                  scaling: mapImage.scaling,
                  originalHeight: mapImage.originalHeight,
                  originalWidth: mapImage.originalWidth,
                },
              })
            );
          } else {
            const image = new Image();
            image.src = mapImageUrl;
            image.onload = () => {
              dispatch(
                updateMapImagePropertiesAction({
                  properties: {
                    URL: mapImageUrl,
                    name: mapImage.name,
                    height: mapImage.height,
                    x: mapImage.x,
                    y: mapImage.y,
                    scaling: mapImage.scaling,
                    originalHeight: image.height,
                    originalWidth: image.width,
                  },
                })
              );
            };
          }

          dispatch(
            addMapImageFilterAction({
              name: mapImage.name,
            })
          );
        } else {
          // eslint-disable-next-line no-console
          console.warn(`Layout image "${mapImage.name}" not found and therefore not loaded.`);

          SnackbarUtils.warning(`The layout image "${mapImage.name}" has not been found.`);
        }
      }
    });
  }

  // we load the lidar map file
  let mapFile: Awaited<ReturnType<typeof PreferencesService.loadMapFile>> | undefined;
  try {
    mapFile = await PreferencesService.loadMapFile();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error while loading map file', e);
  }

  if (mapFile?.mapDataTxt && mapFile.mapDataTxt.length) {
    const { mapDataTxt, mapNameGeo } = mapFile;
    const mapFileArr = mapDataTxt.split('\n');
    dispatch(
      importLidarAction({ position: LidarPosition.Background, coords: mapFileArr, name: mapNameGeo || undefined })
    );
  } else {
    SnackbarUtils.warning(`The navigation map has not been found.`);
  }

  // we load the lidar map obstacle file
  const mapObstacle = circuitGeoJSON?.properties?.mapObstacleData;
  if (mapObstacle) {
    const mapObstacleName = mapObstacle.name.replace('.geo', '.txt');
    const mapObstacleFile = await PreferencesService.getFileByPath(`MAP/${mapObstacleName}`);

    if (mapObstacleFile) {
      const mapObstacleFileContent = await mapObstacleFile.text();
      const mapObstacleFileArr = mapObstacleFileContent.split('\n');

      dispatch(
        importLidarAction({
          position: LidarPosition.Foreground,
          coords: mapObstacleFileArr,
          name: mapObstacleName,
        })
      );
    } else {
      SnackbarUtils.warning(`The map obstacle has not been found.`);
    }
  }

  // we load the circuit images
  if (circuitGeoJSON && circuitGeoJSON.properties && circuitGeoJSON.properties.circuitImageArray) {
    const circuitImages = circuitGeoJSON.properties.circuitImageArray;

    circuitImages?.forEach(async (circuitImage: CircuitImageDataToSave) => {
      if (circuitImage.name) {
        const directoryHandle = await PreferencesService.getDirectoryHandle();

        if (directoryHandle) {
          const circuitsDirHandle = await directoryHandle.getDirectoryHandle('Circuits');
          const circuitImageFolderHandle = await circuitsDirHandle.getDirectoryHandle('Images');
          const circuitImageFileHandle = await circuitImageFolderHandle.getFileHandle(`${circuitImage.name}`);
          const circuitImageFile = await circuitImageFileHandle.getFile();

          if (circuitImageFile) {
            const imageUrl = window.URL.createObjectURL(circuitImageFile);
            const image = new Image();
            image.src = imageUrl;
            image.onload = () => {
              dispatch(
                addCircuitImage({
                  id: circuitImage.id,
                  name: circuitImage.name,
                  url: imageUrl,
                  width: image.width,
                  height: image.height,
                })
              );
            };
          }
        }
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Circuit image "${circuitImage.name}" not found and therefore not loaded.`);

        SnackbarUtils.warning(`The circuit image "${circuitImage.name}" has not been found.`);
      }
    });
  }

  // loading all the shapes
  dispatch(importCircuitAction({ shapes }));

  await checkProperSchedulerConfigPath();

  const schedulerConfigFile = await PreferencesService.getFileByPath(`${schedulerConfigFilePath}`);
  if (schedulerConfigFile) {
    try {
      const schedulerConfigContent = await schedulerConfigFile.text();
      const schedulerConfig = schedulerConfigSchema.safeParse(JSON.parse(schedulerConfigContent));
      if (!schedulerConfig.success) {
        throw new Error('Failed to parse the scheduler config');
      }

      let configName = circuitGeoJSON?.properties?.schedulerConfiguration || 'standard';
      if (configName && configName !== 'custom') {
        // we check that the configuration is still the same
        const availableSchedulerConfigs = getAvailableSchedulerConfig();
        const config = availableSchedulerConfigs.find((config) => config.name === configName);

        if (!config || !config.configuration || !checkConfigsAreTheSame(config.configuration, schedulerConfig.data)) {
          configName = 'custom';

          SnackbarUtils.info(`The scheduler configuration has been reset to "custom".`);
        }
      }

      dispatch(setSchedulerConfiguration(configName));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Failed to parse the scheduler config', e);

      SnackbarUtils.error('The scheduler configuration is corrupted. It has been ignored.');
    }
  } else {
    // eslint-disable-next-line no-console
    console.log('No scheduler config file found, setting it to standard');

    const dirHandle = await PreferencesService.getDirectoryHandle();
    if (dirHandle) {
      await saveSchedulerConfigurationOnDisk(dirHandle);
    } else {
      // eslint-disable-next-line no-console
      console.error('No directory handle found');
    }
  }

  if (circuitGeoJSON?.properties?.simulationParameters?.maxDuration !== undefined) {
    dispatch(setMaxDuration(circuitGeoJSON.properties.simulationParameters.maxDuration));
  }

  if (circuitGeoJSON?.properties?.simulationParameters?.robotsBatteryLevel !== undefined) {
    dispatch(
      setRobotsInitialBatteryLevel({ robots: circuitGeoJSON.properties.simulationParameters.robotsBatteryLevel })
    );
  }

  if (circuitGeoJSON?.properties.simulationParameters?.customSteps !== undefined) {
    dispatch(setCustomSteps(circuitGeoJSON.properties.simulationParameters.customSteps));
  }

  if (circuitGeoJSON?.properties.simulationParameters?.enableCharging !== undefined) {
    dispatch(setEnableCharging(circuitGeoJSON.properties.simulationParameters.enableCharging));
  }

  // check next free id
  const [checkNextFreeIdOk, newNextFreeId] = checkNextFreeId();
  if (!checkNextFreeIdOk) {
    // eslint-disable-next-line no-console
    console.error(`Next free id is not correct, we set it to ${newNextFreeId}, former value: ${window.nextFreeId}`);

    window.nextFreeId = newNextFreeId;

    SnackbarUtils.warning(`The ids of the shapes have been updated.`);
  }

  // check duplicate id
  const isThereDuplicateIds = testDuplicateIds();
  if (isThereDuplicateIds) {
    // eslint-disable-next-line no-console
    console.error('There are duplicate ids in the circuit');

    SnackbarUtils.error(`The circuit may be corrupted, we have detected duplicate ids.`);
  }

  // we set whether road manages the devices in the preferences or not
  if (circuitGeoJSON) {
    const enableDevicePrefManagement = circuitGeoJSON.properties.enableDevicePrefManagement;
    /**
     * Default value = true
     */
    dispatch(setDevicePrefManagement(enableDevicePrefManagement === undefined ? true : enableDevicePrefManagement));
  }

  dispatch(setStations(circuitGeoJSON?.properties?.stations ?? []));

  dispatch(setFlows(circuitGeoJSON?.properties?.flows ?? []));

  dispatch(setTriggers(circuitGeoJSON?.properties?.triggers ?? []));

  await loadingPreferenceToLibTrack;

  Sentry.getActiveSpan()?.end();

  if (circuitGeoJSON) {
    const needUpgradeSlotIdMissing = testUpgradeRackSlotIdNeeded(circuitGeoJSON);
    if (needUpgradeSlotIdMissing) {
      updateCircuitSimu();
    }
  }

  //Check if all turns are correctly computed
  const turnsToComputeAgain = checkIfTurnsAreNotComputedCorrectly();

  if (turnsToComputeAgain.length > 0) {
    // eslint-disable-next-line no-console
    console.info(
      `${turnsToComputeAgain.length} turns are not ok and need to be recomputed. To do this use the function checkAndComputeAgainWrongTurns()`
    );
  }

  //We load the opcData
  const opcData = await getOpcConfig();

  if (opcData) {
    dispatch(setOpcData(opcData));
  }

  return await finishLoadingProject();
}

export async function loadPreferencesToLibTrack(): Promise<boolean> {
  if (!PreferencesService.arePreferencesFullyLoaded()) {
    // eslint-disable-next-line no-console
    console.error('Preferences are not fully loaded in loadPreferencesToLibTrack');

    return false;
  }

  const xmlStrFiles = PreferencesService.getPreferencesXmlFiles();

  const simulationServiceModule = await import('../services/simulation.service.ts');
  // we wait for the service to be ready
  await simulationServiceModule.waitForSimulationService;

  const simulationService = simulationServiceModule.simulationService;
  if (!simulationService) {
    // eslint-disable-next-line no-console
    console.error('SimulationService is not defined');

    return false;
  }

  let displayErrorInitSimulationService = false;
  try {
    const resStrPtr = await simulationService._WEBSIMU_WasmWrapper_initialize();
    const resStr = await simulationService.UTF8ToString(resStrPtr);
    let res: SimulationErrorResponse | undefined;
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-assignment
      res = JSON.parse(resStr);
    } catch (e) {}

    // check disabled while basile fixes the lib
    // if (!res || res.errorID !== 0) {
    //   throw new Error(
    //     `Failing initializing the simulation service, errorID: ${res?.errorID}, error: ${res?.error}, raw response: ${resStr}, pointer value: ${resStrPtr}`
    //   );
    // }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('libTrack._WEBSIMU_WasmWrapper_initialize() failed', e);

    displayErrorInitSimulationService = true;
  }

  try {
    const resStrPtr = await simulationService._PREF_WasmWrapper_Initialize();
    const resStr = await simulationService.UTF8ToString(resStrPtr);
    let res: SimulationErrorResponse | undefined;
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-assignment
      res = JSON.parse(resStr);
    } catch (e) {}

    // check disabled while basile fixes the lib
    // if (!res || res.errorID !== 0) {
    //   throw new Error(
    //     `Failing initializing the simulation service, errorID: ${res?.errorID}, error: ${res?.error}, raw response: ${resStr}, pointer value: ${resStrPtr}`
    //   );
    // }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('libTrack._PREF_WasmWrapper_Initialize() failed', e);

    displayErrorInitSimulationService = true;
  }

  if (displayErrorInitSimulationService) {
    SnackbarUtils.error('Error initializing the simulation service');
  }

  if (xmlStrFiles) {
    // we load the dictionaries into the libtrack
    for (let i = 0; i < xmlStrFiles.installDictionaries.length; i++) {
      const installDictionary = xmlStrFiles.installDictionaries[i];
      try {
        const installDictionaryPtr = await simulationService.allocateUTF8(installDictionary);
        const resStrPtr = await simulationService._PREF_WasmWrapper_loadInstallDictionary(installDictionaryPtr);
        const resStr = await simulationService.UTF8ToString(resStrPtr);
        await simulationService._OS_WasmWrapper_free(installDictionaryPtr);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = JSON.parse(resStr);

        if (!res || res.errorID !== 0) {
          // eslint-disable-next-line no-console
          console.warn(
            `Failing loading the install dictionary preferences, errorID: ${res.errorID}, error: ${res.error}`
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`libTrack._PREF_WasmWrapper_loadInstallDictionary() failed`, e);
      }
    }

    for (let i = 0; i < xmlStrFiles.modelsDictionaries.length; i++) {
      const modelDictionary = xmlStrFiles.modelsDictionaries[i];
      try {
        const modelDictionaryPtr = await simulationService.allocateUTF8(modelDictionary);
        const resStrPtr = await simulationService._PREF_WasmWrapper_loadModelDictionary(modelDictionaryPtr);
        const resStr = await simulationService.UTF8ToString(resStrPtr);
        simulationService._OS_WasmWrapper_free(modelDictionaryPtr);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = JSON.parse(resStr);

        if (!res || res.errorID !== 0) {
          // eslint-disable-next-line no-console
          console.warn(
            `Failing loading the model dictionary preferences, errorID: ${res.errorID}, error: ${res.error}`
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`libTrack._PREF_WasmWrapper_loadModelDictionary() failed`, e);
      }
    }

    for (let i = 0; i < xmlStrFiles.trucksDictionaries.length; i++) {
      const truckDictionary = xmlStrFiles.trucksDictionaries[i];
      try {
        const truckDictionaryPtr = await simulationService.allocateUTF8(truckDictionary);
        const resStrPtr = await simulationService._PREF_WasmWrapper_loadTruckDictionary(truckDictionaryPtr);
        const resStr = await simulationService.UTF8ToString(resStrPtr);
        simulationService._OS_WasmWrapper_free(truckDictionaryPtr);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = JSON.parse(resStr);

        if (!res || res.errorID !== 0) {
          // eslint-disable-next-line no-console
          console.warn(
            `Failing loading the truck dictionary preferences, errorID: ${res.errorID}, error: ${res.error}`
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`libTrack._PREF_WasmWrapper_loadTruckDictionary() failed`, e);
      }
    }

    // we load the install preferences into the libtrack
    try {
      const installPreferencesPtr = await simulationService.allocateUTF8(xmlStrFiles.install);
      const resStrPtrt = await simulationService._PREF_WasmWrapper_loadInstallPref(installPreferencesPtr);
      const resStr = await simulationService.UTF8ToString(resStrPtrt);
      simulationService._OS_WasmWrapper_free(installPreferencesPtr);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = JSON.parse(resStr);

      if (!res || res.errorID !== 0) {
        // eslint-disable-next-line no-console
        console.warn(`Failing loading the install preferences, errorID: ${res.errorID}, error: ${res.error}`);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`libTrack._PREF_WasmWrapper_loadInstallPref() failed`, e);
    }

    // we load the truck preferences into the libtrack
    for (let i = 0; i < xmlStrFiles.trucks.length; i++) {
      const truck = xmlStrFiles.trucks[i];
      try {
        const truckPtr = await simulationService.allocateUTF8(truck.xml);
        const truckSerialPtr = await simulationService.allocateUTF8(truck.serial);
        const resStrPtr = await simulationService._PREF_WasmWrapper_loadTruckPref(truckPtr, truckSerialPtr);
        const resStr = await simulationService.UTF8ToString(resStrPtr);

        simulationService._OS_WasmWrapper_free(truckPtr);
        simulationService._OS_WasmWrapper_free(truckSerialPtr);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = JSON.parse(resStr);

        if (!res || res.errorID !== 0) {
          // eslint-disable-next-line no-console
          console.error(
            `Failing loading the truck "${JSON.stringify(truck, null, 2)}" preferences, errorID: ${
              res.errorID
            }, error: ${res.error}`
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`libTrack._PREF_WasmWrapper_loadTruckPref() failed`, e);
      }
    }

    // we load the model preferences into the libtrack
    for (let i = 0; i < xmlStrFiles.models.length; i++) {
      const model = xmlStrFiles.models[i];
      try {
        const modelPtr = await simulationService.allocateUTF8(model.xml);
        const modelNamePtr = await simulationService.allocateUTF8(model.model);
        const resStrPtr = await simulationService._PREF_WasmWrapper_loadModelPref(modelPtr, modelNamePtr);
        const resStr = await simulationService.UTF8ToString(resStrPtr);

        simulationService._OS_WasmWrapper_free(modelPtr);
        simulationService._OS_WasmWrapper_free(modelNamePtr);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = JSON.parse(resStr);

        if (!res || res.errorID !== 0) {
          // eslint-disable-next-line no-console
          console.error(
            `Failing loading the model "${model}" preferences, errorID: ${res.errorID}, error: ${res.error}`
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`libTrack._PREF_WasmWrapper_loadModelPref() failed`, e);
      }
    }
  }

  try {
    await simulationService._RTE_WasmWrapper_initialize();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`libTrack._RTE_WasmWrapper_initialize() failed`, e);

    SnackbarUtils.error('Failed to initialize the simulation service');
  }

  (window as any).simulationService = simulationService;

  return true;
}

async function finishLoadingProject(): Promise<boolean> {
  const dispatch = store.dispatch;

  const isMultiplayer = store.getState().multiplayer.multiplayer;

  if (!projectHost) {
    setProjectHost(true);
  }

  if (isMultiplayer) {
    const updatedShapesActionsArrayType = localDoc.getArray('updatedShapesActions');
    updatedShapesActionsArrayType.delete(0, updatedShapesActionsArrayType.length);

    const localNextFreeIdArray = localDoc.getArray('nextFreeId');
    localNextFreeIdArray.delete(0, localNextFreeIdArray.length);
    localNextFreeIdArray.insert(0, window.nextFreeIdArray ?? Array(MAX_EDITING_USERS).fill(0));

    const localCircuitMap = localDoc.getMap('circuit');
    localCircuitMap.delete('circuit');
    localCircuitMap.set('circuit', store.getState().circuit.present);

    const localLidarMap = localDoc.getMap('lidar');
    localLidarMap.delete('lidar');
    localLidarMap.delete('background-uInt8Array');

    if (localLidarMap) {
      localLidarMap.set('lidar', store.getState().maps.lidar);

      const mapFilePath = store.getState().maps.lidar['background-lidar']?.name;
      if (mapFilePath) {
        const mapFile = await PreferencesService.getFileByPath(`MAP/${mapFilePath}`);

        if (mapFile) {
          const backgroundLidarUInt8Array = await loadFileAsUint8Array(mapFile);

          localLidarMap.set('background-uInt8Array', backgroundLidarUInt8Array);
        }
      }
    }

    const localMapImageMap = localDoc.getMap('mapImage');
    localMapImageMap.forEach((value, key) => localMapImageMap.delete(key));

    const mapImages = store.getState().maps.mapImage.mapImages;

    if (mapImages?.length) {
      mapImages.forEach(async (mapImage) => {
        const layoutImageUInt8Array = await convertBlobUrlToUInt8Array(mapImage.URL);

        const { URL, name, ...data } = mapImage;
        localMapImageMap.set(name, {
          data,
          uInt8Array: layoutImageUInt8Array,
        });
      });
    }

    /* Preferences */
    if (PreferencesService.getInstallDocument()) {
      const preferencesFilesWithIndentation = PreferencesService.getPreferencesXmlFilesWithIndentation();
      if (preferencesFilesWithIndentation) {
        const { install, ...xmls } = preferencesFilesWithIndentation;

        const localInstallStr = localDoc.getText('install');
        localDoc.transact(() => {
          localInstallStr.delete(0, localInstallStr?.length);
          localInstallStr.insert(0, install);
        });

        const circuitVersionStr = localDoc.getText('circuitVersion');
        const circuitVersion = store.getState().project.circuitVersion;
        localDoc.transact(() => {
          circuitVersionStr.delete(0, circuitVersionStr?.length);
          circuitVersionStr.insert(0, circuitVersion);
        });

        const circuitDescriptionStr = localDoc.getText('circuitDescription');
        const circuitDescription = store.getState().project.circuitDescription;
        localDoc.transact(() => {
          circuitDescriptionStr.delete(0, circuitDescriptionStr?.length);
          circuitDescriptionStr.insert(0, circuitDescription);
        });

        const enableDevicePrefManagement = store.getState().project.enableDevicePrefManagement;
        const enableDevicePrefManagementMap = localDoc.getMap('enableDevicePrefManagement');
        enableDevicePrefManagementMap.set('state', enableDevicePrefManagement);

        const localPrefMap = localDoc.getMap('pref');
        localPrefMap.delete('xmls');
        localPrefMap.delete('projectName');
        localPrefMap.delete('circuitName');
        localPrefMap.delete('projectNEWVersion');
        localPrefMap.delete('availableCircuits');

        if (xmls) {
          localPrefMap.set('xmls', xmls);
        }

        const projectName = PreferencesService.getProjectName();
        if (projectName) {
          localPrefMap.set('projectName', projectName);
        }

        const circuitName = getLoadedCircuitName().replace('.geojson', '');
        if (circuitName) {
          localPrefMap.set('circuitName', circuitName);
        }

        const projectNEWVersion = PreferencesService.getNEW4XCoreVersion();
        if (projectNEWVersion) {
          localPrefMap.set('projectNEWVersion', projectNEWVersion);
        }

        const availableCircuits = PreferencesService.getAvailableCircuitsName();
        if (availableCircuits) {
          localPrefMap.set('availableCircuits', availableCircuits);
        }
      }
    }

    const projectHostChanged = localDoc.getMap('projectHostChanged');
    projectHostChanged?.set('bool', true);

    syncYJSLocalToRemote();
  }

  sessionStorage.unsavedChanges = false;

  document.title = `${getLoadedCircuitName().replace(
    '.geojson',
    ''
  )} (${PreferencesService.getProjectName()}) - Road Editor`;

  const manageDevicePrefEnabled = store.getState().project.enableDevicePrefManagement;
  if (manageDevicePrefEnabled) {
    const doesDevicesMatchPref = analyzeCoherencyDevices();
    if (!doesDevicesMatchPref) {
      // eslint-disable-next-line no-console
      console.log('Devices do not match preferences');

      try {
        await ConfirmUtil({
          title: 'Devices mismatch',
          description: (
            <>
              The devices in the circuit do not match the devices in the preferences.
              <br />
              Do you want to import the devices of the preferences in Road Editor?
              <br />
              <em>(Mismatching devices will be overwritten when saving the circuit)</em>
              <br />
            </>
          ),
          allowClose: false,
        });
        // the user accepted the import
        analyzeCoherencyDevices(true);
      } catch (e) {
        // we do nothing we continue :)
      }
    }
  }

  dispatch(setLoadingStateAction({ newLoadingState: false }));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  dispatch(ActionCreators.clearHistory());

  // adding context data to sentry
  try {
    Sentry.setContext('project', {
      name: PreferencesService.getProjectName(),
    });
    Sentry.setContext('circuit', {
      name: PreferencesService.getCircuitName(),
    });
    Sentry.setContext('versions', {
      NEW4X: PreferencesService.getNEW4XCoreVersion(),
      sdk: PreferencesService.getSDKVersion(),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Sentry error while adding context data', e);
  }

  checkUnicityTrucksIpsAndDisplayError();

  return true;
}

function checkPreferencesOfDeviceType(
  prefName: string,
  propertiesToCheck: string[],
  expectedValues: string[][]
): [boolean, string | string[] | undefined] {
  for (let i = 0; i < propertiesToCheck.length; i++) {
    const expValues = expectedValues[i];
    const propertyToCheck = propertiesToCheck[i];

    try {
      const prefValue = PreferencesService.getPreferenceValue(`${prefName}/${propertyToCheck}`);

      if (typeof prefValue === 'string') {
        const expValue = expValues[0];

        return [prefValue === expValue, prefValue];
      } else if (isArray(prefValue)) {
        let ok = true;
        if (prefValue.length !== expValues.length) {
          ok = false;

          return [ok, prefValue];
        }

        for (let j = 0; j < prefValue.length; j++) {
          const expValue = expValues[j];

          if (prefValue[j] !== expValue) {
            ok = false;
            break;
          }
        }

        if (!ok) return [ok, prefValue];
      } else {
        // eslint-disable-next-line no-console
        console.warn(`The preference ${prefName}/${propertyToCheck} is not a string nor an array.`);
      }
    } catch (e) {
      if (expValues.length !== 0) {
        return [false, []];
      }
    }
  }

  return [true, undefined];
}

/**
 * This function check if devices in the preferences in the install.xml file is the same as the devices in the circuit
 * @param solve whether we need to solve the problem or not
 * @returns whether the devices match the preferences or not
 */
function analyzeCoherencyDevices(solve = false): boolean {
  let nbDevicesAdded = 0;
  let nbDevicesEdited = 0;
  let nbDevicesDeleted = 0;
  const actions: AddOrSaveOrDeleteDevice[] = [];

  const storeState = store.getState();
  const devices = Object.values(storeState.circuit.present.devices.entities);

  const comboxesIOE = devices.filter((device) => device.properties.deviceType === 'IOECombox');
  const comboxes3BT = devices.filter((device) => device.properties.deviceType === '3BTCombox');
  const mobileDockingStations = devices.filter((device) => device.properties.deviceType === 'mobileDockingStation');
  const smartChargers = devices.filter((device) => device.properties.deviceType === 'smartCharger');
  const modbusDevices = devices.filter((device) => device.properties.deviceType === 'modbusDevice');
  const allComboxGen2 = devices.filter((device) => device.properties.deviceType === 'comboxGen2');
  const standalone = devices.filter((device) => device.properties.network === 'Standalone');
  const comboxGen2NotStandaloneUnsorted = allComboxGen2.filter((device) => device.properties.network !== 'Standalone');
  const comboxGen2Gateways = comboxGen2NotStandaloneUnsorted.filter(
    (device) => device.properties.network === 'Gateway'
  );
  const comboxGen2Devices = comboxGen2NotStandaloneUnsorted.filter((device) => device.properties.network === 'Device');

  const comboxGen2NotStandalone = comboxGen2Gateways.flatMap((gateway) => {
    const gatewayDevices = comboxGen2Devices.filter((device) => device.properties.gateway === gateway.id);

    return [gateway, ...gatewayDevices];
  });

  const devicePrefProperty = getDeviceSamplePeriodPrefName();

  // comboxes IOE
  const comboxesIOENames = comboxesIOE.map((device) => device.properties.name);
  const comboxesIOEIps = comboxesIOE.map((device) => device.properties.IP);
  const comboxesIOEDisplayNames = comboxesIOE.map((device) => device.properties.displayName);
  const comboxesIOEFrequencies = comboxesIOE.map((device) =>
    (device.properties.frequency ?? frequencyDefaultValue).toString()
  );
  const comboxesIOEPropsNames = ['name', 'IP', 'displayName', devicePrefProperty];
  const comboxesIOEPropsValues = [comboxesIOENames, comboxesIOEIps, comboxesIOEDisplayNames, comboxesIOEFrequencies];
  const prefStrIOE = 'devices/IOEComboxes';
  const [comboxesIOEOk] = checkPreferencesOfDeviceType(prefStrIOE, comboxesIOEPropsNames, comboxesIOEPropsValues);

  if (!comboxesIOEOk && solve) {
    const resImport = importDevicesFromPref(
      prefStrIOE,
      'IOECombox',
      comboxesIOE,
      comboxesIOENames,
      comboxesIOEPropsNames,
      comboxesIOEPropsValues
    );
    if (resImport.actions.length) {
      nbDevicesAdded += resImport.nbDevicesAdded;
      nbDevicesEdited += resImport.nbDevicesEdited;
      nbDevicesDeleted += resImport.nbDevicesDeleted;
      actions.push(...resImport.actions);
    }
  } else if (!comboxesIOEOk) {
    return false;
  }

  // comboxes 3BT
  const comboxes3BTNames = comboxes3BT.map((device) => device.properties.name);
  const comboxes3BTIps = comboxes3BT.map((device) => device.properties.IP);
  const comboxes3BTDisplayNames = comboxes3BT.map((device) => device.properties.displayName);
  const comboxes3BTFrequencies = comboxes3BT.map((device) =>
    (device.properties.frequency ?? frequencyDefaultValue).toString()
  );
  const comboxes3BTPosition = comboxes3BT.map((device) => devicePositionToCpxStr(device));
  const comboxes3BTPropsNames = ['name', 'IP', 'displayName', devicePrefProperty, 'position'];
  const comboxes3BTPropsValues = [
    comboxes3BTNames,
    comboxes3BTIps,
    comboxes3BTDisplayNames,
    comboxes3BTFrequencies,
    comboxes3BTPosition,
  ];
  const prefStr3BT = 'devices/3BTComboxes';
  const [comboxes3BTOk] = checkPreferencesOfDeviceType(prefStr3BT, comboxes3BTPropsNames, comboxes3BTPropsValues);

  if (!comboxes3BTOk && solve) {
    const resImport = importDevicesFromPref(
      prefStr3BT,
      '3BTCombox',
      comboxes3BT,
      comboxes3BTNames,
      comboxes3BTPropsNames,
      comboxes3BTPropsValues
    );
    if (resImport.actions.length) {
      nbDevicesAdded += resImport.nbDevicesAdded;
      nbDevicesEdited += resImport.nbDevicesEdited;
      nbDevicesDeleted += resImport.nbDevicesDeleted;
      actions.push(...resImport.actions);
    }
  } else if (!comboxes3BTOk) {
    return false;
  }

  // mobile docking stations
  const mobileDockingStationsNames = mobileDockingStations.map((device) => device.properties.name);
  const mobileDockingStationsIps = mobileDockingStations.map((device) => device.properties.IP);
  const mobileDockingStationsDisplayNames = mobileDockingStations.map((device) => device.properties.displayName);
  const mobileDockingStationsFrequencies = mobileDockingStations.map((device) =>
    (device.properties.frequency ?? frequencyDefaultValue).toString()
  );
  const mobileDockingStationsPropsNames = ['name', 'IP', 'displayName', devicePrefProperty];
  const mobileDockingStationsPropsValues = [
    mobileDockingStationsNames,
    mobileDockingStationsIps,
    mobileDockingStationsDisplayNames,
    mobileDockingStationsFrequencies,
  ];
  const prefStrMobileDockingStations = 'devices/mobileDockingStations';
  const [mobileDockingStationsOk] = checkPreferencesOfDeviceType(
    prefStrMobileDockingStations,
    mobileDockingStationsPropsNames,
    mobileDockingStationsPropsValues
  );
  if (!mobileDockingStationsOk && solve) {
    const resImport = importDevicesFromPref(
      prefStrMobileDockingStations,
      'mobileDockingStation',
      mobileDockingStations,
      mobileDockingStationsNames,
      mobileDockingStationsPropsNames,
      mobileDockingStationsPropsValues
    );
    if (resImport.actions.length) {
      nbDevicesAdded += resImport.nbDevicesAdded;
      nbDevicesEdited += resImport.nbDevicesEdited;
      nbDevicesDeleted += resImport.nbDevicesDeleted;
      actions.push(...resImport.actions);
    }
  } else if (!mobileDockingStationsOk) {
    return false;
  }

  // smart chargers
  const smartChargersNames = smartChargers.map((device) => device.properties.name);
  const smartChargersIps = smartChargers.map((device) => device.properties.IP);
  const smartChargersDisplayNames = smartChargers.map((device) => device.properties.displayName);
  const smartChargersFrequencies = smartChargers.map((device) =>
    (device.properties.frequency ?? frequencyDefaultValue).toString()
  );
  const smartChargersPropsNames = ['name', 'IP', 'displayName', devicePrefProperty];
  const smartChargersPropsValues = [
    smartChargersNames,
    smartChargersIps,
    smartChargersDisplayNames,
    smartChargersFrequencies,
  ];
  const prefStrSmartChargers = 'devices/smartChargers';
  const [smartChargersOk] = checkPreferencesOfDeviceType(
    prefStrSmartChargers,
    smartChargersPropsNames,
    smartChargersPropsValues
  );
  if (!smartChargersOk && solve) {
    const resImport = importDevicesFromPref(
      prefStrSmartChargers,
      'smartCharger',
      smartChargers,
      smartChargersNames,
      smartChargersPropsNames,
      smartChargersPropsValues
    );
    if (resImport.actions.length) {
      nbDevicesAdded += resImport.nbDevicesAdded;
      nbDevicesEdited += resImport.nbDevicesEdited;
      nbDevicesDeleted += resImport.nbDevicesDeleted;
      actions.push(...resImport.actions);
    }
  } else if (!smartChargersOk) {
    return false;
  }

  // modbus devices
  const modbusDevicesNames = modbusDevices.map((device) => device.properties.name);
  const modbusDevicesIps = modbusDevices.map((device) => device.properties.IP);
  const modbusDevicesDisplayNames = modbusDevices.map((device) => device.properties.displayName);
  const modbusDevicesFrequencies = modbusDevices.map((device) =>
    (device.properties.frequency ?? frequencyDefaultValue).toString()
  );
  const modbusDevicesKeepWritingOutputs = modbusDevices.map((device) =>
    device.properties.keepWritingOutputs ? '1' : '0'
  );
  const modbusDevicesPropsNames = ['name', 'IP', 'displayName', devicePrefProperty, 'keepWritingOutputs'];
  const modbusDevicesPropsValues = [
    modbusDevicesNames,
    modbusDevicesIps,
    modbusDevicesDisplayNames,
    modbusDevicesFrequencies,
    modbusDevicesKeepWritingOutputs,
  ];
  const prefStrModbusDevices = 'devices/modbusDevices';
  const [modbusDevicesOk] = checkPreferencesOfDeviceType(
    prefStrModbusDevices,
    modbusDevicesPropsNames,
    modbusDevicesPropsValues
  );
  if (!modbusDevicesOk && solve) {
    const resImport = importDevicesFromPref(
      prefStrModbusDevices,
      'modbusDevice',
      modbusDevices,
      modbusDevicesNames,
      modbusDevicesPropsNames,
      modbusDevicesPropsValues
    );
    if (resImport.actions.length) {
      nbDevicesAdded += resImport.nbDevicesAdded;
      nbDevicesEdited += resImport.nbDevicesEdited;
      nbDevicesDeleted += resImport.nbDevicesDeleted;
      actions.push(...resImport.actions);
    }
  } else if (!modbusDevicesOk) {
    return false;
  }

  // combox gen2
  const comboxGen2Names = comboxGen2NotStandalone.map((device) => device.properties.name);
  const comboxGen2Ips = comboxGen2NotStandalone.map((device) => device.properties.IP);
  const comboxGen2DisplayNames = comboxGen2NotStandalone.map((device) => device.properties.displayName);
  const comboxGen2Frequencies = comboxGen2NotStandalone.map((device) =>
    (device.properties.frequency ?? frequencyDefaultValue).toString()
  );
  const comboxGen2PropsNames = ['name', 'IP', 'displayName', devicePrefProperty];
  const comboxGen2PropsValues = [comboxGen2Names, comboxGen2Ips, comboxGen2DisplayNames, comboxGen2Frequencies];
  const prefStrComboxGen2 = 'devices/comboxGen2';
  const [comboxGen2Ok] = checkPreferencesOfDeviceType(prefStrComboxGen2, comboxGen2PropsNames, comboxGen2PropsValues);
  if (!comboxGen2Ok && solve) {
    const resImport = importDevicesFromPref(
      prefStrComboxGen2,
      'comboxGen2',
      comboxGen2NotStandalone,
      comboxGen2Names,
      comboxGen2PropsNames,
      comboxGen2PropsValues
    );
    if (resImport.actions.length) {
      nbDevicesAdded += resImport.nbDevicesAdded;
      nbDevicesEdited += resImport.nbDevicesEdited;
      nbDevicesDeleted += resImport.nbDevicesDeleted;
      actions.push(...resImport.actions);
    }
  } else if (!comboxGen2Ok) {
    return false;
  }

  // standalone
  const standaloneNames = standalone.map((device) => device.properties.name);
  const standaloneIps = standalone.map((device) => device.properties.IP);
  const standaloneDisplayNames = standalone.map((device) => device.properties.displayName);
  const standaloneFrequencies = standalone.map((device) =>
    (device.properties.frequency ?? frequencyDefaultValue).toString()
  );
  const standalonePropsNames = ['name', 'IP', 'displayName', devicePrefProperty];
  const standalonePropsValues = [standaloneNames, standaloneIps, standaloneDisplayNames, standaloneFrequencies];
  const prefStrStandalone = 'devices/comboxGen2Standalone';
  const [standaloneOk] = checkPreferencesOfDeviceType(prefStrStandalone, standalonePropsNames, standalonePropsValues);
  if (!standaloneOk && solve) {
    const resImport = importDevicesFromPref(
      prefStrStandalone,
      'comboxGen2',
      standalone,
      standaloneNames,
      standalonePropsNames,
      standalonePropsValues
    );
    if (resImport.actions.length) {
      nbDevicesAdded += resImport.nbDevicesAdded;
      nbDevicesEdited += resImport.nbDevicesEdited;
      nbDevicesDeleted += resImport.nbDevicesDeleted;
      actions.push(...resImport.actions);
    }
  } else if (!standaloneOk) {
    return false;
  }

  if (solve && actions.length) {
    actions.forEach((action) => {
      store.dispatch(action);
    });
  }

  if (nbDevicesAdded) {
    SnackbarUtils.success(
      <>
        {nbDevicesAdded} device{nbDevicesAdded > 1 ? 's' : ''} imported from the preferences{' '}
        <SystemUpdateAltIcon sx={{ marginLeft: theme.spacing(1) }} />
      </>,
      {
        persist: true,
      }
    );
  }

  if (nbDevicesEdited) {
    SnackbarUtils.success(
      <>
        {nbDevicesEdited} device{nbDevicesEdited > 1 ? 's' : ''} updated from the preferences{' '}
        <UpdateIcon sx={{ marginLeft: theme.spacing(1) }} />
      </>,
      {
        persist: true,
      }
    );
  }

  if (nbDevicesDeleted) {
    SnackbarUtils.success(
      <>
        {nbDevicesDeleted} device{nbDevicesDeleted > 1 ? 's' : ''} deleted from the preferences{' '}
        <DeleteIcon sx={{ marginLeft: theme.spacing(1) }} />
      </>,
      {
        persist: true,
      }
    );
  }

  return true;
}

type AddOrSaveOrDeleteDevice = AddDevice | SaveDevice | DeleteDevice;
interface ImportDevicesFromPrefReturn {
  actions: AddOrSaveOrDeleteDevice[];
  nbDevicesEdited: number;
  nbDevicesAdded: number;
  nbDevicesDeleted: number;
}
function importDevicesFromPref(
  prefStr: string,
  deviceType: DeviceType,
  devicesList: CircuitDevice[],
  devicesNames: string[],
  propsNames: string[],
  propsValues: string[][]
): ImportDevicesFromPrefReturn {
  const actions: AddOrSaveOrDeleteDevice[] = [];
  let nbDevicesEdited = 0;
  let nbDevicesAdded = 0;
  let nbDevicesDeleted = 0;

  const devicePrefName = (() => {
    try {
      return PreferencesService.getPreferenceValue(`${prefStr}/name`) as string[];
    } catch (e) {
      return [];
    }
  })();
  const devicePrefNameChecked = devicePrefName.map(() => false);
  const indexesToImport: number[] = [];
  const indexesToDelete: number[] = [];

  const propsValuesInPref: Record<string, string[]> = {};
  propsNames.forEach((propName) => {
    propsValuesInPref[propName] = (() => {
      try {
        return PreferencesService.getPreferenceValue(`${prefStr}/${propName}`) as string[];
      } catch (e) {
        return [];
      }
    })();
  });

  const devicePrefProperty = getDeviceSamplePeriodPrefName();

  devicesNames.forEach((deviceName, indexDevice) => {
    devicePrefNameChecked[indexDevice] = true;

    const associatedDeviceIndex = devicePrefName.findIndex((name) => name === deviceName);
    if (associatedDeviceIndex !== -1) {
      // the device has been changed but we already had it
      const associatedCombox = devicesList[associatedDeviceIndex];
      const newDevice = cloneDeep(associatedCombox);

      propsNames.forEach((propName, propIndex) => {
        if (propName === 'name') return; // we don't update the name is it is the "id"
        if (propName === 'position') {
          const positionCpxStr = propsValuesInPref['postion']?.[indexDevice];
          if (positionCpxStr) {
            const position = cpxStrToPosition(positionCpxStr);
            newDevice.geometry.coordinates = position;
          } else {
            // eslint-disable-next-line no-console
            console.warn(`The position of the device ${deviceName} is not defined in the preferences`);
          }
        }

        const propPrefValue = propsValuesInPref[propName];
        if (typeof newDevice.properties[propName] === 'number') {
          const val = parseInt(propPrefValue[indexDevice], 10);
          if (!isNaN(val)) {
            newDevice.properties[propName] = val;
          } else {
            // eslint-disable-next-line no-console
            console.warn(
              `The value of the property ${propName} of the device ${deviceName} is not a number (${propPrefValue[indexDevice]})`
            );
          }
        } else {
          newDevice.properties[propName] = propPrefValue[indexDevice];
        }
      });

      const deviceUpdated = !isEqual(newDevice, associatedCombox); // deep comparison
      // we update the device only if a change has been made
      if (deviceUpdated) {
        nbDevicesEdited++;

        actions.push(saveDeviceAction(newDevice));
      }
    } else {
      // if the device name was not found, we remove ot
      indexesToDelete.push(indexDevice);
    }
  });

  // every device we didn't had in the preferences (list length increased), we add them as well
  devicePrefNameChecked.forEach((checked, index) => {
    if (!checked) {
      indexesToImport.push(index);
    }
  });

  // we actually create the delete device action
  indexesToDelete.forEach((indexDevice) => {
    const name = devicesNames[indexDevice];

    const device = devicesList.find((device) => device.properties.name === name);
    if (device) {
      nbDevicesDeleted++;

      actions.push(
        deleteDeviceAction({
          id: device.id as string,
        })
      );
    } else {
      // eslint-disable-next-line no-console
      console.error(`The device ${name} is not found in the list of devices, thereby it cannot be deleted`);
    }
  });

  // we actually create the add device action
  indexesToImport.forEach((indexDevice) => {
    const name = devicePrefName[indexDevice];

    const ip = propsValuesInPref['IP'][indexDevice];
    const displayName = propsValuesInPref['displayName'][indexDevice];
    const frequencyStr = propsValuesInPref[devicePrefProperty][indexDevice];
    const frequency = frequencyStr ? parseInt(frequencyStr, 10) : undefined;

    // we place them around the origin but not exactly to prevent the new devices to overlap
    const coord = [Math.random() - 0.5, Math.random() - 0.5];

    // it's a new device, we need to create it
    const action = addDeviceAction({
      deviceType,
      name,
      displayName: displayName || undefined,
      IP: ip || undefined,
      frequency: frequency && !isNaN(frequency) ? frequency : undefined,
      coord,
    });

    nbDevicesAdded++;

    actions.push(action);
  });

  return {
    actions,
    nbDevicesEdited,
    nbDevicesAdded,
    nbDevicesDeleted,
  };
}
