import type { Position } from 'geojson';
import { ShapeTypes } from 'models/circuit';

export type AlignAction =
  | 'horizontallyLeft'
  | 'horizontallyCenter'
  | 'horizontallyRight'
  | 'verticallyTop'
  | 'verticallyCenter'
  | 'verticallyBottom';

/**
 * Function that gives the new ref value to align vartically or horizontally the shape
 * @param shape The coordinates of the shape (rack, stockZone or point) and the type
 * @param alignAction The action the user want to apply
 * @returns number: the ref value to align the shape
 */
export function findNewPositionToAlignElement(
  shape: {
    coordinates: Position[][] | Position;
    type: ShapeTypes.StockZoneShape | ShapeTypes.RackShape | ShapeTypes.PointShape;
  },
  alignAction: AlignAction
): number {
  const coords = shape.coordinates.flat().flat();

  let maxX: number, minX: number, sumX: number, maxY: number, minY: number, sumY: number;
  maxX = minX = sumX = coords[0];
  maxY = minY = sumY = coords[1];

  coords.forEach((coord, i) => {
    //We don't check the first x and y because these are our min default value
    if ([0, 1].includes(i)) return;

    //For the rectangle shapes there are a total of 10 coordinates but the ones that interest us are the first 8
    if (i >= 8) return;

    //i modulo 2 because we are only interested here in the value of x
    if (i % 2 === 0) {
      // x
      if (coord < minX) {
        minX = coord;
      }

      if (coord > maxX) {
        maxX = coord;
      }

      sumX += coord;
    } else {
      // y
      if (coord < minY) {
        minY = coord;
      }

      if (coord > maxY) {
        maxY = coord;
      }

      sumY += coord;
    }
  });

  //if the shape is not a point, the coords type is Position[][]
  const divider = shape.type === ShapeTypes.PointShape ? 1 : (shape.coordinates as Position[][])[0].length - 1;

  if (alignAction === 'horizontallyLeft') {
    return minX;
  }

  if (alignAction === 'horizontallyCenter') {
    return sumX / divider;
  }

  if (alignAction === 'horizontallyRight') {
    return maxX;
  }

  if (alignAction === 'verticallyTop') {
    return maxY;
  }

  if (alignAction === 'verticallyCenter') {
    return sumY / divider;
  }

  if (alignAction === 'verticallyBottom') {
    return minY;
  }

  return coords[0];
}
