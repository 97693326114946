import type { SimulationReportData } from 'components/toolboxes/simulation/report-model';
import type { RobotEmulation } from 'components/toolboxes/simulation/use-set-robots';
import type { EditorMode } from 'editor/model';
import type { Station } from 'flows/flows';
import type { CircuitShape } from 'models/circuit';
import type { DialogTypes } from 'models/dialog';
import type { MapImageScalingSourceData } from 'models/maps';
import type { Tools } from 'models/tools';
import type { OpenRobotDataType } from 'reducers/dialog';
import type { SelectedShapesData } from 'reducers/local/state';
import type { Action } from 'redux';

export enum DialogActionTypes {
  OpenDialog = '[Dialog] Open',
  CloseDialog = '[Dialog] Close',
}
export interface DetectedShapes {
  searchedShapesIds?: string[];
}

export interface SearchShapeResult {
  shapes?: CircuitShape[];
}

export interface RackEditionZoom {
  zoomToCellId?: string;
}

export interface RobotsList {
  robotsList: RobotEmulation[];
}

export interface NewToolOrMode {
  newToolOrMode: Tools | EditorMode;
}

export interface CreateStation {
  stations: Station[];
  selectedShapes: SelectedShapesData;
}

export interface RotateSelectedShapes {
  selectedShapes: SelectedShapesData;
}

export type DialogData =
  | OpenRobotDataType
  | MapImageScalingSourceData
  | DetectedShapes
  | SearchShapeResult
  | SimulationReportData
  | RackEditionZoom
  | RobotsList
  | NewToolOrMode
  | CreateStation
  | RotateSelectedShapes;

export interface OpenDialogPayload<T = DialogData> {
  type: DialogTypes;
  payload: T;
}

export interface OpenDialog extends Action {
  type: DialogActionTypes.OpenDialog;
  payload?: OpenDialogPayload;
}

export function openDialogAction(payload: OpenDialogPayload): OpenDialog {
  return { type: DialogActionTypes.OpenDialog, payload };
}

export interface CloseDialog extends Action {
  type: DialogActionTypes.CloseDialog;
}

export function closeDialogAction(): CloseDialog {
  return { type: DialogActionTypes.CloseDialog };
}

export type DialogActionsUnion = OpenDialog | CloseDialog;
