import { List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import type { SxProps } from '@mui/system';
import type { MultipleStations } from 'flows/flows';
import { stationPositionTypesIcons } from 'flows/flows';
import { positionIdToPositionName } from 'flows/position-id-to-position-name';
import { capitalize } from 'lodash';
import { useRef } from 'react';
import type { Theme } from 'react-select';
import { ViewportList } from 'react-viewport-list';

interface ListPositionOfMultipleStationsProps {
  stations: MultipleStations;
  sx?: SxProps<Theme>;
}
export function ListPositionOfMultipleStations(props: ListPositionOfMultipleStationsProps): JSX.Element {
  const { stations } = props;

  const listRef = useRef<HTMLUListElement>(null);

  return (
    <List
      dense
      ref={listRef}
      sx={{
        maxHeight: '50vh',
        overflowY: 'auto',
      }}
    >
      <ViewportList viewportRef={listRef} items={stations.positions}>
        {(position) => {
          const Icon = stationPositionTypesIcons[position.type];
          const positionName = positionIdToPositionName(position.id, position.type);

          return (
            <ListItem
              key={position.id}
              sx={{
                '&:hover .display-parent-hover': {
                  opacity: '100%',
                },
              }}
            >
              <Tooltip title={capitalize(position.type)}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={positionName} />
            </ListItem>
          );
        }}
      </ViewportList>
    </List>
  );
}
