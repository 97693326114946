import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DockIcon from '@mui/icons-material/Dock';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import SchoolIcon from '@mui/icons-material/School';
import type { AccordionProps, SxProps, Theme } from '@mui/material';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { closeDialogAction } from 'actions';
import opcBatteryLevelImg from 'assets/opcBatteryLevelImg.png';
import opcWorkloadImg from 'assets/opcWorkloadImg.png';
import { Border } from 'components/utils/border';
import { CustomWidthHelpIconTooltip, HelpIconTooltip } from 'components/utils/tooltips';
import { setOpcData } from 'project/project';
import { useCallback, useMemo, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import type { OPCConfiguration } from 'simulation/opc';
import { getDefaultOPCThresholds, saveOpcConfigurationOnDisk } from 'simulation/opc';
import store, { useAppDispatch, useAppSelector } from 'store';
import { findCommonNumbers } from 'utils/helpers';
import { theme } from 'utils/mui-theme';
import { isDefined } from 'utils/ts/is-defined';
import type { RobotEmulation } from './use-set-robots';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
  ({ theme }) => ({
    margintop: 0,
    marginBottom: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  marginTop: 0,
  marginBottom: 0,
  '& .MuiAccordionSummary-content': {
    marginTop: 0,
    marginBottom: 0,
  },
  ':hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

type OpcCategory =
  | 'lowWLTrigger'
  | 'highWLTrigger'
  | 'lowWLBattThreshold'
  | 'mediumWLBattThreshold'
  | 'highWLBattThreshold';

interface SettingsDialogProps {
  robotsList: RobotEmulation[];
}

const opcDocumentationLink =
  'https://docs.google.com/presentation/d/1rhguSv-CpSH9PUtpaUfCFfuowVJzwtSlT-LRIttxPvA/edit#slide=id.p1';

export function UpdateOPCThresholdsDialog({ robotsList }: SettingsDialogProps): JSX.Element | null {
  const dispatch = useAppDispatch();

  const handleClose = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const opcData = useAppSelector((state) => state.project.opcData);

  if (!opcData) return null;

  const opcGroupsIds = Object.keys(opcData.groups);

  const handleChangeOpcData = (groupId: string, newValue: number, category: OpcCategory): void => {
    if (newValue > 100) {
      newValue = 100;
    }

    if (newValue < 0 || isNaN(newValue)) {
      newValue = 0;
    }

    const newOpcData = {
      ...opcData,
      groups: {
        ...opcData.groups,
        [groupId]: {
          ...opcData.groups[groupId],
          [category]: newValue,
        },
      },
    };

    store.dispatch(setOpcData(newOpcData));
  };

  const handleChangeRobotsList = (robotId: number | 'all', newValue: boolean, groupId: string): void => {
    const newOpcData = structuredClone(opcData);
    const newRobotList = [...newOpcData.groups[groupId].robotList];

    if (robotId === 'all') {
      if (newValue) {
        const newRobotList = robotsList.map((robot) => robot.ID);

        newOpcData.groups[groupId].robotList = newRobotList.sort((a, b) => a - b);
      } else {
        newOpcData.groups[groupId].robotList = [];
      }
    } else {
      if (newValue) {
        newRobotList.push(robotId);

        newOpcData.groups[groupId].robotList = newRobotList.sort((a, b) => a - b);
      } else {
        newOpcData.groups[groupId].robotList = newRobotList.filter((id) => id !== robotId);
      }
    }

    store.dispatch(setOpcData(newOpcData));
  };

  const handleChangeRobotsAuth = (robotId: number | 'all', newValue: boolean, charger: string): void => {
    const newOpcData = structuredClone(opcData);

    const newRobotAuthList = [...newOpcData.chargers[charger].robotAuth];

    if (robotId === 'all') {
      if (newValue) {
        const robotsToAdd = robotsList
          .map((robot) => {
            if (charger.includes(robot.batteryModel)) {
              return robot.ID;
            }

            return undefined;
          })
          .filter(isDefined);

        newOpcData.chargers[charger].robotAuth = robotsToAdd;
      } else {
        newOpcData.chargers[charger].robotAuth = [];
      }
    } else {
      if (newValue) {
        newRobotAuthList.push(robotId);

        newOpcData.chargers[charger].robotAuth = newRobotAuthList.sort((a, b) => a - b);
      } else {
        newOpcData.chargers[charger].robotAuth = newRobotAuthList.filter((id) => id !== robotId);
      }
    }

    store.dispatch(setOpcData(newOpcData));
  };

  const handleResetOpcData = (): void => {
    const newOpcData = structuredClone(opcData);

    opcGroupsIds.forEach((groupId) => {
      const defaultOPCThresholds = getDefaultOPCThresholds();
      defaultOPCThresholds.id = Number(groupId);
      defaultOPCThresholds.robotList = newOpcData.groups[groupId].robotList;

      newOpcData.groups[groupId] = defaultOPCThresholds;
    });

    store.dispatch(setOpcData(newOpcData));
  };

  const handleCreateNewGroup = (): void => {
    const newOpcData = structuredClone(opcData);

    const highestId = opcGroupsIds
      .map((id) => Number(id))
      .sort((a, b) => a - b)
      .pop();

    const newGroup = getDefaultOPCThresholds();
    newGroup.id = highestId ? highestId + 1 : opcGroupsIds.length + 1;
    newGroup.robotList = [];

    newOpcData.groups[newGroup.id] = newGroup;

    store.dispatch(setOpcData(newOpcData));
  };

  const handleDeleteGroup = (groupId: string): void => {
    const newOpcData = structuredClone(opcData);

    delete newOpcData.groups[groupId];

    store.dispatch(setOpcData(newOpcData));
  };

  const handleSaveOpc = (): void => {
    const totalRobotAuthList: number[] = [];

    Object.entries(opcData.chargers).forEach(([key]) => {
      opcData.chargers[key].robotAuth.forEach((robotId) => {
        if (!totalRobotAuthList.includes(robotId)) {
          totalRobotAuthList.push(robotId);
        }
      });
    });

    const groups = Object.keys(opcData.groups);

    const totalRobotChecked: number[][] = [];

    Object.entries(opcData.groups).forEach(([key]) => {
      totalRobotChecked.push(opcData.groups[key].robotList);
    });

    const totalRobotGroupList: number[] = [];

    Object.entries(opcData.groups).forEach(([key]) => {
      opcData.groups[key].robotList.forEach((robotId) => {
        if (!totalRobotGroupList.includes(robotId)) {
          totalRobotGroupList.push(robotId);
        }
      });
    });

    const areRobotsInMultipleGroups = findCommonNumbers(totalRobotChecked);

    if (groups.length > 1 && areRobotsInMultipleGroups.length > 0) {
      SnackbarUtils.error('Some robots are in multiple groups');

      return;
    }

    if (totalRobotGroupList.length < robotsList.length) {
      SnackbarUtils.error('Not all robots have a group assigned');

      return;
    }

    if (totalRobotAuthList.length < robotsList.length) {
      SnackbarUtils.error('Not all robots have a charger assigned');

      return;
    }

    saveOpcConfigurationOnDisk(opcData);

    SnackbarUtils.success(`OPC thresholds updated`);
    handleClose();
  };

  return (
    <Dialog open={true} fullWidth={true} maxWidth="sm" onClose={handleClose}>
      <DialogTitle sx={{ paddingBottom: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Configure Charging Strategy{' '}
        <Link target="_blank" href={opcDocumentationLink} sx={{ display: 'flex', columnGap: theme.spacing(1) }}>
          <SchoolIcon fontSize="small" />
          <Typography variant="body2">Need help? Documentation here</Typography>
          <OpenInNewIcon fontSize="small" />
        </Link>
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: theme.spacing(2),
        }}
      >
        <OpcGroup
          opcData={opcData}
          opcGroupsIds={opcGroupsIds}
          handleChangeOpcData={handleChangeOpcData}
          robotsList={robotsList}
          handleChangeRobotsList={handleChangeRobotsList}
          handleDeleteGroup={handleDeleteGroup}
          handleCreateNewGroup={handleCreateNewGroup}
          handleResetOpcData={handleResetOpcData}
        />
        <ChargersAccordion robotsList={robotsList} opcData={opcData} handleChangeRobotsAuth={handleChangeRobotsAuth} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" endIcon={<SaveIcon />} onClick={handleSaveOpc}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface OpcGroupProps {
  opcData: OPCConfiguration;
  opcGroupsIds: string[];
  handleChangeOpcData: (groupId: string, newValue: number, category: OpcCategory) => void;
  robotsList: RobotEmulation[];
  handleChangeRobotsList: (robotId: number | 'all', newValue: boolean, groupId: string) => void;
  handleDeleteGroup: (groupId: string) => void;
  handleCreateNewGroup: () => void;
  handleResetOpcData: () => void;
}

export function OpcGroup({
  opcData,
  opcGroupsIds,
  handleChangeOpcData,
  robotsList,
  handleChangeRobotsList,
  handleDeleteGroup,
  handleCreateNewGroup,
  handleResetOpcData,
}: OpcGroupProps): JSX.Element {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string): void => {
    setExpanded((expandedPanel) => {
      if (expandedPanel === panel) {
        return false;
      }

      return panel;
    });
  };

  return (
    <List
      dense
      sx={{
        paddingTop: 0,
      }}
    >
      <ListItem>
        <ListItemText
          primary={
            <div>
              Groups configuration for Opportunity Charging{' '}
              <HelpIconTooltip title="Robots of a same fleet may be equipped with different types of battery, needing a different charging logic, or even infrastructure.The robotic fleet can be separated into groups, each being monitored independently." />
            </div>
          }
          primaryTypographyProps={{
            fontSize: 18,
          }}
        />
      </ListItem>
      <Border
        sx={{
          marginLeft: theme.spacing(2),
        }}
      >
        {opcGroupsIds.map((groupId) => (
          <Accordion expanded={expanded === groupId} key={groupId} onChange={() => handleChange(groupId)}>
            <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      pointerEvents: 'auto',
                    }}
                  />
                }
                aria-controls={`${groupId}-content`}
                id={`${groupId}-header`}
                sx={{ flex: 1 }}
              >{`Group ${groupId}`}</AccordionSummary>
              <Tooltip title="Delete">
                <IconButton onClick={() => handleDeleteGroup(groupId)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <AccordionDetails>
              <List dense>
                <OPCThresholdListItem
                  label="Trigger for low workload"
                  category="lowWLTrigger"
                  tooltip="(lowWLTrigger) The workload is a percentage that reflects the current availability of the system quantity of robots assigned to running tasks). A low workload means robots are free and it is a good time to go to charge."
                  groupId={groupId}
                  value={opcData.groups[groupId].lowWLTrigger}
                  handleChangeOpcData={handleChangeOpcData}
                />

                <OPCThresholdListItem
                  label="Trigger for high workload"
                  category="highWLTrigger"
                  tooltip="(highWLTrigger) The workload is a percentage that reflects the current availability of the system quantity of robots assigned to running tasks). A high workload means robots are busy and we should avoid going to charge."
                  groupId={groupId}
                  value={opcData.groups[groupId].highWLTrigger}
                  handleChangeOpcData={handleChangeOpcData}
                />
              </List>

              <List dense>
                <OPCThresholdListItem
                  label="Battery Level Threshold at Low Workload"
                  category="lowWLBattThreshold"
                  tooltip="(lowWLBattThreshold) Battery level threshold at low workload. The Robot Manager will create an opportunity charge mission depending on the battery level and workload value at a given time."
                  groupId={groupId}
                  value={opcData.groups[groupId].lowWLBattThreshold}
                  handleChangeOpcData={handleChangeOpcData}
                />

                <OPCThresholdListItem
                  label="Battery Level Threshold at Medium Workload"
                  category="mediumWLBattThreshold"
                  tooltip="(mediumWLBattThreshold) Battery level threshold at medium workload. The Robot Manager will create an opportunity charge mission depending on the battery level and workload value at a given time."
                  groupId={groupId}
                  value={opcData.groups[groupId].mediumWLBattThreshold}
                  handleChangeOpcData={handleChangeOpcData}
                />

                <OPCThresholdListItem
                  label="Battery Level Threshold at High Workload"
                  category="highWLBattThreshold"
                  tooltip="(highWLBattThreshold) Battery level threshold at high workload. The Robot Manager will create an opportunity charge mission depending on the battery level and workload value at a given time."
                  groupId={groupId}
                  value={opcData.groups[groupId].highWLBattThreshold}
                  handleChangeOpcData={handleChangeOpcData}
                />
              </List>
              <RobotsListAccordion
                groupId={groupId}
                robotsList={robotsList}
                opcData={opcData}
                handleChangeRobotsList={handleChangeRobotsList}
              />
            </AccordionDetails>
          </Accordion>
        ))}

        {opcGroupsIds.length === 0 && (
          <ListItem>
            <ListItemText>No groups configured</ListItemText>
          </ListItem>
        )}
      </Border>

      <Box
        component={'div'}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: theme.spacing(2),
          columnGap: theme.spacing(1),
        }}
      >
        <Button variant="outlined" endIcon={<RestartAltIcon />} onClick={handleResetOpcData}>
          Reset To Default
        </Button>
        <Button variant="outlined" endIcon={<AddIcon />} onClick={handleCreateNewGroup}>
          Create New Group
        </Button>
      </Box>
    </List>
  );
}

interface OPCThresholdListItemProps {
  label: string;
  category: OpcCategory;
  tooltip: string;
  groupId: string;
  value: number;
  handleChangeOpcData: (groupId: string, newValue: number, category: OpcCategory) => void;
}

function OPCThresholdListItem({
  label,
  category,
  tooltip,
  groupId,
  value,
  handleChangeOpcData,
}: OPCThresholdListItemProps): JSX.Element {
  return (
    <ListItem>
      <ListItemText>
        {label}{' '}
        <CustomWidthHelpIconTooltip
          title={
            <div>
              {tooltip}
              <img src={category.includes('Batt') ? opcBatteryLevelImg : opcWorkloadImg} alt="opc graph" width={483} />
            </div>
          }
        />
      </ListItemText>
      <FormControl variant="standard" sx={{ width: 75 }}>
        <Input
          type="number"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          size="small"
          value={value}
          onChange={(e) => handleChangeOpcData(groupId, Number(e.target.value), category)}
        />
      </FormControl>
    </ListItem>
  );
}

interface RobotsListAccordionProps {
  groupId: string;
  robotsList: RobotEmulation[];
  opcData: OPCConfiguration;
  handleChangeRobotsList: (robotId: number | 'all', newValue: boolean, groupId: string) => void;
}

function RobotsListAccordion({
  groupId,
  robotsList,
  opcData,
  handleChangeRobotsList,
}: RobotsListAccordionProps): JSX.Element {
  const getRobotCheckedState = (groupId: string, status: 'checked' | 'indeterminate'): boolean => {
    const groupRobotsList = opcData.groups[groupId].robotList;

    const allRobots = robotsList.map((robot) => robot.ID);

    if (status === 'checked') {
      return groupRobotsList.length === allRobots.length;
    }

    return allRobots.length > groupRobotsList.length && groupRobotsList.length !== 0;
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              pointerEvents: 'auto',
            }}
          />
        }
        aria-controls={`${groupId}-content`}
        id={`${groupId}-header`}
      >{`Robots Group ${groupId} configuration`}</AccordionSummary>
      <AccordionDetails>
        <ListItem
          secondaryAction={
            <Tooltip title={'Assign/unassign all robots to the group'} disableInteractive>
              <Checkbox
                edge="end"
                checked={getRobotCheckedState(groupId, 'checked')}
                indeterminate={
                  getRobotCheckedState(groupId, 'indeterminate') && !getRobotCheckedState(groupId, 'checked')
                }
                onChange={(e, newValue) => {
                  handleChangeRobotsList('all', newValue, groupId);
                }}
              />
            </Tooltip>
          }
          sx={{ padding: 0, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
        ></ListItem>
        {robotsList.map((robot) => (
          <ListItem
            key={robot.serial}
            secondaryAction={
              <Tooltip title={'Assign/unassign the robot to the group'} disableInteractive>
                <Checkbox
                  edge="end"
                  checked={opcData.groups[groupId].robotList.includes(robot.ID)}
                  onChange={(e, newValue) => {
                    handleChangeRobotsList(robot.ID, newValue, groupId);
                  }}
                />
              </Tooltip>
            }
          >
            <ListItemIcon>
              <Avatar src={`/img/trucks/${robot.picturePath}-icon.png`} sx={{ width: 24, height: 24 }} />
            </ListItemIcon>
            <ListItemText primary={robot.name} secondary={robot.modelName} />
          </ListItem>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

interface ChargersAccordionProps {
  robotsList: RobotEmulation[];
  opcData: OPCConfiguration;
  handleChangeRobotsAuth: (robotId: number | 'all', newValue: boolean, charger: string) => void;
  sx?: SxProps<Theme>;
}

function ChargersAccordion({ robotsList, opcData, handleChangeRobotsAuth, sx }: ChargersAccordionProps): JSX.Element {
  const chargersList = useMemo(() => Object.keys(opcData.chargers).sort(), [opcData]);

  const getRobotCheckedState = (charger: string, status: 'checked' | 'indeterminate'): boolean => {
    const robotAuth = opcData.chargers[charger].robotAuth;

    const robotAuthInTheRobotList = robotsList
      .map((robot) => {
        if (charger.includes(robot.batteryModel)) {
          return robot.ID;
        }

        return undefined;
      })
      .filter(isDefined);

    if (status === 'checked') {
      return robotAuthInTheRobotList.length === robotAuth.length;
    }

    return robotAuthInTheRobotList.length > robotAuth.length && robotAuth.length !== 0;
  };

  return (
    <Accordion sx={sx}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              pointerEvents: 'auto',
            }}
          />
        }
        sx={{ fontSize: 18, fontWeight: 400 }}
      >{`Chargers configuration`}</AccordionSummary>
      <AccordionDetails>
        {chargersList.map((charger) => (
          <Box component={'div'}>
            <ListItem
              secondaryAction={
                <Tooltip title={'Assign/unassign all robots to the charger'} disableInteractive>
                  <Checkbox
                    edge="end"
                    checked={getRobotCheckedState(charger, 'checked')}
                    indeterminate={
                      getRobotCheckedState(charger, 'indeterminate') && !getRobotCheckedState(charger, 'checked')
                    }
                    onChange={(e, newValue) => {
                      handleChangeRobotsAuth('all', newValue, charger);
                    }}
                  />
                </Tooltip>
              }
              sx={{ padding: 0, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
            >
              <ListItemIcon>
                <DockIcon />
              </ListItemIcon>
              <ListItemText>{charger}</ListItemText>
            </ListItem>

            {robotsList.map((robot) => {
              if (charger.includes(robot.batteryModel)) {
                return (
                  <Border
                    sx={{
                      paddingLeft: 0,
                      marginLeft: theme.spacing(2),
                    }}
                  >
                    <ListItem
                      key={robot.serial}
                      secondaryAction={
                        <Tooltip title={'Assign/unassign the robot to the charger'} disableInteractive>
                          <Checkbox
                            edge="end"
                            checked={opcData.chargers[charger].robotAuth.includes(robot.ID)}
                            onChange={(e, newValue) => {
                              handleChangeRobotsAuth(robot.ID, newValue, charger);
                            }}
                          />
                        </Tooltip>
                      }
                    >
                      <ListItemIcon>
                        <Avatar src={`/img/trucks/${robot.picturePath}-icon.png`} sx={{ width: 24, height: 24 }} />
                      </ListItemIcon>
                      <ListItemText primary={robot.name} secondary={robot.modelName} />
                    </ListItem>
                  </Border>
                );
              }

              return <></>;
            })}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
