import { ArrowDropDown, Edit } from '@mui/icons-material';
import { Button, Divider, Menu, MenuItem } from '@mui/material';
import { selectToolAction } from 'actions';
import type { FlowStepWithissionType } from 'flows/custom-steps.model';
import type { Flow } from 'flows/flows';
import { setFlowStepMissionType } from 'flows/flows';
import type { StepData } from 'flows/get-steps-from-flow';
import { Tools } from 'models/tools';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';

interface EditStepBtnProps {
  step: StepData;
  station: FlowStepWithissionType;
  flow: Flow;
  stepIndex: number;
  disableSelect?: boolean;
}
export function EditStepBtn(props: EditStepBtnProps): JSX.Element {
  const { step, flow, stepIndex, disableSelect = false } = props;

  const customSteps = useAppSelector((state) => state.flows.customSteps);
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);

  const availableSteps = useMemo(() => {
    return [
      {
        name: 'Default',
        missionType: undefined,
      },
      {
        name: 'Pick',
        missionType: 'pick' as const,
      },
      {
        name: 'Drop',
        missionType: 'drop' as const,
      },
      {
        name: 'Move',
        missionType: 'move' as const,
      },
      ...customSteps.map((customStep) => ({
        name: customStep.name,
        missionType: 'custom' as const,
        customStepId: customStep.id,
      })),
    ];
  }, [customSteps]);

  const handleClickSelectStep = useCallback(
    ({
      stepIndex,
      missionType,
      customMissionId,
    }: {
      stepIndex: number;
      missionType: FlowStepWithissionType['missionType'] | undefined;
      customMissionId?: string;
    }) => {
      if (customMissionId && missionType !== 'custom') {
        // eslint-disable-next-line no-console
        console.error(`Incoherency between missionType and customMissionId`, { customMissionId, missionType });

        return;
      }

      dispatch(
        setFlowStepMissionType({
          flowId: flow.id,
          stepIndex,
          missionType,
          customMissionId,
        })
      );

      setOpen(false);
    },
    [dispatch, flow.id]
  );

  const handleOpenCustomStepsEditor = useCallback(() => {
    dispatch(
      selectToolAction({
        toolName: Tools.CustomStepsEditor,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Button
        ref={anchorEl}
        disabled={disableSelect}
        onClick={() => setOpen(true)}
        size="small"
        endIcon={<ArrowDropDown />}
        color="inherit"
      >
        {step.name}
      </Button>

      <Menu open={open} anchorEl={anchorEl.current} onClose={() => setOpen(false)}>
        {availableSteps.map((availableStep, i) => {
          const step = flow.stations[stepIndex];
          const isCustom = step.missionType === 'custom';
          const selected =
            (!isCustom && step.missionType === availableStep.missionType) ||
            (isCustom && 'customStepId' in availableStep && step.customMissionTypeId === availableStep.customStepId);

          return (
            <MenuItem
              key={`${availableStep.name}-${i}`}
              selected={selected}
              onClick={() =>
                handleClickSelectStep({
                  stepIndex,
                  missionType: availableStep.missionType,
                  customMissionId: 'customStepId' in availableStep ? availableStep.customStepId : undefined,
                })
              }
            >
              {availableStep.name}
            </MenuItem>
          );
        })}

        <Divider />
        <MenuItem onClick={handleOpenCustomStepsEditor}>
          <Edit
            fontSize="small"
            sx={{
              marginRight: theme.spacing(1),
            }}
          />
          Edit custom steps
        </MenuItem>
      </Menu>
    </>
  );
}
