import FrameOfReferenceAxisFront from 'assets/perception/frame-of-reference-axis_front.png';
import type { PerceptionReference } from 'models/circuit';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { CellTemplate, LoadedCellTemplate } from 'reducers/circuit/state';
import { computeLoadsPosition } from 'utils/circuit/racks-compute-load-position';
import { epsilon } from 'utils/circuit/utils';
import { theme } from 'utils/mui-theme';
import { CellTemplateDim } from './CellTemplateDim';
import type { FocusInputs, PickOrDrop, ViewCellTemplate } from './rack-edition-celltemplate';
import {
  DefsCellTemplateSvg,
  PalletRackPreview,
  SelectReferenceCellTemplate,
  beamThickness,
  minimumVerticalClearanceForDropColor,
} from './rack-edition-celltemplate';

interface RackEditionCellTemplatePreviewFrontProps {
  /** cell template to be rendered */
  cellTemplate: CellTemplate;
  /** function to update the current cell template rendered */
  updateCellTemplateAction: (newCellTemplate: CellTemplate) => void;
  /** if the top view is also displayed, used to compute the height of the view */
  topViewEnabled?: boolean;
  /** which kind of parameters are we currently editing */
  view: ViewCellTemplate;
  /** which input is currently focused */
  focusedInputs?: FocusInputs;
  /** change which input is currently focused */
  onChangeFocusedInputs?: (newState: FocusInputs) => void;
  /** width of the largest load [m] */
  widthTakenByLargestLoads?: number;
  /** for perception preview, display the pick or the drop mode of the pallets */
  pickOrDropMode: PickOrDrop;
}
export function RackEditionCellTemplatePreviewFront({
  cellTemplate,
  updateCellTemplateAction,
  topViewEnabled,
  view,
  focusedInputs,
  onChangeFocusedInputs,
  widthTakenByLargestLoads,
  pickOrDropMode,
}: RackEditionCellTemplatePreviewFrontProps): JSX.Element {
  const selectedLoad = cellTemplate.selectedLoad;
  const load = cellTemplate.loads[selectedLoad];

  const offset = topViewEnabled ? 0.8 : 1.1;
  const uprightWidth = 0.08;
  const svgMinX = -offset * 100;
  const svgWidth = (cellTemplate.width + 2 * offset) * 100;
  const svgMinY = (-cellTemplate.height - offset) * 100;
  const svgHeight = (cellTemplate.height + 2 * offset) * 100;

  const heightCellWidth = -(cellTemplate.height + 0.15) * 100;
  const heightA = -(cellTemplate.height + 0.4) * 100;
  const heightLoadWidth = 0.25 * 100;
  const heightSpaceBetweenLoads = 0.6 * 100;

  const [heightDialogContent, setHeightDialogContent] = useState(1000);

  const recomputeSizeDialogContent = useCallback(() => {
    const el = document.querySelector('#rack-edition-dialog-content');
    if (!el) return;

    const bounds = el.getBoundingClientRect();

    setHeightDialogContent(bounds.height - 8);
  }, []);

  useEffect(() => {
    recomputeSizeDialogContent();

    window.addEventListener('resize', recomputeSizeDialogContent);

    return () => window.removeEventListener('resize', recomputeSizeDialogContent);
  }, [recomputeSizeDialogContent]);

  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);

  const onChangeParameter = useCallback(
    (param: string, value: number | PerceptionReference[]) => {
      let newCellTemplate: LoadedCellTemplate;
      if (param.startsWith('load.')) {
        const newLoad = { ...load };
        newLoad[param.substring(5)] = value;
        const newLoads = [...cellTemplate.loads];
        newLoads[selectedLoad] = newLoad;

        newCellTemplate = {
          ...cellTemplate,
          loads: [...newLoads],
        };
      } else {
        if (typeof value !== 'number') {
          // eslint-disable-next-line no-console
          console.error('wrong value type (must be number)', value);

          return;
        }

        newCellTemplate = {
          ...cellTemplate,
          [param]: value,
        };
      }

      updateCellTemplateAction(newCellTemplate);
    },
    [cellTemplate, load, selectedLoad, updateCellTemplateAction]
  );

  const onChangeLink = useCallback(
    (param: string) => {
      const newLoad = { ...load };
      newLoad[param] = newLoad[param] ? undefined : newLoad.a1;
      const newLoads = [...cellTemplate.loads];
      newLoads[selectedLoad] = newLoad;

      const newCellTemplate = {
        ...cellTemplate,
        loads: newLoads,
      };

      updateCellTemplateAction(newCellTemplate);
    },
    [cellTemplate, load, selectedLoad, updateCellTemplateAction]
  );

  if (!load) return <></>;

  const xLoads = computeLoadsPosition(load, cellTemplate.width);

  const middlePalletIndex = Math.floor(load.N / 2);
  const xBeginMiddlePallet = xLoads[middlePalletIndex] * 100;

  const spaceBetweenLoads = xLoads[1] - xLoads[0] - load.W;
  const xEndFirstPallet = xLoads[0] + load.W * 100;
  const xBeginSecondPallet = xLoads[1] * 100;

  const frameOfReferenceSize = 50;

  return (
    <svg
      id="cell-template-view-front"
      width="1000"
      height={heightDialogContent / (topViewEnabled ? 2 : 1)}
      viewBox={`${svgMinX} ${svgMinY} ${svgWidth} ${svgHeight}`}
      preserveAspectRatio="xMidYMid meet"
      className={`view-${view}`}
    >
      <DefsCellTemplateSvg />

      {cellTemplate.leftObstacle > epsilon && (
        <rect
          x={0}
          y={-cellTemplate.height * 100}
          width={cellTemplate.leftObstacle * 100}
          height={cellTemplate.height * 100}
          fill="url(#diagonalHatch)"
          name="left-obstacle"
        />
      )}

      {cellTemplate.rightObstacle > epsilon && (
        <rect
          x={(cellTemplate.width - cellTemplate.rightObstacle) * 100}
          y={-cellTemplate.height * 100}
          width={cellTemplate.rightObstacle * 100}
          height={cellTemplate.height * 100}
          fill="url(#diagonalHatch)"
          name="right-obstacle"
        />
      )}
      {!firstRender.current && (
        <>
          <rect
            x={-uprightWidth * 100}
            y={-cellTemplate.height * 100}
            width={uprightWidth * 100}
            height={cellTemplate.height * 100}
            name="left-upright"
          />

          <rect
            x={cellTemplate.width * 100}
            y={-cellTemplate.height * 100}
            width={uprightWidth * 100}
            height={cellTemplate.height * 100}
            name="right-upright"
          />

          <rect
            x={-uprightWidth * 100}
            y={0}
            width={(cellTemplate.width + uprightWidth * 2) * 100}
            height={beamThickness * 100}
            fill={theme.palette.primary.light}
            name="bottom-beam"
          />

          {Array(load.N)
            .fill(0)
            .map((_, i) => {
              return (
                <PalletRackPreview
                  key={i}
                  cellTemplate={cellTemplate}
                  load={load}
                  x={xLoads[i]}
                  opacity={view === 'perception' && pickOrDropMode === 'drop' ? 0.5 : undefined}
                  displayShadow={false}
                />
              );
            })}

          {view === 'perception' &&
            pickOrDropMode === 'drop' &&
            Array(load.N)
              .fill(0)
              .map((_, i) => {
                const offsetContactBeam = 1.7;
                const w = load.W * 100;
                const h = w;
                const y = 0;

                return (
                  <rect
                    key={i}
                    x={xLoads[i] * 100}
                    y={y * 100 - h + offsetContactBeam - cellTemplate.perception.minimumVerticalClearanceForDrop * 100}
                    width={w}
                    height={cellTemplate.perception.minimumVerticalClearanceForDrop * 100}
                    fill={minimumVerticalClearanceForDropColor}
                    fillOpacity={0.5}
                    stroke={minimumVerticalClearanceForDropColor}
                    strokeOpacity={0.5}
                  ></rect>
                );
              })}

          {view === 'geometry' && (
            <>
              <CellTemplateDim
                param="width"
                label="Th. Cell Width"
                unit="mm"
                unitFactor={1000}
                value={cellTemplate.width}
                x1={0}
                x2={cellTemplate.width * 100}
                y1={heightCellWidth}
                y2={heightCellWidth}
                ratioWidth={0.65}
                min={0}
                step={10}
                onChange={onChangeParameter}
                inputMinWidth={180}
                focused={focusedInputs === 'cellWidth' ? true : undefined}
                onFocus={() => onChangeFocusedInputs && onChangeFocusedInputs('cellWidth')}
                onBlur={() => onChangeFocusedInputs && onChangeFocusedInputs(undefined)}
                title={
                  widthTakenByLargestLoads
                    ? `Template cell width compatible down to a column of ${(widthTakenByLargestLoads * 1000).toFixed(
                        0
                      )} mm`
                    : undefined
                }
              />

              {!load.center && (
                <CellTemplateDim
                  param="load.a1"
                  label={
                    <>
                      a<sub style={{ marginTop: 5, fontSize: '0.8em' }}>1</sub>
                    </>
                  }
                  unit="mm"
                  unitFactor={1000}
                  value={load.a1}
                  x1={0}
                  x2={load.a1 * 100}
                  y1={heightA}
                  y2={heightA}
                  ratioWidth={1}
                  min={0}
                  step={10}
                  onChange={onChangeParameter}
                  inputMinWidth={100}
                  fontSize={8}
                  inputHeight={12}
                  arrowSize="small"
                  onChangeLink={() => onChangeLink('a1')}
                  focused={focusedInputs === 'a1' ? true : undefined}
                  onFocus={() => onChangeFocusedInputs && onChangeFocusedInputs('a1')}
                  onBlur={() => onChangeFocusedInputs && onChangeFocusedInputs(undefined)}
                />
              )}
              {load.N > 1 && (
                <CellTemplateDim
                  param="load.a2"
                  label={
                    <>
                      a<sub style={{ marginTop: 5, fontSize: '0.8em' }}>2</sub>
                    </>
                  }
                  unit="mm"
                  unitFactor={1000}
                  value={load.a2 !== undefined ? load.a2 : load.a1}
                  x1={(cellTemplate.width - (load.a2 !== undefined ? load.a2 : load.a1)) * 100}
                  x2={cellTemplate.width * 100}
                  y1={heightA}
                  y2={heightA}
                  offsetInputX={-90}
                  ratioWidth={1}
                  min={0}
                  step={10}
                  onChange={onChangeParameter}
                  inputMinWidth={115}
                  fontSize={8}
                  inputHeight={12}
                  arrowSize="small"
                  linked
                  linkState={load.a2 === undefined}
                  disabled={load.a2 === undefined}
                  onChangeLink={() => onChangeLink('a2')}
                />
              )}

              <CellTemplateDim
                param="load.W"
                label="Pallet Width"
                unit="mm"
                unitFactor={1000}
                value={load.W}
                x1={xBeginMiddlePallet}
                x2={xBeginMiddlePallet + load.W * 100}
                y1={heightLoadWidth}
                y2={heightLoadWidth}
                ratioWidth={1}
                min={0}
                step={10}
                onChange={onChangeParameter}
                inputMinWidth={150}
                offsetInputY={30}
                offsetInputX={-30}
                focused={focusedInputs === 'loadWidth' ? true : undefined}
                onFocus={() => onChangeFocusedInputs && onChangeFocusedInputs('loadWidth')}
                onBlur={() => onChangeFocusedInputs && onChangeFocusedInputs(undefined)}
              />

              {load.N > 1 && (
                <CellTemplateDim
                  param=""
                  label={undefined}
                  unit="mm"
                  unitFactor={1000}
                  value={spaceBetweenLoads}
                  x1={xEndFirstPallet}
                  x2={xBeginSecondPallet}
                  y1={heightSpaceBetweenLoads}
                  y2={heightSpaceBetweenLoads}
                  ratioWidth={1}
                  min={0}
                  step={10}
                  inputMinWidth={35}
                  offsetInputY={20}
                  //offsetInputX={-30}
                  disabled
                  fontSize={8}
                  inputHeight={12}
                  arrowSize="small"
                  error={spaceBetweenLoads < 0}
                />
              )}
            </>
          )}

          {view === 'perception' && (
            <>
              {load.references.map((perceptionRef, index) => (
                <SelectReferenceCellTemplate
                  key={`select-ref-${index}`}
                  perceptionRef={perceptionRef}
                  index={index}
                  x={xLoads[index]}
                  cellTemplate={cellTemplate}
                  onChangeParameter={onChangeParameter}
                  load={load}
                />
              ))}
            </>
          )}

          {view === 'perception' &&
            load.references.map((perceptionRef, index) => (
              <circle
                key={index}
                name="perception-start-height-inspection-point"
                cx={xLoads[index] * 100 + (load.W / 2) * 100}
                cy={-cellTemplate.perception.startHeight * 100}
                r={5}
                fill="yellow"
                fillOpacity={0.8}
                stroke="black"
              >
                <title>Inspection Point</title>
              </circle>
            ))}

          <image
            href={FrameOfReferenceAxisFront}
            id="frame-of-reference-axis-front-view"
            x={cellTemplate.width * 100 + frameOfReferenceSize}
            y={-frameOfReferenceSize}
            width={frameOfReferenceSize}
            height={frameOfReferenceSize}
          />
        </>
      )}
    </svg>
  );
}
