export const demonstration = {
  speed: 10,
  maxDuration: 0,
  simulationDisplay: true,
  fastRefresh: true,
  displayAllRobotData: false,
  displayTraffic: true,
  advancedDisplayTraffic: false,
  robotErrorMonitoring: {
    newMode: 'robotError',
    newValue: 'ResetRobot',
  },
  deadlockErrorMonitoring: {
    newMode: 'deadlock',
    newValue: 'ResetRobot',
  },
} as const;

export const detailed = {
  speed: 1,
  maxDuration: 0,
  simulationDisplay: true,
  fastRefresh: false,
  displayAllRobotData: true,
  displayTraffic: true,
  advancedDisplayTraffic: true,
  robotErrorMonitoring: {
    newMode: 'robotError',
    newValue: 'StopSimu',
  },
  deadlockErrorMonitoring: {
    newMode: 'deadlock',
    newValue: 'StopSimu',
  },
} as const;

export const throughput = {
  speed: 10000,
  maxDuration: 36000,
  simulationDisplay: true,
  fastRefresh: false,
  displayAllRobotData: false,
  displayTraffic: false,
  advancedDisplayTraffic: false,
  robotErrorMonitoring: {
    newMode: 'robotError',
    newValue: 'ResetRobot',
  },
  deadlockErrorMonitoring: {
    newMode: 'deadlock',
    newValue: 'ResetRobot',
  },
} as const;
