import HelpIcon from '@mui/icons-material/Help';
import { ListItem, ListItemSecondaryAction, ListItemText, Switch, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import type { ZoneFormValues } from './zone-properties';

interface AllowByIdSwitchProps {
  locked: boolean;
  setFormValues: (value: React.SetStateAction<ZoneFormValues>) => void;
  setAskUpdateZone: (value: React.SetStateAction<boolean>) => void;
  zoneFormValues: ZoneFormValues;
}

export function AllowByIdSwitch(props: AllowByIdSwitchProps): JSX.Element {
  const { locked, setFormValues, setAskUpdateZone, zoneFormValues } = props;

  const handleTrafficByIdChange = useCallback((): void => {
    setFormValues((state) => {
      const newTrafficByIdState = !state.trafficById;

      return { ...state, trafficById: newTrafficByIdState };
    });

    setAskUpdateZone(true);
  }, [setAskUpdateZone, setFormValues]);

  return (
    <ListItem disabled={locked} button onClick={handleTrafficByIdChange}>
      <ListItemText
        id="switch-list-label-trafficById"
        primary={
          <>
            Allow by ID{' '}
            <Tooltip
              sx={{
                verticalAlign: 'middle',
                cursor: 'help',
                opacity: 0.7,
                maxHeight: '20px',
                color: 'rgba(0, 0, 0, 0.54)',
              }}
              title="Allow the zone access for some robot given in the project code"
            >
              <HelpIcon />
            </Tooltip>
          </>
        }
      />
      <ListItemSecondaryAction>
        <Switch
          disabled={locked}
          edge="end"
          onChange={handleTrafficByIdChange}
          checked={zoneFormValues.trafficById}
          inputProps={{ 'aria-labelledby': 'switch-list-label-trafficById' }}
          color="primary"
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}
