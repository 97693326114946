import { Stack, Typography } from '@mui/material';
import { getTriggerTypeIcon } from 'components/toolboxes/simulation/triggers-list';
import type { Flow } from 'flows/flows';
import type { Trigger, TriggerType } from 'models/simulation';
import { useCallback } from 'react';
import { useAppSelector } from 'store';

interface DisplayTriggersCellProps {
  flow: Flow;
}
export function DisplayTriggersCell(props: DisplayTriggersCellProps): JSX.Element {
  const { flow } = props;

  const triggers = useAppSelector((state) => state.triggers.triggers);

  const getTriggersByFlowId = useCallback(
    (flowId: string) => {
      const triggersOfFlow = triggers.filter((trigger) =>
        Array.isArray(trigger['flowId']) ? trigger['flowId'].includes(flowId) : trigger['flowId'] === flowId
      );

      return triggersOfFlow;
    },
    [triggers]
  );

  const getTriggersDisplay = useCallback((triggersOfFlow: Trigger[]) => {
    const triggerCounts: Record<TriggerType, number> = {} as Record<TriggerType, number>;

    triggersOfFlow.forEach((trigger) => {
      const triggerType = trigger.type as TriggerType;
      triggerCounts[triggerType] = (triggerCounts[triggerType] || 0) + 1;
    });

    return Object.entries(triggerCounts) as [TriggerType, number][];
  }, []);

  const displayTriggers = useCallback(
    (flowId: string) => {
      const triggersOfFlow = getTriggersByFlowId(flowId);

      if (triggersOfFlow.length === 1) {
        return (
          <Stack>
            <Stack alignItems="center" direction="row" gap={1}>
              {getTriggerTypeIcon(triggersOfFlow[0])}
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {triggersOfFlow[0].name}
              </Typography>
            </Stack>
            <Typography variant="body1" sx={{ color: 'gray' }}>
              Type: {triggersOfFlow[0].type}
            </Typography>
          </Stack>
        );
      } else if (triggersOfFlow.length > 1) {
        return (
          <Stack>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Triggers
            </Typography>

            <Stack alignItems="center" direction="row" gap={2}>
              {getTriggersDisplay(triggersOfFlow).map(([type, count]) => (
                <Stack alignItems="center" direction="row" gap={1} key={type}>
                  {getTriggerTypeIcon(type)}
                  <Typography variant="body2">x{count}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        );
      }

      return <Typography variant="body1">No triggers assigned</Typography>;
    },
    [getTriggersByFlowId, getTriggersDisplay]
  );

  return displayTriggers(flow.id);
}
