import { Queue } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import type { SelectChangeEvent } from '@mui/material';
import { Autocomplete, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { Station, StationPosition, StationPositionWithName } from 'flows/flows';
import { addPositionToStation, addSeveralPositionsToStation } from 'flows/flows';
import { ShapeTypes, SlotTypes } from 'models/circuit';
import { startTransition, useCallback, useMemo, useState } from 'react';
import { useAppDispatch } from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { getAllPositions } from 'utils/circuit/get-all-positions';
import { theme } from 'utils/mui-theme';
import type { StationPositionTypeAndAll } from './station';

const availablePositionTypes: StationPositionTypeAndAll[] = [
  'all',
  ShapeTypes.PointShape,
  ShapeTypes.RackShape,
  'conveyors',
  ShapeTypes.StockZoneShape,
  SlotTypes.Slot,
  SlotTypes.StockLine,
];

function isStationPositionTypeAndAll(value: unknown): value is StationPositionTypeAndAll {
  return availablePositionTypes.includes(value as StationPositionTypeAndAll);
}

interface AddPositionToStationProps {
  station: Station;
}
export function AddPositionToStation(props: AddPositionToStationProps): JSX.Element {
  const { station } = props;
  const dispatch = useAppDispatch();

  const stationContainsPoint = useMemo(
    () => station.positions.some((position) => position.type === ShapeTypes.PointShape),
    [station.positions]
  );
  const canAddSlot = !stationContainsPoint;
  const canAddPoint = !station.positions.length || (station.positions.length > 0 && stationContainsPoint);

  const [selectedPositionType, setSelectedPositionType] = useState<StationPositionTypeAndAll>('all');

  const allPositions = useMemo(() => {
    const availablePositions = getAllPositions({
      canAddPoints: canAddPoint,
      canAddSlots: canAddSlot,
      selectedPositionType,
    });

    const stationsPositionsIds = new Set(station.positions.map((p) => p.id));

    const allPositionsWithoutAlreadyAdded = availablePositions.filter((position) => {
      return !stationsPositionsIds.has(position.id);
    });

    return allPositionsWithoutAlreadyAdded;
  }, [canAddPoint, canAddSlot, selectedPositionType, station.positions]);

  const handleChangedPositionType = useCallback(
    (
      e: SelectChangeEvent<
        ShapeTypes.PointShape | ShapeTypes.StockZoneShape | ShapeTypes.RackShape | SlotTypes | 'all' | 'conveyors'
      >
    ) => {
      const newValue = e.target.value;
      if (!isStationPositionTypeAndAll(newValue)) {
        // eslint-disable-next-line no-console
        console.error('Invalid position type', newValue);

        return;
      }

      setSelectedPositionType(newValue);
    },
    [setSelectedPositionType]
  );

  const [keyAutocomplete, setKeyAutocomplete] = useState(0);
  const [valueToAdd, setValueToAdd] = useState<StationPositionWithName | string | null>(null);
  const handleChangePositionToAdd = useCallback((value: string | StationPositionWithName | null) => {
    setValueToAdd(value);
  }, []);

  const allPositionsThatMatches = useMemo(() => {
    if (typeof valueToAdd !== 'string' && valueToAdd !== null) return null;

    const valueToAddLowercase = (valueToAdd ?? '').toLowerCase();
    const allPositionsThatMatchesTmp = valueToAddLowercase.length
      ? allPositions.filter((position) => {
          return position.name.toLowerCase().includes(valueToAddLowercase);
        })
      : allPositions;

    const pointInThePositions = allPositionsThatMatchesTmp.some((position) => {
      return position.type === ShapeTypes.PointShape;
    }, []);
    const slotInThePositions = allPositionsThatMatchesTmp.some((position) => {
      return (
        position.type === ShapeTypes.StockZoneShape ||
        position.type === ShapeTypes.RackShape ||
        position.type === SlotTypes.Slot ||
        position.type === SlotTypes.StockLine
      );
    });
    const allPositionsThatMatchesTmp2 = pointInThePositions
      ? allPositionsThatMatchesTmp.filter((position) => {
          return position.type === ShapeTypes.PointShape;
        })
      : allPositionsThatMatchesTmp;
    const allPositionsThatMatchesTmp3 = slotInThePositions
      ? allPositionsThatMatchesTmp.filter((position) => {
          return (
            position.type === ShapeTypes.StockZoneShape ||
            position.type === ShapeTypes.RackShape ||
            position.type === SlotTypes.Slot ||
            position.type === SlotTypes.StockLine
          );
        })
      : allPositionsThatMatchesTmp2;

    return allPositionsThatMatchesTmp3;
  }, [allPositions, valueToAdd]);

  const handleAddPosition = useCallback(() => {
    if (!valueToAdd) return;
    if (typeof valueToAdd !== 'object') {
      return;
    }

    const { id, type } = valueToAdd;

    const position: StationPosition = {
      id,
      type,
    };

    dispatch(addPositionToStation({ stationId: station.id, position }));

    setValueToAdd(null);
    setKeyAutocomplete((k) => k + 1);
  }, [dispatch, station.id, valueToAdd]);

  const handleAllPositionsThatMatches = useCallback(() => {
    if (!allPositionsThatMatches) return;

    const positions = allPositionsThatMatches.map((position) => {
      return {
        id: position.id,
        type: position.type,
      };
    });

    dispatch(
      addSeveralPositionsToStation({
        stationId: station.id,
        positions,
      })
    );

    setValueToAdd(null);
    setKeyAutocomplete((k) => k + 1);
  }, [allPositionsThatMatches, dispatch, station.id]);

  const handleKeyUpAutoComplete = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = e.currentTarget.querySelector('input');
      const value = `${input?.value}`;

      if (typeof value === 'string') handleChangePositionToAdd(value);

      if (e.key === 'Enter') {
        handleAddPosition();
      }
    },
    [handleAddPosition, handleChangePositionToAdd]
  );

  return (
    <>
      <FormControl
        fullWidth
        size="small"
        sx={{
          marginTop: theme.spacing(2),
        }}
      >
        <InputLabel id="position-type-to-add-label">
          Position type
          <HelpIconTooltip
            title={'A station can not have points and the other elements at the same time'}
          ></HelpIconTooltip>
        </InputLabel>
        <Select
          labelId="position-type-to-add-label"
          id="position-type-to-add"
          value={selectedPositionType}
          label={
            <Box component={'div'}>
              Position type
              <HelpIconTooltip
                title={'A station can not have points and the other elements at the same time'}
              ></HelpIconTooltip>
            </Box>
          }
          onChange={handleChangedPositionType}
          size="small"
        >
          {availablePositionTypes.map((positionType) => {
            const isSlot =
              positionType === SlotTypes.Slot ||
              positionType === SlotTypes.StockLine ||
              positionType === ShapeTypes.StockZoneShape ||
              positionType === ShapeTypes.RackShape ||
              positionType === 'conveyors';
            const isPoint = positionType === ShapeTypes.PointShape;

            const disabled = (isSlot && !canAddSlot) || (isPoint && !canAddPoint);

            return (
              <MenuItem key={positionType} value={positionType} disabled={disabled}>
                {stationPositionTypeToLabel(positionType)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Stack
        direction="row"
        spacing={1}
        sx={{
          marginTop: theme.spacing(1),
        }}
      >
        <Autocomplete
          key={keyAutocomplete.toString()}
          id="position-to-add"
          fullWidth
          size="small"
          options={allPositions}
          renderInput={(params) => (
            <TextField
              {...params}
              onKeyUp={(e) => {
                startTransition(() => {
                  handleKeyUpAutoComplete(e);
                });
              }}
              label="Position Name"
            />
          )}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          onChange={(event, value) => {
            startTransition(() => {
              handleChangePositionToAdd(value);
            });
          }}
          freeSolo
        />

        <Tooltip title="Add position">
          <Box component={'span'}>
            <IconButton
              aria-label="add"
              onClick={handleAddPosition}
              disabled={!valueToAdd || typeof valueToAdd !== 'object'}
            >
              <AddCircleIcon />
            </IconButton>
          </Box>
        </Tooltip>

        <Tooltip
          title={
            allPositionsThatMatches?.length
              ? `Add the ${allPositionsThatMatches.length} positions that match`
              : 'Add all positions that matches'
          }
        >
          <Box component={'span'}>
            <IconButton
              aria-label="add"
              onClick={handleAllPositionsThatMatches}
              disabled={!allPositionsThatMatches || !allPositionsThatMatches.length}
            >
              <Queue />
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>
    </>
  );
}

function stationPositionTypeToLabel(positionType: StationPositionTypeAndAll): string {
  switch (positionType) {
    case 'all': {
      return 'All';
    }

    case ShapeTypes.PointShape: {
      return 'Points';
    }

    case ShapeTypes.RackShape: {
      return 'Racks';
    }

    case 'conveyors': {
      return 'Conveyors';
    }

    case ShapeTypes.StockZoneShape: {
      return 'Stock zones';
    }

    case SlotTypes.Slot: {
      return 'Slots';
    }

    case SlotTypes.StockLine: {
      return 'Stock lines';
    }
  }

  assert<Equals<typeof positionType, never>>();
}
