import type {
  CircuitDevice,
  CircuitMeasurer,
  CircuitNote,
  CircuitPoint,
  CircuitRack,
  CircuitSegment,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
} from 'models/circuit';
import { ShapeTypes } from '../../models/circuit';

export function isCircuitSegment(shape: any): shape is CircuitSegment {
  return shape?.properties?.type === ShapeTypes.SegmentShape;
}

export function isCircuitPoint(shape: any): shape is CircuitPoint {
  return shape?.properties?.type === ShapeTypes.PointShape;
}

export function isCircuitZone(shape: any): shape is CircuitZone {
  return shape?.properties?.type === ShapeTypes.ZoneShape;
}

export function isCircuitMeasurer(shape: any): shape is CircuitMeasurer {
  return shape?.properties?.type === ShapeTypes.MeasurerShape;
}

export function isCircuitTurn(shape: any): shape is CircuitTurn {
  return shape?.properties?.type === ShapeTypes.TurnShape;
}

export function isCircuitStockZone(shape: any): shape is CircuitStockZone {
  return shape?.properties?.type === ShapeTypes.StockZoneShape;
}

export function isCircuitRack(shape: any): shape is CircuitRack {
  return shape?.properties?.type === ShapeTypes.RackShape;
}

export function isCircuitDevice(shape: any): shape is CircuitDevice {
  return shape?.properties?.type === ShapeTypes.DeviceShape;
}

export function isCircuitNote(shape: any): shape is CircuitNote {
  return shape?.properties?.type === ShapeTypes.NoteShape;
}
