import { FormControl, FormControlLabel, FormHelperText, FormLabel, Switch } from '@mui/material';
import { useConsentCookie } from 'components/core/use-consent-cookies';
import { theme } from 'utils/mui-theme';

export function AnalyticsConsent(): JSX.Element {
  const [consent, handleChangeConsent] = useConsentCookie();

  const measurementId = import.meta.env.VITE_FEATURE_FLAG_GA_MEASUREMENT_ID;

  if (!measurementId) {
    return <></>;
  }

  return (
    <FormControl hiddenLabel={true} size="small" fullWidth={true} sx={{ marginTop: theme.spacing(1) }}>
      <FormLabel>Analytics</FormLabel>

      <FormControlLabel
        labelPlacement={'start'}
        control={<Switch checked={consent} onChange={(e, value) => handleChangeConsent(value)} name="Allow cookies" />}
        label="Allow cookies"
        sx={{ flexDirection: 'row' }}
      ></FormControlLabel>

      <FormHelperText>Allow cookies to improve your experience</FormHelperText>
    </FormControl>
  );
}
