import { Alert, AlertTitle } from '@mui/material';
import { Box } from '@mui/system';
import { positionIdToPositionName } from 'flows/position-id-to-position-name';
import { useEffect, useMemo, useRef } from 'react';
import { theme } from 'utils/mui-theme';
import type { FlowCheckResult } from '../flow-configuration';

interface CheckResultAlertProps {
  checkResult: FlowCheckResult;
}
export function CheckResultAlert(props: CheckResultAlertProps): JSX.Element {
  const { checkResult } = props;

  const nbMaxSlotsIssuesDisplay = 50;

  const allSlotsReachables = checkResult.issues.length === 0;

  const alertRef = useRef<HTMLDivElement>(null);

  const nbUnreachableSlots = useMemo(() => {
    const slots = new Set<string>();

    checkResult.issues.forEach((itinerary) => {
      slots.add(itinerary.src);
      slots.add(itinerary.dst);
    });

    return slots.size;
  }, [checkResult]);

  useEffect(() => {
    // scroll to the alert element when mounted
    if (alertRef.current) {
      alertRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, []);

  return (
    <Alert
      severity={allSlotsReachables ? 'success' : 'warning'}
      ref={alertRef}
      sx={{
        marginTop: theme.spacing(1),
      }}
    >
      <AlertTitle>
        {allSlotsReachables ? 'All slots are reachables' : `${nbUnreachableSlots} slots are not reachable`}
      </AlertTitle>
      {checkResult.nbItineraries} itineraries computed (
      {((1 - checkResult.issues.length / checkResult.nbItineraries) * 100).toFixed(1)}% OK)
      {checkResult.issues.length > 0 && (
        <Box
          component="div"
          sx={{
            maxHeight: '150px',
          }}
        >
          The following itineraries had issues:
          <ul>
            {checkResult.issues.map((itinerary, i) => {
              if (i === nbMaxSlotsIssuesDisplay) return <li key="more">...</li>;
              if (i >= nbMaxSlotsIssuesDisplay) return null;

              const from = positionIdToPositionName(itinerary.src, 'all');
              const to = positionIdToPositionName(itinerary.dst, 'all');

              return (
                <li key={`${itinerary.src}-${itinerary.dst}`}>
                  {from} → {to}
                </li>
              );
            })}
          </ul>
        </Box>
      )}
    </Alert>
  );
}
