import { Alert } from '@mui/material';
import { useMemo } from 'react';
import type { LoadedPoint } from 'reducers/circuit/state';
import store from 'store';
import { isShapeAvailableToRobot } from 'utils/circuit/is-shape-exported-in-layer-group';
import { theme } from 'utils/mui-theme';
import { getRobotsEmulation } from './use-set-robots';

export function CheckMissingTaxiPoints(): JSX.Element {
  const robots = useMemo(() => getRobotsEmulation(), []);

  const allTaxiPoints = useMemo(() => {
    const allTaxiPoints: { [robotId: number]: LoadedPoint } = {};

    const pointsState = store.getState().circuit.present.points;
    const pointsIds = pointsState.ids;
    const points = pointsState.entities;

    const robotIds = robots.map((robot) => `taxi${robot.ID}`);

    pointsIds.forEach((pointId) => {
      const point = points[pointId];

      if (robotIds.includes(point.properties.name)) {
        const robotId = point.properties.name.replace('taxi', '');
        allTaxiPoints[robotId] = point;
      }
    });

    return allTaxiPoints;
  }, [robots]);

  const robotsWithTaxi = useMemo(() => {
    return robots.map((robot) => {
      if (!robot.emulationEnabled) {
        return {
          ...robot,
          hasTaxi: null,
          hasTaxiLayerGroup: null,
        };
      }

      const taxiPoint = allTaxiPoints[robot.ID];

      const hasTaxiInTheLayerGroup = taxiPoint ? isShapeAvailableToRobot(taxiPoint, robot.serial) : false;

      return {
        ...robot,
        hasTaxi: !!taxiPoint,
        hasTaxiLayerGroup: hasTaxiInTheLayerGroup,
      };
    });
  }, [allTaxiPoints, robots]).filter((robot) => robot.emulationEnabled);

  const errorTaxiPointsMissing = useMemo(() => {
    return robotsWithTaxi.some((robot) => {
      const taxiPoint = allTaxiPoints[robot.ID];
      const isTaxiPoint = taxiPoint && taxiPoint.properties.isTaxi ? taxiPoint : undefined;

      return !robot.hasTaxi || !robot.hasTaxiLayerGroup || !isTaxiPoint;
    });
  }, [allTaxiPoints, robotsWithTaxi]);

  return errorTaxiPointsMissing ? (
    <Alert
      severity="warning"
      sx={{
        marginTop: theme.spacing(1),
        marginBottom: 0,
      }}
    >
      The following robot{robotsWithTaxi.length > 1 ? 's' : ''} are missing taxi points and will not be initialized in
      the simulation.
      <ul>
        {robotsWithTaxi.map((robot) => {
          const taxiName = `taxi${robot.ID}`;

          const taxiPoint = allTaxiPoints[robot.ID];

          const isTaxiPoint = taxiPoint && taxiPoint.properties.isTaxi ? taxiPoint : undefined;

          return !robot.hasTaxi || !robot.hasTaxiLayerGroup || !isTaxiPoint ? (
            <li key={robot.ID}>
              {robot.name} ({taxiName}){' '}
              {robot.hasTaxi && !robot.hasTaxiLayerGroup
                ? '(exists but not in the correct layer group)'
                : robot.hasTaxi && !isTaxiPoint
                  ? '(exists but the taxi option has not been activated)'
                  : ''}
            </li>
          ) : (
            <></>
          );
        })}
      </ul>
    </Alert>
  ) : (
    <></>
  );
}
