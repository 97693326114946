import { ViewTimeline } from '@mui/icons-material';
import FactoryIcon from '@mui/icons-material/Factory';
import InventoryIcon from '@mui/icons-material/Inventory';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import type { SxProps } from '@mui/material';
import { Checkbox, List, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { CollapseMore } from 'components/utils/collapse-more';
import type { Trigger, TriggerType } from 'models/simulation';
import React, { useCallback, useEffect, useMemo } from 'react';
import { changeEnableStateTrigger, setEnableStateAllTriggers } from 'simulation/triggers';
import { isTriggerWithoutPosition } from 'simulation/triggers/check-triggers';
import { useAppDispatch, useAppSelector } from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { theme } from 'utils/mui-theme';

interface TriggersListProps {
  /** whether or not the component is collapsable */
  useCollapse?: boolean;
  /** whether or not the component is expanded by default */
  defaultExpended?: boolean;
  /** The sx props of the component */
  sx?: SxProps;
}
export function TriggersList(props: TriggersListProps): JSX.Element {
  const { useCollapse = true } = props;
  const triggers = useAppSelector((state) => state.triggers.triggers);
  const dispatch = useAppDispatch();

  const Wrapper = useCollapse ? CollapseMore : React.Fragment;

  const activatableTriggers = useMemo(() => {
    return triggers.filter((trigger) => !isTriggerWithoutPosition(trigger));
  }, [triggers]);

  const handleEnableTrigger = useCallback(
    (triggerId: string, enabled: boolean) => {
      dispatch(
        changeEnableStateTrigger({
          id: triggerId,
          enabled,
        })
      );
    },
    [dispatch]
  );

  const AvatarIcon = useMemo(() => {
    return FactoryIcon;
  }, []);

  const allTriggersEnabled = useMemo(() => {
    return !!(activatableTriggers.length && activatableTriggers.every((trigger) => trigger.enabled));
  }, [activatableTriggers]);

  const someTriggersEnabled = useMemo(() => {
    return !!(activatableTriggers.length && activatableTriggers.some((trigger) => trigger.enabled));
  }, [activatableTriggers]);

  const handleChangeMasterTrigger = useCallback(() => {
    const newState = !allTriggersEnabled;

    dispatch(
      setEnableStateAllTriggers({
        enabled: newState,
        triggerIds: activatableTriggers.map(({ id }) => id),
      })
    );
  }, [allTriggersEnabled, dispatch, activatableTriggers]);

  useEffect(() => {
    triggers.forEach((trigger) => {
      if (isTriggerWithoutPosition(trigger) && trigger.enabled) handleEnableTrigger(trigger.id, false);
    });
  }, [handleEnableTrigger, triggers]);

  if (!triggers.length) {
    return <></>;
  }

  return (
    <Wrapper
      title={
        useCollapse ? (
          <Stack direction="row" spacing={2} sx={{ pt: 0.6 }}>
            {!!AvatarIcon ? (
              <AvatarIcon
                fontSize="small"
                sx={{
                  verticalAlign: 'middle',
                  marginLeft: theme.spacing(0.5),
                }}
              />
            ) : undefined}

            <Typography variant="body2" sx={{ color: theme.palette.grey[700], fontWeight: 600 }}>
              Triggers
            </Typography>
          </Stack>
        ) : undefined
      }
      hasDivider={false}
      defaultExpended={props.defaultExpended !== undefined && useCollapse ? props.defaultExpended : undefined}
      sx={props.sx}
    >
      <List dense>
        {triggers.length > 0 && (
          <ListItem
            key="all-triggers"
            secondaryAction={
              <Tooltip title="Enable/disable all triggers" disableInteractive placement="left">
                <Checkbox
                  checked={allTriggersEnabled}
                  indeterminate={someTriggersEnabled && !allTriggersEnabled}
                  onChange={handleChangeMasterTrigger}
                />
              </Tooltip>
            }
          ></ListItem>
        )}
        {triggers.map((trigger) => {
          const triggerMainDataTxt = getTriggerMainDataTxt(trigger);
          const triggerIcon = getTriggerTypeIcon(trigger);

          return (
            <ListItem
              key={trigger.id}
              secondaryAction={
                <Tooltip title={`Enable/disable trigger ${trigger.name}`} disableInteractive placement="left">
                  <Checkbox
                    checked={trigger.enabled}
                    disabled={isTriggerWithoutPosition(trigger)}
                    onChange={(e) => {
                      handleEnableTrigger(trigger.id, e.target.checked);
                    }}
                  />
                </Tooltip>
              }
            >
              {triggerIcon && <ListItemIcon>{triggerIcon}</ListItemIcon>}
              <ListItemText
                primary={trigger.name}
                secondary={
                  <>
                    {trigger.type} / {triggerMainDataTxt}
                  </>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Wrapper>
  );
}

/**
 * Returns the main data text for a trigger.
 *
 * @param trigger - The trigger object.
 * @returns The text that describe the the main feature of the triggger
 */
export function getTriggerMainDataTxt(trigger: Trigger): string {
  const triggerType = trigger.type;

  switch (triggerType) {
    case 'interval': {
      const count = trigger.count ?? 1;
      const plural = count !== 1 ? 's' : '';
      const taskOrSequenceWord = Array.isArray(trigger.flowId) ? 'sequence' : 'task';

      return `${count} ${taskOrSequenceWord}${plural} every ${trigger.interval.toFixed(0)} s`;
    }

    case 'buffer': {
      if (Array.isArray(trigger.flowId)) {
        // buffer trigger
        return `at least ${trigger.size} sequences`;
      }

      return `at least ${trigger.size} tasks`;
    }

    case 'replay': {
      const count = trigger.tasks.reduce((acc, task) => acc + ('tasks' in task ? task.tasks.length : 1), 0);
      const plural = count !== 1 ? 's' : '';

      return `replay ${count} task${plural}`;
    }

    default: {
      assert<Equals<typeof triggerType, never>>();

      return '';
    }
  }
}

/**
 * Returns the icon for a given trigger type.
 *
 * @param triggerOrTriggerType - The trigger object or trigger type.
 * @returns The icon that corresponds to the trigger type.
 */
export function getTriggerTypeIcon(triggerOrTriggerType: Trigger | TriggerType): JSX.Element | undefined {
  const triggerType = typeof triggerOrTriggerType === 'string' ? triggerOrTriggerType : triggerOrTriggerType.type;

  switch (triggerType) {
    case 'interval': {
      return <TimelapseIcon fontSize="small" />;
    }

    case 'buffer': {
      return <InventoryIcon fontSize="small" />;
    }

    case 'replay': {
      return <ViewTimeline fontSize="small" />;
    }

    default: {
      return undefined;
    }
  }
}
