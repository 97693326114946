import { ShapeTypes, type CellLoad, type CircuitRack, type RackCell, type RackCellTemplate } from 'models/circuit';
import { memoize } from 'moderndash';
import type { LoadedCellTemplate, LoadedRack } from 'reducers/circuit/state';
import { CircuitService, getShapesNamesMemoized } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe/assert';
import { isDefined } from 'utils/ts/is-defined';
import { convertBase } from './default-circuit-shapes';
import { computeLoadsPosition } from './racks-compute-load-position';
import { isVariableName } from './racks-naming.guard';
import type { BlockDataPositionName, VariableParams } from './racks-naming.model';

const seperatorPositionName = '-';
const columnPrefix = 'C';
const levelPrefix = 'L';

const nbMaxCharCellName = 32;

export const getAllPositionsNamesMemoized = memoize(getAllPositionsNames, {
  ttl: 200,
  resolver: (opts: GetAllPositionsNamesOptions) => {
    return `${opts.rackIdToExclude ?? ''}-${opts.cellIdToExclude ?? ''}`;
  },
});

/**
 * Generate a name for each position of every loads of a cell
 * It matches the following format:
 * nameoftherack-C3-L1-EURO-2
 * with:
 * - nameoftherack: the name of the rack
 * - C3: the column number
 * - L1: the level number
 * - EURO: the name of the load
 * - 2: the position in the load (in the cell)
 *
 * @param rack the rack in which the cell is
 * @param cellPosition the cell position, e.g. the column number as well as the level number
 * @param cellTemplate the cell template assigned to the cell
 * @param formerPositionNames the former position names of the cell if you want to keep them, you can pass undefined in some of them if you want to update just a few positions
 * @returns the generated names
 */
export function generatePositionNames(
  rack: CircuitRack,
  cellPosition: { column: number; level: number },
  cellTemplate: RackCellTemplate,
  formerPositionNames?: (string | undefined)[][]
): string[][] {
  const loads = cellTemplate.loads;

  const positionNames: string[][] = loads.map((load, index) => {
    const n: string[] = [];

    for (let i = 0; i < load.N; i++) {
      // if we provided a former position name, we use it
      const fomerName = formerPositionNames?.[index]?.[i];
      if (fomerName) {
        n.push(fomerName);
        continue;
      }

      // otherwise we generate a new one
      n.push(
        `${rack.properties.name}${seperatorPositionName}${columnPrefix}${
          cellPosition.column + 1
        }${seperatorPositionName}${levelPrefix}${cellPosition.level + 1}${seperatorPositionName}${
          load.name
        }${seperatorPositionName}${i + 1}`
      );
    }

    return n;
  });

  return positionNames;
}

export interface ConvertCellPositionToNameOptions {
  rack: CircuitRack;
  columnIndex: number;
  level: number;
  cell: RackCell;
  palletIndex: number;
  load: CellLoad;
  cellTemplates: Record<string, LoadedCellTemplate>;
  cellTemplate: RackCellTemplate;
}
/**
 * Apply a rule to a position to generate a rack position name
 * @param rules the rules entered by the user
 * @param props data about the position to convert the variable to generated strings
 * @returns the generated cell position name
 */
export function convertCellPositionToName(
  rules: BlockDataPositionName[],
  props: ConvertCellPositionToNameOptions
): string {
  const { rack, columnIndex, palletIndex, load, cellTemplates, cellTemplate, level } = props;

  const columns = rack.properties.columns;
  const column = columns[columnIndex];

  return rules
    .map((rule) => {
      if (rule.type === 'char') {
        return rule.value;
      }

      const formattingParams = rule.params as VariableParams;
      const { formattingDirection, startAt, step } = formattingParams;

      if (rule.type === 'nbVariable' || rule.type === 'strVariable') {
        const value = rule.value;
        if (isVariableName(value)) {
          if (value === '@column!') {
            const val =
              formattingDirection === 'ltr'
                ? columnIndex * step + startAt
                : (columns.length - 1 - columnIndex) * step + startAt;

            return positionNameConvertNumberToFormattedStr(val, formattingParams);
          } else if (value === '@positionInCell!') {
            const val =
              formattingDirection === 'ltr'
                ? palletIndex * step + startAt
                : (load.N - 1 - palletIndex) * step + startAt;

            return positionNameConvertNumberToFormattedStr(val, formattingParams);
          } else if (value === '@rackName!') {
            const val = rack.properties.name;

            return val;
          } else if (value === '@level!') {
            const val =
              formattingDirection === 'ltr'
                ? props.level * step + startAt
                : (columns[columnIndex].cells.length - 1 - props.level) * step + startAt;

            return positionNameConvertNumberToFormattedStr(val, formattingParams);
          } else if (value === '@loadPattern!') {
            const val = load.name;

            return val;
          } else if (value === '@numberOfColumns!') {
            const val = columns.length + (startAt - 1);

            return positionNameConvertNumberToFormattedStr(val, formattingParams);
          } else if (value === '@numberOfLevels!') {
            const val = columns[columnIndex].cells.length + (startAt - 1);

            return positionNameConvertNumberToFormattedStr(val, formattingParams);
          } else if (value === '@position!') {
            let val = startAt;
            for (
              let i = formattingDirection === 'ltr' ? 0 : columns.length - 1;
              formattingDirection === 'ltr' ? i < columnIndex : i > columnIndex;
              formattingDirection === 'ltr' ? i++ : i--
            ) {
              const column = columns[i];
              const cell = column.cells[props.level];
              if (!cell) continue;

              const cellTemplate = cell.cellTemplate ? cellTemplates[cell.cellTemplate] : undefined;
              if (!cellTemplate) continue;

              const loads = cellTemplate.loads;
              if (loads.length === 0) continue;
              else if (loads.length === 1) {
                // only one load, it's simple
                val += loads[0].N * step;
              } else {
                // several load, we take the max
                val += loads.reduce((acc, load) => Math.max(acc, load.N), 0) * step;
              }
            }

            const positionInCell = (formattingDirection === 'ltr' ? palletIndex : load.N - palletIndex - 1) * step;

            val += positionInCell;

            return positionNameConvertNumberToFormattedStr(val, formattingParams);
          } else if (value === '@positionInCellMultiLoad!') {
            const allPositionsInCell = cellTemplate.loads.map((l) =>
              computeLoadsPosition(l, column.width).map((pos) => pos + l.W / 2)
            ); // the center of all loads in the column frame of reference
            const allPositionsInCellSorted = allPositionsInCell.flat().sort((a, b) => a - b);
            const loadIndex = cellTemplate.loads.indexOf(load);
            const positionsOfThisLoad = allPositionsInCell[loadIndex];

            const positionOfThisPallet = positionsOfThisLoad[palletIndex];
            const nbPositionsAtThisAbscissa = allPositionsInCellSorted.filter((p) => p === positionOfThisPallet).length;
            let positionInCellIndex = allPositionsInCellSorted.indexOf(positionOfThisPallet);
            if (nbPositionsAtThisAbscissa > 1) {
              // we have this piece of code if we have several pallets at the same position, in this case we don't
              // want to have duplicate position names
              // so we add the index of the pallet in the list of pallets at this position, see https://redmine.balyo.com/issues/43849
              positionInCellIndex += loadIndex;
            }

            const nbPositions = allPositionsInCellSorted.length;

            const val =
              formattingDirection === 'ltr'
                ? positionInCellIndex * step + startAt
                : (nbPositions - 1 - positionInCellIndex) * step + startAt;

            return positionNameConvertNumberToFormattedStr(val, formattingParams);
          } else if (value === '@positionMultiLoad!') {
            const allPositionsInRackAtThisLevel = columns.map(
              (column) =>
                cellTemplates[column.cells[level]?.cellTemplate ?? -1]?.loads.map((l) =>
                  computeLoadsPosition(l, column.width).map((pos) => column.x + pos + l.W / 2)
                )
            ); // the center of all loads in the rack frame of reference
            const allPositionsInRackAtThisLevelSorted = allPositionsInRackAtThisLevel
              .flat()
              .flat()
              .sort((a, b) => a - b);

            const loadIndex = cellTemplate.loads.indexOf(load);
            const positionsOfThisLoad = allPositionsInRackAtThisLevel[columnIndex][loadIndex];

            const positionOfThisPallet = positionsOfThisLoad[palletIndex];

            const nbPositionsAtThisAbscissa = allPositionsInRackAtThisLevelSorted.filter(
              (p) => p === positionOfThisPallet
            ).length;
            let positionInRackIndex = allPositionsInRackAtThisLevelSorted.indexOf(positionOfThisPallet);
            if (nbPositionsAtThisAbscissa > 1) {
              // we have this piece of code if we have several pallets at the same position, in this case we don't
              // want to have duplicate position names
              // so we add the index of the pallet in the list of pallets at this position, see https://redmine.balyo.com/issues/43849
              positionInRackIndex += loadIndex;
            }

            const nbPositions = allPositionsInRackAtThisLevelSorted.length;

            const val =
              formattingDirection === 'ltr'
                ? positionInRackIndex * step + startAt
                : (nbPositions - 1 - positionInRackIndex) * step + startAt;

            return positionNameConvertNumberToFormattedStr(val, formattingParams);
          }

          assert<Equals<typeof value, never>>();

          // eslint-disable-next-line no-console
          console.error('Variable name not handled', value);
        } else {
          // eslint-disable-next-line no-console
          console.error('Unknown variable name', rule.value);

          return '';
        }
      }

      // eslint-disable-next-line no-console
      console.error('Unknown type', rule);

      return '';
    })
    .join('');
}

/**
 * Convert a position name variable in number to a formatted string
 *
 * @param nb the position name variable in number
 * @param formattingParams the formatting parameters
 * @param fillWith (optional) if the number of characters taken by the formatted string is not long enough, we fill these characters with this value
 * @returns the formatted string
 */
export function positionNameConvertNumberToFormattedStr(
  nb: number,
  formattingParams: VariableParams,
  fillWith?: string
): string {
  fillWith = fillWith ?? formattingParams.fillCharacter;

  let str: string;
  if (formattingParams.formatting === 'letter') {
    // convert from base 10 (numbers) to base 26 (letters)
    str = convertBase(nb.toString(), 10, 26);
  } else {
    // keep it in base 10
    str = nb.toString();
  }

  const nbMissingChars = formattingParams.nbCharacters - str.length;

  const res = nbMissingChars > 0 ? `${fillWith.repeat(nbMissingChars)}${str}` : str;

  return res;
}

interface IsPositionNameNotAlreadyUsedOptions {
  /** if true, it will return true if the other names are not unique */
  allowOkIfOtherNamesAreNotUnique?: boolean;
  /** whether we display a snackbar when we detect a position name that is not unique */
  displaySnackbar?: boolean;
  /**
   * whether we forbid memoization
   * @default false
   */
  pure?: boolean;
}

/**
 * This function returns wether a/several position name(s) are unique or not
 * It looks at the currently being edited rack as well as the other racks (you can pass the ones of the store for example)
 *
 * @param newNamesValues the position names to test
 * @param rack the currently being edited rack
 * @param racksArr the other racks to check (from the store for example)
 * @param cellId the id of the cell in which you are checking the position names unicity
 * @param cellsNames the names of all the cells of the currently being edited rack
 * @param otherNames (optional) other names to check against
 * @param options options of the functions
 * @returns wether the position names are unique or not
 */
export function isPositionNameNotAlreadyUsed(
  newNamesValues: string[],
  rack: LoadedRack,
  racksArr: LoadedRack[],
  cellId: string,
  cellsNames: Record<string, string[]>,
  otherNames: string[] = [],
  options?: IsPositionNameNotAlreadyUsedOptions
): boolean {
  let isUnique = true;

  const { allowOkIfOtherNamesAreNotUnique = true, displaySnackbar = true, pure = false } = options ?? {};

  /* zero, we check that the name is not already used in the other names optional param */
  if (otherNames.length) {
    const tmpArr = [...otherNames, ...newNamesValues];
    const tmpSet = new Set(tmpArr);

    isUnique = tmpArr.length === tmpSet.size;
    if (!isUnique) {
      const duplicate = tmpArr.find((n, i) => tmpArr.indexOf(n) !== i);
      // eslint-disable-next-line no-console
      console.error(`The name ${duplicate} is already used in the rack`);

      return false;
    }
  }

  /* first, we check that the name is not already present in the cell */
  isUnique = newNamesValues.length === new Set(newNamesValues).size;
  if (!isUnique) {
    const duplicate = newNamesValues.find((n, i) => newNamesValues.indexOf(n) !== i);
    // eslint-disable-next-line no-console
    console.error(`The name ${duplicate} is already used in the cell`);

    return false;
  }

  /**
   * second, we check that the new names of the cell are not present in all the other racks
   *
   * for the other racks, we look at the store state
   * but for the current rack, we look at the state of the useState of the currently being edited rack (given that the store state may be outdated)
   */
  const rackIndex = racksArr.findIndex((r) => r.id === rack.id);
  if (rackIndex !== -1) racksArr.splice(rackIndex, 1);

  const cellsNamesWithoutCurrentCell = { ...cellsNames };
  delete cellsNamesWithoutCurrentCell[cellId];
  const cellsNamesWithoutCurrentCellArr = Object.values(cellsNamesWithoutCurrentCell).flatMap((n) => n);
  const cellsNamesWithoutCurrentCellSet = new Set(cellsNamesWithoutCurrentCellArr);
  const cellsNamesWithoutCurrentCellArrWithoutDuplicates =
    cellsNamesWithoutCurrentCellSet.size === cellsNamesWithoutCurrentCellArr.length
      ? cellsNamesWithoutCurrentCellArr
      : [...cellsNamesWithoutCurrentCellSet];
  const allNamesInThisRack: string[] = [...cellsNamesWithoutCurrentCellArrWithoutDuplicates, ...newNamesValues.flat()];
  const allNames: string[] = [];

  const getAllPositionsNamesOpts = {
    racksArr,
    cellIdToExclude: cellId,
  };
  const namesToAddTmp = (pure ? getAllPositionsNames : getAllPositionsNamesMemoized)(getAllPositionsNamesOpts);

  for (const name of namesToAddTmp) {
    allNames.push(name);
  }

  allNames.push(...allNamesInThisRack);

  // we add the shape names
  const getShapesOpts = {
    exclude: {
      // we exclude some shapes types for performance reason, and because their name do not matter that much
      [ShapeTypes.NoteShape]: true,
      [ShapeTypes.SegmentShape]: true,
      [ShapeTypes.TurnShape]: true,
    },
  };

  const allShapesNames = pure
    ? CircuitService.getShapes(undefined, getShapesOpts)
        .map((shape) => shape.properties.name)
        .filter(isDefined)
    : getShapesNamesMemoized(undefined, getShapesOpts);

  allNames.push(...allShapesNames);

  // check if there's duplicates
  isUnique = allNames.length === new Set(allNames).size;

  if (!isUnique) {
    const seenNames = new Set();
    const duplicates = allNames.filter((n) => (seenNames.has(n) ? true : !seenNames.add(n)));
    const duplicatesSet = new Set(duplicates);
    const msg = `The name${duplicates.length > 1 ? 's' : ''} ${duplicates.join(', ')} ${
      duplicates.length > 1 ? 'are' : 'is'
    } already used in the rack`;
    // eslint-disable-next-line no-console
    console.warn(msg);

    if (displaySnackbar) {
      SnackbarUtils.warning(msg);
    }

    if (allowOkIfOtherNamesAreNotUnique) {
      let newNameInDuplicates = false;
      for (let i = 0; i < newNamesValues.length; i++) {
        const newName = newNamesValues[i];
        if (duplicatesSet.has(newName)) {
          newNameInDuplicates = true;
          break;
        }
      }

      if (!newNameInDuplicates) {
        isUnique = true;
      }
    }
  }

  return isUnique;
}

/**
 * Check if a position cell name is valid or not
 * It does not check the unicity here
 * @param name the position cell name to check
 * @returns wether it is valid or not
 */
export function isValidPositionCellName(name: string): boolean {
  return name.length > 0 && name.length < nbMaxCharCellName;
}

interface GetAllPositionsNamesOptions {
  rackIdToExclude?: string;
  cellIdToExclude?: string;
  racksArr: LoadedRack[];
}
export function getAllPositionsNames(opts: GetAllPositionsNamesOptions): Set<string> {
  const cellId = opts?.cellIdToExclude;
  const rackIdToExclude = opts?.rackIdToExclude;
  const racksArr = opts.racksArr;

  const namesToAddTmp = new Set<string>();
  for (let i = 0; i < racksArr.length; i++) {
    const r = racksArr[i];
    if (r.id === rackIdToExclude) continue;

    for (let j = 0; j < r.properties.columns.length; j++) {
      const c = r.properties.columns[j];
      for (let k = 0; k < c.cells.length; k++) {
        const cell = c.cells[k];
        // given that we already checked the unicity of the new names in its own cell, we can skip the check here
        if (cell.id === cellId) {
          continue;
        }

        for (let cellNameIndex = 0; cellNameIndex < cell.names.length; cellNameIndex++) {
          cell.names[cellNameIndex].forEach((n) => {
            namesToAddTmp.add(n.value);
          });
        }
      }
    }
  }

  return namesToAddTmp;
}
