import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { saveZoneAction } from 'actions/zones';
import { HelpIconTooltip, WarningIconTooltip } from 'components/utils/tooltips';
import { useSdkVersionOk } from 'components/utils/use-sdk-version-ok';
import type { CircuitZone } from 'models/circuit';
import { useCallback } from 'react';
import { useAppDispatch } from 'store';
import { theme } from 'utils/mui-theme';

type EventType = 'enter' | 'exit';

interface SelectZoneEventsProps {
  zone: CircuitZone;
}
export function SelectZoneEvents(props: SelectZoneEventsProps): JSX.Element {
  const { zone } = props;
  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (newValue: string, type: EventType) => {
      const eventName = newValue || undefined;
      const newZone = structuredClone(zone);

      if (type === 'enter') {
        newZone.properties.enterEventName = eventName;
      } else if (type === 'exit') {
        newZone.properties.exitEventName = eventName;
      }

      dispatch(saveZoneAction(newZone));
    },
    [dispatch, zone]
  );

  return (
    <Stack
      direction="row"
      spacing={1}
      useFlexGap
      sx={{
        textAlign: 'left',
      }}
    >
      <InputEvent handleChange={handleChange} zone={zone} type="enter" />
      <InputEvent handleChange={handleChange} zone={zone} type="exit" />
    </Stack>
  );
}

interface InputEventProps {
  handleChange: (newValue: string, type: EventType) => void;
  zone: CircuitZone;
  type: EventType;
}
function InputEvent(props: InputEventProps): JSX.Element {
  const { handleChange, zone, type } = props;

  /**
   * Minimum version to define an enter or exit event on a zone.
   * @link
   */
  const minimumSDKVersionEnterExitEvents = '4.14.0';
  const isSdkVersionOk = useSdkVersionOk(minimumSDKVersionEnterExitEvents);

  const value = type === 'enter' ? zone.properties.enterEventName : zone.properties.exitEventName;
  const tooltip = `Event triggered on the robot when it ${type === 'enter' ? 'enters' : 'exits'} the zone.`;
  const label = (
    <>
      {`${type === 'enter' ? 'Enter' : 'Exit'} Event`} <HelpIconTooltip title={tooltip} />
      {!isSdkVersionOk && (
        <WarningIconTooltip title={`Requires SDK version ${minimumSDKVersionEnterExitEvents} or higher`} />
      )}
    </>
  );

  return (
    <TextField
      id={`${type}-event-name`}
      defaultValue={value || ''}
      onBlur={(event) => handleChange(event.target.value, type)}
      label={label}
      sx={{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      }}
      size="small"
      fullWidth
      variant="standard"
      InputLabelProps={{ shrink: true }}
    ></TextField>
  );
}
