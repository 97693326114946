import { Add, RemoveCircle } from '@mui/icons-material';
import type { SelectChangeEvent } from '@mui/material';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import type { Flow } from 'flows/flows';

interface ListFlowsInTriggersProps {
  flowIds: string[];
  flows: Flow[];
  handleChangeFlow: (e: SelectChangeEvent<string>, index: number) => void;
  handleRemoveFlow: (index: number) => void;
  handleAddFlow: () => void;
}

export function ListFlowsInTriggers(props: ListFlowsInTriggersProps): JSX.Element {
  const { flowIds, handleChangeFlow, flows, handleRemoveFlow, handleAddFlow } = props;

  return (
    <>
      {flowIds.map((flowId, index) => {
        const isLast = index === flowIds.length - 1;
        const label = `Flow ${index > 0 ? index + 1 : ''}`;

        return (
          <FormControl
            fullWidth
            size="small"
            key={`${flowId}-${index}`}
            sx={{
              marginTop: (theme) => theme.spacing(1),
            }}
          >
            <Stack direction="row" spacing={0}>
              <InputLabel id="select-trigger-flow">{label}</InputLabel>
              <Select
                labelId="select-trigger-flow"
                label={label}
                value={flowId}
                size="small"
                onChange={(e) => handleChangeFlow(e, index)}
                fullWidth
              >
                {flows.map((flow) => (
                  <MenuItem key={flow.id} value={flow.id}>
                    {flow.name}
                  </MenuItem>
                ))}
              </Select>
              <IconButton
                size="small"
                disabled={flowIds.length <= 1}
                onClick={() => handleRemoveFlow(index)}
                sx={{
                  marginLeft: (theme) => theme.spacing(1),
                }}
              >
                <RemoveCircle />
              </IconButton>
            </Stack>
            {isLast && (
              <Tooltip title="Add another flow to be executed once the previous flow is done" placement="left" arrow>
                <Button
                  fullWidth
                  size="small"
                  onClick={handleAddFlow}
                  sx={{
                    marginTop: (theme) => theme.spacing(1),
                  }}
                >
                  <Add />
                </Button>
              </Tooltip>
            )}
          </FormControl>
        );
      })}
    </>
  );
}
