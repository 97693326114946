import HistoryIcon from '@mui/icons-material/History';
import { FormControl, IconButton, TextField, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import type { Station } from 'flows/flows';
import { setStationName } from 'flows/flows';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'store';

interface RenameStationProps {
  station: Station;
  formerState?: Station;
  isInvalidName: (newName: string) => boolean;
}
export function RenameStation(props: RenameStationProps): JSX.Element {
  const { station, formerState, isInvalidName } = props;

  const forbiddenCharacterMsg = `The character '/' is not allowed`;
  const incorrectNameMsg = 'This name is incorrect';
  const duplicateNameMsg = 'This name is already used';

  const dispatch = useAppDispatch();

  const [helperTextMsg, setHelperTextMsg] = useState('');

  const handleRevertName = useCallback(() => {
    if (!formerState) return;

    dispatch(
      setStationName({
        id: station.id,
        name: formerState?.name,
      })
    );
  }, [dispatch, formerState, station.id]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        handleRevertName();
      }
    },
    [handleRevertName]
  );

  const setErrorMsg = useCallback(
    (newName: string) => {
      if (!newName) {
        setHelperTextMsg(incorrectNameMsg);

        return;
      }

      // Check if the name contains the forbidden character '/':  Name with '/' break the simulation
      if (newName?.includes('/')) {
        setHelperTextMsg(forbiddenCharacterMsg);

        return;
      }

      setHelperTextMsg(duplicateNameMsg);
    },
    [forbiddenCharacterMsg]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (helperTextMsg) setHelperTextMsg('');

      const stationName = e.target.value;

      dispatch(
        setStationName({
          id: station.id,
          name: stationName,
        })
      );

      if (isInvalidName(stationName)) setErrorMsg(stationName);
    },
    [dispatch, helperTextMsg, isInvalidName, setErrorMsg, station.id]
  );

  return (
    <>
      <FormControl fullWidth size="small">
        <TextField
          id="station-name"
          label="Station name"
          variant="outlined"
          size="small"
          value={station.name}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          error={helperTextMsg.length !== 0}
          helperText={helperTextMsg}
          autoFocus
        />
      </FormControl>

      {formerState && (
        <div>
          <Tooltip title="Revert">
            <Box component={'span'}>
              <IconButton aria-label="revert" onClick={handleRevertName} disabled={formerState.name === station.name}>
                <HistoryIcon fontSize="small" />
              </IconButton>
            </Box>
          </Tooltip>
        </div>
      )}
    </>
  );
}
