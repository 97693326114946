import { ArrowForward, Close, Email, OpenInNew, Send } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { AccordionProps } from '@mui/material';
import {
  AccordionSummary,
  Alert,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { closeDialogAction } from 'actions';
import { robotNameToSrc } from 'components/editor/robot-data';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthService } from 'services/auth.service';
import { useAppDispatch } from 'store';
import { Container } from 'typescript-ioc';
import { capitalize } from 'utils/helpers';
import { AvailableModels, availableModels } from 'utils/robots/models';
import { robotNameToDescription, robotNameToUrl } from 'utils/robots/robots-information-url';
import { z } from 'zod';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
  ({ theme }) => ({
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const createProjectCollabUrl = `${window.location.protocol}//${import.meta.env.VITE_API_DNS}${
  import.meta.env.VITE_API_LIVE_PREFIX_URL
}/create-project-collab`;
const createProjectUrl = `${window.location.protocol}//${import.meta.env.VITE_API_DNS}${
  import.meta.env.VITE_API_LIVE_PREFIX_URL
}/create-project-mail`;

type AvailableModelsType = z.infer<typeof AvailableModels>;

const ProjectData = z.object({
  creatorEmail: z.string().email(),
  creatorName: z.string(),
  projectName: z.string(),
  robots: z.array(
    z.object({
      model: AvailableModels,
      modelVariants: z.array(z.object({ modelVariantName: z.string(), number: z.number() })),
    })
  ),
});

type ProjectDataType = z.infer<typeof ProjectData>;

const steps = ['Project Name', 'Robots', 'Validate'];

function isProjectDataComplete(projectData: Partial<ProjectDataType>): projectData is ProjectDataType {
  return !!projectData.projectName && !!projectData.robots;
}

export function CreateProjectDialog(): JSX.Element {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useAppDispatch();

  const [projectData, setProjectData] = useState<Partial<ProjectDataType>>({});

  const closeDialog = useCallback(() => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const handleSetProjectName = useCallback((projectName: string) => {
    setProjectData((prev) => ({ ...prev, projectName }));
    setActiveStep((prev) => prev + 1);
  }, []);

  const handleSetRobots = useCallback((robots: ProjectDataType['robots']) => {
    setProjectData((prev) => ({ ...prev, robots }));
    setActiveStep((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (!projectData.creatorEmail || !projectData.creatorName) {
      const authService = Container.get(AuthService);
      const profile = authService.getLocalUserProfile();

      setProjectData((prev) => ({
        ...prev,
        creatorEmail: profile?.email ?? 'alexis.delrieu+test@balyo.com',
        creatorName: profile?.name ?? 'Unknown',
      }));
    }
  }, [projectData.creatorEmail, projectData.creatorName]);

  return (
    <Dialog open={true} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        Create Project
        <IconButton aria-label="close" onClick={closeDialog} sx={{ float: 'right' }}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          overflow: 'hidden',
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel={false}>
          {steps.map((label, labelIndex) => (
            <Step
              key={label}
              onClick={labelIndex < activeStep ? () => setActiveStep(labelIndex) : undefined}
              sx={{
                cursor: labelIndex < activeStep ? 'pointer' : undefined,
              }}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogContent>

      <DialogContent
        sx={{
          paddingTop: 0,
        }}
      >
        {activeStep === 0 && <ProjectNameForm onSubmit={handleSetProjectName} projectData={projectData} />}

        {activeStep === 1 && <RobotsPicker onSubmit={handleSetRobots} projectData={projectData} />}

        {activeStep === 2 && isProjectDataComplete(projectData) && <ValidateCreateProject projectData={projectData} />}
      </DialogContent>
    </Dialog>
  );
}

const ProjectName = z.string().min(3).max(50);

interface ProjectNameFormProps {
  onSubmit: (projectName: string) => void;
  projectData?: Partial<ProjectDataType>;
}
export function ProjectNameForm(props: ProjectNameFormProps): JSX.Element {
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      const input = e.currentTarget.elements.namedItem('projectName');
      if (!(input instanceof HTMLInputElement)) {
        // eslint-disable-next-line no-console
        console.error('Wrong input type');

        return;
      }

      e.preventDefault();

      const projectNameZod = ProjectName.safeParse(input.value);
      if (!projectNameZod.success) {
        setError(projectNameZod.error.issues[0].message);

        return;
      }

      setError(null);

      props.onSubmit(projectNameZod.data);
    },
    [props]
  );

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        autoFocus
        name="projectName"
        margin="dense"
        label="Project Name"
        fullWidth
        error={!!error}
        helperText={error}
        onChange={() => setError(null)}
        defaultValue={props.projectData?.projectName}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type="submit">
                <ArrowForward />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
}

interface RobotInRobotPicker {
  model: AvailableModelsType;
  modelVariants: { modelVariantName: string; number: number; variantImg: string }[];
  imageSrc: string;
  url: string;
  description: string;
  finalNumber: number;
  userAction: boolean;
}

const availableModelsVariantsGbl = [
  {
    modelRef: 'reachy',
    variants: [
      'Reachy 14 Gen 2 LL',
      'Reachy 14 Gen 2 ML',
      'Reachy 16 Gen 2 LL',
      'Reachy 16 Gen 2 ML',
      'Reachy 16 Gen 2 HL',
      'Reachy 16 Gen 2 EXHL',
    ],
  },
  {
    modelRef: 'lowy',
    variants: ['Lowy 12 Gen 1', 'Lowy 16 Gen 2 LL A', 'Lowy 16 Gen 2 LL B', 'Lowy 16 Gen 2 ML A', 'Lowy 16 Gen 2 ML B'],
  },
  {
    modelRef: 'lowy CB',
    variants: [
      'Lowy CB 12 Gen 1 LL',
      'Lowy CB 12 Gen 1 ML',
      'Lowy CB 16 Gen 1 LL',
      'Lowy CB 16 Gen 1 ML',
      'Lowy CB 16 Gen 1 HL',
      'Lowy CB-A 10 Gen 1',
      'Lowy CB-A 12 Gen 1',
      'Lowy CB-A 15 Gen 1',

      // ! Disabled models with problem on google sheets & gitlab for now
      // 'Lowy CB 10 Gen 3 LL',
      // 'Lowy CB 16 Gen 3 LL',
      // 'Lowy CB 10 Gen 3 HL',
      // 'Lowy CB 13 Gen 3 HL',
      'Lowy CB-A 10 Gen 2',
      'Lowy CB-A 12 Gen 2',
      'Lowy CB-A 15 Gen 2',
    ],
  },
  {
    modelRef: 'veeny',
    variants: ['Veeny Gen 2 LL', 'Veeny Gen 2 ML', 'Veeny Gen 2 HL'],
  },
  {
    modelRef: 'tuggy',
    variants: ['Tuggy 70 Gen 2'],
  },
  {
    modelRef: 'trucky',
    variants: ['Trucky 80 Gen 1'],
  },
] as const;

type ModelRef = (typeof availableModelsVariantsGbl)[number]['modelRef'];

function getInitialAvailableRobots(
  availableModelsVariants: typeof availableModelsVariantsGbl,
  projectData?: Partial<ProjectDataType>
): RobotInRobotPicker[] {
  return availableModels.map((model) => {
    const variants = availableModelsVariants.find((variant) => variant.modelRef === model)?.variants;
    const finalNumberToFind =
      projectData?.robots
        ?.find((robot) => robot.model === model)
        ?.modelVariants.reduce((acc, variant) => acc + variant.number, 0) ?? 0;

    return {
      model,
      modelVariants: variants?.map((variant: string) => {
        const numberToFind =
          projectData?.robots
            ?.find((robot) => robot.model === model)
            ?.modelVariants.find((modelVariant) => modelVariant.modelVariantName === variant)?.number ?? 0;

        const variantImg = variantAndModelToImageSrc(model, variant);

        return {
          modelVariantName: variant,
          number: numberToFind,
          variantImg: variantImg,
        };
      }) ?? [{ modelVariantName: '', number: 0, variantImg: '' }],
      imageSrc: robotNameToSrc(model, false),
      url: robotNameToUrl(model),
      description: robotNameToDescription(model),
      finalNumber: finalNumberToFind,
      userAction: false,
    };
  });
}

interface RobotsPickerProps {
  onSubmit: (robots: ProjectDataType['robots']) => void;
  projectData?: Partial<ProjectDataType>;
}
function RobotsPicker(props: RobotsPickerProps): JSX.Element {
  const initialRobots = useMemo(() => {
    return getInitialAvailableRobots(availableModelsVariantsGbl, props.projectData);
  }, [props.projectData]);

  const [robots, setRobots] = useState(initialRobots);

  const nbRobots = useMemo(() => robots.reduce((acc, robot) => acc + robot.finalNumber, 0), [robots]);

  const isSubmitOk = nbRobots > 0;

  const maxInput = 100;

  const handleChangeRobotNb = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, robotName: AvailableModelsType) => {
      let newValue = parseInt(e.currentTarget.value, 10);

      if (e.currentTarget.value === '') newValue = 0;

      if (newValue > maxInput) newValue = maxInput;

      const nb = z.number().min(0).safeParse(newValue);
      if (!nb.success) {
        return;
      }

      const mainVariants = [
        'Reachy 16 Gen 2 ML',
        'Veeny Gen 2 ML',
        'Lowy 16 Gen 2 ML A',
        'Lowy CB 12 Gen 1 ML',
        'Tuggy 70 Gen 2',
        'Trucky 80 Gen 1',
      ];

      function updateMainVariant(
        robot: RobotInRobotPicker,
        nb: z.SafeParseSuccess<number>
      ): {
        modelVariantName: string;
        number: number;
        variantImg: string;
      }[] {
        const variantToUpdate = robot.modelVariants.map((modelVariant) => {
          const mainVariant = mainVariants.find((name) => name === modelVariant.modelVariantName);

          if (!mainVariant) {
            return modelVariant;
          }

          return {
            modelVariantName: modelVariant.modelVariantName,
            number: modelVariant.number + (nb.data - robot.finalNumber),
            variantImg: modelVariant.variantImg,
          };
        });

        return variantToUpdate;
      }

      setRobots((prev) =>
        prev.map((robot) => {
          if (robot.model !== robotName) {
            return robot;
          }

          const mainVariantToUpdate = updateMainVariant(robot, nb);

          return {
            ...robot,
            finalNumber: nb.data,
            modelVariants: mainVariantToUpdate,
          };
        })
      );
    },
    []
  );

  const handleChangeRobotNbVariant = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      robotName: AvailableModelsType,
      variantName: string,
      variantNumber: number
    ) => {
      let newValue = parseInt(e.currentTarget.value, 10);

      if (e.currentTarget.value === '') newValue = 0;

      if (newValue > maxInput) newValue = maxInput;

      const nb = z.number().min(0).safeParse(newValue);
      if (!nb.success) {
        return;
      }

      setRobots((prev) =>
        prev.map((robot) => {
          if (robot.model !== robotName) {
            return robot;
          }

          const variantToUpdate = robot.modelVariants.map((modelVariant, i) => {
            if (modelVariant.modelVariantName === variantName) {
              return {
                modelVariantName: modelVariant.modelVariantName,
                number: nb.data,
                variantImg: modelVariant.variantImg,
              };
            }

            return modelVariant;
          });

          return {
            ...robot,
            finalNumber: robot.finalNumber + (nb.data - variantNumber),
            modelVariants: variantToUpdate,
            userAction: true,
          };
        })
      );
    },
    []
  );

  const submit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!isSubmitOk) return;

      const robotsToPass = robots
        .filter((robot) => robot.finalNumber > 0)
        .map((robot) => ({
          model: robot.model,
          nb: robot.finalNumber,
          modelVariants: robot.modelVariants,
        }));

      props.onSubmit(robotsToPass);
    },
    [isSubmitOk, props, robots]
  );

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string): void => {
    setExpanded((expandedPanel) => {
      if (expandedPanel === panel) {
        return false;
      }

      return panel;
    });
  };

  return (
    <form onSubmit={submit}>
      <List
        dense
        sx={{
          paddingTop: 0,
        }}
      >
        {robots.map((robot) => (
          <Accordion
            expanded={expanded === robot.model}
            key={robot.model}
            sx={{
              margintop: 0,
              marginBottom: 0,
            }}
          >
            <AccordionSummary
              expandIcon={
                <IconButton onClick={() => handleChange(robot.model)}>
                  <ExpandMoreIcon
                    sx={{
                      pointerEvents: 'auto',
                    }}
                  />
                </IconButton>
              }
              aria-controls={`${robot.model}-content`}
              id={`${robot.model}-header`}
              sx={{
                pointerEvents: 'none',
                marginTop: 0,
                marginBottom: 0,
                '& .MuiAccordionSummary-content': {
                  marginTop: 0,
                  marginBottom: 0,
                },
              }}
            >
              <ListItem
                dense
                secondaryAction={
                  <TextField
                    type="number"
                    autoComplete="off"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    value={robot.finalNumber.toString()}
                    disabled={robot.userAction}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: maxInput,
                      },
                    }}
                    onChange={(e) => handleChangeRobotNb(e, robot.model)}
                    sx={{
                      pointerEvents: 'auto',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000000',
                      },
                    }}
                  />
                }
              >
                <ListItemAvatar>
                  <Avatar alt={`Robot icon`} src={robot.imageSrc} />
                </ListItemAvatar>
                <ListItemText
                  primary={capitalize(robot.model, true)}
                  secondary={
                    <>
                      {robot.description}{' '}
                      {robot.url && (
                        <Tooltip title={`Open ${capitalize(robot.model, true)} documentation`}>
                          <IconButton
                            aria-label="Open Robot Documentation"
                            href={robot.url}
                            target="_blank"
                            sx={{
                              pointerEvents: 'auto',
                            }}
                          >
                            <OpenInNew
                              sx={{
                                fontSize: '.9rem',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                />
              </ListItem>
            </AccordionSummary>
            <AccordionDetails>
              {robot.modelVariants.map((modelVariant) => (
                <ListItem
                  dense
                  key={modelVariant.modelVariantName}
                  secondaryAction={
                    <TextField
                      type="number"
                      autoComplete="off"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      value={modelVariant.number.toString()}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: maxInput,
                        },
                      }}
                      onChange={(e) =>
                        handleChangeRobotNbVariant(e, robot.model, modelVariant.modelVariantName, modelVariant.number)
                      }
                    />
                  }
                >
                  <ListItemIcon>
                    <Avatar
                      alt={`Robot variant icon for ${modelVariant.modelVariantName}`}
                      src={`/img/trucks/${modelVariant.variantImg}`}
                      sx={{ width: 24, height: 24 }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ marginLeft: '10px' }}>{modelVariant.modelVariantName}</ListItemText>
                </ListItem>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </List>

      <Button type="submit" variant="contained" fullWidth endIcon={<ArrowForward />} disabled={!isSubmitOk}>
        Validate
      </Button>
    </form>
  );
}

interface ValidateCreateProjectProps {
  projectData: ProjectDataType;
}
function ValidateCreateProject(props: ValidateCreateProjectProps): JSX.Element {
  const { projectData } = props;

  const [isCollabOk, setIsCollabOk] = useState(false);
  const [isMailOk, setIsMailOk] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [submittingCollab, setSubmittingCollab] = useState(false);
  const [submittingMail, setSubmittingMail] = useState(false);

  const checkIfRoomIsReady = useCallback(async (roomId: string) => {
    if (!roomId) return;

    try {
      const response = await fetch(`${createProjectCollabUrl}/status/${roomId}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const json = await response.json();

      if (!json.processed) return;

      window.location.href = `/editor/${roomId}`;
    } catch (e) {}
  }, []);

  const handleProjectCreationCollabRoom = useCallback(async () => {
    if (submittingCollab) return;

    setSubmittingCollab(true);
    setError(null);

    const projectDataZod = ProjectData.safeParse(projectData);
    if (!projectDataZod.success) {
      setError(projectDataZod.error.issues[0].message);
      setSubmittingCollab(false);

      return;
    }

    try {
      const response = await fetch(createProjectCollabUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(projectDataZod.data),
        mode: 'cors',
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const json = await response.json();

      if (!response.ok || !json?.success) {
        // eslint-disable-next-line no-console
        console.warn(json);
        setError(typeof json?.message === 'string' ? json?.message : 'An error occured');
        setSubmittingCollab(false);

        return;
      }

      setInterval(() => {
        checkIfRoomIsReady(json.roomId);
      }, 1000);
      setIsCollabOk(true);
    } catch (e) {
      const networkStatus = navigator.onLine;
      setError(
        `An error occured, network status: ${networkStatus ? 'OK' : 'NOK'}, ${
          networkStatus ? 'the server might be down' : 'you might be offline'
        }`
      );
      setSubmittingCollab(false);
    }
  }, [projectData, submittingCollab, checkIfRoomIsReady]);

  const handleProjectCreation = useCallback(async () => {
    if (submittingMail) return;

    setSubmittingMail(true);
    setError(null);

    const projectDataZod = ProjectData.safeParse(projectData);
    if (!projectDataZod.success) {
      setError(projectDataZod.error.issues[0].message);
      setSubmittingMail(false);

      return;
    }

    try {
      const response = await fetch(createProjectUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(projectDataZod.data),
        mode: 'cors',
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const json = await response.json();

      if (!response.ok || !json?.success) {
        // eslint-disable-next-line no-console
        console.warn(json);
        setError(typeof json?.message === 'string' ? json?.message : 'An error occured');
        setSubmittingMail(false);

        return;
      }

      setIsMailOk(true);
    } catch (e) {
      const networkStatus = navigator.onLine;
      setError(
        `An error occured, network status: ${networkStatus ? 'OK' : 'NOK'}, ${
          networkStatus ? 'the server might be down' : 'you might be offline'
        }`
      );
      setSubmittingMail(false);
    }
  }, [projectData, submittingMail]);

  return (
    <Card
      sx={{
        background: submittingCollab ? 'rgba(0, 0, 0, 0.12)' : undefined,
      }}
    >
      {submittingCollab && <LinearProgress sx={{ marginBottom: '-4px' }} />}

      <CardContent>
        <Typography variant="h5" component="div">
          {projectData.projectName}
        </Typography>

        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {props.projectData.robots
            .map((robot) => {
              const robotNumber = robot.modelVariants.reduce((acc, variant) => acc + variant.number, 0);

              return `${robotNumber} ${robot.model}`;
            })
            .join(', ')}
        </Typography>
      </CardContent>
      <CardActions>
        <Stack direction="column" rowGap={1} flexGrow={1}>
          <Button
            fullWidth
            variant="contained"
            endIcon={<Send />}
            onClick={handleProjectCreationCollabRoom}
            disabled={submittingCollab}
          >
            Create a collaborative room with the project
          </Button>
          <Button
            fullWidth
            variant="outlined"
            endIcon={<Email />}
            onClick={handleProjectCreation}
            disabled={submittingMail}
          >
            Email me the project
          </Button>
        </Stack>
      </CardActions>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      {isCollabOk && (
        <Alert severity="success" sx={{ mt: 2 }}>
          Your multiplayer room is being created! You will be redirected once it's ready
        </Alert>
      )}

      {isMailOk && (
        <Alert severity="success" sx={{ mt: 2 }}>
          Your project is on its way to your mailbox! ({projectData.creatorEmail})
        </Alert>
      )}
    </Card>
  );
}

/**
 * Generates the source path for a robot's image based on its model and variant.
 *
 * This function takes a model reference and a variant string as inputs and returns
 * a string that represents the path to the corresponding robot image. The path
 * is determined based on specific conditions related to the model and variant provided.
 *
 * @param {ModelRef} model - The model reference of the robot.
 * @param {string} variant - The variant of the robot model.
 * @returns {string} The path to the robot's image.
 */

function variantAndModelToImageSrc(model: ModelRef, variant: string): string {
  if (model === 'lowy CB') {
    if (variant.includes('Lowy CB-A')) {
      return 'Hyster/MC10-icon.png';
    }

    return 'Linde/LmaticAC-icon.png';
  }

  if (model === 'lowy' || model === 'reachy' || model === 'trucky' || model === 'tuggy' || model === 'veeny') {
    return `Balyo/${capitalize(model)}-icon.png`;
  }

  return '';
}
