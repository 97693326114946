import type { LayerGroupData } from 'reducers/circuit/state';
import { PreferencesService } from 'utils/preferences';
import type { LayerGroupModelPreferences } from './layer-group-model-preferences.model';

export function getLayerGroupModelPreferences(layerGroup: LayerGroupData): LayerGroupModelPreferences {
  return {
    'trajectoryDriver/robotKinematicsModel/turretXPositionForksDown': ((): string => {
      try {
        return PreferencesService.getModelPreferenceValue(
          'trajectoryDriver/robotKinematicsModel/turretXPositionForksDown',
          layerGroup.name
        ) as string;
      } catch {
        return '1.15';
      }
    })(),

    'trajectoryDriver/robotKinematicsModel/maxTurretAngularVelocity': ((): string => {
      try {
        return PreferencesService.getModelPreferenceValue(
          'trajectoryDriver/robotKinematicsModel/maxTurretAngularVelocity',
          layerGroup.name
        ) as string;
      } catch {
        return '45';
      }
    })(),

    'cantonnement/pattern': ((): string[] => {
      try {
        return PreferencesService.getModelPreferenceValue('cantonnement/pattern', layerGroup.name) as string[];
      } catch {
        return [];
      }
    })(),

    'safety/pattern/Fempty': ((): string[] => {
      try {
        return PreferencesService.getModelPreferenceValue('safety/pattern/Fempty', layerGroup.name) as string[];
      } catch {
        return [];
      }
    })(),

    'safety/pattern/Fcarry': ((): string[] => {
      try {
        return PreferencesService.getModelPreferenceValue('safety/pattern/Fcarry', layerGroup.name) as string[];
      } catch {
        return [];
      }
    })(),

    'general/shape': ((): string[] => {
      try {
        return PreferencesService.getModelPreferenceValue('general/shape', layerGroup.name) as string[];
      } catch {
        return [];
      }
    })(),

    'trajectoryDriver/robotKinematicsModel/maxTurretAngle': ((): string => {
      try {
        return PreferencesService.getModelPreferenceValue(
          'trajectoryDriver/robotKinematicsModel/maxTurretAngle',
          layerGroup.name
        ) as string;
      } catch {
        return '';
      }
    })(),

    'trajectoryDriver/robotKinematicsModel/minTurretAngle': ((): string => {
      try {
        return PreferencesService.getModelPreferenceValue(
          'trajectoryDriver/robotKinematicsModel/minTurretAngle',
          layerGroup.name
        ) as string;
      } catch {
        return '';
      }
    })(),

    'trajectoryDriver/robotKinematicsModel/maxDistanceToWheels': ((): string => {
      try {
        return PreferencesService.getModelPreferenceValue(
          'trajectoryDriver/robotKinematicsModel/maxDistanceToWheels',
          layerGroup.name
        ) as string;
      } catch {
        return '';
      }
    })(),
  };
}
