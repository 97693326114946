import { AddCircle } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import type { SelectChangeEvent } from '@mui/material';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useMemo } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { addDefaultTrigger, removeTrigger, selectTrigger } from 'simulation/triggers';
import store, { useAppDispatch, useAppSelector } from 'store';

interface TriggersSelectProps {
  handleEditName?: (newValue: boolean) => void;
}
export function TriggersSelect({ handleEditName }: TriggersSelectProps): JSX.Element {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const triggers = useAppSelector((state) => state.triggers.triggers);

  const selectedTriggerId = useAppSelector((state) => state.triggers.selectedTriggerId);
  const selectedTrigger = useMemo(() => {
    return triggers.find((trigger) => trigger.id === selectedTriggerId);
  }, [selectedTriggerId, triggers]);

  const handleChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      const triggerId = e.target.value;
      dispatch(selectTrigger(triggerId));
    },
    [dispatch]
  );

  const handleAddTrigger = useCallback(() => {
    const storeState = store.getState();
    const flows = storeState.flows.flows;
    const selectedFlowId = storeState.flows.selectedFlowId ?? flows[0]?.id;
    if (!selectedFlowId) {
      SnackbarUtils.error(`The trigger has not been created. Please select a flow first.`);

      return;
    }

    dispatch(
      addDefaultTrigger({
        flowId: selectedFlowId,
      })
    );

    const newTrigger = store.getState().triggers.triggers.at(-1);
    if (!newTrigger) {
      SnackbarUtils.error(`The trigger has not been created.`);

      return;
    }

    dispatch(selectTrigger(newTrigger.id));

    SnackbarUtils.success(`Trigger "${newTrigger.name}" created`);
  }, [dispatch]);

  const handleDeleteTrigger = useCallback(async () => {
    if (!selectedTriggerId) {
      // eslint-disable-next-line no-console
      console.error(`No trigger selected`);

      return;
    }

    try {
      await confirm({ title: `Are you sure you want to remove "${selectedTrigger?.name}"?` });
    } catch (e) {
      return;
    }

    dispatch(removeTrigger(selectedTriggerId));
  }, [confirm, dispatch, selectedTrigger?.name, selectedTriggerId]);

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="select-trigger-label">Triggers</InputLabel>
        <Select
          labelId="select-trigger-label"
          label="Triggers"
          value={selectedTriggerId ?? ''}
          onChange={handleChange}
          size="small"
        >
          {triggers.map((trigger) => (
            <MenuItem key={trigger.id} value={trigger.id}>
              {trigger.name}
            </MenuItem>
          ))}
          {triggers.length === 0 && <MenuItem disabled>No triggers</MenuItem>}
        </Select>
      </FormControl>

      <Tooltip title="Rename this trigger">
        <Box component="span">
          <IconButton
            aria-label="rename trigger"
            onClick={() => handleEditName && handleEditName(true)}
            disabled={!selectedTriggerId}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Delete this trigger">
        <Box component="span">
          <IconButton aria-label="delete trigger" onClick={handleDeleteTrigger} disabled={!selectedTriggerId}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Create a new trigger">
        <Box component="span">
          <IconButton aria-label="create trigger" onClick={handleAddTrigger}>
            <AddCircle fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
}
