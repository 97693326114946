import { combineEpics } from 'redux-observable';
import { combineAuthEpics } from './auth.epic';
import { combineCircuitEpics } from './circuit.epic';
import { combineImagesEpics } from './images.epic';
import { combineMapsEpics } from './maps.epic';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const rootEpic = combineEpics(combineAuthEpics(), combineMapsEpics(), combineCircuitEpics(), combineImagesEpics);

export default rootEpic;
