import LightbulbIcon from '@mui/icons-material/Lightbulb';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Checkbox,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  TextField,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import type { CircuitRack } from 'models/circuit';
import type { MouseEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { LoadedCellTemplate } from 'reducers/circuit/state';
import store from 'store';
import { useDebounce } from 'use-debounce';
import { computeAutoExtendedLength, nbDigitsExtendedLength } from 'utils/extended-length';
import { toDigits } from 'utils/helpers';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import {
  getMaxPalletOverflow,
  getMaxPalletToUpright,
  getMaxPalletWidth,
  getMaxUprightYTolerance,
} from './utils-extended-length';

interface ExtendedLengthTextfieldProps {
  extendedLength: number;
  isProjectLoaded: boolean;
  locked?: boolean;
  rack?: CircuitRack;
  handleSetExtendedLength: (extendedLength: number) => void;
  onExtendedLengthChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function ExtendedLengthTextfield(props: ExtendedLengthTextfieldProps): JSX.Element {
  const { isProjectLoaded, locked, extendedLength, rack, handleSetExtendedLength, onExtendedLengthChangeHandler } =
    props;

  const projectModelNames = useMemo(
    () => (isProjectLoaded ? PreferencesService.getModelNames() : []),
    [isProjectLoaded]
  );
  const [modelNamesToUse, setModelNamesToUse] = useState<string[]>(projectModelNames);
  useEffect(() => {
    if (!isProjectLoaded) return;

    setModelNamesToUse(projectModelNames);
  }, [isProjectLoaded, projectModelNames]);

  const rackMaxPalletOverflow = useMemo(() => getMaxPalletOverflow(rack), [rack]);

  const rackMaxPalletWidth = useMemo(() => getMaxPalletWidth(rack), [rack]);

  const rackMaxPalletToUpright = useMemo(() => getMaxPalletToUpright(rack), [rack]);

  const rackMaxUprightYTolerance = useMemo(() => getMaxUprightYTolerance(rack), [rack]);

  const autoExtendedLengthsByModel = useMemo(() => {
    if (!isProjectLoaded) return undefined;

    const cellTemplates = store.getState().circuit.present.cellTemplates.entities;

    const allCellTemplateUsed = new Set<string>();
    rack?.properties.columns.forEach((column) =>
      column.cells.forEach((cell) => {
        if (cell.cellTemplate) allCellTemplateUsed.add(cell.cellTemplate);
      })
    );
    let maxApproachDistance = 0;
    allCellTemplateUsed.forEach((cellTemplateId) => {
      const cellTemplate: LoadedCellTemplate | undefined = cellTemplates[cellTemplateId];
      if (!cellTemplate) {
        // eslint-disable-next-line no-console
        console.error(`Cell template ${cellTemplateId} not found`);

        return;
      }

      const approachDistance = cellTemplate.approachDistance;
      if (approachDistance > maxApproachDistance) {
        maxApproachDistance = approachDistance;
      }
    });

    const computedExtendedLengthsByModel = projectModelNames
      .map((modelName) => {
        try {
          const extendedLength = computeAutoExtendedLength({
            safetyPointMargin: maxApproachDistance,
            modelNames: [modelName],
            palletOverflow: rackMaxPalletOverflow,
            distancePalletToUpright: rackMaxPalletToUpright,
            palletWidth: rackMaxPalletWidth,
            uprightYTolerance: rackMaxUprightYTolerance,
          });

          return {
            modelName,
            extendedLength,
          };
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);

          return {
            modelName,
            extendedLength: -1,
          };
        }
      })
      .filter((computedValue) => computedValue.extendedLength >= 0);

    if (!computedExtendedLengthsByModel.length) return undefined;

    return computedExtendedLengthsByModel;
  }, [
    isProjectLoaded,
    projectModelNames,
    rack?.properties.columns,
    rackMaxPalletOverflow,
    rackMaxPalletToUpright,
    rackMaxPalletWidth,
    rackMaxUprightYTolerance,
  ]);

  const autoExtendedLength = useMemo(() => {
    // in this function we get the computed extended length for all the selected robot models by the user and get the maximum of it
    // we consider the max to be the most restrictive case

    if (!autoExtendedLengthsByModel) return undefined;

    const autoExtendedLengthsByUsedModel = autoExtendedLengthsByModel.filter((autoExtendedLengthByModel) =>
      modelNamesToUse.includes(autoExtendedLengthByModel.modelName)
    );

    if (!autoExtendedLengthsByUsedModel.length) return undefined;

    return Math.max(
      ...autoExtendedLengthsByUsedModel.map((autoExtendedLengthByModel) => autoExtendedLengthByModel.extendedLength)
    );
  }, [autoExtendedLengthsByModel, modelNamesToUse]);

  const colorWarningOrUndefined =
    autoExtendedLength && extendedLength < autoExtendedLength ? 'warning.main' : 'undefined';
  const colorWarningOrPrimary =
    autoExtendedLength && extendedLength < autoExtendedLength ? 'warning.main' : 'primary.main';

  const warningText =
    autoExtendedLength && extendedLength < autoExtendedLength
      ? 'The defined extended length is under the suggested one'
      : '';

  return (
    <TextField
      type="number"
      value={extendedLength}
      sx={{
        margin: theme.spacing(1),
        width: '100%',
        textAlign: 'left',
        '& .MuiInput-root': {
          // Bottom border
          '&:before': {
            borderColor: colorWarningOrUndefined,
          },
          // Border on focus
          '&:after': {
            borderColor: colorWarningOrPrimary,
          },
          ':hover:not(.Mui-focused)': {
            '&:before': {
              borderColor: colorWarningOrUndefined,
            },
          },
        },
        '& .MuiInputLabel-standard': {
          color: colorWarningOrUndefined,
        },
      }}
      onChange={onExtendedLengthChangeHandler}
      fullWidth
      margin="normal"
      label="Extended length"
      disabled={locked}
      inputProps={{ step: 0.01, min: 0 }}
      InputProps={{
        endAdornment: (
          <EndAdornmentTextfieldExtendedLength
            extendedLength={extendedLength}
            isProjectLoaded={isProjectLoaded}
            locked={locked}
            handleSetExtendedLength={handleSetExtendedLength}
            modelNamesToUse={modelNamesToUse}
            setModelNamesToUse={setModelNamesToUse}
            autoExtendedLength={autoExtendedLength}
            autoExtendedLengthsByModel={autoExtendedLengthsByModel}
            projectModelNames={projectModelNames}
          />
        ),
      }}
      variant="standard"
      helperText={warningText}
      FormHelperTextProps={{ sx: { color: 'warning.main' } }}
    />
  );
}

interface EndAdornmentTextfieldExtendedLengthProps {
  extendedLength: number;
  isProjectLoaded: boolean;
  locked?: boolean;
  handleSetExtendedLength: (extendedLength: number) => void;
  modelNamesToUse: string[];
  setModelNamesToUse: React.Dispatch<React.SetStateAction<string[]>>;
  autoExtendedLength: number | undefined;
  autoExtendedLengthsByModel:
    | {
        modelName: string;
        extendedLength: number;
      }[]
    | undefined;
  projectModelNames: string[];
}

export function EndAdornmentTextfieldExtendedLength(props: EndAdornmentTextfieldExtendedLengthProps): JSX.Element {
  const {
    isProjectLoaded,
    locked,
    extendedLength,
    handleSetExtendedLength,
    modelNamesToUse,
    setModelNamesToUse,
    autoExtendedLength,
    autoExtendedLengthsByModel,
    projectModelNames,
  } = props;

  const [openMenuRefIcon, setOpenMenuRefIcon] = useState<HTMLButtonElement | null>(null);
  const [openMenuRefIconDelayed] = useDebounce(openMenuRefIcon, 1000);

  const handleClickDisplayRobotModels = useCallback((e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    const buttonEl = e.currentTarget;
    setOpenMenuRefIcon(buttonEl);
  }, []);

  const handleToggleRobotModel = useCallback(
    (robotModelName: string) => {
      const newRobotModelsNamesToUse = [...modelNamesToUse];
      const index = newRobotModelsNamesToUse.indexOf(robotModelName);
      if (index === -1) {
        newRobotModelsNamesToUse.push(robotModelName);
      } else {
        newRobotModelsNamesToUse.splice(index, 1);
      }

      setModelNamesToUse(newRobotModelsNamesToUse);
    },
    [modelNamesToUse, setModelNamesToUse]
  );

  const epsilonExtendedLength = 0.01;

  const isExtendedLengthOk =
    autoExtendedLength !== undefined &&
    Math.abs(extendedLength - autoExtendedLength) < epsilonExtendedLength &&
    extendedLength >= autoExtendedLength;
  const lightBulbColor = isExtendedLengthOk ? 'success' : 'primary';

  return (
    <InputAdornment position="end">
      <>
        {autoExtendedLength !== undefined ? (
          <Tooltip
            title={`Suggested extended length with the robots used: ${toDigits(
              autoExtendedLength,
              nbDigitsExtendedLength
            )} m`}
            sx={{
              marginRight: theme.spacing(1),
            }}
          >
            <Box component="span">
              <IconButton
                onClick={() => {
                  if (autoExtendedLength !== undefined) handleSetExtendedLength(autoExtendedLength);
                }}
                disabled={locked}
              >
                <LightbulbIcon fontSize="small" color={locked ? undefined : lightBulbColor} />
              </IconButton>
            </Box>
          </Tooltip>
        ) : null}
        {isProjectLoaded ? (
          <Tooltip
            title="Model to use to auto-compute the extended length"
            sx={{
              marginRight: theme.spacing(1),
            }}
          >
            <Box component={'span'}>
              <IconButton onClick={handleClickDisplayRobotModels} disabled={locked}>
                <MoreVertIcon fontSize="small" />
              </IconButton>
            </Box>
          </Tooltip>
        ) : null}
        {isProjectLoaded && (openMenuRefIcon || openMenuRefIconDelayed) ? (
          <Menu
            anchorEl={openMenuRefIcon}
            open={!!openMenuRefIcon}
            onClose={() => {
              setOpenMenuRefIcon(null);
            }}
          >
            {projectModelNames.map((robotModelName) => {
              const extendedLengthForThisModel = autoExtendedLengthsByModel
                ? autoExtendedLengthsByModel.find(
                    (autoExtendedLengthByModel) => autoExtendedLengthByModel.modelName === robotModelName
                  )?.extendedLength
                : undefined;

              return (
                <ListItem
                  key={robotModelName}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={() => {
                        handleToggleRobotModel(robotModelName);
                      }}
                      checked={!!modelNamesToUse.includes(robotModelName)}
                    />
                  }
                  disablePadding
                >
                  <ListItemButton
                    onClick={() => {
                      handleToggleRobotModel(robotModelName);
                    }}
                  >
                    <ListItemText
                      primary={robotModelName}
                      secondary={
                        extendedLengthForThisModel
                          ? `${toDigits(extendedLengthForThisModel, nbDigitsExtendedLength)} m`
                          : undefined
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </Menu>
        ) : null}
        m
      </>
    </InputAdornment>
  );
}
