import PromisePool from '@supercharge/promise-pool';
import * as zip from '@zip.js/zip.js';
import { closeDialogAction } from 'actions';
import { loadProject } from 'services/project';
import store from 'store';
import { PreferencesService, writeToFile } from 'utils/preferences';
import { clearNavigatorStorage } from './navigator-storage';

export async function downloadProjectDirectory(): Promise<void> {
  const directoryHandle = await PreferencesService.getDirectoryHandle();
  if (!directoryHandle) {
    // eslint-disable-next-line no-console
    console.log('No directory handle found');

    return;
  }

  await downloadDirectoryContent(directoryHandle, ['.archives', '.git'], true);
}

/**
 * Recursively searches for a directory containing a subdirectory with the given keyword.
 * @param directoryHandle The root directory handle to start the search from.
 * @param keyword The keyword to search for in subdirectory names.
 * @returns A Promise that resolves to the FileSystemDirectoryHandle of the directory containing the keyword, or null if not found.
 */
async function findDirectoryWithKeyword(
  directoryHandle: FileSystemDirectoryHandle,
  keyword: string
): Promise<FileSystemDirectoryHandle | null> {
  // Check if the current directory contains a subdirectory with the given keyword
  try {
    await directoryHandle.getDirectoryHandle(keyword);

    return directoryHandle;
  } catch (error) {
    // Keyword not found in this directory, continue searching
  }

  // Recursively search subdirectories
  for await (const entry of directoryHandle.values()) {
    if (entry.kind === 'directory') {
      const result = await findDirectoryWithKeyword(entry, keyword);
      if (result) {
        return result;
      }
    }
  }

  // Directory with the keyword not found in this branch
  return null;
}

/**
 * Finds the project root directory by searching for a 'PREF' subdirectory.
 * @param directoryHandle The starting directory handle to search from.
 * @returns A Promise that resolves to the FileSystemDirectoryHandle of the project root, or null if not found.
 */
export async function findProjectRoot(
  directoryHandle: FileSystemDirectoryHandle
): Promise<FileSystemDirectoryHandle | null> {
  const projectRoot = await findDirectoryWithKeyword(directoryHandle, 'PREF');
  if (!projectRoot) {
    // eslint-disable-next-line no-console
    console.warn('No directory containing a "PREF" subdirectory was found.');
  }

  return projectRoot;
}

interface LoadProjectDirectoryOptions {
  forceSimulationVersion?: string;
}

export async function loadProjectDirectory(options?: LoadProjectDirectoryOptions): Promise<void> {
  const input = document.createElement('input');
  input.id = 'load-project-directory';
  input.type = 'file';
  input.accept = '.zip';
  input.style.display = 'none';
  document.body.appendChild(input);

  async function handleFileSelection(file: File): Promise<Blob> {
    try {
      const fileContent = await file.arrayBuffer();
      const blob = new Blob([fileContent], { type: file.type });
      // eslint-disable-next-line no-console
      console.log('Processed zip file:', file.name);

      return blob;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error processing zip file:', error);
      throw error;
    }
  }

  input.addEventListener('change', (event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      // eslint-disable-next-line no-console
      console.log('File selected:', file.name);
    }

    document.body.removeChild(input);
  });

  input.click();

  const file = await new Promise<File>((resolve) => {
    const checkFile = setInterval(() => {
      const selectedFile = input.files?.[0];
      if (selectedFile) {
        clearInterval(checkFile);
        resolve(selectedFile);
      }
    }, 100);
  });

  const fileBlob = await handleFileSelection(file);

  await clearNavigatorStorage();

  const directoryHandle = await navigator.storage.getDirectory();

  await loadDirectoryContent(fileBlob, directoryHandle);

  const projectRoot = await findProjectRoot(directoryHandle);

  if (!projectRoot) {
    throw new Error('No project root directory found');
  }

  PreferencesService.setDirectoryHandle(projectRoot);

  if (options?.forceSimulationVersion) {
    await changeInstallXmlVersion(projectRoot, options.forceSimulationVersion);
  }

  await loadProject({
    keepDirectoryHandle: true,
    newCircuit: false,
  });

  store.dispatch(closeDialogAction());

  await new Promise((resolve) => setTimeout(resolve, 1000));
}

/**
 * Changes the version in the install.xml file of a project.
 *
 * @param projectRoot - The FileSystemDirectoryHandle of the project root directory.
 * @param version - The new version string to set in the install.xml file.
 * @returns A Promise that resolves to true if the version was successfully changed, false otherwise.
 */
async function changeInstallXmlVersion(projectRoot: FileSystemDirectoryHandle, version: string): Promise<boolean> {
  try {
    const prefDirectory = await projectRoot.getDirectoryHandle('PREF');
    const installXmlFileHandle = await prefDirectory.getFileHandle('install.xml');
    const installXmlFile = await installXmlFileHandle.getFile();
    const installXmlText = await installXmlFile.text();

    // Parse the XML
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(installXmlText, 'text/xml');

    // Find the Preferences element
    const preferencesElement = xmlDoc.querySelector('Preferences');

    if (preferencesElement) {
      // Update the sdk attribute
      preferencesElement.setAttribute('sdk', version);

      // Serialize the updated XML
      const serializer = new XMLSerializer();
      const updatedXmlText = serializer.serializeToString(xmlDoc);

      // Write the updated XML back to the file
      const writable = await installXmlFileHandle.createWritable();
      await writable.write(updatedXmlText);
      await writable.close();

      return true;
    }

    // eslint-disable-next-line no-console
    console.error('Preferences element not found in install.xml');

    return false;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error changing install.xml version', e);

    return false;
  }
}

/**
 * Downloads all the content from a FileSystemDirectoryHandle and returns it as a Blob.
 * This function can be used to save the content for later loading.
 * @param directoryHandle The FileSystemDirectoryHandle to download content from.
 * @param actualDownload If true, the content will be downloaded to a Blob and returned. If false, the content will be added to a zip.ZipWriter but not actually downloaded.
 * @returns A Promise that resolves to a Blob containing all the directory content.
 */
async function downloadDirectoryContent(
  directoryHandle: FileSystemDirectoryHandle,
  directoiesToSkip: string[] = [],
  actualDownload = false
): Promise<Blob> {
  // eslint-disable-next-line no-console
  console.log(`Starting to download content from directory: ${directoryHandle.name}`);
  const zipWriter = new zip.ZipWriter(new zip.BlobWriter('application/zip'));
  // eslint-disable-next-line no-console
  console.log('Created zip writer');

  async function addDirectoryToZip(dir: FileSystemDirectoryHandle, path: string): Promise<void> {
    // eslint-disable-next-line no-console
    console.log(`Adding directory to zip: ${path}`);
    for await (const entry of dir.values()) {
      if (entry.kind === 'file') {
        // eslint-disable-next-line no-console
        console.log(`Adding file to zip: ${path}${entry.name}`);
        const file = await entry.getFile();
        await zipWriter.add(`${path}${entry.name}`, new zip.BlobReader(file));
        // eslint-disable-next-line no-console
        console.log(`Added file to zip: ${path}${entry.name}`);
      } else if (entry.kind === 'directory') {
        if (directoiesToSkip.includes(entry.name)) {
          // eslint-disable-next-line no-console
          console.log(`Skipping directory: ${path}${entry.name}/`);
          continue;
        }

        // eslint-disable-next-line no-console
        console.log(`Recursing into directory: ${path}${entry.name}/`);
        await addDirectoryToZip(entry, `${path}${entry.name}/`);
      }
    }
  }

  await addDirectoryToZip(directoryHandle, '');
  // eslint-disable-next-line no-console
  console.log('Finished adding all entries to zip');
  const blob = await zipWriter.close();
  // eslint-disable-next-line no-console
  console.log('Closed zip writer and got blob');

  if (actualDownload) {
    // eslint-disable-next-line no-console
    console.log('Triggering download');
    // trigger the download
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${directoryHandle.name}.zip`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  return blob;
}

/**
 * Loads the content from a previously downloaded Blob into a specified directory handle.
 * This function can be used to restore the storage state to a specific directory.
 * @param blob The Blob containing the storage content to load.
 * @param directoryHandle The FileSystemDirectoryHandle to load the content into.
 */
async function loadDirectoryContent(blob: Blob, directoryHandle: FileSystemDirectoryHandle): Promise<void> {
  // eslint-disable-next-line no-console
  console.log('Starting to load content into directory');
  const zipReader = new zip.ZipReader(new zip.BlobReader(blob));
  const entries = await zipReader.getEntries();

  const nbEntries = entries.length;
  let nbEntriesProcessed = 0;

  await PromisePool.for(entries)
    .withConcurrency(10)
    .process(async (entry) => {
      nbEntriesProcessed++;

      const progress = (nbEntriesProcessed / nbEntries) * 100;
      if (Math.floor(progress) % 5 === 0) {
        // eslint-disable-next-line no-console
        console.log(`${progress.toFixed(2)}% (${nbEntriesProcessed}/${nbEntries}) (${entry.filename})`);
      }

      try {
        if (entry.directory) {
          await createDirectoryIfNotExists(directoryHandle, entry.filename);
        } else {
          await createFileIfNotExists(directoryHandle, entry);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(
          `Error processing entry "${entry.filename}": ${error instanceof Error ? error.message : 'Unknown error'}`
        );
      }
    });

  await zipReader.close();

  // eslint-disable-next-line no-console
  console.log('Finished loading content into directory');
}

async function createDirectoryIfNotExists(rootDir: FileSystemDirectoryHandle, path: string): Promise<void> {
  const parts = path.split('/').filter(Boolean).map(sanitizeName);
  let currentDir = rootDir;

  for (const part of parts) {
    try {
      currentDir = await currentDir.getDirectoryHandle(part, { create: true });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to create directory "${part}": ${error instanceof Error ? error.message : 'Unknown error'}`);
      break;
    }
  }
}

async function createFileIfNotExists(rootDir: FileSystemDirectoryHandle, entry: zip.Entry): Promise<void> {
  const pathParts = entry.filename.split('/').map(sanitizeName);
  const fileName = pathParts.pop();
  let currentDir = rootDir;

  for (const part of pathParts) {
    if (part) {
      try {
        currentDir = await currentDir.getDirectoryHandle(part, { create: true });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(
          `Failed to access directory "${part}": ${error instanceof Error ? error.message : 'Unknown error'}`
        );

        return;
      }
    }
  }

  if (fileName) {
    try {
      const fileHandle = await currentDir.getFileHandle(fileName, { create: true });
      const writable = await fileHandle.createWritable();
      if (entry.getData) {
        await writable.write(await entry.getData(new zip.BlobWriter()));
      }

      await writable.close();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to create file "${fileName}": ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  }
}

/**
 * Recursively copies the contents of one directory to another.
 * @param sourceDir The source directory to copy from.
 * @param targetDir The target directory to copy to.
 */
export async function copyDirectory(
  sourceDir: FileSystemDirectoryHandle,
  targetDir: FileSystemDirectoryHandle
): Promise<void> {
  // eslint-disable-next-line no-console
  console.log(`Copying directory: ${sourceDir.name} to ${targetDir.name}`);
  for await (const entry of sourceDir.values()) {
    if (entry.kind === 'file') {
      // eslint-disable-next-line no-console
      console.log(`Copying file: ${entry.name}`);
      const file = await entry.getFile();
      const writeHandle = await targetDir.getFileHandle(entry.name, { create: true });
      await writeToFile(writeHandle, await file.text());
      // eslint-disable-next-line no-console
      console.log(`File copied: ${entry.name}`);
    } else if (entry.kind === 'directory') {
      // eslint-disable-next-line no-console
      console.log(`Creating directory: ${entry.name}`);
      const newDir = await targetDir.getDirectoryHandle(entry.name, { create: true });
      await copyDirectory(entry, newDir);
      // eslint-disable-next-line no-console
      console.log(`Directory copied: ${entry.name}`);
    }
  }

  // eslint-disable-next-line no-console
  console.log(`Finished copying directory: ${sourceDir.name}`);
}

function sanitizeName(name: string): string {
  // Remove or replace characters that are not allowed in file/directory names
  // eslint-disable-next-line no-control-regex
  return name.replace(/[<>:"/\\|?*\x00-\x1F]/g, '_').trim();
}
