import { useLayoutEffect } from 'react';

export function GoogleTagManager(): JSX.Element | null {
  const measurementId = import.meta.env.VITE_FEATURE_FLAG_GA_MEASUREMENT_ID;

  useLayoutEffect(() => {
    if (!measurementId) {
      // eslint-disable-next-line no-console
      console.log('Google Tag Manager measurement ID is not set');

      return undefined;
    }

    const script = document.createElement('script');
    script.id = 'google-tag-manager';
    script.async = true;
    script.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${measurementId}');
        `;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [measurementId]);

  if (!measurementId) return null;

  return null;
}
