import type { SimulationService } from 'components/toolboxes/simulation';
import type { SimulationSliceState } from 'simulation/simulation';
import { setAllErrorsMonitoringMode, setSpeedFactor } from 'simulation/simulation';
import store from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { SnackbarUtils } from './snackbar.service';

export type ErrorMonitoringAction = 'StopSimu' | 'Continue' | 'ResetRobot';
type StopSimuError = keyof SimulationSliceState['errorsMonitoring'] | 'other';

type Response = {
  errorMonitoringAction: ErrorMonitoringAction;
  robotIdsResetReport?: number[];
};
export function isErrorMonitoringAction(value: string): value is ErrorMonitoringAction {
  const typedValue = value as ErrorMonitoringAction;

  return typedValue === 'StopSimu' || typedValue === 'Continue' || typedValue === 'ResetRobot';
}

interface GetErrorsMonitoringActionParams {
  robotId?: number;
}

const resetInfinityLoopInterval = 5; // [s]
/**
 * key = robot ID
 * value = number of times the robot has been reset in the last 5 seconds (real)
 */
let infinityLoopPrevent: Record<number, number> = {};

const maxNbResetPerRobot = 5;

setInterval(() => {
  infinityLoopPrevent = {};
}, resetInfinityLoopInterval * 1000);

export async function getErrorsMonitoringAction(
  stopSimuError: StopSimuError,
  params: GetErrorsMonitoringActionParams,
  simulationService: SimulationService
): Promise<Response> {
  const actionToPerform =
    stopSimuError === 'other' ? 'StopSimu' : store.getState().simulation.errorsMonitoring[stopSimuError];

  const simulationSpeedFactor = store.getState().simulation.speedFactor;

  const robotsIds: number[] = [];

  switch (actionToPerform) {
    case 'StopSimu': {
      // eslint-disable-next-line no-console
      console.log('stopSimu received, setting the speed to 0...');
      store.dispatch(setSpeedFactor(0));
      break;
    }

    case 'Continue': {
      // eslint-disable-next-line no-console
      console.log('stopSimu received, but stopSimuErrorMode is set to continue, ignoring it');
      await simulationService._WEBSIMU_WasmWrapper_setSpeed(simulationSpeedFactor);

      break;
    }

    case 'ResetRobot': {
      // eslint-disable-next-line no-console
      console.log(`stopSimu received, but stopSimuErrorMode is set to reset, resetting robots`);

      let i = 100;
      while (!robotsIds.length) {
        if (params.robotId) {
          robotsIds.push(params.robotId);
        }

        if (!robotsIds.length) {
          i--;
          await new Promise((resolve) => {
            setTimeout(resolve, 100);
          });

          if (!i) break;
        }
      }

      for (let i = 0; i < robotsIds.length; i++) {
        const robotId = robotsIds[i];

        // eslint-disable-next-line no-console
        console.log(`Resetting robot ${robotId}`);
        await simulationService._ROBEMU_WasmWrapper_resetRobot(robotId);

        infinityLoopPrevent[robotId] = (infinityLoopPrevent[robotId] ?? 0) + 1;
      }

      Object.values(infinityLoopPrevent).forEach((nbReset) => {
        if (nbReset > maxNbResetPerRobot) {
          SnackbarUtils.error('Too many reset, advanced errors monotoring set to stop');
          store.dispatch(
            setAllErrorsMonitoringMode({
              newValue: 'StopSimu',
            })
          );
        }
      });

      await simulationService._WEBSIMU_WasmWrapper_setSpeed(simulationSpeedFactor);

      break;
    }

    default: {
      assert<Equals<typeof actionToPerform, never>>();
    }
  }

  return { errorMonitoringAction: actionToPerform, robotIdsResetReport: robotsIds };
}

export function humanifyErrorType(errorType: 'deadlock' | 'robotError'): string {
  if (errorType === 'robotError') {
    return 'robot error';
  }

  return 'deadlock';
}
