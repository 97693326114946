import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Menu, MenuItem, Tooltip } from '@mui/material';
import type { MultipleStations, Station } from 'flows/flows';
import { useState } from 'react';

interface EditStationMenuProps {
  selectedStations: MultipleStations;
  stations: Station[];
  editStationPerm: boolean | undefined;
  handleEditStation: (stationId: string) => void;
}

export function EditStationMenu(props: EditStationMenuProps): JSX.Element {
  const { selectedStations, stations, editStationPerm, handleEditStation } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Tooltip title={!editStationPerm ? 'You do not have the permission to edit stations' : ''}>
      <Box component="span">
        <Button
          id="edit-station-button"
          aria-controls={open ? 'edit-station-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(e) => {
            selectedStations.id.length < 2 ? handleEditStation(selectedStations.id[0]) : handleClick(e);
          }}
          startIcon={<EditIcon />}
          sx={{
            textTransform: 'none',
          }}
          size="small"
          disabled={!editStationPerm}
        >
          Edit the station
        </Button>
        <Menu
          id="edit-staion-menu"
          aria-labelledby="edit-station-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {selectedStations.id.map((stationId) => {
            const name = stations.find((station) => station.id === stationId)?.name;

            if (!name) {
              return null;
            }

            return (
              <MenuItem
                key={stationId}
                onClick={() => {
                  handleEditStation(stationId);
                  handleClose();
                }}
              >
                {name}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    </Tooltip>
  );
}
