import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import type { Flow } from 'flows/flows';
import { getSteps } from 'flows/get-steps-from-flow';
import { useCallback } from 'react';
import { useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';

interface DisplayStationsCellProps {
  flow: Flow;
}
export function DisplayStationsCell(props: DisplayStationsCellProps): JSX.Element {
  const { flow } = props;

  const displayStations = useCallback((flow: Flow) => {
    const stationsListId = flow.stations;
    const lastIndex = stationsListId.length - 1;

    if (stationsListId.length > 0) {
      return (
        <Stepper nonLinear alternativeLabel>
          {stationsListId.map((stationId, index) => (
            <Step key={index} active={index === 0 || index === lastIndex}>
              <StepLabel icon={index + 1} color="inherit">
                {(index === 0 || index === lastIndex) && (
                  <GetLabelCell flow={flow} stationId={stationId.id} indexStation={index} />
                )}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      );
    }

    return <Typography variant="body1">No station assigned</Typography>;
  }, []);

  return displayStations(flow);
}

interface GetLabelCelllProps {
  stationId: string | string[];
  indexStation?: number;
  flow: Flow;
}
export function GetLabelCell(props: GetLabelCelllProps): JSX.Element {
  const { stationId, indexStation = 0, flow } = props;

  const stations = useAppSelector((state) => state.flows.stations);
  const steps = getSteps(flow);

  const getStationById = useCallback(
    (stationId: string | string[]) => {
      if (Array.isArray(stationId)) {
        return stations.filter((station) => stationId.includes(station.id));
      }

      return stations.filter((station) => station.id === stationId);
    },
    [stations]
  );

  const station = getStationById(stationId);
  const stationsListId = flow.stations;
  const lastIndex = stationsListId.length - 1;

  return (
    <>
      {station.map((stationOfFlow, index) => (
        <Typography variant="body2" key={stationOfFlow.id} sx={{ fontWeight: 600, color: theme.palette.grey[800] }}>
          {stationOfFlow.name}
          {station.length > 1 && index !== lastIndex && ','}
        </Typography>
      ))}
      <Typography component="div" sx={{ color: 'gray' }}>
        {steps?.[indexStation].name}
      </Typography>
    </>
  );
}
