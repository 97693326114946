import { segmentLineStrokeWidthToStrokeWidth } from 'drawings/editor-elements';
import React, { useMemo } from 'react';
import { useAppSelector } from 'store';
import type { TrafficSliceState } from 'traffic/traffic';
import { cantonsColors, type CantonsDict } from 'traffic/traffic';
import { highlightStrokeWidthFactor } from './itineraries';

type CantonNotExtended = CantonsDict[0];

type CantonExtended = {
  id: string;
  cantonType: string;
  color: string;
};

type CantonsDictKeyExtended = (CantonNotExtended & CantonExtended) | undefined;

export const DisplayTrafficComponentMemo = React.memo(DisplayTrafficComponent);

export function DisplayTrafficComponent(): JSX.Element {
  const hoveredRobotIndex = useAppSelector((state) => state.robots.hoveredRobotIndex);
  const performanceModeEnabled = useAppSelector((state) => state.editor.isPerformanceModeEnabled);

  const noStopCantons = useAppSelector((state) => state.traffic.noStopCantons);
  const noStopZoneCantons = useAppSelector((state) => state.traffic.noStopZoneCantons);
  const currentCantons = useAppSelector((state) => state.traffic.currentCantons);
  const linkedByCollisionCantons = useAppSelector((state) => state.traffic.linkedByCollision);
  const linkedByDeadendCantons = useAppSelector((state) => state.traffic.linkedByDeadend);
  const linkedByZoneCantons = useAppSelector((state) => state.traffic.linkedByZone);
  const manualCantons = useAppSelector((state) => state.traffic.manualCantons);
  const truckPatterns = useAppSelector((state) => state.traffic.truckPatterns);
  const manoeuverCantons = useAppSelector((state) => state.traffic.manoeuver);
  const kernelNoStopCantons = useAppSelector((state) => state.traffic.kernelNoStop);
  const neutralCantons = useAppSelector((state) => state.traffic.neutral);
  const blockedCantons = useAppSelector((state) => state.traffic.blocked);
  const occupiedCantons = useAppSelector((state) => state.traffic.occupiedCantons);
  const reservedCantons = useAppSelector((state) => state.traffic.reservedCantons);

  const displayCantons = useAppSelector((state) => state.traffic.display);

  const strokeSize = useAppSelector((state) => state.tool.lineStrokeSize);

  const strokeWidth = useMemo(() => {
    return 2 * segmentLineStrokeWidthToStrokeWidth(strokeSize);
  }, [strokeSize]);

  const cantons: CantonsDictKeyExtended[] = useMemo(() => {
    return [
      ...concatIfNeeded(displayCantons, 'noStopCantons', noStopCantons, 'noStopCantons', cantonsColors.noStopCantons),
      ...concatIfNeeded(
        displayCantons,
        'noStopZoneCantons',
        noStopZoneCantons,
        'noStopZoneCanton',
        cantonsColors.noStopZoneCantons
      ),
      ...concatIfNeeded(displayCantons, 'manoeuver', manoeuverCantons, 'manoeuver', cantonsColors.manoeuver),
      ...concatIfNeeded(
        displayCantons,
        'kernelNoStop',
        kernelNoStopCantons,
        'kernelNoStop',
        cantonsColors.kernelNoStop
      ),
      ...concatIfNeeded(displayCantons, 'neutral', neutralCantons, 'neutral', cantonsColors.neutral),
      ...concatIfNeeded(displayCantons, 'blocked', blockedCantons, 'blocked', cantonsColors.blocked),
      ...concatIfNeeded(displayCantons, 'manualCantons', manualCantons, 'manualCanton', cantonsColors.manualCantons),
      ...concatIfNeeded(
        displayCantons,
        'linkedByCollision',
        linkedByCollisionCantons,
        'linkedByCollisionCanton',
        cantonsColors.linkedByCollisionCantons
      ),
      ...concatIfNeeded(
        displayCantons,
        'linkedByZone',
        linkedByZoneCantons,
        'linkedByZoneCanton',
        cantonsColors.linkedByZoneCantons
      ),
      ...concatIfNeeded(
        displayCantons,
        'linkedByDeadend',
        linkedByDeadendCantons,
        'linkedByDeadendCanton',
        cantonsColors.linkedByDeadendCantons
      ),
      ...concatIfNeeded(
        displayCantons,
        'reservedCantons',
        reservedCantons,
        'reservedCantons',
        cantonsColors.reservedCantons
      ),
      ...concatIfNeeded(
        displayCantons,
        'occupiedCantons',
        occupiedCantons,
        'occupiedCantons',
        cantonsColors.occupiedCantons
      ),
      ...concatIfNeeded(
        displayCantons,
        'currentCantons',
        currentCantons,
        'currentCanton',
        cantonsColors.currentCantons
      ),
    ]; // current cantons must be displayed at the top
  }, [
    displayCantons,
    noStopCantons,
    noStopZoneCantons,
    manoeuverCantons,
    kernelNoStopCantons,
    neutralCantons,
    blockedCantons,
    manualCantons,
    linkedByCollisionCantons,
    linkedByZoneCantons,
    linkedByDeadendCantons,
    reservedCantons,
    occupiedCantons,
    currentCantons,
  ]);

  return (
    <g className="display-traffic">
      {cantons.map((canton) => {
        if (!canton) {
          // eslint-disable-next-line no-console
          console.error('canton is undefined');

          return <></>;
        }

        return (
          <DisplayCanton
            key={`${canton.id}_${canton.cantonType}`}
            cantonTypeName={canton.cantonType}
            canton={canton}
            color={canton.color}
            strokeWidth={strokeWidth}
            highlight={
              hoveredRobotIndex === canton.robotIndex && hoveredRobotIndex !== undefined && !performanceModeEnabled
            }
          />
        );
      })}

      {displayCantons['truckPattern'] &&
        truckPatterns &&
        truckPatterns.length > 0 &&
        truckPatterns.map((truckPattern) => {
          return (
            <polygon
              id="traffic-truck-pattern"
              stroke={cantonsColors.truckPattern}
              strokeWidth={5}
              strokeOpacity={0.9}
              fill="none"
              points={truckPattern.map((point) => `${point.x * 100} ${-point.y * 100}`).join(' ')}
            />
          );
        })}
    </g>
  );
}

interface DisplayCantonProps {
  cantonTypeName: string;
  canton: CantonsDictKeyExtended;
  color: string;
  strokeWidth?: number;
  highlight?: boolean;
}
export function DisplayCanton(props: DisplayCantonProps): JSX.Element {
  const { canton, color, strokeWidth, highlight = false } = props;

  if (!canton) return <></>;

  return (
    <line
      x1={canton.start.x * 100}
      y1={-canton.start.y * 100}
      x2={canton.end.x * 100}
      y2={-canton.end.y * 100}
      stroke={color}
      strokeWidth={(strokeWidth ?? 5) * (highlight ? highlightStrokeWidthFactor : 1)}
      strokeOpacity={0.9}
      style={{
        filter: highlight ? `drop-shadow(0 0 5px ${color})` : undefined,
        transition: 'stroke-width 0.5s',
      }}
    />
  );
}

/**
 * This a helper function to extend the cantons dict with the canton type and color
 * @param cantonsDict the cantons dictionary to extend
 * @param cantonType the type to add to the cantons
 * @param color the color to add to the cantons
 * @returns the extended cantons dictionary
 */
function extendCantonsDict(cantonsDict: CantonsDict, cantonType: string, color: string): CantonsDictKeyExtended[] {
  const keys = Object.keys(cantonsDict);

  const res = keys.map((id) => {
    const canton = cantonsDict[id];
    if (!canton) return undefined;

    return {
      ...canton,
      id,
      cantonType,
      color,
    };
  });

  return res;
}

function concatIfNeeded(
  displayDict: TrafficSliceState['display'],
  displayKey: keyof TrafficSliceState,
  cantonsDict: CantonsDict,
  cantonType: string,
  cantonColor: string
): CantonsDictKeyExtended[] {
  return displayDict[displayKey] ? extendCantonsDict(cantonsDict, cantonType, cantonColor) : [];
}
