import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { closeDialogAction, openDialogAction } from 'actions';
import { rotateSelectionAction } from 'actions/circuit';
import { DialogTypes } from 'models';
import { useCallback, useRef } from 'react';
import type { SelectedShapesData } from 'reducers/local/state';
import { useAppDispatch } from 'store';

interface RotateSelectedShapesProps {
  selectedShapes: SelectedShapesData;
  handleClose: VoidFunction;
}

export function RotateSelectedShapes({ selectedShapes, handleClose }: RotateSelectedShapesProps): JSX.Element {
  const dispatch = useAppDispatch();

  const handleOpenRotateSelectedShapesDialog = useCallback(() => {
    dispatch(
      openDialogAction({
        type: DialogTypes.RotateSelectedShapes,
        payload: undefined,
      })
    );

    handleClose();
  }, [dispatch, handleClose]);

  return (
    <MenuItem dense={true} onClick={handleOpenRotateSelectedShapesDialog}>
      <ListItemIcon sx={{ minWidth: '28px' }}>
        <Rotate90DegreesCcwIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={<>Rotate selected shapes</>}></ListItemText>
    </MenuItem>
  );
}

export function RotateSelectedShapesDialog(): JSX.Element {
  const dispatch = useAppDispatch();

  const handleCloseDialog = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const rotateValue = useRef<number>(0);

  const handleChangeRotateValue = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void => {
    let newRotateValue = parseFloat(e.currentTarget.value);

    if (isNaN(newRotateValue)) {
      newRotateValue = 0;
    }

    rotateValue.current = newRotateValue;
  };

  const handleValidate = (): void => {
    dispatch(rotateSelectionAction({ rotateValue: rotateValue.current }));

    handleCloseDialog();
  };

  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '300px',
        },
      }}
      onClose={handleCloseDialog}
    >
      <DialogTitle sx={{ paddingBottom: 0 }}>Rotate selection</DialogTitle>
      <DialogContent sx={{ marginTop: 2 }}>
        <Stack alignItems={'center'}>
          <FormControl>
            <TextField
              type="number"
              defaultValue={rotateValue.current.toString()}
              onBlur={handleChangeRotateValue}
              variant="standard"
              inputProps={{ step: 1 }}
              InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
            ></TextField>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleValidate}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
