import { getAvailableSchedulerConfig } from 'components/toolboxes/simulation/get-available-scheduler-config';
import { SnackbarUtils } from 'services/snackbar.service';
import type { SchedulerConfig } from 'simulation/scheduler/scheduler-config';
import { schedulerConfigFilePath } from 'simulation/scheduler/scheduler-config';
import store from 'store';
import { PreferencesService, writeToFile } from 'utils/preferences';

export async function saveSchedulerConfigurationOnDisk(dirHandle: FileSystemDirectoryHandle): Promise<void> {
  const schedulerConfig = store.getState().simulation.schedulerConfiguration;
  if (schedulerConfig && schedulerConfig !== 'custom') {
    const availableSchedulerConfigs = getAvailableSchedulerConfig();
    const config = availableSchedulerConfigs.find((config) => config.name === schedulerConfig);
    if (!config) {
      const msg = `The scheduler configuration ${schedulerConfig} is not available in the app. It will not be saved on disk.`;
      // eslint-disable-next-line no-console
      console.error(msg);

      SnackbarUtils.error(msg);

      return;
    }

    const jsonConfiguration = config.configuration;
    if (!jsonConfiguration) {
      const msg = `The scheduler configuration ${schedulerConfig} has no configuration. It will not be saved on disk.`;
      // eslint-disable-next-line no-console
      console.error(msg);
      SnackbarUtils.error(msg);

      return;
    }

    const schedulerConfigBlob = new Blob([JSON.stringify(jsonConfiguration, null, 2)], {
      type: 'text/plain',
    });
    const schedulerConfigFileHandle = await dirHandle.getFileHandle(schedulerConfigFilePath, { create: true });

    await writeToFile(schedulerConfigFileHandle, schedulerConfigBlob);
  }
}

export async function saveCustomSchedulerConfigOnDisk(customSchedulerConfig: SchedulerConfig): Promise<void> {
  const dirHandle = await PreferencesService.getDirectoryHandle();

  if (dirHandle) {
    const schedulerConfigBlob = new Blob([JSON.stringify(customSchedulerConfig, null, 2)], {
      type: 'text/plain',
    });
    const schedulerConfigFileHandle = await dirHandle.getFileHandle(schedulerConfigFilePath, { create: true });
    await writeToFile(schedulerConfigFileHandle, schedulerConfigBlob);
  }
}
