import type { Dictionary } from 'shared';

/** number of digits after the point */
const ROUND_LEVEL = 3;

/**
 * Convert an angle from radians to degrees
 * @param angle the angle in radians
 * @returns the angle in degrees
 */
export function toDeg(angle: number): number {
  return angle * (180 / Math.PI);
}

/**
 * Convert an angle from degrees to radians
 * @param angle the angle in degrees
 * @returns the angle in radians
 */
export function toRad(angle: number): number {
  return angle * (Math.PI / 180);
}

/**
 * Normalize an angle to be between -pi and pi
 * @param angle an angle in radian
 * @returns the normalized angle
 */
export function normalizeAngleToMinusPiPi(angle: number): number {
  const a = (angle + Math.PI) % (2 * Math.PI);

  return a >= 0 ? a - Math.PI : a + Math.PI;
}

/**
 * Creates an array of elements split into two groups, the first of which contains elements predicate returns truthy for,
 * the second of which contains elements predicate returns falsey for. The predicate is invoked with one argument: (value).
 *
 * @param array The array to partition
 * @param predicate The function invoked per iteration
 * @returns The array of grouped elements
 */
export function partition<T>(array: T[], predicate: (item: T) => boolean): [T[], T[]] {
  return array.reduce(
    (result, item) => {
      if (predicate(item)) {
        result[0].push(item);
      } else {
        result[1].push(item);
      }

      return result;
    },
    [[], []] as [T[], T[]]
  );
}

/**
 * Remove duplicate values from an array
 * @param array The array to filter
 * @returns A filtered array
 */
export function distinct<T>(...arrays: T[][]): T[] {
  return Array.from(new Set(arrays.filter((array) => array.length).flatMap((array) => array)));
}

/**
 * Remove falsy value from array
 * @param array The array to filter
 * @returns A filtered array
 */
export function filterFalsy<T>(array: T[]): T[] {
  return array.filter(Boolean);
}

/**
 * Get the last value of an array
 * @param array The array to get the value from
 * @returns The last value
 */
export function lastValue<T>(array: T[]): T | undefined {
  if (!array) {
    return;
  }

  return array[array.length - 1];
}

export function purgeObjectFromUndefinedValues<T>(obj: T): Partial<T> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.keys(obj).reduce((purged, key) => {
    if (obj[key] === undefined) {
      return purged;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    purged[key] = obj[key];

    return purged;
  }, {} as T);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function debounceTime<T extends Function>(fn: T, timer: number): (...args: any[]) => void {
  let timeoutId;

  return function debouncedCallback(...args): void {
    clearTimeout(timeoutId);
    // eslint-disable-next-line
    timeoutId = setTimeout(fn, timer, ...args);
  };
}

interface ThrottledArgs<T, U, V> {
  args: [T, U, V];
  canRunFn: () => boolean;
  onLock: () => void;
  onUnlock: () => void;
}

export function throttleTime<T, U, V>(
  fn: (args: [T, U, V]) => void,
  delay: number
): (args: ThrottledArgs<T, U, V>) => void {
  let allowed = true;

  return function throttledCallback({ onUnlock, onLock, canRunFn, args }: ThrottledArgs<T, U, V>): void {
    if (!allowed || !canRunFn()) {
      return;
    }

    allowed = false;
    onLock();

    setTimeout(() => {
      allowed = true;
      onUnlock();
    }, delay);

    fn(args);
  };
}

/**
 * Resolve route paramters: replace each placeholder within a path by their value
 * @param path The route's path to resolve
 * @param params The route's parameters to resolve the route with
 * @returns The resolved route url
 */
export function resolveRouteParams(path: string, params: Dictionary<string>): string {
  return Object.keys(params)
    .reduce((url, key) => url.replace(':' + key, params[key]), path)
    .replace(/:\w+/g, '')
    .replace(/\/$/, '');
}

export function roundCoordinates(coords: number[]): number[] {
  return coords; // does nothing because if we round the coordinates, the SDK function Vlimnow doesn't work as expected
}

export function roundCoordinates2(coords: number[]): number[] {
  return coords.map((coord) => roundValue(coord));
}

export function roundValue(coord: number): number {
  return Math.round(coord * Math.pow(10, ROUND_LEVEL)) / Math.pow(10, ROUND_LEVEL);
}

export function toDigits(value: number, digits: number, ceil = true): number {
  return ceil
    ? Math.ceil(value * Math.pow(10, digits)) / Math.pow(10, digits)
    : Math.floor(value * Math.pow(10, digits)) / Math.pow(10, digits);
}

/**
 * Make a deep copy of an array
 * @param array the array to copy
 * @returns the array deep copied
 */
export function deepCopy<T>(array: T[]): T[] {
  const copy: any[] = [];
  array.forEach((elem) => {
    if (Array.isArray(elem)) {
      copy.push(deepCopy(elem));
    } else {
      copy.push(elem);
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return copy;
}

export function roundArray(arr: number[]): number[] {
  return arr.map((x) => Math.round(x));
}

export function getPathFromPublic(path: string): string {
  return `${import.meta.env.BASE_URL}/${path}`;
}

/**
 * Capitalizes the first letter of a given string. Optionally, it can leave the rest of the string as is.
 *
 * @param {string} str - The string to capitalize.
 * @param {boolean} [noLowerCaseAction=false] - If true, the function will not convert the rest of the string to lowercase.
 * @returns {string} The capitalized string.
 */
export function capitalize(str: string, noLowerCaseAction = false): string {
  const firstCharUpper = str.charAt(0).toUpperCase();
  const restOfString = noLowerCaseAction ? str.slice(1) : str.slice(1).toLowerCase();

  return `${firstCharUpper}${restOfString}`;
}

/**
 * Converts a camelCase string to a normal string
 *
 * @param {camelCase} The string to convert.
 * @returns {string} The normal string.
 */
export function camelCaseToTitle(camelCase: string): string {
  return camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
}

/**
 * Converts a number to a human-readable string representation with up to 6 decimal places, trimming any trailing zeros.
 * If the input is not a number, an empty string is returned.
 *
 * @param {number} x - The number to humanize.
 * @returns {string} The humanized string representation of the number.
 */
export function humanize(x: number): string {
  return typeof x === 'number' ? x.toFixed(6).replace(/\.?0*$/, '') : '';
}

// for the next two types, see related answer: https://stackoverflow.com/questions/57683303/how-can-i-see-the-full-expanded-contract-of-a-typescript-type/57683652#57683652
// expands object types one level deep
export type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;

// expands object types recursively
// eslint-disable-next-line @typescript-eslint/ban-types
export type ExpandRecursively<T> = T extends object
  ? T extends infer O
    ? { [K in keyof O]: ExpandRecursively<O[K]> }
    : never
  : T;

/**
 * Count the number of elements in a sparse array
 * @param {Array} collection
 * @return {number}
 */
export function count(collection: Array<any>): number {
  let totalCount = 0;
  for (let index = 0, l = collection.length; index < l; index++) {
    if (index in collection) {
      totalCount++;
    }
  }

  return totalCount;
}

/**
 * Keep the proximity of the angle to the original angle when updating the angle
 * @param originalAngle the previous angle [rad]
 * @param newAngle the new angle [rad]
 * @returns the updated angle [rad]
 */
export function keepAngleProximity(originalAngle: number, newAngle: number): number {
  const twoPI = 2 * Math.PI;

  // we constrain the angle between 0 and 2*PI
  newAngle = (newAngle + twoPI) % twoPI;

  const quotient = Math.floor(originalAngle / twoPI);

  const multiples = [0, 1, -1];
  const candidates = multiples.map((multiple) => newAngle + (quotient + multiple) * twoPI);

  const distances = candidates.map((candidate) => Math.abs(candidate - originalAngle));

  let minimumDistance = 1 / 0;
  let minimumDistanceIndex = -1;
  for (let index = 0; index < distances.length; index++) {
    const distance = distances[index];
    if (distance < minimumDistance) {
      minimumDistance = distance;
      minimumDistanceIndex = index;
    }
  }

  return candidates[minimumDistanceIndex];
}

/**
 * Get random number between two numbers (include)
 * @param min the min number
 * @param max the max number
 * @returns the random number
 */
export function getRandomIntInclusive(min: number, max: number): number {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);

  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
}

/**
 * Find if at least two number arrays have a number in commom
 * @param arrays the array with all the arrays to compare
 * @returns an array with the commom numbers
 */
export function findCommonNumbers(arrays: number[][]): number[] {
  if (arrays.length === 0) return [];

  const frequencyMap: Map<number, number> = new Map();

  // Count the frequency of each number across all arrays
  for (const array of arrays) {
    const uniqueNumbers = new Set(array); // Use a set to avoid counting duplicates within the same array
    for (const num of uniqueNumbers) {
      frequencyMap.set(num, (frequencyMap.get(num) || 0) + 1);
    }
  }

  // Filter numbers that appear in at least two arrays
  const commonNumbers: number[] = [];
  for (const [num, count] of frequencyMap.entries()) {
    if (count >= 2) {
      commonNumbers.push(num);
    }
  }

  return commonNumbers;
}

/**
 * Remove useless ,0 in the input (avoid 124,0 for exemple)
 * @param number the value
 * @param n number of digits after the decimal point
 * @returns string of the final value
 */
export function integerInputIfNeeded(number: number, n: number): string {
  const valueToCompare = number.toFixed(n);

  if (Number(valueToCompare) === number) {
    return number.toString();
  }

  return valueToCompare;
}
