// @ts-nocheck
/*
 * Generated type guards for "states.ts".
 * WARNING: Do not manually change this file.
 */
import { RobotTaskTaxi, RobotTrafficStateOK, RobotTrafficStateWait } from "./states";

export function isRobotTaskTaxi(obj: unknown): obj is RobotTaskTaxi {
    const typedObj = obj as RobotTaskTaxi
    return (
        (typedObj === "RunToTaxi" ||
            typedObj === "Available")
    )
}

export function isRobotTrafficStateOK(obj: unknown): obj is RobotTrafficStateOK {
    const typedObj = obj as RobotTrafficStateOK
    return (
        (typedObj === "Init" ||
            typedObj === "Manu" ||
            typedObj === "AutoNav" ||
            typedObj === "Auto" ||
            typedObj === "AutoStart" ||
            typedObj === "ManuOK" ||
            typedObj === "AutoWait")
    )
}

export function isRobotTrafficStateWait(obj: unknown): obj is RobotTrafficStateWait {
    const typedObj = obj as RobotTrafficStateWait
    return (
        (typedObj === "AutoNav" ||
            typedObj === "AutoWait" ||
            typedObj === "AutoWaitDoors")
    )
}
