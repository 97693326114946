import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { closeDialogAction } from 'actions';
import type { StationPosition } from 'flows/flows';
import { addStation, type Station } from 'flows/flows';
import { ShapeTypes } from 'models/circuit';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { SelectedShapesData } from 'reducers/local/state';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAppDispatch } from 'store';
import { generateShapeId } from 'utils/circuit/next-free-id';

interface CreateNewStationFromRightClickProps {
  stations: Station[];
  selectedShapes: SelectedShapesData;
}

export function CreateNewStationFromRightClickDialog({
  stations,
  selectedShapes,
}: CreateNewStationFromRightClickProps): JSX.Element {
  const dispatch = useAppDispatch();

  const DEFAULT_STATION_BASE_NAME = 'New Station';

  function generateUniqueStationName(baseName: string, stations: Station[]): string {
    let counter = 1;
    let name = baseName;

    // eslint-disable-next-line no-loop-func
    while (stations.find((station) => station.name === name)) {
      name = `${baseName} ${counter++}`;
    }

    return name;
  }

  const defaultNewStationName = useMemo(
    () => generateUniqueStationName(DEFAULT_STATION_BASE_NAME, stations),
    [stations]
  );

  const [newStationName, setNewStationName] = useState(defaultNewStationName);
  const [error, setError] = useState(false);
  const [option, setOption] = useState('points');

  const stationPositions: StationPosition[] = [];

  selectedShapes.forEach((shape) => {
    const shapeTypeMapping: {
      [key: string]: ShapeTypes.PointShape | ShapeTypes.RackShape | ShapeTypes.StockZoneShape | undefined;
    } = {
      POINT: ShapeTypes.PointShape,
      RACK: ShapeTypes.RackShape,
      STOCK: ShapeTypes.StockZoneShape,
    } as const;

    const type = shapeTypeMapping[shape.type];

    if (!type) {
      return;
    }

    const position: StationPosition = {
      id: shape.id,
      type: type,
    };

    stationPositions.push(position);
  });

  const isOnlyRacksOrStockZones = !!stationPositions.every((stationPosition) => {
    return stationPosition.type !== ShapeTypes.PointShape;
  });

  const isOnlyPoints = !!stationPositions.every((stationPosition) => {
    return stationPosition.type === ShapeTypes.PointShape;
  });

  const isMultipleShapeTypesSelection = !isOnlyPoints && !isOnlyRacksOrStockZones;

  const handleCloseDialog = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const handleChangeStationName = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewStationName(e.target.value);
  };

  useEffect(() => {
    const existingStation = stations.find((st) => st.name === newStationName);

    if (newStationName.length < 1 || existingStation) {
      setError(true);
    } else {
      setError(false);
    }
  }, [newStationName, stations]);

  const handleChangeOption = (e: ChangeEvent<HTMLInputElement>): void => {
    setOption(e.target.value);
  };

  const handleCreateNewStation = (): void => {
    const newStationId = generateShapeId();

    if (!error) {
      if (isOnlyPoints || isOnlyRacksOrStockZones) {
        dispatch(
          addStation({
            name: newStationName,
            id: newStationId,
            positions: stationPositions,
          })
        );

        const message =
          stationPositions.length > 1
            ? `Every selected ${isOnlyPoints ? 'points' : 'racks and stock zones'} have been added to ${newStationName}`
            : `The selected ${isOnlyPoints ? 'point' : 'rack or stock zone'} has been added to ${newStationName}`;

        SnackbarUtils.success(message);
      }

      if (isMultipleShapeTypesSelection && option === 'points') {
        const filteredStationPosition = stationPositions.filter(
          (stationPosition) => stationPosition.type === ShapeTypes.PointShape
        );

        dispatch(
          addStation({
            name: newStationName,
            id: newStationId,
            positions: filteredStationPosition,
          })
        );

        const message =
          filteredStationPosition.length > 1
            ? `Every selected points have been added to ${newStationName}`
            : `The selected point has been added to ${newStationName}`;

        SnackbarUtils.success(message);
      }

      if (isMultipleShapeTypesSelection && option === 'racks/stockZones') {
        const filteredStationPosition = stationPositions.filter(
          (stationPosition) => stationPosition.type !== ShapeTypes.PointShape
        );

        dispatch(
          addStation({
            name: newStationName,
            id: newStationId,
            positions: filteredStationPosition,
          })
        );

        const message =
          filteredStationPosition.length > 1
            ? `Every selected racks and stockZones have been added to ${newStationName}`
            : `The selected rack or stockZone has been added to ${newStationName}`;

        SnackbarUtils.success(message);
      }

      handleCloseDialog();
    }
  };

  return (
    <Dialog open={true} fullWidth={true} maxWidth="xs" onClose={handleCloseDialog}>
      <DialogTitle sx={{ paddingBottom: 0 }}>Choose the new station name</DialogTitle>
      <DialogContent sx={{ marginTop: 2 }}>
        <Stack alignItems={'center'}>
          <FormControl>
            <TextField
              value={newStationName}
              onChange={handleChangeStationName}
              variant="standard"
              error={error}
              helperText={error ? 'This name is incorrect or already used' : undefined}
            ></TextField>
          </FormControl>
          {isMultipleShapeTypesSelection && (
            <Box component={'div'} marginTop={2}>
              A station can not have points and stock zones/racks at the same time.
              <FormControl>
                <RadioGroup value={option} onChange={handleChangeOption}>
                  <FormControlLabel value="points" control={<Radio />} label="Only add selected points" />
                  <FormControlLabel
                    value="racks/stockZones"
                    control={<Radio />}
                    label="Only add selected racks/stock zones"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleCreateNewStation}>
          Validate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
