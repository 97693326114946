import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { CircuitImageData } from 'models/images';
import { remoteDoc } from 'multiplayer/globals';

export interface ImagesSliceState {
  /** images used on circuit */
  circuitImages: CircuitImageData[];
}

const initialState: ImagesSliceState = {
  circuitImages: [],
};

const imagesSlicePrivate = createSlice({
  initialState,
  name: 'images',
  reducers: {
    setCircuitImages(state, action: PayloadAction<CircuitImageData[]>) {
      state.circuitImages = action.payload;
    },
    addCircuitImage(state, action: PayloadAction<CircuitImageData>) {
      state.circuitImages.push(action.payload);
    },
    removeCircuitImage(state, action: PayloadAction<{ imageId: string }>) {
      state.circuitImages = state.circuitImages.filter((img) => img.id !== action.payload.imageId);
    },
    updateCircuitImage(state, action: PayloadAction<CircuitImageData>) {
      state.circuitImages = state.circuitImages.map((img) =>
        img.id === action.payload.id ? { ...img, ...action.payload } : img
      );
    },
    clearCircuitImages(state) {
      state.circuitImages = [];
    },
    clearCircuitImageFromYJS(state, action: PayloadAction<{ id: string }>) {
      state.circuitImages = state.circuitImages.filter((img) => img.id !== action.payload.id);
    },
    loadCircuitImageFromYJS(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      const circuitImageMap = remoteDoc?.getMap('circuitImages');
      if (!circuitImageMap) return;

      const circuitImage = circuitImageMap.get(id) as { data: CircuitImageData; uInt8Array: Uint8Array };

      if (!circuitImage.uInt8Array) return;

      const circuitImageBlob = new Blob([circuitImage.uInt8Array]);
      const circuitImageURL = URL.createObjectURL(circuitImageBlob);

      const newImage: CircuitImageData = {
        id,
        url: circuitImageURL,
        width: circuitImage.data.width ?? 0,
        height: circuitImage.data.height ?? 0,
        name: circuitImage.data.name,
      };

      const existingImages = state.circuitImages ?? [];
      const existingImageIndex = existingImages.findIndex((img) => img.id === newImage.id);

      if (existingImageIndex > -1) {
        // Update existing image
        state.circuitImages = existingImages.map((img, index) =>
          index === existingImageIndex ? { ...img, ...newImage } : img
        );
      } else {
        // Add new image
        state.circuitImages.push(newImage);
      }
    },
    removeCircuitImageFromYJS(state, action: PayloadAction<CircuitImageData>) {
      const imageId = action.payload.id;

      state.circuitImages = state.circuitImages.filter((img) => img.id !== imageId);
    },
  },
});

export const {
  setCircuitImages,
  addCircuitImage,
  removeCircuitImage,
  updateCircuitImage,
  clearCircuitImages,
  clearCircuitImageFromYJS,
  loadCircuitImageFromYJS,
  removeCircuitImageFromYJS,
} = imagesSlicePrivate.actions;

export const imagesSlice = {
  reducer: imagesSlicePrivate.reducer,
  name: imagesSlicePrivate.name,
};
