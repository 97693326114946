import { Alert, AlertTitle, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';

interface AlertOldDispatcherEnabledProps {
  reloadSimulationToolbox: () => void;
}

/**
 * Alert component for notifying about isOldDispatcher when it enable but should not be
 * It does not display anything if useOldDispatcher === 0 or is not enable
 * @returns JSX.Element
 */
export function AlertOldDispatcherEnabled(props: AlertOldDispatcherEnabledProps): JSX.Element {
  const [displayAlert, setDisplayAlert] = useState(false);

  const onSetNewDispatcher = async (): Promise<void> => {
    setDisplayAlert(false);

    const [okOldDispatcher] = await PreferencesService.setPreferenceValue(
      'general/useOldDispatcher',
      '0',
      true,
      'pref',
      true
    );
    const [okOldScheduler] = await PreferencesService.setPreferenceValue(
      'general/useOldScheduler',
      '0',
      true,
      'pref',
      true
    );
    if (okOldDispatcher && okOldScheduler) {
      props.reloadSimulationToolbox();
    } else {
      setDisplayAlert(true);
      // eslint-disable-next-line no-console
      console.error('An error occurred while enabling the new dispatcher');
      SnackbarUtils.error('An error occurred while enabling the new dispatcher');
    }
  };

  useEffect(() => {
    let isOldDispatcherEnabled: boolean | undefined;
    try {
      isOldDispatcherEnabled = !!Number(PreferencesService.getPreferenceValue('general/useOldDispatcher'));
    } catch (e) {}

    if (!isOldDispatcherEnabled) return;

    setDisplayAlert(true);
  }, []);

  if (!displayAlert) {
    return <></>;
  }

  return (
    <Alert
      severity="error"
      sx={{
        marginTop: theme.spacing(2),
      }}
    >
      <AlertTitle>Wrong dispatcher configuration</AlertTitle>
      The preference useOldDispatcher should be set to 0 to create and assign tasks in the simulation.
      <Button
        onClick={onSetNewDispatcher}
        variant="contained"
        color="error"
        sx={{
          marginTop: theme.spacing(2),
          color: 'white',
        }}
      >
        Enable new dispatcher
      </Button>
    </Alert>
  );
}
