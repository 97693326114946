import * as auth0 from 'auth0-js';
import * as jose from 'jose';
import type { JwtPayload } from 'jwt-decode';
import jwt_decode from 'jwt-decode';
import type { Observer } from 'rxjs';
import { Observable } from 'rxjs';
import type { UserPermissions, UserProfileData } from 'shared/core';
import { getConfig } from 'utils/config';

const VITE_AUTH0_JWKS = import.meta.env.VITE_AUTH0_JWKS;
if (!VITE_AUTH0_JWKS && getConfig('online') === 'true') {
  throw new Error('Missing Auth0 JWKS');
}

class NoAccessTokenError extends Error {
  constructor(message?: string) {
    super(message);

    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class AuthService {
  public get accessToken(): string | null {
    return this.token;
  }

  public get tokenExpiresAt(): number {
    return this.expiresAt;
  }

  public get authenticated(): boolean {
    return this.loggedIn;
  }

  public get authorized(): boolean {
    window.dispatchEvent(new CustomEvent('updateToolsAuth'));

    return !!(this.loggedIn && this.checkPermission('open:application'));
  }

  private auth0 = new auth0.WebAuth({
    audience: getConfig('auth0Audience'),
    clientID: getConfig('auth0ClientId'),
    domain: getConfig('auth0Domain'),
    redirectUri: `${getConfig('auth0RedirectUri')}?originalUrl=${encodeURIComponent(window.location.href)}`,
    responseType: getConfig('auth0ResponseType'),
    scope: getConfig('auth0Scope'),
  });

  private expiresAt = 0;
  private loggedIn = false;
  private token: string;
  private jwtDomain: string;

  constructor() {
    this.jwtDomain = getConfig('jwtDomain');

    const token = localStorage.getItem('accessToken') || '';
    if (token && token.length > 0) {
      this.verifyToken(token).then((verified) => {
        if (!verified) {
          // eslint-disable-next-line no-console
          console.warn(`Token ${token} is not valid`);
          this.logout();
        }
      });
    }

    this.token = token;

    const decodedToken = this.token ? jwt_decode<JwtPayload>(this.token) : null;
    const expiresAt = decodedToken?.exp ? decodedToken.exp * 1000 : -1;

    this.expiresAt = expiresAt;
    this.loggedIn = !!this.token && this.expiresAt > new Date().getTime();
  }

  public async verifyToken(token: string): Promise<boolean> {
    if (!VITE_AUTH0_JWKS) return false;

    const jwksStr = VITE_AUTH0_JWKS;
    if (!jwksStr || typeof jwksStr !== 'string') {
      // eslint-disable-next-line no-console
      console.error(`Invalid JWKS string: ${jwksStr}`);

      return false;
    }

    try {
      const jwks = JSON.parse(jwksStr) as unknown;
      if (!isJwks(jwks)) {
        // eslint-disable-next-line no-console
        console.error(`Invalid JWKS: ${jwks}`);

        return false;
      }

      const JWKS = jose.createLocalJWKSet(jwks);

      await jose.jwtVerify(token, JWKS);
    } catch (err) {
      if (err instanceof jose.errors.JWTExpired) {
        // eslint-disable-next-line no-console
        console.warn(`Error verifying token (token expired): ${err}`, err);
      } else {
        // eslint-disable-next-line no-console
        console.error(`Error verifying token: ${err}`, err);
      }

      return false;
    }

    return true;
  }

  public getLocalUserProfile(): UserProfileData | null {
    const accessToken = localStorage.getItem('accessToken');
    const profile = JSON.parse(localStorage.getItem('profile') || '{}') as UserProfileData;

    return accessToken && profile.sub ? profile : null;
  }

  public login(): void {
    this.auth0.authorize();
  }

  public checkPermission(permissionName: UserPermissions): boolean {
    if (!this.loggedIn) return false;

    const token = this.token;
    const decodedToken = jwt_decode<JwtPayload>(token);

    const expired = decodedToken.exp ? decodedToken.exp * 1000 < new Date().getTime() : true;
    if (expired) return false;

    const permissions = decodedToken[`${this.jwtDomain}/user_authorization`]?.permissions as string[] | undefined;

    if (!permissions) return false;

    return permissions.includes(permissionName);
  }

  public handleLoginCallback(): Observable<{ expiresAt: number }> {
    return new Observable((observer: Observer<{ expiresAt: number }>) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          this.loggedIn = false;
          observer.error(err);

          return observer.complete();
        }

        if (!authResult || !authResult.accessToken) {
          observer.error(new NoAccessTokenError('There was no access token in the authentication response'));

          return observer.complete();
        }

        this.loggedIn = true;

        this.setSession(authResult);

        observer.next({ expiresAt: this.expiresAt });

        window.dispatchEvent(new CustomEvent('updateToolsAuth'));

        return observer.complete();
      });
    });
  }

  public logout(): void {
    this.token = '';
    this.loggedIn = false;

    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('profile');

    this.auth0.logout({
      clientID: getConfig('auth0ClientId'),
      returnTo: import.meta.env.VITE_AUTH0_LOGOUT_URI,
    });
  }

  public fetchUserProfile(): Observable<UserProfileData> {
    return new Observable((observer: Observer<UserProfileData>) => {
      if (!this.token) {
        observer.error(new NoAccessTokenError('Cannot get user profile without access token'));

        return observer.complete();
      }

      this.auth0.client.userInfo(this.token, (err, data) => {
        if (err) {
          observer.error(err);

          return observer.complete();
        }

        const domain = getConfig('jwtDomain');

        const profile: UserProfileData = {
          name: data.name,
          nickname: data.nickname,
          picture: data.picture,
          sub: data.sub,
          updatedAt: data.updated_at,
          groups: (data[`${domain}/groups`] || []) as string[],
          roles: (data[`${domain}/roles`] || []) as string[],
          permissions: (data[`${domain}/permissions`] || []) as UserPermissions[],
          email: data.email ?? '',
        };

        localStorage.setItem('profile', JSON.stringify(profile));

        observer.next(profile);

        return observer.complete();
      });
    });
  }

  public refreshToken(): Observable<{ expiresAt: number }> {
    return new Observable((observer: Observer<{ expiresAt: number }>) => {
      this.auth0.checkSession({}, (err, result) => {
        if (err) {
          observer.error(err);

          return observer.complete();
        }

        this.setSession(result);

        observer.next({ expiresAt: this.expiresAt });

        return observer.complete();
      });
    });
  }

  protected setSession(authResult: auth0.Auth0DecodedHash): void {
    if (!authResult.accessToken) {
      throw new NoAccessTokenError('There was no access token in the authentication response');
    }

    // Set the time that the Access Token will expire at
    this.expiresAt = (authResult.expiresIn || 60 * 60) * 1000 + new Date().getTime();
    this.token = authResult.accessToken;

    const token = this.token;
    this.verifyToken(token).then((verified) => {
      if (!verified) {
        // eslint-disable-next-line no-console
        console.warn(`Token ${token} is not valid`);
        this.logout();
      }
    });

    localStorage.setItem('accessToken', this.token);
    localStorage.setItem('expiresAt', JSON.stringify(this.expiresAt));

    this.loggedIn = !!this.token && this.expiresAt > new Date().getTime();
  }
}

/**
 * Typeguard for the JWKS
 * @param jwks the JWKS object to test
 * @returns whether or not the JWKS looks ok
 */
function isJwks(jwks: unknown): jwks is jose.JSONWebKeySet {
  return typeof jwks === 'object' && jwks !== null && 'keys' in jwks;
}
