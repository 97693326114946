import { Chip, Stack, Tooltip } from '@mui/material';
import { useAppSelector } from 'store';

export function DisplayActiveModes(): JSX.Element {
  const isRender2Enabled = useAppSelector((state) => state.editor.isRender2Enabled);
  const isPerformanceEnabled = useAppSelector((state) => state.editor.isPerformanceModeEnabled);

  return (
    <Stack sx={{ position: 'absolute', top: '28px', right: '12px' }} direction="row-reverse" columnGap={1}>
      {isPerformanceEnabled && (
        <Tooltip
          title="This mode will disable some features to improve performance. It is recommended to enable this mode when working with large projects."
          arrow
        >
          <Chip
            label="Performance mode"
            color="warning"
            sx={{
              userSelect: 'none',
            }}
          />
        </Tooltip>
      )}
      {isRender2Enabled && (
        <Tooltip
          title="Render 2 is a rewrite of our rendering engine with a 3D engine. It offers better performance but is still in beta. Consider using it for large projects but it might lack a few features and might have some bugs."
          arrow
        >
          <Chip
            label="Render2"
            color="info"
            sx={{
              userSelect: 'none',
            }}
          />
        </Tooltip>
      )}
    </Stack>
  );
}
