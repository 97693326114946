import { Straighten, Timelapse } from '@mui/icons-material';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import ClearIcon from '@mui/icons-material/Clear';
import RouteIcon from '@mui/icons-material/Route';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { CollapseMore } from 'components/utils/collapse-more';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { isLibTrackItinerary } from 'models/circuit.guard';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { Itinerary } from 'routes/routes';
import {
  addItinerary,
  clearAllItineraries,
  clearNextItineraryPosition,
  reverseRoute,
  setKeepDisplayItineraryOnLeave,
  setNewPathFlag,
  setSelectNextPoint,
} from 'routes/routes';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { AnimatedIcon } from 'utils/animated-icon';
import { generateXML } from 'utils/export/generate-xml.tsx';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import type { PathFlagCheckbox } from 'utils/routes/path-flag';
import { checkboxesToPathFlag, pathFlagCheckboxesInitial, pathFlagToCheckboxes } from 'utils/routes/path-flag';
import { simulationService } from '../../services/simulation.service.ts';
import { LayerGroupSelect } from './layer-group-select';

export function RouteToolbox(): JSX.Element {
  const dispatch = useAppDispatch();

  const selectedLayerGroup = useAppSelector((state) => state.routes.selectedLayerGroup);
  const pathFlag = useAppSelector((state) => state.routes.pathFlag);

  const [pathFlagCheckboxes, setPathFlagCheckboxes] = useState<PathFlagCheckbox[]>(
    pathFlagToCheckboxes(pathFlag, pathFlagCheckboxesInitial)
  );

  useEffect(() => {
    try {
      const trucks = PreferencesService.getTrucks();
      const models = trucks.map((truck) => {
        try {
          return PreferencesService.getPreferenceValue('general/modelFileName', truck.serial) as string;
        } catch (e) {}

        return null;
      });

      const selectedLayerGroupName = selectedLayerGroup
        ? store.getState().circuit.present.layers.layerGroups[selectedLayerGroup].name
        : undefined;
      if (!selectedLayerGroupName) return;

      const truckIndexOfThisLayerGroup = models.findIndex((model) => model === selectedLayerGroupName);
      const truckSerialOfThisLayerGroup =
        truckIndexOfThisLayerGroup !== -1 ? trucks[truckIndexOfThisLayerGroup].serial : undefined;
      if (!truckSerialOfThisLayerGroup) return;

      const modelPathFlagStr = PreferencesService.getPreferenceValue(
        'navigation/pathFlag',
        truckSerialOfThisLayerGroup
      );
      if (typeof modelPathFlagStr === 'string' && modelPathFlagStr.length) {
        const modelPathFlag = parseInt(modelPathFlagStr, 10);
        dispatch(
          setNewPathFlag({
            pathFlag: modelPathFlag,
          })
        );

        setPathFlagCheckboxes(pathFlagToCheckboxes(modelPathFlag, pathFlagCheckboxesInitial));
      }
    } catch (e) {}
  }, [dispatch, selectedLayerGroup]);

  const handleChangePathFlagCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean, checkboxValue: number) => {
      const newCheckboxes = pathFlagCheckboxes.map((checkbox) => {
        if (checkbox.value === checkboxValue) {
          return {
            ...checkbox,
            checked,
          };
        }

        return checkbox;
      });

      setPathFlagCheckboxes(newCheckboxes);

      const newPathFlag = checkboxesToPathFlag(newCheckboxes);

      dispatch(
        setNewPathFlag({
          pathFlag: newPathFlag,
        })
      );
    },
    [dispatch, pathFlagCheckboxes]
  );

  const nextItineraryPostionFrom = useAppSelector((state) => state.routes.nextItineraryFrom);
  const nextItineraryPostionTo = useAppSelector((state) => state.routes.nextItineraryTo);
  const nextClickSelect = useAppSelector((state) => state.routes.nextClickSelect);
  const itineraries = useAppSelector((state) => state.routes.itineraries);

  const nextItineraryPostionFromLabel = useMemo(() => {
    if (nextItineraryPostionFrom) {
      return `(x: ${(nextItineraryPostionFrom.x / 100).toFixed(1)}, y: ${(nextItineraryPostionFrom.y / 100).toFixed(
        1
      )})`;
    }

    return 'Select departure point';
  }, [nextItineraryPostionFrom]);

  const nextItineraryPostionToLabel = useMemo(() => {
    if (nextItineraryPostionTo) {
      return `(x: ${(nextItineraryPostionTo.x / 100).toFixed(1)}, y: ${(nextItineraryPostionTo.y / 100).toFixed(1)})`;
    }

    return 'Select arrival point';
  }, [nextItineraryPostionTo]);

  const [computingItinerary, setComputingItinerary] = useState(false);

  const circuitLoaded = useRef(false);
  const circuitPtr = useRef<number | undefined>(undefined);

  const loadCircuit = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.log('Generating the xml file...');

    const [xml] = await generateXML({
      zones: CircuitService.zones,
      segments: CircuitService.segments,
      stockZones: CircuitService.stockZones,
      measurers: CircuitService.measurers,
      turns: CircuitService.turns,
      racks: CircuitService.racks,
      points: CircuitService.points,
    });

    // eslint-disable-next-line no-console
    console.log('generated');

    // eslint-disable-next-line no-console
    console.log('Loading the circuit in the libtrack...');

    if (!simulationService) {
      // eslint-disable-next-line no-console
      console.error('Simulation service not initialized');

      return;
    }

    const circuitPtrLocal = await simulationService.allocateUTF8(xml);
    await simulationService._TRACK_WasmWrapper_loadCircuit(circuitPtrLocal);

    circuitPtr.current = circuitPtrLocal;

    circuitLoaded.current = true;
  }, []);

  const unloadCircuit = useCallback(async () => {
    if (circuitPtr.current) {
      const simulationService = (await import('../../services/simulation.service.ts'))?.simulationService;
      if (!simulationService) {
        // eslint-disable-next-line no-console
        console.error('Simulation service not initialized');

        return;
      }

      await simulationService._OS_WasmWrapper_free(circuitPtr.current);
    }
  }, []);

  useEffect(() => {
    loadCircuit();

    return () => {
      unloadCircuit();
    };
  }, [loadCircuit, unloadCircuit]);

  const computeItinerary = useCallback(async () => {
    if (!selectedLayerGroup) {
      // eslint-disable-next-line no-console
      console.error('No layer group selected');

      return;
    }

    setComputingItinerary(true);

    dispatch(clearAllItineraries());

    const simulationService = (await import('../../services/simulation.service.ts'))?.simulationService;
    if (!simulationService) {
      // eslint-disable-next-line no-console
      console.error('Simulation service not initialized');

      return;
    }

    while (!circuitLoaded.current) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    // eslint-disable-next-line no-console
    console.log('Computing itinerary...');

    const selectedLayerGroupName = store.getState().circuit.present.layers.layerGroups[selectedLayerGroup].name;
    const layerNamePtr = await simulationService.allocateUTF8(selectedLayerGroupName);

    if (!nextItineraryPostionFrom || !('x' in nextItineraryPostionFrom)) {
      // eslint-disable-next-line no-console
      console.error('No departure point selected or in a not supported format');

      return;
    }

    if (!nextItineraryPostionTo || !('x' in nextItineraryPostionTo)) {
      // eslint-disable-next-line no-console
      console.error('No arrival point selected or in a not supported format');

      return;
    }

    const resStrPtr = await simulationService._TRACK_WasmWrapper_findItinerary(
      layerNamePtr,
      nextItineraryPostionFrom.x / 100,
      nextItineraryPostionFrom.y / 100,
      nextItineraryPostionFrom.heading ?? 0,
      nextItineraryPostionTo.x / 100,
      nextItineraryPostionTo.y / 100,
      nextItineraryPostionTo.heading ?? 0,
      pathFlag
    );
    const resStr = await simulationService.UTF8ToString(resStrPtr);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const res = (() => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return JSON.parse(resStr);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Not a valid json`, e, resStr);
      }
    })();

    await simulationService._OS_WasmWrapper_free(layerNamePtr);

    let snackbarErrorMsg = '';

    if (res && isLibTrackItinerary(res)) {
      if (res.errorID === 0) {
        dispatch(
          addItinerary({
            id: nanoid(),
            distance: res.distance ?? 0,
            errorId: res.errorID,
            error: res.error,
            positions: (res.listOfPosition ?? []).map((position) => ({
              x: position.x * 100,
              y: -position.y * 100,
              heading: position.heading,
            })),
            portionIds: res.listOfPortionID ?? [],
            duration: res.duration,
            cost: res.cost,
          })
        );
      } else if (res.errorID === -588) {
        snackbarErrorMsg = 'No itinerary found';
      } else {
        // eslint-disable-next-line no-console
        console.error('Itinerary non zero error code', res);

        snackbarErrorMsg = 'Error while computing itinerary';
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('Error while computing itinerary', res, resStr);

      snackbarErrorMsg = 'Error while computing itinerary';
    }

    setComputingItinerary(false);

    if (snackbarErrorMsg) {
      SnackbarUtils.error(snackbarErrorMsg);
    }
  }, [dispatch, nextItineraryPostionFrom, nextItineraryPostionTo, pathFlag, selectedLayerGroup]);

  const deleteItineraryPoint = useCallback(
    (fromOrTo: 'from' | 'to') => {
      dispatch(
        clearNextItineraryPosition({
          from: fromOrTo === 'from',
          to: fromOrTo === 'to',
        })
      );
    },
    [dispatch]
  );

  const handleNextItineraryPostionClick = useCallback(
    (fromOrTo: 'from' | 'to') => {
      dispatch(
        setSelectNextPoint({
          select: fromOrTo === nextClickSelect ? undefined : fromOrTo,
        })
      );
    },
    [dispatch, nextClickSelect]
  );

  const handleClearItineraries = useCallback(() => {
    dispatch(clearAllItineraries());
  }, [dispatch]);

  const switchRef = useRef<HTMLInputElement>(null);

  const keepDisplayOnLeave = useAppSelector((state) => state.routes.keepDisplayItineraryOnLeave);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target;
      const checked = !!target.checked;

      dispatch(setKeepDisplayItineraryOnLeave(checked));
    },
    [dispatch]
  );

  const handleReverseRoute = useCallback(() => {
    dispatch(reverseRoute());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setSelectNextPoint({ select: undefined }));
    };
  }, [dispatch]);

  return (
    <Card sx={{ position: 'absolute', right: theme.spacing(2), top: theme.spacing(2), width: '400px' }}>
      <CardHeader
        title="Route"
        avatar={<RouteIcon />}
        sx={{
          paddingBottom: theme.spacing(0.5),
        }}
      ></CardHeader>
      <CardContent
        sx={{
          textAlign: 'left',
        }}
      >
        <CollapseMore title={'Route options'} sx={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }}>
          <Box component="div" sx={{ textAlign: 'right', marginRight: theme.spacing(3) }}>
            Path Flag: {pathFlag}
          </Box>
          <FormGroup>
            {pathFlagCheckboxes.map((checkbox) => (
              <FormControlLabel
                key={checkbox.value}
                control={
                  <Checkbox
                    checked={checkbox.checked}
                    size="small"
                    onChange={(e, checked) => handleChangePathFlagCheckbox(e, checked, checkbox.value)}
                  />
                }
                label={
                  <>
                    {checkbox.value}: {checkbox.label}
                  </>
                }
              />
            ))}
          </FormGroup>
        </CollapseMore>

        <LayerGroupSelect />

        <List
          dense={true}
          sx={{
            padding: 0,
          }}
        >
          <ListItem>
            <ListItemIcon>
              <Switch checked={keepDisplayOnLeave} onChange={handleChange} size="small" inputRef={switchRef} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Box
                  component="span"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => switchRef.current?.click()}
                >
                  Keep Display{' '}
                  <HelpIconTooltip
                    title="Whether or not the itinerary is hidden on circuit mode"
                    sx={{
                      float: 'right',
                    }}
                  />
                </Box>
              }
            />
          </ListItem>
        </List>

        <Grid
          container
          sx={{
            justifyContent: 'space-around',
            alignContent: 'center',
            alignItems: 'flex-end',
            marginTop: theme.spacing(2),
          }}
        >
          <Grid item>
            <Chip
              label={nextItineraryPostionFromLabel}
              color={nextClickSelect === 'from' ? 'primary' : undefined}
              onClick={() => handleNextItineraryPostionClick('from')}
              onDelete={nextItineraryPostionFrom ? () => deleteItineraryPoint('from') : undefined}
            />
          </Grid>
          <Grid item>
            <AnimatedIcon triggerOnClick={true} defaultAnimation="zoom-and-rotate">
              <Tooltip title="Reverse route" arrow disableInteractive>
                <IconButton onClick={handleReverseRoute}>
                  <AltRouteIcon sx={{ transform: 'rotate(90deg)' }} fontSize="small" />
                </IconButton>
              </Tooltip>
            </AnimatedIcon>
          </Grid>
          <Grid item>
            <Chip
              label={nextItineraryPostionToLabel}
              color={nextClickSelect === 'to' ? 'primary' : undefined}
              onClick={() => handleNextItineraryPostionClick('to')}
              onDelete={nextItineraryPostionTo ? () => deleteItineraryPoint('to') : undefined}
            />
          </Grid>
        </Grid>

        <ButtonGroup
          variant="contained"
          sx={{
            textTransform: 'none',
            margin: theme.spacing(1),
            width: '100%',
            marginTop: theme.spacing(2),
          }}
          disabled={computingItinerary}
        >
          <LoadingButton
            variant="contained"
            fullWidth
            onClick={computeItinerary}
            disabled={!nextItineraryPostionFrom || !nextItineraryPostionTo}
            sx={{
              textTransform: 'none',
            }}
            loading={computingItinerary}
          >
            Compute itinerary
          </LoadingButton>
          <Button onClick={handleClearItineraries}>
            <ClearIcon />
          </Button>
        </ButtonGroup>

        {itineraries.map((itinerary) => (
          <RouteInfo key={itinerary.id} itinerary={itinerary} />
        ))}
      </CardContent>
    </Card>
  );
}

interface RouteInfoProps {
  itinerary: Itinerary;
}
function RouteInfo(props: RouteInfoProps): JSX.Element {
  const { itinerary } = props;

  return (
    <Box
      component="div"
      sx={{
        textAlign: 'center',
        marginTop: theme.spacing(2),
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Tooltip title="Distance" placement="top" arrow>
            <Typography variant="subtitle2">
              <Straighten />
              <br />
              {itinerary.distance.toFixed(2)} m
            </Typography>
          </Tooltip>
        </Grid>
        {itinerary.duration !== undefined && (
          <Grid item xs={6}>
            <Typography variant="subtitle2">
              <Timelapse />
              <br />~{itinerary.duration.toFixed(1)} s
              <HelpIconTooltip title="This duration is provided as an estimate, acknowledging a potential margin of error. However, the comparative analysis between two itineraries remains highly relevant. A more accurate value will soon be accessible in an upcoming version of Road Editor. It's important to note that this estimate assumes the robot is operating in standalone mode (alone) and does not account for traffic, unlike the simulation." />
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
