import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Border } from 'components/utils/border';
import type { Station } from 'flows/flows';
import { removeStation, setSelectedStationId } from 'flows/flows';
import { useConfirm } from 'material-ui-confirm';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { ListPositionOfStation } from './list-position-of-station';
import { StationConfigDisplay } from './station';

interface StationListAccordionProps {
  setStationConfigDisplay: (value: StationConfigDisplay) => void;
}
export function StationListAccordion(props: StationListAccordionProps): JSX.Element {
  const { setStationConfigDisplay } = props;

  const stations = useAppSelector((state) => state.flows.stations);
  const selectedStationId = useAppSelector((state) => state.flows.selectedStationId);

  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const handleAccordionChange = useCallback(
    (stationId: string): void => {
      dispatch(setSelectedStationId(stationId));
    },
    [dispatch]
  );

  const handleDeleteStation = useCallback(
    (station: Station): void => {
      confirm({ title: `Are you sure you want to remove "${station.name}"?` })
        .then(() => {
          dispatch(removeStation(station.id));
        })
        .catch(() => undefined);
    },
    [confirm, dispatch]
  );

  const handleUnselectStation = useCallback((): void => {
    dispatch(setSelectedStationId(undefined));
  }, [dispatch]);

  return (
    <div>
      {stations.map((station) => (
        <Accordion
          key={station.id}
          slotProps={{ transition: { unmountOnExit: true } }}
          expanded={selectedStationId === station.id}
          onChange={(event, isExpanded) => {
            if (!isExpanded) {
              // disable onCLick when accordion is expanded
              return;
            }

            handleAccordionChange(station.id);
          }}
        >
          <AccordionSummary
            expandIcon={selectedStationId !== station.id && <ExpandMoreIcon />}
            aria-controls="panel1-content"
            id={`${station.id}-header`}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: selectedStationId === station.id ? 'default !important' : 'pointer !important',
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 600, color: theme.palette.grey[800], flexGrow: 1 }}>
              {station.name}
            </Typography>

            {selectedStationId === station.id && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Edit this station">
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => setStationConfigDisplay(StationConfigDisplay.Edition)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete this station">
                  <IconButton aria-label="delete" size="small" onClick={() => handleDeleteStation(station)}>
                    <DeleteIcon fontSize="small" color="error" />
                  </IconButton>
                </Tooltip>
                <IconButton aria-label="close" size="small" onClick={() => handleUnselectStation()}>
                  <ExpandLessIcon />
                </IconButton>
              </Stack>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Border>
              <ListPositionOfStation station={station} displayRemovePositionBtn={false} />
            </Border>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
