export enum ChargerStatus {
  Unavailable = 0,
  Available = 1,
}

// State interpretation (Reserved or charging state takes priority over unavailable state)
export enum ChargerStatusRelatedToRob {
  Available = 0,
  Reserved = 1,
  Charging = 2,
}
