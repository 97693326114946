import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, TextField } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { Flow } from 'flows/flows';
import { setFlow } from 'flows/flows';
import { useCallback, useRef } from 'react';
import { useAppDispatch } from 'store';

interface ObjectiveTextfieldProps {
  selectedFlowId: string;
  selectedFlow: Flow;
}
export function ObjectiveTextfield(props: ObjectiveTextfieldProps): JSX.Element {
  const { selectedFlowId, selectedFlow } = props;

  const dispatch = useAppDispatch();

  const objectiveInputRef = useRef<HTMLInputElement>(null);

  const minObjectiveValue = 0;

  const handleChangeObjective = useCallback(() => {
    if (!selectedFlow) return;
    const input = objectiveInputRef.current;
    if (!input) return;

    const objective = input.valueAsNumber;
    let objectifOk = true;
    if (isNaN(objective)) objectifOk = false;
    else if (objective < minObjectiveValue) objectifOk = false;

    if (!objectifOk) {
      input.value = '';

      dispatch(
        setFlow({
          ...selectedFlow,
          objective: undefined,
        })
      );

      return;
    }

    dispatch(
      setFlow({
        ...selectedFlow,
        objective,
      })
    );
  }, [dispatch, selectedFlow]);

  return (
    <TextField
      key={selectedFlowId}
      type="number"
      label={
        <>
          Objective <HelpIconTooltip title="Objective for the flows in task per hour (optional)" />
        </>
      }
      variant="outlined"
      fullWidth
      size="small"
      inputProps={{ min: minObjectiveValue, step: 1 }}
      InputProps={{
        endAdornment: (
          <>
            <IconButton
              onClick={(e) => {
                if (objectiveInputRef.current) {
                  objectiveInputRef.current.value = '';
                  handleChangeObjective();
                }
              }}
              size="small"
            >
              <ClearIcon fontSize="small" />
            </IconButton>
            tasks/hr
          </>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={selectedFlow?.objective}
      inputRef={objectiveInputRef}
      onBlur={handleChangeObjective}
    />
  );
}
