import type { union } from '@turf/turf';
import * as Comlink from 'comlink';
import type {
  generateGabaritPolygon,
  generatePointGabarit,
  generateSegmentGabarit,
  generateTurnGabarit,
  mergeAllGabarits,
} from 'utils/circuit/gabarits';
import importedWorker from '../utils/circuit/gabarits.ts?worker';

const worker = new importedWorker({
  name: 'gabarit-worker',
});

export const gabaritsWorkerProxy = Comlink.wrap<{
  generateGabaritPolygon: typeof generateGabaritPolygon;
  generateSegmentGabarit: typeof generateSegmentGabarit;
  generateTurnGabarit: typeof generateTurnGabarit;
  generatePointGabarit: typeof generatePointGabarit;
  union: typeof union;
  mergeAllGabarits: typeof mergeAllGabarits;
}>(worker);
