import { localDoc, remoteDoc } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import { applyUpdate } from 'yjs';

export const useSyncYJSRemoteToLocal = (): void => {
  useLayoutEffect(() => {
    const eventHandle = (update, origin): void => {
      const isTransactionLocal = origin === 'local';
      if (isTransactionLocal) return;

      applyUpdate(localDoc, update);
      //! Not available for now using our provider y-websocket
      //! https://discuss.yjs.dev/t/update-ydoc-connected-to-y-websocket-using-applyupdatev2/2542
      // applyUpdateV2(localDoc, update);
    };

    remoteDoc?.on('update', eventHandle);
    //! Not available for now using our provider y-websocket
    //! https://discuss.yjs.dev/t/update-ydoc-connected-to-y-websocket-using-applyupdatev2/2542
    // remoteDoc?.on('updateV2', eventHandle);

    return () => {
      remoteDoc?.off('update', eventHandle);
      //! Not available for now using our provider y-websocket
      //! https://discuss.yjs.dev/t/update-ydoc-connected-to-y-websocket-using-applyupdatev2/2542
      // remoteDoc?.off('updateV2', eventHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
