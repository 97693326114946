import { saveCircuitToHistoryAction, updateMeasurerAction } from 'actions/circuit';
import { saveMeasurerAction } from 'actions/measurers';
import * as d3 from 'd3';
import { LineString } from 'drawings/elements';
import {
  arrowsToString,
  computeArrowsFromLineString,
  findIntersectionBetweenLines,
  findShapeOrientation,
  getClosestPointAndLineInPolygon,
  getClosestPointInSegment,
  offsetPositionLine,
} from 'drawings/helpers';
import type { SimpleSelection } from 'drawings/shared';
import { snap90degLine } from 'librarycircuit/utils/geometry/snap-90-deg-line';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import { cloneDeep, isEqual } from 'lodash';
import type {
  CircuitPoint,
  CircuitRack,
  CircuitSegment,
  CircuitStockZone,
  CircuitZone,
  MeasurerProperties,
} from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { Tools } from 'models/tools';
import { isShapeInSelectedShapes } from 'multiplayer/globals';
import { setShapeJustMoved } from 'services/circuit.service';
import store from 'store';
import { getConfig } from 'utils/config';
import { toRad } from 'utils/helpers';

export class Measurer extends LineString {
  public properties: MeasurerProperties;

  constructor(
    id: string,
    geometry: GeoJSON.LineString,
    projection: d3.GeoPath<any, d3.GeoPermissibleObjects>,
    zoomScale: number,
    properties?: MeasurerProperties
  ) {
    super(id, ShapeTypes.MeasurerShape, geometry, projection, zoomScale);
    this.properties = properties || ({ name: id, type: ShapeTypes.MeasurerShape } as MeasurerProperties);

    this.setLocked(!!this.properties?.locked);

    this.node = this.showEndPoints(this.node);
    if (this.properties.guide) {
      this.showGuide();
    }

    this.node.attr('layer-id', properties?.layerId || '');
  }
  private showGuide(): void {
    const node = this.node;
    const coords = this.geometry.coordinates;
    const angle = toRad(findShapeOrientation(coords[0], coords[1]));
    const x1 = coords[0][0];
    const y1 = -coords[0][1];
    const x2 = coords[1][0];
    const y2 = -coords[1][1];

    const isPerformanceModeEnable = store.getState().editor.isPerformanceModeEnabled;
    // guideLength is the distance for the act guide line to specify how long a guide line should be
    // we tried larger values (such as 1e6 for example) but the browser became quite laggy, 5e4 is long enough and no lag behavior have been observed
    // 100m when performance mode is enabled, 500m otherwise
    const guideLength = isPerformanceModeEnable ? 1e4 : 5e4; // far far away distance, way beyond the screen

    const x3 = x2 + Math.cos(angle) * guideLength;
    const y3 = y2 - Math.sin(angle) * guideLength;

    const x4 = x1 - Math.cos(-angle) * guideLength;
    const y4 = y1 - Math.sin(-angle) * guideLength;

    // guide 0 calculate of the second guide line.
    node.append('line').attr('x1', x1).attr('y1', y1).attr('x2', x4).attr('y2', y4).classed('guide', true);

    // guide 1 calculate of the first guide line
    node.append('line').attr('x1', x2).attr('y1', y2).attr('x2', x3).attr('y2', y3).classed('guide', true);
  }
  public createNode(): SimpleSelection<SVGGElement, undefined> {
    const node = super.createNode();

    return node;
  }

  private showEndPoints(
    node: SimpleSelection<SVGGElement, undefined>,
    show = true
  ): SimpleSelection<SVGGElement, undefined> {
    if (!show) return node;

    const dragHandler = d3
      .drag<SVGGElement, undefined>()
      .on('drag', (e, d, n) => this.onDragEndPoints(e, d, n))
      .on('end', () => this.onDragEndPointsEnd());

    const measurerLength = getDistanceBetweenPoints(this.geometry.coordinates[0], this.geometry.coordinates[1]) / 100;
    const measurerAngle = findShapeOrientation(this.geometry.coordinates[0], this.geometry.coordinates[1]);
    const lengthThresholdSmallMeasurer = 0.7;
    const isSmallMeasurer = measurerLength < lengthThresholdSmallMeasurer;
    const arrowsDirection = !isSmallMeasurer ? [-1, 1] : [2, -2];

    const arrows = arrowsToString(computeArrowsFromLineString(this.geometry.coordinates, arrowsDirection));

    const lines = computeArrowsFromLineString(this.geometry.coordinates, arrowsDirection);

    const arrowStart = node.append('g').classed('arrow-measurer', true);
    const arrowEnd = node.append('g').classed('arrow-measurer', true);

    arrowStart
      .append<SVGGElement>('svg:polygon')
      .attr('id', `arrow-start-${this.id}`)
      .attr('stroke', 'none')
      .attr('points', arrows[0])
      .classed('measurer-attached-arrow', !!this.properties.link0)
      .call(dragHandler)
      .exit();

    arrowStart
      .insert<SVGGElement>('svg:line', 'polygon')
      .attr('x1', lines[2][0][0])
      .attr('y1', lines[2][0][1])
      .attr('x2', lines[2][1][0])
      .attr('y2', lines[2][1][1])
      .style('stroke-width', `2`)
      .style('stroke-dasharray', 'none')
      .style('opacity', '0.8')
      .classed('measurer-attached-arrow-line', !!this.properties.link0);

    arrowEnd
      .append<SVGGElement>('svg:polygon')
      .attr('id', `arrow-end-${this.id}`)
      .attr('stroke', 'none')
      .attr('points', arrows[1])
      .classed('measurer-attached-arrow', !!this.properties.link1)
      .call(dragHandler)
      .exit();

    arrowEnd
      .insert<SVGGElement>('svg:line', 'polygon')
      .attr('x1', lines[3][0][0])
      .attr('y1', lines[3][0][1])
      .attr('x2', lines[3][1][0])
      .attr('y2', lines[3][1][1])
      .style('stroke-width', `2`)
      .style('stroke-dasharray', 'none')
      .style('opacity', '0.8')
      .classed('measurer-attached-arrow-line', !!this.properties.link1);

    // we need to clip the central stroke line to let display only the arrows at the extremities
    node
      .style('stroke-width', getConfig('editor').elementWidth.measurerStroke / (isSmallMeasurer ? 4 : 1))
      .style('stroke-dasharray', isSmallMeasurer ? '8 2' : '4 2');

    if (!isSmallMeasurer) {
      // if it's a long measurer, we clip a bit the path to let the arrows visible
      node.selectAll('[main-shape]').style('clip-path', `circle(${(measurerLength / 2) * 100 - 10}px at 50% 50%)`);
    }

    const offsetSmallMeasurer = 30;
    const offsetInAbsissaDirection = Math.abs(measurerAngle) > 45 && Math.abs(measurerAngle) < 135;
    const textPosX =
      (this.geometry.coordinates[1][0] + this.geometry.coordinates[0][0]) / 2 +
      (isSmallMeasurer && offsetInAbsissaDirection ? offsetSmallMeasurer : 0);
    const textPosY =
      -(this.geometry.coordinates[1][1] + this.geometry.coordinates[0][1]) / 2 +
      (isSmallMeasurer && !offsetInAbsissaDirection ? -offsetSmallMeasurer : 0);
    const measurerLengthTxt = measurerLength.toFixed(3);
    const textEl = node.select('text');

    if (this.properties?.showLength === undefined || this.properties?.showLength) {
      if (!textEl.size()) {
        node
          .append('svg:text')
          .attr('x', textPosX)
          .attr('y', textPosY)
          .attr('stroke', 'none')
          .attr('text-anchor', 'middle')
          .style('pointer-events', 'none')
          .classed('length-measurer-text', true)
          .text(`${measurerLengthTxt}m`);

        // we need the setTimeout to wait for the text element to be rendered and get its bounding box, otherwise we get 0
        setTimeout(() => {
          const bbox = (node.select('.length-measurer-text').node() as SVGGraphicsElement).getBBox();

          node
            .insert('svg:rect', 'text')
            .attr('x', bbox.x)
            .attr('y', bbox.y)
            .attr('width', bbox.width)
            .attr('height', bbox.height)
            .attr('stroke', 'none')
            .classed('length-measurer-bg-txt', true);
        }, 0);
      } else {
        node.select('.length-measurer-text').attr('x', textPosX).attr('y', textPosY).text(`${measurerLengthTxt}m`);

        setTimeout(() => {
          const bbox = (node.select('.length-measurer-text').node() as SVGGraphicsElement).getBBox();
          node
            .select('.length-measurer-bg-txt')
            .attr('x', bbox.x)
            .attr('y', bbox.y)
            .attr('width', bbox.width)
            .attr('height', bbox.height);
        }, 0);
      }
    } else {
      textEl.remove();
      node.select('.length-measurer-bg-txt').remove();
    }

    return node;
  }

  private updateEndPoints(node: SimpleSelection<SVGGElement, undefined>): SimpleSelection<SVGGElement, undefined> {
    node.selectAll('polygon').remove();
    node.selectAll('line').remove();
    node.select('defs').remove();
    this.node = node = this.showEndPoints(node);

    return node;
  }

  private updateGuide(): void {
    this.node.selectAll('line').remove();
    this.showGuide();
  }

  private onDragEndPoints(e: undefined, d: number, nodes: d3.ArrayLike<SVGGElement> | { id: string }[]): void {
    if (this.locked || isShapeInSelectedShapes(this.id)) return;

    const event: d3.D3DragEvent<SVGGElement, any, any> = d3.event as d3.D3DragEvent<SVGGElement, any, any>;
    const stateStore = store.getState();

    if (stateStore.tool.activeTool !== Tools.Move) return;

    const startArrow = nodes[0].id.split('-')[1] === 'start';
    const movingArrow = startArrow ? 0 : this.geometry.coordinates.length - 1; // if it is the start of the end arrow
    const notMovingArrow = movingArrow === 0 ? 1 : 0;

    if (this.properties[`link${movingArrow}`]) return; // if the arrow is linked you cannot move it

    const arrowEl = this.node.select(`[id="arrow-${startArrow ? 'start' : 'end'}-${this.id}"]`);
    if (arrowEl && !arrowEl.classed('dragging')) {
      store.dispatch(saveCircuitToHistoryAction());
    }

    const previousCoords = [...this.geometry.coordinates];
    this.geometry = cloneDeep(this.geometry);
    const coordinates = this.geometry.coordinates;

    const x1 = coordinates[0][0];
    const y1 = coordinates[0][1];
    const x2 = coordinates[1][0];
    const y2 = coordinates[1][1];

    const measurerLength = getDistanceBetweenPoints([x1, y1], [x2, y2]);

    const snap90deg = stateStore.tool.snap90deg || false;
    const lockOrientation = stateStore.tool.lockOrientation || false;

    const deltaX = Math.abs(coordinates[0][0] - coordinates[1][0]);
    const deltaY = Math.abs(coordinates[0][1] - coordinates[1][1]);

    if (
      (this.properties.lockedLength && snap90deg) ||
      (this.properties.lockedLength && lockOrientation) ||
      isShapeInSelectedShapes(this.id)
    )
      return;

    if (!lockOrientation) {
      coordinates[movingArrow][0] += event.dx;
      coordinates[movingArrow][1] += -event.dy;
    } else if (lockOrientation && snap90deg) {
      //to keep the snap90 behavior when the user have selected lockOrientation and snap90
      coordinates[movingArrow][0] += event.dx;
      coordinates[movingArrow][1] += -event.dy;
    }

    if (snap90deg) {
      const attachedSegmentOtherEndpoint = this.properties[`link${notMovingArrow}`];

      if (attachedSegmentOtherEndpoint) {
        const measurerCoords = this.geometry.coordinates;
        const segment = stateStore.circuit.present.segments.entities[attachedSegmentOtherEndpoint.id] as
          | CircuitSegment
          | undefined;
        const rack = stateStore.circuit.present.racks.entities[attachedSegmentOtherEndpoint.id] as
          | CircuitRack
          | undefined;
        const zone = stateStore.circuit.present.zones.entities[attachedSegmentOtherEndpoint.id] as
          | CircuitZone
          | undefined;
        const stockZone = stateStore.circuit.present.stockZones.entities[attachedSegmentOtherEndpoint.id] as
          | CircuitStockZone
          | undefined;
        const point = stateStore.circuit.present.points.entities[attachedSegmentOtherEndpoint.id] as
          | CircuitPoint
          | undefined;

        if (segment) {
          const segmentCoords = segment.geometry.coordinates;
          // When the measurer is attached to the end of a segment
          if (
            measurerCoords[0][0] === segmentCoords[0][0] ||
            measurerCoords[0][0] === segmentCoords[1][0] ||
            measurerCoords[1][0] === segmentCoords[0][0] ||
            measurerCoords[1][0] === segmentCoords[1][0]
          ) {
            this.geometry.coordinates = snap90degLine(coordinates, notMovingArrow);

            if (deltaX > deltaY) {
              coordinates[movingArrow][0] = event.x;
            } else {
              coordinates[movingArrow][1] = -event.y;
            }
          } else {
            this.geometry.coordinates = snap90degLine(coordinates, movingArrow);

            const [linesIntersect, , intersection] = findIntersectionBetweenLines(
              measurerCoords[0],
              measurerCoords[1],
              segmentCoords[0],
              segmentCoords[1]
            );

            if (linesIntersect && intersection) {
              this.geometry.coordinates[notMovingArrow] = intersection;
            } else {
              this.geometry.coordinates = previousCoords;
            }
          }
        } else if (point) {
          this.geometry.coordinates = snap90degLine(coordinates, notMovingArrow);

          if (deltaX > deltaY) {
            coordinates[movingArrow][0] = event.x;
          } else {
            coordinates[movingArrow][1] = -event.y;
          }
        } else if (rack || stockZone || zone) {
          const shape = rack ?? stockZone ?? zone;

          if (!shape) {
            // eslint-disable-next-line no-console
            console.error(`No rack/stockzone/zone with id ${attachedSegmentOtherEndpoint.id}`);

            return;
          }

          const line = getClosestPointAndLineInPolygon(measurerCoords[notMovingArrow], shape).line;

          this.geometry.coordinates = snap90degLine(coordinates, movingArrow);

          const [linesIntersect, , intersection] = findIntersectionBetweenLines(
            measurerCoords[0],
            measurerCoords[1],
            line[0],
            line[1]
          );

          if (linesIntersect && intersection) {
            this.geometry.coordinates[notMovingArrow] = intersection;
          } else {
            this.geometry.coordinates = previousCoords;
          }
        }
      } else {
        this.geometry.coordinates = snap90degLine(coordinates, notMovingArrow);

        if (deltaX > deltaY) {
          coordinates[movingArrow][0] = event.x;
        } else {
          coordinates[movingArrow][1] = -event.y;
        }
      }
    } else if (lockOrientation) {
      // when we snap to 90deg we don't need to lock the orientation given that it is already locked in a way
      // we compute the coordinates of the closest point on the line from the mouse position
      const [[newX, newY]] = getClosestPointInSegment([event.x, -event.y], coordinates[0], coordinates[1], false);

      coordinates[movingArrow][0] = newX;
      coordinates[movingArrow][1] = newY;
    } else if (this.properties.lockedLength) {
      const measurerAngleUpdated = toRad(findShapeOrientation(coordinates[notMovingArrow], coordinates[movingArrow]));

      let newX = coordinates[movingArrow][0];
      let newY = coordinates[movingArrow][1];

      newX = coordinates[notMovingArrow][0] + Math.cos(measurerAngleUpdated) * measurerLength;
      newY = coordinates[notMovingArrow][1] + Math.sin(measurerAngleUpdated) * measurerLength;

      coordinates[movingArrow][0] = newX;
      coordinates[movingArrow][1] = newY;
    }

    super.onDrag({
      isEndPoint: true,
    });
    this.updateEndPoints(this.node);

    arrowEl.classed('dragging', true);
  }

  private onDragEndPointsEnd(): void {
    const measurerMoved =
      this.geometry.coordinates !== store.getState().circuit.present.measurers.entities[this.id].geometry.coordinates;
    if (this.locked || isShapeInSelectedShapes(this.id) || !measurerMoved) return;

    setShapeJustMoved(this.id);

    const stateStore = store.getState();

    store.dispatch(
      updateMeasurerAction({
        measurerId: this.id,
        coordinates: this.geometry.coordinates,
        doNotRecomputeCoordinates: stateStore.tool.snap90deg || undefined,
      })
    );

    super.onDragEnd(this.properties);
  }

  protected onDrag(): void {
    if (!this.draggeable()) return;

    const isAttached0 = !!this.properties.link0;

    const isAttached1 = !!this.properties.link1;

    if (
      isShapeInSelectedShapes(this.id) ||
      this.locked ||
      (isAttached0 && this.properties.link0?.type === 'POINT') ||
      (isAttached1 && this.properties.link1?.type === 'POINT')
    )
      return;

    const event = d3.event as d3.D3DragEvent<SVGGElement, any, any>;

    this.geometry.coordinates = offsetPositionLine(this.geometry.coordinates, event.dx, event.dy);

    super.onDrag();

    this.updateEndPoints(this.node);
    if (this.properties.guide) this.updateGuide();
  }

  protected onDragEnd(): void {
    const measurerMoved = !isEqual(
      this.geometry.coordinates,
      store.getState().circuit.present.measurers.entities[this.id].geometry.coordinates
    );
    if (!this.draggeable() || !measurerMoved) return;

    setShapeJustMoved(this.id);

    store.dispatch(
      updateMeasurerAction({
        measurerId: this.id,
        coordinates: this.geometry.coordinates,
      })
    );

    super.onDragEnd(this.properties);
  }

  protected updateShape(): void {
    this.refreshGeometry();
    this.updateEndPoints(this.node);
  }

  public updateProperties(): void {
    const properties = store.getState().circuit.present.measurers.entities[this.id]?.properties;
    if (properties) {
      this.properties = properties;
    }
  }

  protected draggeable(): boolean {
    return !((this.locked && this.properties.link0 && this.properties.link1) || isShapeInSelectedShapes(this.id));
  }

  protected translateEnd(): void {
    const measurerMoved =
      this.geometry.coordinates !== store.getState().circuit.present.measurers.entities[this.id].geometry.coordinates;

    if (!this.draggeable() || !measurerMoved) return;

    const isAttached = !!(this.properties.link0 || this.properties.link1);
    if (isAttached) return;

    store.dispatch(
      saveMeasurerAction({
        id: this.id,
        geometry: this.geometry,
      })
    );
  }
}
