import * as Comlink from 'comlink';
import importedWorker from '../export/generate-xml-utils.ts?worker';
import type { generateXMLWorker } from './generate-xml-utils.ts';

const worker = new importedWorker({
  name: 'generate-xml-worker',
});
export const generateXmlWorkerProxy = Comlink.wrap<{
  generateXMLWorker: typeof generateXMLWorker;
}>(worker);
