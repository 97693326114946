import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, ButtonGroup, Divider, ListSubheader, Menu, MenuItem, Tooltip } from '@mui/material';
import type { SxProps, Theme } from '@mui/system';
import { Box } from '@mui/system';
import { findShapeOrientation } from 'drawings/helpers';
import type { CircuitRack } from 'models/circuit';
import { isCheckTracklessResult } from 'models/circuit.guard';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Itinerary } from 'routes/routes';
import { addMultipleItineraries, clearAllItineraries, selectLayerGroup } from 'routes/routes';
import { checkPermission } from 'services/check-permission';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import { minimumDistanceBetweenPointsTrackless } from 'services/trajectory.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';
import { simplifyListOfPositions } from 'utils/circuit/simplify-itinerary';
import { generateXML } from 'utils/export/generate-xml.tsx';
import { capitalize, normalizeAngleToMinusPiPi, toRad } from 'utils/helpers';

interface CheckTrackLessRackButtonProps {
  rack: CircuitRack;
  sx?: SxProps<Theme> | undefined;
}

export function CheckTrackLessRackButton(props: CheckTrackLessRackButtonProps): JSX.Element {
  const [isComputing, setIsComputing] = useState(false);

  const layerGroups = useAppSelector((state) => state.circuit.present.layers.layerGroups);
  const layerGroupsArrWithoutCommon = useMemo(
    () => Object.values(layerGroups).filter((layerGroup) => layerGroup.name !== 'Common'),
    [layerGroups]
  );
  const selectedLayerGroup = useAppSelector((state) => state.routes.selectedLayerGroup);
  const dispatch = useAppDispatch();

  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);

  const handleClickDropDown = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenu(e.currentTarget);
  }, []);

  const handleCloseDropDown = useCallback(() => {
    setAnchorElMenu(null);
  }, []);

  const handleChangeSelectedLayerGroup = useCallback(
    (layerGroupId: string) => {
      dispatch(selectLayerGroup({ layerGroup: layerGroupId }));
      handleCloseDropDown();
    },
    [dispatch, handleCloseDropDown]
  );

  // define a selected layer group by default if none is selected
  useEffect(() => {
    if (selectedLayerGroup === null && layerGroupsArrWithoutCommon[0]) {
      dispatch(
        selectLayerGroup({
          layerGroup: layerGroupsArrWithoutCommon[0].id,
        })
      );
    }
  }, [dispatch, layerGroupsArrWithoutCommon, selectedLayerGroup]);

  const rack = props.rack;

  const handleCheckTrackLessRack = useCallback(async () => {
    const rackId = rack.id;
    if (!rack || !rackId || typeof rackId !== 'string') {
      // eslint-disable-next-line no-console
      console.log('No rack selected');

      return;
    }

    if (!selectedLayerGroup) {
      // eslint-disable-next-line no-console
      console.error('No layer group selected');

      return;
    }

    setIsComputing(true);

    dispatch(clearAllItineraries());

    const extendedLengthSegments = rack.properties.columns.flatMap((column) => {
      return column.extendedLengthSegments;
    });

    // eslint-disable-next-line no-console
    console.log('Generating the xml file...');

    const [xml] = await generateXML({
      zones: CircuitService.zones,
      segments: CircuitService.segments,
      stockZones: CircuitService.stockZones,
      measurers: CircuitService.measurers,
      turns: CircuitService.turns,
      racks: CircuitService.racks,
      points: CircuitService.points,
    });

    // eslint-disable-next-line no-console
    console.log('generated');

    const segments = store.getState().circuit.present.segments.entities;
    const simulationService = (await import('../../../services/simulation.service.ts'))?.simulationService;
    if (!simulationService) {
      // eslint-disable-next-line no-console
      console.error('libTrack not loaded');

      return;
    }

    // eslint-disable-next-line no-console
    console.log('Loading the circuit in the libtrack...');

    const circuitPtr = await simulationService.allocateUTF8(xml);
    await simulationService._TRACK_WasmWrapper_loadCircuit(circuitPtr);
    await simulationService._OS_WasmWrapper_free(circuitPtr);

    const selectedLayerGroupName = store.getState().circuit.present.layers.layerGroups[selectedLayerGroup].name;

    const layerNamePtr = await simulationService.allocateUTF8(selectedLayerGroupName);

    const itinerariesToAdd: Itinerary[] = [];

    let nbErrorComputationTracklessPortions = 0;

    const report = {
      nbGeneratedTracklessPortions: 0,

      errorsList: new Map<string, number>(),
      nbErrors: 0,
    };

    // we loop through every extended length of the rack to check if we can create trackless portions
    for (let i = 0; i < extendedLengthSegments.length; i++) {
      const extendedLengthSegment = extendedLengthSegments[i];
      const segment = segments[extendedLengthSegment[0]];

      if (!segment) {
        // eslint-disable-next-line no-console
        console.error(`Segment ${extendedLengthSegment[0]} not found`);

        return;
      }

      const coords = segment.geometry.coordinates;

      const heading = normalizeAngleToMinusPiPi(toRad(findShapeOrientation(coords[0], coords[1])));

      // eslint-disable-next-line no-console
      console.time('_TRACK_WasmWrapper_checkTrackless');
      const resStrPtr = await simulationService._TRACK_WasmWrapper_checkTrackless(
        layerNamePtr,
        coords[0][0] / 100,
        coords[0][1] / 100,
        heading
      );
      const resStr = await simulationService.UTF8ToString(resStrPtr);
      // eslint-disable-next-line no-console
      console.timeEnd('_TRACK_WasmWrapper_checkTrackless');

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const tracklessRes = (() => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return JSON.parse(resStr);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(`Error while parsing the result of check trackless`, e);
          // eslint-disable-next-line no-console
          console.error(resStr);

          return '';
        }
      })();
      if (isCheckTracklessResult(tracklessRes)) {
        if (tracklessRes.errorID === 0) {
          const tracklessTrajectories = tracklessRes.tracklessTrajectories;
          if (!tracklessTrajectories) {
            // eslint-disable-next-line no-console
            console.error(`Check trackless returned a wrong type`, tracklessRes);

            return;
          }

          // we loop through the computed trajectories for the trackless turns
          // and we add them to the store to display them
          for (const key in tracklessTrajectories) {
            const tracklessTrajectory = tracklessTrajectories[key];

            if (!tracklessTrajectory || 'error' in tracklessTrajectory) {
              // eslint-disable-next-line no-console
              console.warn('No trajectory in the check trackless despite no error', {
                tracklessTrajectories,
                tracklessTrajectory,
              });

              report.nbErrors++;

              report.errorsList.set(
                tracklessTrajectory?.error ?? '',
                (report.errorsList.get(tracklessTrajectory?.error ?? '') ?? 0) + 1
              );

              continue;
            }

            const positions = simplifyListOfPositions(
              tracklessTrajectory.listOfPosition,
              minimumDistanceBetweenPointsTrackless
            ).map((position) => ({
              x: position.x * 100,
              y: -position.y * 100,
              heading: position.heading,
            }));

            const newItinerary: Itinerary = {
              id: nanoid(),
              distance: tracklessTrajectory.distance,
              error: tracklessRes.error ?? '',
              errorId: tracklessRes.errorID,
              portionIds: tracklessTrajectory.listOfPortionID,
              positions,
            };

            itinerariesToAdd.push(newItinerary);

            report.nbGeneratedTracklessPortions++;
          }
        } else {
          report.nbErrors++;

          report.errorsList.set(tracklessRes.error ?? '', (report.errorsList.get(tracklessRes.error ?? '') ?? 0) + 1);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(`Check trackless returned a wrong type`, tracklessRes);

        nbErrorComputationTracklessPortions++;
      }
    }

    if (nbErrorComputationTracklessPortions) {
      SnackbarUtils.error(
        `An error happened during the computation of ${nbErrorComputationTracklessPortions} out of ${extendedLengthSegments.length} trackless portions`
      );
    }

    SnackbarUtils.info(
      <Box component="div">
        <Box component="div">Number of generated trackless turns: {report.nbGeneratedTracklessPortions}</Box>
        {report.errorsList.size ? (
          <Box component="div">
            {Array.from(report.errorsList).map(([errorTxt, errorNb]) => (
              <>
                <br />×{errorNb}: {capitalize(errorTxt)}
              </>
            ))}
          </Box>
        ) : (
          <></>
        )}
      </Box>
    );

    if (itinerariesToAdd.length) {
      dispatch(addMultipleItineraries(itinerariesToAdd));
    }

    await simulationService._OS_WasmWrapper_free(layerNamePtr);

    setIsComputing(false);
  }, [dispatch, rack, selectedLayerGroup]);

  const handleClearItineraries = useCallback(() => {
    dispatch(clearAllItineraries());
  }, [dispatch]);

  const authorized = useAsyncMemo(async () => {
    return await checkPermission('open:traffic_tools');
  }, []);

  return (
    <Tooltip title={!authorized ? 'You are not authorized to use this feature' : ''}>
      <Box component="span">
        <ButtonGroup
          variant="contained"
          sx={{
            textTransform: 'none',
            width: '100%',
            ...props.sx,
          }}
          color="inherit"
          disabled={isComputing || !authorized}
        >
          <LoadingButton
            loading={isComputing}
            onClick={handleCheckTrackLessRack}
            sx={{ textTransform: 'none' }}
            variant="contained"
            fullWidth
          >
            Check Trackless
          </LoadingButton>
          <Button onClick={handleClickDropDown}>
            <ArrowDropDownIcon />
          </Button>
          <Tooltip title="Clear itineraries" onClick={handleClearItineraries}>
            <Box component="span">
              <Button>
                <ClearIcon />
              </Button>
            </Box>
          </Tooltip>
        </ButtonGroup>
        <Menu anchorEl={anchorElMenu} open={!!anchorElMenu} onClose={handleCloseDropDown}>
          <ListSubheader>Layer group</ListSubheader>
          <Divider />

          {layerGroupsArrWithoutCommon.map((layerGroup) => (
            <MenuItem
              key={layerGroup.id}
              selected={layerGroup.id === selectedLayerGroup}
              onClick={() => handleChangeSelectedLayerGroup(layerGroup.id)}
            >
              {layerGroup.name}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Tooltip>
  );
}
