import { Edit } from '@mui/icons-material';
import { Alert, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { selectToolAction } from 'actions';
import { setSelectedStationId } from 'flows/flows';
import { Tools } from 'models/tools';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { isDefined } from 'utils/ts/is-defined';

export function AlertUsedEmptyStation(): JSX.Element {
  const flows = useAppSelector((state) => state.flows.flows);
  const stations = useAppSelector((state) => state.flows.stations);

  const dispatch = useAppDispatch();

  const usedStations = useMemo(() => {
    const stationIds = flows.flatMap((flow) => flow.stations.flatMap((station) => station.id)).filter(isDefined);

    return [...new Set(stations.filter((station) => stationIds.includes(station.id)))];
  }, [flows, stations]);

  const emptyUsedStations = useMemo(() => {
    return usedStations.filter((station) => station.positions.length === 0);
  }, [usedStations]);

  const emptyUsedStationsUnique = useMemo(() => {
    const ids = emptyUsedStations.map((station) => station.id);
    const uniqueIds = [...new Set(ids)];

    return uniqueIds.map((id) => emptyUsedStations.find((station) => station.id === id)).filter(isDefined);
  }, [emptyUsedStations]);

  const handleClickStation = useCallback(
    (stationId: string) => {
      dispatch(setSelectedStationId(stationId));

      dispatch(
        selectToolAction({
          toolName: Tools.StationsConfiguration,
        })
      );
    },
    [dispatch]
  );

  if (emptyUsedStationsUnique.length === 0) return <></>;

  return (
    <Alert
      severity="warning"
      sx={{
        marginBottom: theme.spacing(1),
      }}
    >
      The following station{emptyUsedStationsUnique.length !== 1 ? 's are' : ' is'} used but{' '}
      {emptyUsedStationsUnique.length !== 1 ? 'have' : ' has'} no position:
      <List dense>
        {emptyUsedStationsUnique.map((station) => (
          <ListItem
            key={station.id}
            secondaryAction={
              <IconButton onClick={() => handleClickStation(station.id)} edge="end" size="small">
                <Edit />
              </IconButton>
            }
          >
            <ListItemText primary={station.name} />
          </ListItem>
        ))}
      </List>
      The simulation will work without using the flows and triggers impacted.
    </Alert>
  );
}
