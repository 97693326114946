import { Box, Stack } from '@mui/material';
import { DialogDisplayer } from 'components/core';
import { MenuBar } from 'components/menu-bar';
import { ToolBarDrawer } from 'components/menu-bar/tool-bar-drawer';
import { useAppSelector } from 'store';
import PerformanceMonitor from './performance-monitor';

export function MainLayout({ children }): JSX.Element {
  const showPerformances = useAppSelector((state) => state.editor.isShowPerformancesEnabled);

  return (
    <Stack direction="column" height="100vh">
      <MenuBar />
      <Stack direction="row" height="calc(100vh - 64px)">
        <ToolBarDrawer />
        <Box
          component="div"
          sx={{
            height: '100%',
            width: '100%',
            position: 'relative',
            overflowY: 'hidden',
            overflowX: 'hidden',
            outlineOffset: '-8px',
          }}
          id="editorView"
        >
          {children}
          <DialogDisplayer />
          {showPerformances && <PerformanceMonitor />}
        </Box>
      </Stack>
    </Stack>
  );
}
