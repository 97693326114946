import { selectCircuitShapeAction } from 'actions/circuit';
import { saveStockZoneAction } from 'actions/stock-zones';
import PalletSvgUrl from 'assets/pallet.svg';
import * as d3 from 'd3';
import type { DragEventProps } from 'drawings/elements';
import { RectangleOrTriangle } from 'drawings/elements';
import {
  arrowsToString,
  computeArrowsFromLineString,
  findShapeOrientation,
  rotatePolygon,
  svgCoordsToMathCoords,
} from 'drawings/helpers';
import type { SimpleSelection } from 'drawings/shared';
import type { Polygon } from 'geojson';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import type { PalletPosition, StockZoneProperties } from 'models/circuit';
import { ReferenceMethodX, ReferenceMethodY, ShapeTypes } from 'models/circuit';
import { CircuitService } from 'services/circuit.service';
import type { StockManagerRecord } from 'simulation/simulation';
import store from 'store';
import { updateStockZone } from 'utils/circuit/stockzones';
import { cssTransformToPosition, epsilon } from 'utils/circuit/utils';
import { SEGMENT_ARROW_LENGTH, SEGMENT_ARROW_THICKNESS } from 'utils/config';
import { toDeg, toRad } from 'utils/helpers';
import { theme } from 'utils/mui-theme';

export const palletSvg = PalletSvgUrl;

export interface StockZoneDrawProperties {
  stock?: StockManagerRecord;
}

export class StockZone extends RectangleOrTriangle {
  private translating = false;

  public segmentMovedTMO: number | NodeJS.Timeout | undefined;

  constructor(
    id: string,
    geometry: Polygon,
    projection: d3.GeoPath<any, d3.GeoPermissibleObjects>,
    zoomScale: number,
    public properties: StockZoneProperties,
    stockZoneDrawProperties?: StockZoneDrawProperties
  ) {
    super(id, ShapeTypes.StockZoneShape, geometry, projection, zoomScale);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.projection = projection;
    this.zoomScale = zoomScale;

    this.setLocked(!!this.properties?.locked);

    this.node.attr('layer-id', properties?.layerId || '');

    this.node = this.showSlots(this.node, stockZoneDrawProperties);
  }

  public showSlots(
    node: SimpleSelection<SVGGElement, undefined>,
    stockZoneDrawProperties?: StockZoneDrawProperties
  ): SimpleSelection<SVGGElement, undefined> {
    const performanceMode = store.getState().editor.isPerformanceModeEnabled;

    const originZone = [...this.geometry.coordinates[0][0]];
    originZone[1] *= -1;

    const angleDeg = this.properties.cap;
    const cap = toRad(this.properties.cap);
    const cos = Math.cos;
    const sin = Math.sin;

    let pointFirstSlot: number[];

    const referencePosX = this.properties.referencePosX;
    const referencePosY = this.properties.referencePosY;
    const offsetX = this.properties.referenceOffsetX;
    const offsetY = this.properties.referenceOffsetY;
    const palletSize = this.properties.palletSize;

    const stock = stockZoneDrawProperties?.stock;
    this.properties?.slots.forEach((slotLine) => {
      slotLine.slots.forEach((slot, index) => {
        const posSlotInZone = [slot.slotPosition.x - originZone[0], slot.slotPosition.y - originZone[1]];
        const slotSize = { ...slot.slotSize };

        slotSize.length *= 100; // m -> cm
        slotSize.width *= 100; // m -> cm

        let pointsSlot = [
          [posSlotInZone[0], posSlotInZone[1]],
          [posSlotInZone[0] + slotSize.length, posSlotInZone[1]],
          [posSlotInZone[0] + slotSize.length, posSlotInZone[1] + slotSize.width],
          [posSlotInZone[0], posSlotInZone[1] + slotSize.width],
        ];

        let pointsPallet = [...pointsSlot];

        let dx = 0,
          dy = 0;
        if (referencePosX === ReferenceMethodX.frontEdge) {
          dx = slotSize.length - offsetX - palletSize.length * 100;
        } else if (referencePosX === ReferenceMethodX.center) {
          dx = (slotSize.length - offsetX - palletSize.length * 100) / 2;
        } // no need for referencePosX === ReferenceMethodX.rearEdge given that it is already the proper position

        if (referencePosY === ReferenceMethodY.rightEdge) {
          dy = slotSize.width - offsetY - palletSize.width * 100;
        } else if (referencePosY === ReferenceMethodY.center) {
          dy = (slotSize.width - offsetY - palletSize.width * 100) / 2;
        }

        if (dx !== 0 || dy !== 0) {
          pointsPallet = pointsPallet.map((point) => [point[0] + dx, point[1] + dy]);
        }

        // first we rotate the rectangle (rotation matrix in 2D)
        pointsSlot = pointsSlot.map((point) => [
          point[0] * cos(cap) - point[1] * sin(cap),
          point[0] * sin(cap) + point[1] * cos(cap),
        ]);
        pointsPallet = pointsPallet.map((point) => [
          point[0] * cos(cap) - point[1] * sin(cap),
          point[0] * sin(cap) + point[1] * cos(cap),
        ]);
        // then we translate it
        pointsSlot = pointsSlot?.map((point) => [
          point[0] + slot.slotPosition.x - posSlotInZone[0],
          point[1] + slot.slotPosition.y - posSlotInZone[1],
        ]);
        pointsPallet = pointsPallet?.map((point) => [
          point[0] + slot.slotPosition.x - posSlotInZone[0],
          point[1] + slot.slotPosition.y - posSlotInZone[1],
        ]);

        const slotEl = node
          .append<SVGElement>('svg:polygon')
          .classed('slot', true)
          .attr('points', slot.geometry.map((coords) => coords.join(',')).join(' ')); // for performance, it may be better to use a css transform instead of computing ourselves the points
        if (slot.disabled) {
          slotEl.attr('disabled', true);
        }

        if (this.properties.displayPallets && !slot.disabled && !performanceMode) {
          const stockInThisStockLine = stock?.[slotLine.id];
          if (stockInThisStockLine !== undefined && index > stockInThisStockLine) return; // we do not display the pallet, because not enough stock

          const width = this.properties.palletSize.length * 100;
          const height = this.properties.palletSize.width * 100;

          node
            .append<SVGElement>('svg:g')
            .classed('pallet', true)
            .style('transform', `rotate(${angleDeg}deg)`)
            .style('transform-box', 'fill-box')
            .append('svg')
            .attr('viewBox', '0 0 512 366')
            .attr('x', pointsPallet[0][0])
            .attr('y', pointsPallet[0][1])
            .attr('preserveAspectRatio', 'none')
            .attr('width', width)
            .attr('height', height)
            .append('svg:use')
            .attr('href', `${palletSvg}#pallet-svg`);
        }

        if (index === 0) {
          pointFirstSlot = [slot.slotPosition.x, slot.slotPosition.y];
        }
      });

      const arrowLength = SEGMENT_ARROW_LENGTH; // px
      const arrowThickness = SEGMENT_ARROW_THICKNESS; // px

      const startArrow = [originZone[0], -pointFirstSlot[1] - (this.properties.slotSize.width * 100) / 2];
      const endArrow = [
        originZone[0] + getDistanceBetweenPoints(this.geometry.coordinates[0][0], this.geometry.coordinates[0][1]),
        startArrow[1],
      ];

      let arrowCoords = [startArrow, endArrow];
      arrowCoords = rotatePolygon([originZone[0], -originZone[1]], arrowCoords, toDeg(cap));
      const arrows = arrowsToString(computeArrowsFromLineString(arrowCoords, [1, 1], arrowLength, arrowThickness));

      node
        .append<SVGElement>('svg:line')
        .style('stroke-dasharray', 'none')
        .attr('x1', arrowCoords[0][0])
        .attr('y1', -arrowCoords[0][1])
        .attr('x2', arrowCoords[1][0] - arrowLength * cos(cap))
        .attr('y2', -arrowCoords[1][1] - arrowLength * sin(cap));

      node
        .append<SVGElement>('svg:polygon')
        .attr('points', arrows[0])
        .attr('fill', 'currentColor')
        .attr('fill-opacity', '1')
        .attr('stroke', 'none');

      node
        .append<SVGElement>('svg:polygon')
        .attr('points', arrows[1])
        .attr('fill', 'currentColor')
        .attr('fill-opacity', '1')
        .attr('stroke', 'none');
    });

    this.drawStockLineNameText(true);

    return node;
  }

  protected onDrag(): void {
    if (!this.draggeable()) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const event = d3.event;
    const dx = event.dx as number;
    const dy = event.dy as number;

    this.node.selectAll('rect, line, polygon, [type=SEGMENT] path').style('transform', (d, i, nodes) => {
      const node = d3.select(nodes[i]);
      const transform = node?.style('transform') as string | undefined | null;

      if (transform === 'none' || !transform) {
        return `translate(${dx}px, ${dy}px)`;
      }

      // next line converts translate(32px, 12px) to [32, 12]
      const translated = cssTransformToPosition(transform);

      return `translate(${translated[0] + dx}px, ${translated[1] + dy}px)`;
    });
    if (!this.translating) {
      // while dragging we only display the square of the stockzone
      this.node.selectAll('g').style('display', 'none');

      // and we decrease the opacity of its extended length
      // to show that they are outdated and will be updated soon
      this.properties.slots.forEach((stockLine) => {
        d3.selectAll(`[TYPE="SEGMENT"][stockline-id="${stockLine.id}"]`).style('opacity', 0.1);
      });

      this.translating = true;
    }

    this.drawStockLineNameText(false);

    super.onDrag();
  }

  protected translateEnd(): void {
    if (!this.draggeable()) return;

    const props = this.properties;

    const slotSample = props.slots[0].slots[0];
    const slotSize = props.slotSize;
    const length = props.length;
    const width = props.width;
    const palletTypes = props.palletTypes;
    const palletPosition: PalletPosition = {
      referenceX: props.referencePosX,
      offsetX: props.referenceOffsetX,
      referenceY: props.referencePosY,
      offsetY: props.referenceOffsetY,
    };
    const palletSize = props.palletSize;
    const tolerancePosition = slotSample.tolerancePosition;
    const gap = props.gap;
    const cap = props.cap;

    const newCoords = [...this.geometry.coordinates[0][0]];
    const transform = this.node?.style('transform');

    if (transform && transform !== 'none') {
      const translated = transform
        .split('(')[1]
        .split(')')[0]
        .split(',')
        .map((nb) => parseFloat(nb.replace('px', '')));
      newCoords[0] += translated[0];
      newCoords[1] += translated[1];
    }

    let slots = CircuitService.generateStockZoneSlots(
      [newCoords],
      slotSize,
      gap,
      length,
      width,
      palletTypes,
      palletPosition,
      palletSize,
      tolerancePosition,
      cap,
      props.slots,
      props.customGapSlots,
      props.customGapLines,
      props.name,
      {
        lineNameOnlyIfNotUserGenerated: true,
      }
    );

    slots = CircuitService.computeSlotsGeometry(slots, cap);

    this.translating = false;

    store.dispatch(
      saveStockZoneAction({
        id: this.id,
        geometry: { ...this.geometry },
        properties: {
          ...props,
          cap: props.cap,
          slots,
        },
      })
    );
  }

  protected onHandleDragStart(): void {
    if (this.locked) return;

    this.node.selectAll('rect, line, polygon, [type=SEGMENT] path').style('fill-opacity', '0.05');
    this.node.selectAll('[type=SEGMENT]').style('stroke-opacity', '0.05');
    this.node.selectAll('g').style('display', 'none');

    super.onHandleDragStart(true);
  }

  protected onHandleDragEnd(properties?: DragEventProps): void {
    super.onHandleDragEnd();

    this.onDragEnd(true);
  }

  protected onDragEnd(forceMove = false): void {
    if (!this.draggeable()) return;

    super.onDragEnd(this.properties);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const shape = store.getState().circuit.present.stockZones.entities[this.id];
    const geometry = this.geometry;
    const properties = this.properties;
    const coords = shape.geometry.coordinates;
    const dx = geometry.coordinates[0][0][0] - coords[0][0][0];
    const dy = geometry.coordinates[0][0][1] - coords[0][0][1];

    const d = Math.sqrt(dx * dx + dy * dy);
    // if the dragging distance is null, we consider it as a click and select the shape, otherwise we just move the shape
    if (d < epsilon && !forceMove) {
      if (store.getState().local.selectedShapesData.some((selectedShape) => selectedShape.id === this.id)) return;

      setTimeout(() => {
        store.dispatch(
          selectCircuitShapeAction({
            selectedShapeId: this.id,
            selectedShapeType: this.type,
          })
        );
      }, 10);
    } else {
      const p1 = svgCoordsToMathCoords(geometry.coordinates[0][0] as [number, number]);
      const p2 = svgCoordsToMathCoords(geometry.coordinates[0][1] as [number, number]);
      const cap = findShapeOrientation(p1, p2);

      updateStockZone(
        {
          id: this.id,
          geometry: { ...geometry },
          properties: { ...properties },
          type: 'Feature',
        },
        {
          cap: cap ?? undefined,
          forceUpdateCap: true,
          newCoordinates: geometry.coordinates,
        }
      );
    }
  }

  protected translate(): void {
    if (!this.translating) {
      this.node.selectAll('rect, line, polygon, [type=SEGMENT] path').style('fill-opacity', '0.05');
      this.node.selectAll('[type=SEGMENT]').style('stroke-opacity', '0.05');

      this.translating = true;
    }

    this.drawStockLineNameText(true);
    super.translate();
  }
  protected drawStockLineNameText(active = this.active): void {
    this.node.selectAll(`.stock-line-name`).remove();

    if (!active) return;
    const shape = store.getState().circuit.present.stockZones.entities[this.id];
    const layers = store.getState().circuit.present.layers.layers;
    const shapeLayerId = shape?.properties.layerId;
    const layerColor = layers[shapeLayerId]?.color;
    const backgroundColor = theme.palette.getContrastText(layerColor);

    this.properties.slots.forEach((slotLine) => {
      active = active === undefined ? this.active : active;
      const middleSlotIndex = Math.floor(slotLine.slots.length / 2);
      const middleSlot = slotLine.slots[middleSlotIndex];
      const middleSlotX = middleSlot.geometry[0][0];
      const middleSlotY = middleSlot.geometry[0][1];

      const cap = this.properties.cap;

      const capToRad = toRad(cap);
      const textOffsetX = 20;
      const textOffsetY = 40;
      const adjustedX = middleSlotX + textOffsetX * Math.cos(capToRad) - textOffsetY * Math.sin(capToRad);
      const adjustedY = middleSlotY + textOffsetX * Math.sin(capToRad) + textOffsetY * Math.cos(capToRad);
      const isRotateCap = (cap > 90 && cap < 270) || (cap < -90 && cap > -270);
      const rotateTransform = isRotateCap ? `rotate(${cap + 180}deg)` : `rotate(${cap}deg)`;
      const originRotate = `${adjustedX}px ${adjustedY}px`;
      const rectSetX = isRotateCap ? adjustedX - 95 : adjustedX - 20;
      const rectSetY = isRotateCap ? adjustedY - 20 : adjustedY - 20;
      const textSetX = isRotateCap ? adjustedX - 90 : adjustedX - 15;
      const textSetY = isRotateCap ? adjustedY : adjustedY;

      this.node
        .append('svg:rect')
        .attr('x', rectSetX)
        .attr('y', rectSetY)
        .attr('width', slotLine.name.length * 9)
        .attr('height', 30)
        .classed('stock-line-name-background', true)
        .style('fill', backgroundColor)
        .style('fill-opacity', 0.7)
        .style('stroke', 'none')
        .style('transform', rotateTransform)
        .style('transform-origin', originRotate);

      this.node
        .append('svg:text')
        .attr('x', textSetX)
        .attr('y', textSetY)
        .classed('stock-line-name', true)
        .text(slotLine.name)
        .style('fill', layerColor)
        .style('transform', rotateTransform)
        .style('transform-origin', originRotate);
    });
  }
}
