import { openDialogAction } from 'actions';
import { DialogTypes } from 'models';
import { projectHost, provider } from 'multiplayer/globals';
import { connectRoom, disconnectRoom, setSynced } from 'multiplayer/multiplayer';
import type { SnackbarKey } from 'notistack';
import { useEffect, useRef } from 'react';
import { setCameraPosition } from 'reducers/local/camera-position.reducer';
import { CircuitService } from 'services/circuit.service';
import { getLidarBounds } from 'services/maps.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch } from 'store';
import type { RenderMode } from '../multiplayer';

export function useMultiplayerInit({
  multiplayer,
  synced,
  mode,
}: {
  multiplayer: boolean;
  synced: boolean;
  mode: RenderMode;
}): void {
  const dispatch = useAppDispatch();
  const roomId = window.location.pathname.split('/')[2];

  /* Automatically join the room if a roomId is provided and disconnect on cleanup */
  const validRoomIdLength = 21;
  const pendingSnackbar = useRef<SnackbarKey>();
  const slowNetworkTimeout = useRef<NodeJS.Timeout>();
  const disableTimeoutInterval = useRef<NodeJS.Timeout>();

  function disableTimeout(): void {
    if (!provider) return;

    clearInterval(provider._checkInterval);
    clearInterval(provider.awareness._checkInterval);
  }

  function disableTimeoutOnSlowNetwork(): void {
    slowNetworkTimeout.current = setTimeout(() => {
      SnackbarUtils.toast("The network is slower than expected, please don't close the window", {
        variant: 'warning',
        autoHideDuration: 5000,
      });
      disableTimeout();
      disableTimeoutInterval.current = setInterval(() => {
        disableTimeout();
      }, 25000);
    }, 25000);
  }

  useEffect(() => {
    if (!roomId || projectHost) return;

    if (roomId.length !== validRoomIdLength) {
      SnackbarUtils.toast('No multiplayer room found', { variant: 'error', autoHideDuration: 2000 });
      dispatch(openDialogAction({ type: DialogTypes.Welcome, payload: undefined }));
      window.history.replaceState({}, '', '/editor');

      return;
    }

    dispatch(connectRoom({ roomId: roomId }));

    SnackbarUtils.toast('Connected to multiplayer room', { variant: 'success', autoHideDuration: 2000 });

    pendingSnackbar.current = SnackbarUtils.toast('Fetching project...', { variant: 'info', persist: true }, false);
    disableTimeoutOnSlowNetwork();

    return () => {
      if (pendingSnackbar.current) SnackbarUtils.closeSnackbar(pendingSnackbar.current);
      if (disableTimeoutInterval.current) clearInterval(disableTimeoutInterval.current);

      dispatch(disconnectRoom());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInitProjectUploaded = (event): void => {
    /* This variable is used to filter the response of message sent by size so that we know the project was successfuly uploaded */
    const filterAwarenessMessageByteSize = 3000;
    if (event.data.byteLength < filterAwarenessMessageByteSize) return;

    dispatch(setSynced());
  };

  useEffect(() => {
    if (!projectHost) return;

    if (disableTimeoutInterval.current) clearInterval(disableTimeoutInterval.current);
    if (slowNetworkTimeout.current) clearTimeout(slowNetworkTimeout.current);
    if (pendingSnackbar.current) SnackbarUtils.closeSnackbar(pendingSnackbar.current);

    if (!multiplayer || synced) return;

    pendingSnackbar.current = SnackbarUtils.toast('Uploading project...', { variant: 'info', persist: true }, false);
    disableTimeoutOnSlowNetwork();

    provider?.ws?.addEventListener('message', onInitProjectUploaded);

    return () => {
      provider?.ws?.removeEventListener('message', onInitProjectUploaded);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiplayer]);

  useEffect(() => {
    if (!synced) return;
    if (disableTimeoutInterval.current) clearInterval(disableTimeoutInterval.current);
    if (slowNetworkTimeout.current) clearTimeout(slowNetworkTimeout.current);
    if (pendingSnackbar.current) SnackbarUtils.closeSnackbar(pendingSnackbar.current);

    if (projectHost) {
      provider?.ws?.removeEventListener('message', onInitProjectUploaded);
      SnackbarUtils.toast('Project uploaded, you can now share the room', {
        variant: 'success',
        autoHideDuration: 2000,
      });

      return;
    }

    SnackbarUtils.toast('Project fetched', { variant: 'success', autoHideDuration: 2000 });

    if (mode === '2d') {
      CircuitService.getDrawingReference()?.centerZoomToBackground();
    } else {
      // Center the camera to the background for lib3D
      const lidarData = store.getState().maps.lidar['background-lidar'];
      if (lidarData) {
        const { minX, maxY, minY, maxX } = getLidarBounds(lidarData.coordinates);
        dispatch(
          setCameraPosition({
            position: { minX: minX * 0.01, maxY: maxY * 0.01, minY: minY * 0.01, maxX: maxX * 0.01 },
          })
        );
      } else {
        // eslint-disable-next-line no-console
        console.warn('No lidar data found to center on');
      }
    }

    SnackbarUtils.info('Save the project to prevent warning on file detection');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [synced]);
}
