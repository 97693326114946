import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, TextField } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { Flow } from 'flows/flows';
import { setFlow } from 'flows/flows';
import { useCallback, useRef } from 'react';
import { useAppDispatch } from 'store';

interface FlowConfigurationProps {
  selectedFlowId: string;
  selectedFlow: Flow;
}
export function PalletsPerTaskTexfield(props: FlowConfigurationProps): JSX.Element {
  const { selectedFlowId, selectedFlow } = props;

  const dispatch = useAppDispatch();

  const palletsPerTaskInputRef = useRef<HTMLInputElement>(null);

  const minPalletsPerTaskValue = 0.001;
  const maxPalletsPerTaskValue = 50;

  const handleChangePalletsPerTask = useCallback(() => {
    if (!selectedFlow) return;
    const input = palletsPerTaskInputRef.current;
    if (!input) return;

    const palletsPerTask = Math.round(input.valueAsNumber * 1000) / 1000;

    let palletsPerTaskOk = true;
    if (isNaN(palletsPerTask)) palletsPerTaskOk = false;
    else if (palletsPerTask < minPalletsPerTaskValue) palletsPerTaskOk = false;

    if (!palletsPerTaskOk) {
      input.value = '0.001';

      dispatch(
        setFlow({
          ...selectedFlow,
          palletsPerTask: 0.001,
        })
      );

      return;
    }

    if (palletsPerTask > maxPalletsPerTaskValue) {
      input.value = '50';

      dispatch(
        setFlow({
          ...selectedFlow,
          palletsPerTask: 50,
        })
      );

      return;
    }

    dispatch(
      setFlow({
        ...selectedFlow,
        palletsPerTask: palletsPerTask,
      })
    );

    input.value = `${palletsPerTask}`;
  }, [dispatch, selectedFlow]);

  return (
    <TextField
      key={selectedFlowId}
      type="number"
      label={
        <>
          Number of pallets per task{' '}
          <HelpIconTooltip title="Number of pallets moved during one task. Warning ! This value can affect the throughput when expressed in pallets per hour." />
        </>
      }
      variant="outlined"
      fullWidth
      size="small"
      inputProps={{ min: minPalletsPerTaskValue, step: 1 }}
      InputProps={{
        endAdornment: (
          <>
            <IconButton
              onClick={(e) => {
                if (palletsPerTaskInputRef.current) {
                  palletsPerTaskInputRef.current.value = '';
                  handleChangePalletsPerTask();
                }
              }}
              size="small"
            >
              <ClearIcon fontSize="small" />
            </IconButton>
            pal/task
          </>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={selectedFlow.palletsPerTask}
      inputRef={palletsPerTaskInputRef}
      onBlur={handleChangePalletsPerTask}
    />
  );
}
