import { PreferencesService } from 'utils/preferences';

export function saveRackAnalysisDateApplied(): void {
  const dbPromise = indexedDB.open('rackAnalysis');

  dbPromise.onupgradeneeded = (event) => {
    const db = (event.target as IDBOpenDBRequest).result;

    if (!db.objectStoreNames.contains('saved')) {
      db.createObjectStore('saved');
    }
  };

  dbPromise.onsuccess = (event) => {
    const db = (event.target as IDBOpenDBRequest).result;

    if (!db.objectStoreNames.contains('saved')) return;

    const savedTransaction = db.transaction('saved', 'readonly');
    const savedObjectStore = savedTransaction.objectStore('saved');

    const pastSaved = savedObjectStore.get(PreferencesService.getProjectName());

    pastSaved.onsuccess = (event) => {
      const savedRacks = (event.target as IDBRequest<Record<string, number>>).result;
      const newSavedRacks = { ...savedRacks };

      const transaction = db.transaction('_unsaved', 'readonly');
      const objectStore = transaction.objectStore('_unsaved');

      objectStore.openCursor().onsuccess = (event) => {
        const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;

        if (cursor) {
          newSavedRacks[cursor.key as string] = cursor.value as number;

          cursor.continue();
        } else {
          const newSavedTransaction = db.transaction('saved', 'readwrite');
          const newSavedObjectStore = newSavedTransaction.objectStore('saved');
          newSavedObjectStore.put(newSavedRacks, PreferencesService.getProjectName());
          db.close();
        }
      };
    };
  };
}
