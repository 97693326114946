import {
  deleteAllCellTemplatesAction,
  importCellTemplatesAction,
  importCircuitAction,
  importLayersAction,
  restoreCircuitInitialStateAction,
  updateLayerAction,
} from 'actions/circuit';
import type { CircuitShapes, GeoJsonCircuit } from 'models/circuit';
import { DISPLAY_UNIT_FACTOR } from 'models/drawings';
import { projectSliceInitialState, setDescription, setVersion } from 'project/project';
import { connect } from 'react-redux';
import type { LayersDataContainer } from 'reducers/circuit/state';

import { syncYJSLocalToRemote } from 'components/presence/utils/syncYjsDoc';
import { localDoc } from 'multiplayer/globals';
import { setLoadingStateAction } from 'reducers/core/reducer';
import type { FilterState } from 'reducers/local/filters.reducer';
import {
  changeOpacityFilterAction,
  changeStateFilterAction,
  getFilterDataInitialState,
} from 'reducers/local/filters.reducer';
import { selectBackgroundLidar, selectForegroundLidar, selectMapImage } from 'reducers/maps';
import type { AppState } from 'reducers/state';
import type { Dispatch } from 'redux';
import { CircuitService, resetDisplayPriority } from 'services/circuit.service';
import store from 'store';
import { getLegacyLayersInitial } from 'utils/circuit/initialize-layer-groups';
import { ImportCircuitComponent } from './import-circuit';

interface StateProps {
  isBackgroundLoaded: boolean;
}

function mapStateToProps(state: AppState): StateProps {
  const backgroundLidar = selectBackgroundLidar(state);
  const foregroundLidar = selectForegroundLidar(state);
  const mapImage = selectMapImage(state);

  return {
    isBackgroundLoaded: !!foregroundLidar || !!backgroundLidar || !!mapImage,
  };
}

interface DispatchProps {
  uploadCircuit: (file: File) => void;
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    uploadCircuit: (file: File) => {
      // super similar to load project, but load only the circuit
      // to be refactored where we create a function for loading the circuit
      // and then we embed this function in the load project function

      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const textContent = fileReader.result?.toString();

        if (textContent) {
          const circuitGeoJSON = JSON.parse(textContent) as GeoJsonCircuit;
          const shapes: CircuitShapes = CircuitService.convertFeatureArrayCoordinates(
            circuitGeoJSON.features.flatMap((layer) => layer.features),
            (value) => value * DISPLAY_UNIT_FACTOR
          ) as CircuitShapes;

          dispatch(setLoadingStateAction({ newLoadingState: true }));

          dispatch(restoreCircuitInitialStateAction());

          resetDisplayPriority();

          const layers: LayersDataContainer = circuitGeoJSON?.properties?.layers || getLegacyLayersInitial();
          Object.values(layers.layers).forEach((layer, index) => {
            if (layer.order === undefined) {
              layer.order = index;
            }

            if (layer.color === 'brown') {
              // reason: #47564
              layer.color = '#8B4513';
            }
          });
          dispatch(importLayersAction(layers));
          dispatch(
            updateLayerAction({
              layerId: layers.selectedLayer,
              selectedLayer: layers.selectedLayer,
            })
          );

          dispatch(deleteAllCellTemplatesAction());
          const cellTemplates = circuitGeoJSON?.properties?.cellTemplates || {};
          dispatch(importCellTemplatesAction({ cellTemplates }));

          const filters: FilterState | undefined = circuitGeoJSON?.properties?.filters || getFilterDataInitialState();
          if (filters) {
            dispatch(
              changeStateFilterAction({
                ...getFilterDataInitialState(),
                ...filters,
              })
            );

            dispatch(
              changeOpacityFilterAction({
                ...getFilterDataInitialState(),
                ...filters,
              })
            );
          }

          dispatch(importCircuitAction({ shapes }));

          // Here we load circuit version and description from GeaoJSON file to be able to read them
          if (circuitGeoJSON && circuitGeoJSON.properties) {
            dispatch(setVersion(circuitGeoJSON.properties.circuitVersion || projectSliceInitialState.circuitVersion));
            dispatch(
              setDescription(
                circuitGeoJSON.properties.circuitDescription || projectSliceInitialState.circuitDescription
              )
            );
          }

          dispatch(setLoadingStateAction({ newLoadingState: false }));

          if (!store.getState().multiplayer.multiplayer) return;
          const localCircuitMap = localDoc.getMap('circuit');
          localCircuitMap.set('circuit', store.getState().circuit.present);
          syncYJSLocalToRemote();
        }
      };

      if (file) {
        fileReader.readAsText(file);
      }
    },
  };
}

export const ImportCircuit = connect(mapStateToProps, mapDispatchToProps)(ImportCircuitComponent);
