import { createCellTemplateSuccessAction } from 'actions/cell-templates';
import type { RoadEditorAction } from 'actions/circuit';
import {
  addTurnAction,
  clearShapesSelectionAction,
  selectMultipleCircuitShapesAction,
  updateSegmentsPortionsAction,
  updateTurnAction,
} from 'actions/circuit';
import { createDeviceSuccessAction } from 'actions/devices';
import { createMeasurerSuccessAction } from 'actions/measurers';
import { createNoteSuccessAction } from 'actions/notes';
import { createPointSuccessAction } from 'actions/points';
import { createRackSuccessAction } from 'actions/racks';
import { createSegmentSuccessAction } from 'actions/segment';
import type { Failure } from 'actions/shared';
import { failureAction } from 'actions/shared';
import { createStockZoneSuccessAction } from 'actions/stock-zones';
import { createZoneSuccessAction } from 'actions/zones';
import type { Feature, LineString, Point, Polygon } from 'geojson';
import { getDistanceBetweenPoints } from 'librarycircuit/utils/geometry/vectors';
import { cloneDeep } from 'lodash';
import type {
  CircuitDevice,
  CircuitMeasurer,
  CircuitNote,
  CircuitPoint,
  CircuitPortion,
  CircuitRack,
  CircuitSegment,
  CircuitShape,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
  DeviceProperties,
  FeatureCollectionWithProperties,
  GeoJsonCircuit,
  InterestPointProperties,
  MeasurerProperties,
  NoteProperties,
  PalletPosition,
  SegmentProperties,
  StockZoneProperties,
  TurnProperties,
  ZoneProperties,
} from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { isCellTemplate } from 'models/circuit.guard';
import { selectSelectedShapeData } from 'reducers/local/selectors';
import type { SelectedShapesData } from 'reducers/local/state';
import type { AppState } from 'reducers/state';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { combineEpics, ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { CircuitService, generateUnusedIP, getMaxDisplayPriority } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import type { Equals } from 'tsafe';
import { assert } from 'tsafe';
import { checkIsExtendedLength } from 'utils/circuit/is-extended-length';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { isCircuitMeasurer, isCircuitRack, isCircuitSegment, isCircuitZone } from 'utils/circuit/shape-guards';
import type { Action } from 'utils/redux';
import { isDefined } from 'utils/ts/is-defined';
import {
  convertPredefinedShapeCoords,
  convertPredefinedShapeCoordsArray,
  convertPredefinedShapeCoordsLine,
  findAverageCentroid,
  findShapeOrientation,
  getClosestPointInSegment,
  offsetPosition,
  offsetPositionArray,
  offsetPositionLine,
} from './helpers';
import { mouseData } from './mouse-data';

localStorage.turnInTheClipBoard = false;

export enum ClipboardTypes {
  CopySelection = '[Clipboard] Copy selection to clipboard',
  CopySelectionSuccess = '[Clipboard] Copy selection to clipboard success',
  CopySelectionFailure = '[Clipboard] Copy selection to clipboard failure',
  CopyPredefinedShapes = '[Clipboard] Copy predefined shapes to clipboard',
  CopyPredefinedShapesSuccess = '[Clipboard] Copy predefined shapes to clipboard success',
  CopyPredefinedShapesFailure = '[Clipboard] Copy predefined shapes to clipboard failure',
  PasteSelection = '[Clipboard] Paste selection from clipboard',
  PasteSelectionSuccess = '[Clipboard] Paste selection from clipboard success',
  PasteSelectionFailure = '[Clipboard] Paste selection from clipboard failure',
}

export interface CopySelection extends Action {
  type: ClipboardTypes.CopySelection;
}
interface CopySelectionSuccess extends Action {
  type: ClipboardTypes.CopySelectionSuccess;
  payload: {
    shapesData: Feature[];
  };
}
interface CopySelectionFailure extends Failure<ClipboardTypes.CopySelectionFailure> {}

export interface CopyPredefinedShapes extends Action {
  type: ClipboardTypes.CopyPredefinedShapes;
  payload: {
    predefinedShapes: CircuitShape[];
    defaultPredefinedShapes: GeoJsonCircuit;
  };
}

interface CopyPredefinedShapesSuccess extends Action {
  type: ClipboardTypes.CopyPredefinedShapesSuccess;
  payload: {
    shapesData: Feature[];
  };
}
interface CopyPredefinedShapesFailure extends Failure<ClipboardTypes.CopyPredefinedShapesFailure> {}

export function copySelectionAction(): CopySelection {
  return { type: ClipboardTypes.CopySelection, payload: {} };
}

export function copySelectionSuccessAction(payload: CopySelectionSuccess['payload']): CopySelectionSuccess {
  return { type: ClipboardTypes.CopySelectionSuccess, payload };
}

export function copySelectionFailureAction(error: Error): CopySelectionFailure {
  return failureAction(ClipboardTypes.CopySelectionFailure, error);
}

export function copyPredefinedShapesAction(payload: CopyPredefinedShapes['payload']): CopyPredefinedShapes {
  return { type: ClipboardTypes.CopyPredefinedShapes, payload };
}

export function copyPredefinedShapesSuccessAction(
  payload: CopyPredefinedShapesSuccess['payload']
): CopyPredefinedShapesSuccess {
  return { type: ClipboardTypes.CopyPredefinedShapesSuccess, payload };
}

export function copyPredefinedShapesFailureAction(error: Error): CopyPredefinedShapesFailure {
  return failureAction(ClipboardTypes.CopyPredefinedShapesFailure, error);
}

export interface PasteSelection extends Action {
  type: ClipboardTypes.PasteSelection;
  payload: { userAction: boolean; shortcut: boolean };
}
interface PasteSelectionSuccess extends Action {
  type: ClipboardTypes.PasteSelectionSuccess;
  payload: {
    shapesData: Feature[];
  } & RoadEditorAction['payload'];
}
interface PasteSelectionFailure extends Failure<ClipboardTypes.PasteSelectionFailure> {}

export function pasteSelectionAction(manual?: boolean, shortcut?: boolean): PasteSelection {
  return { type: ClipboardTypes.PasteSelection, payload: { userAction: !!manual, shortcut: !!shortcut } };
}

export function pasteSelectionSuccessAction(payload: PasteSelectionSuccess['payload']): PasteSelectionSuccess {
  return { type: ClipboardTypes.PasteSelectionSuccess, payload };
}

export function pasteSelectionFailureAction(error: Error): PasteSelectionFailure {
  return failureAction(ClipboardTypes.PasteSelectionFailure, error);
}

export function copySelection$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<CopySelectionSuccess | CopySelectionFailure> {
  return actions$.pipe(
    ofType<CopySelection>(ClipboardTypes.CopySelection),
    withLatestFrom(state$),
    map(([action, state]) => {
      const selectedShapes = selectSelectedShapeData(state);

      return [selectedShapes, state] as [SelectedShapesData, AppState];
    }),
    filter(([selectedShapes, state]) => selectedShapes.length > 0),
    mergeMap(async ([selectedShapes, state]) => {
      const shapesToCopy: Feature[] = [];

      /** we copy the selected shapes */
      selectedShapes.forEach((selectedShape) => {
        const shape = CircuitService.getShape(selectedShape.id, selectedShape.type);

        if (shape) {
          let feat = CircuitService.shapeToFeature(shape);

          if (isCircuitMeasurer(shape)) {
            /** if it's a measurer, we detach both extremities */
            feat = cloneDeep(feat);
          } else if (isCircuitRack(shape) && 'conveyor' in shape.properties && shape.properties.conveyor) {
            /** if the shape is a conveyor, we ensure to add its zone as well */
            const isZoneInTheSelection = selectedShapes.find(
              (selectedShape) => selectedShape.id === shape.properties?.conveyor?.zone
            );
            if (!isZoneInTheSelection) {
              const zone = CircuitService.getShape(shape.properties.conveyor.zone, ShapeTypes.ZoneShape);
              if (zone) {
                const zoneFeat = CircuitService.shapeToFeature(zone);
                shapesToCopy.push(zoneFeat);
              } else {
                // eslint-disable-next-line no-console
                console.error(`Conveyor ${shape.id} has no zone, we didn't copy it`);

                return;
              }
            }
          } else if (isCircuitZone(shape) && 'conveyor' in shape.properties && shape.properties.conveyor) {
            /** if the shape is a conveyor zone, we ensure to add its conveyor as well */
            const isConveyorInTheSelection = selectedShapes.find(
              (selectedShape) => selectedShape.id === shape.properties?.conveyor
            );
            if (!isConveyorInTheSelection) {
              const conveyor = CircuitService.getShape(shape.properties.conveyor, ShapeTypes.RackShape);
              if (conveyor) {
                const conveyorFeat = CircuitService.shapeToFeature(conveyor);
                shapesToCopy.push(conveyorFeat);
              } else {
                // eslint-disable-next-line no-console
                console.error(`Zone ${shape.id} has no conveyor, we didn't copy it`);

                return;
              }
            }
          } else if (isCircuitSegment(shape)) {
            const isExtendedLength = checkIsExtendedLength(shape);

            if (isExtendedLength) return; // we do not copy extended length segments, they need to be recreated when pasting the rack or the stock zone
          }

          shapesToCopy.push(feat);
        }
      });

      /**
       * we need to compute which cell templates we need to embed in the clipboard
       * = all the cell templates used at least once in a cell of a selected rack
       */
      const racksToCopy: CircuitRack[] = [];
      selectedShapes.forEach((selectedShape) => {
        if (selectedShape.type === ShapeTypes.RackShape) {
          const rack = CircuitService.getShape(selectedShape.id, selectedShape.type) as CircuitRack;
          if (rack) {
            racksToCopy.push(rack);
          }
        }
      });

      const cellTemplatesIdToEmbed = new Set<string>();
      racksToCopy.forEach((rack) => {
        rack.properties.columns.forEach((column) => {
          column.cells.forEach((cell) => {
            if (cell.cellTemplate) {
              cellTemplatesIdToEmbed.add(cell.cellTemplate);
            }
          });
        });
      });
      const cellTemplatesToEmbed = Array.from(cellTemplatesIdToEmbed).map((cellTemplateId) => {
        return state.circuit.present.cellTemplates.entities[cellTemplateId];
      });

      const geoJsonToSave: FeatureCollectionWithProperties = {
        type: 'FeatureCollection',
        properties: {
          copiedTime: new Date(),
          software: 'Balyo Road Editor',
          cellTemplates: cellTemplatesToEmbed,
        },
        features: shapesToCopy,
      };

      try {
        await navigator.clipboard.writeText(JSON.stringify(geoJsonToSave));

        const resPayload = {
          shapesData: shapesToCopy,
        };

        const turnsData = shapesToCopy.filter((shape) => {
          return shape.properties?.type === ShapeTypes.TurnShape;
        });
        localStorage.turnInTheClipBoard = turnsData.length === 1 && turnsData.length === shapesToCopy.length;
        localStorage.nbShapesInClipboard = shapesToCopy.length - turnsData.length;

        return copySelectionSuccessAction(resPayload);
      } catch (err) {
        return copySelectionFailureAction(err as Error);
      }
    })
  );
}

export function copyPredefinedShapes$(
  actions$: ActionsObservable<CopyPredefinedShapes>,
  state$: StateObservable<AppState>
): Observable<CopyPredefinedShapesSuccess | CopyPredefinedShapesFailure> {
  return actions$.pipe(
    ofType<CopyPredefinedShapes>(ClipboardTypes.CopyPredefinedShapes),
    withLatestFrom(state$),
    map(([action, state]) => {
      const predefinedShapes = action.payload.predefinedShapes;
      const defaultPredefinedShapes = action.payload.defaultPredefinedShapes;

      return [predefinedShapes, defaultPredefinedShapes] as [CircuitShape[], GeoJsonCircuit];
    }),
    filter(([predefinedShapes]) => predefinedShapes.length > 0),
    mergeMap(async ([predefinedShapes, defaultPredefinedShapes]) => {
      const shapesToCopy: Feature[] = [];

      predefinedShapes.forEach((predefinedShape) => {
        // we do not copy extended length segments, they need to be recreated when pasting the rack or the stock zone
        if (
          predefinedShape.properties.type === ShapeTypes.SegmentShape &&
          (predefinedShape.properties.rack || predefinedShape.properties.stockLine)
        ) {
          return;
        }

        shapesToCopy.push(predefinedShape);
      });

      /**
       * we need to compute which cell templates we need to embed in the clipboard
       * = all the cell templates used at least once in a cell of a selected rack
       */
      const racksToCopy: CircuitRack[] = [];

      predefinedShapes.forEach((predefinedShape) => {
        if (predefinedShape.properties.type === ShapeTypes.RackShape) {
          const rack = predefinedShape as CircuitRack;
          if (rack) {
            racksToCopy.push(rack);
          }
        }
      });

      const cellTemplatesIdToEmbed = new Set<string>();
      racksToCopy.forEach((rack) => {
        rack.properties.columns.forEach((column) => {
          column.cells.forEach((cell) => {
            if (cell.cellTemplate) {
              cellTemplatesIdToEmbed.add(cell.cellTemplate);
            }
          });
        });
      });
      const cellTemplatesToEmbed = Array.from(cellTemplatesIdToEmbed)
        .map((cellTemplateId) => {
          if (defaultPredefinedShapes.properties.cellTemplates) {
            return defaultPredefinedShapes.properties.cellTemplates[cellTemplateId];
          }

          return undefined;
        })
        .filter(isDefined);

      const geoJsonToSave: FeatureCollectionWithProperties = {
        type: 'FeatureCollection',
        properties: {
          copiedTime: new Date(),
          software: 'Balyo Road Editor',
          cellTemplates: cellTemplatesToEmbed,
          predefinedShapes: true,
        },
        features: shapesToCopy,
      };

      try {
        await navigator.clipboard.writeText(JSON.stringify(geoJsonToSave));

        const resPayload = {
          shapesData: shapesToCopy,
        };

        const turnsData = shapesToCopy.filter((shape) => {
          return shape.properties?.type === ShapeTypes.TurnShape;
        });
        localStorage.turnInTheClipBoard = turnsData.length === 1 && turnsData.length === shapesToCopy.length;
        localStorage.nbShapesInClipboard = shapesToCopy.length - turnsData.length;

        return copyPredefinedShapesSuccessAction(resPayload);
      } catch (err) {
        return copyPredefinedShapesFailureAction(err as Error);
      }
    })
  );
}

export function pasteSelection$(
  actions$: ActionsObservable<PasteSelection>,
  state$: StateObservable<AppState>
): Observable<PasteSelectionSuccess | PasteSelectionFailure> {
  return actions$.pipe(
    ofType<PasteSelection>(ClipboardTypes.PasteSelection),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      // first of all, we retrieve the content of the clipboard
      try {
        // we get the data from the keyboard
        const clipboardData = await navigator.clipboard.readText();
        // we check the data
        if (clipboardData && clipboardData.length) {
          try {
            const geojsonClipboard = JSON.parse(clipboardData) as
              | string
              | number
              | null
              | FeatureCollectionWithProperties;
            // a better validation with a type guard would be better
            if (
              geojsonClipboard &&
              typeof geojsonClipboard === 'object' &&
              'properties' in geojsonClipboard &&
              typeof geojsonClipboard.properties === 'object' &&
              geojsonClipboard.properties !== null &&
              'software' in geojsonClipboard.properties &&
              geojsonClipboard.properties.software === 'Balyo Road Editor' &&
              'features' in geojsonClipboard
            ) {
              const currentLayerId = CircuitService.getSelectedLayer();
              const features: Feature[] = geojsonClipboard.features;
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const isPredefinedShapes: boolean | undefined = geojsonClipboard.properties.predefinedShapes;

              const isShortcutAction = action.payload.shortcut;

              const mousePositon = [mouseData.lastClickX * 100, mouseData.lastClickY * 100];

              const centerSelection = findAverageCentroid(features);

              const offsetPredefinedShapes = {
                x: mousePositon[0] - centerSelection[0] * 100,
                y: -(mousePositon[1] - centerSelection[1] * 100),
              };

              const offsetConfig = {
                x: mousePositon[0] - centerSelection[0],
                y: -(mousePositon[1] - centerSelection[1]),
              };

              const noOffset = { x: 0, y: 0 };

              const turnsToPaste: CircuitTurn[] = [];

              const selectedShapes = store.getState().local.selectedShapesData;
              const selectedTurns = selectedShapes.filter((shape) => shape.type === ShapeTypes.TurnShape);
              const onlyTurnsSelected =
                selectedShapes &&
                selectedShapes.length &&
                selectedTurns &&
                selectedTurns.length &&
                selectedTurns.length === selectedShapes.length;

              const shapesToSelect: SelectedShapesData = [];

              const segmentsToUpdatePortions = new Set<string>();

              // before inserting the shapes, we need to check if there are some cell templates to add
              // to the circuit fist
              let errorPastingCellTemplate = false;
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const cellTemplatesToAdd = geojsonClipboard.properties.cellTemplates;

              if (cellTemplatesToAdd && Array.isArray(cellTemplatesToAdd) && cellTemplatesToAdd.length) {
                const cellTemplatesIds = state.circuit.present.cellTemplates.ids;
                const cellTemplates = state.circuit.present.cellTemplates.entities;
                cellTemplatesToAdd.forEach((cellTemplate) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  if (isCellTemplate(cellTemplate)) {
                    const cellTemplateInStore = state.circuit.present.cellTemplates.entities[cellTemplate.id];
                    const existingCellTemplate = !!(
                      cellTemplateInStore && cellTemplateInStore.name === cellTemplate.name
                    );
                    if (!existingCellTemplate) {
                      // const nameAlreadyExists = cellTemplatesIds.findIndex((cellTemplateId) => cellTemplates[cellTemplateId].name === cellTemplate.name) !== -1;
                      while (
                        cellTemplatesIds.findIndex(
                          (cellTemplateId) => cellTemplates[cellTemplateId].name === cellTemplate.name
                        ) !== -1
                      ) {
                        cellTemplate.name = `${cellTemplate.name} (copy)`;
                      }

                      // we create a new id for the cellTemplate = creating a new cellTemplate
                      const formerdId = cellTemplate.id;
                      const newId = generateShapeId();
                      cellTemplate.id = newId;

                      geojsonClipboard.features.forEach((feature) => {
                        const feat = feature as CircuitShape;

                        if (feat.properties.type === ShapeTypes.RackShape) {
                          const rack = feat as CircuitRack;
                          rack.properties.columns.forEach((column) => {
                            column.cells.forEach((cell) => {
                              if (cell.cellTemplate === formerdId) {
                                cell.cellTemplate = newId;
                              }
                            });
                          });
                        }
                      });

                      store.dispatch(createCellTemplateSuccessAction(structuredClone(cellTemplate)));
                    }
                  } else {
                    // eslint-disable-next-line no-console
                    console.error('Invalid cell template in clipboard', cellTemplate);

                    errorPastingCellTemplate = true;
                  }
                });
              }

              if (errorPastingCellTemplate) {
                SnackbarUtils.error('Invalid cell template in clipboard');
              }

              // if only turns are selected, we don't want to create new shapes, but only
              // paste the turn paramaters in the selected turns
              // otherwise we unselect the selected shapes and paste the new ones
              if (features.length && !onlyTurnsSelected) {
                store.dispatch(clearShapesSelectionAction());
              }

              // we create the ids for the new shapes
              const formerIdsToNewIds = new Map<string, string>();
              features.forEach((feature) => {
                const newId = generateShapeId();

                formerIdsToNewIds.set(feature.id as string, newId);
              });

              features.forEach((feature) => {
                if (!feature || !feature.properties || !feature.properties.type) return;

                const offset = isShortcutAction ? noOffset : isPredefinedShapes ? offsetPredefinedShapes : offsetConfig;

                const shapeType = (feature as CircuitShape).properties.type;

                switch (shapeType) {
                  case ShapeTypes.PointShape: {
                    const pointProps = (feature as CircuitPoint).properties;

                    const coordsPoint = (feature.geometry as Point).coordinates;
                    const newCoordsPoint = isPredefinedShapes
                      ? offsetPosition(convertPredefinedShapeCoords(coordsPoint), offset.x, offset.y)
                      : offsetPosition(coordsPoint, offset.x, offset.y);

                    const newNamePoint = generateCopiedName(pointProps.name);

                    const newId = formerIdsToNewIds.get(feature.id as string) ?? generateShapeId();

                    const linkedSegment = pointProps.segment;
                    const newSegment = (() => {
                      // if the point is linked to a segment, we need to link it a new segment
                      return linkedSegment ? formerIdsToNewIds.get(linkedSegment.id) ?? undefined : undefined;
                    })();

                    const newPoint: CircuitPoint = {
                      id: newId,
                      type: 'Feature',
                      geometry: {
                        ...(feature.geometry as Point),
                        coordinates: newCoordsPoint,
                      },
                      properties: {
                        ...(feature.properties as InterestPointProperties),
                        segment:
                          newSegment && pointProps.segment?.position !== undefined
                            ? {
                                id: newSegment,
                                position: pointProps.segment.position,
                              }
                            : undefined,
                        name: newNamePoint,
                        layerId: currentLayerId,
                        prio: getMaxDisplayPriority(),
                      },
                    };

                    store.dispatch(createPointSuccessAction(structuredClone(newPoint)));

                    shapesToSelect.push({
                      id: newId,
                      type: ShapeTypes.PointShape,
                    });
                    break;
                  }

                  case ShapeTypes.SegmentShape: {
                    const newId = formerIdsToNewIds.get(feature.id as string) ?? generateShapeId();

                    const coordsSegment = (feature.geometry as LineString).coordinates;
                    const newCoordsSegment = isPredefinedShapes
                      ? offsetPositionLine(convertPredefinedShapeCoordsLine(coordsSegment), offset.x, offset.y)
                      : offsetPositionLine(coordsSegment, offset.x, offset.y);

                    const newNameSegment = generateCopiedName(feature.properties.name as string);

                    const newSegment: CircuitSegment = {
                      id: newId,
                      type: 'Feature',
                      geometry: {
                        ...(feature.geometry as LineString),
                        coordinates: newCoordsSegment,
                      },
                      properties: {
                        ...(feature.properties as SegmentProperties),
                        name: newNameSegment,
                        layerId: currentLayerId,
                        prio: getMaxDisplayPriority(),
                        portions: [] as CircuitPortion[],
                      },
                    };

                    store.dispatch(createSegmentSuccessAction(structuredClone(newSegment)));

                    shapesToSelect.push({
                      id: newId,
                      type: ShapeTypes.SegmentShape,
                    });

                    segmentsToUpdatePortions.add(newId);
                    break;
                  }

                  case ShapeTypes.MeasurerShape: {
                    const doMeasurerLinkExist = !!(feature.properties.link0 || feature.properties.link1);

                    const isTheSameLayer = currentLayerId === feature.properties.layerId;

                    if (doMeasurerLinkExist && isTheSameLayer === false) {
                      if (feature.properties.link0) delete feature.properties.link0;
                      if (feature.properties.link1) delete feature.properties.link1;
                    }

                    const coordsMeasurer = (feature.geometry as LineString).coordinates;
                    const newCoordsMeasurer = isPredefinedShapes
                      ? offsetPositionLine(convertPredefinedShapeCoordsLine(coordsMeasurer), offset.x, offset.y)
                      : offsetPositionLine(coordsMeasurer, offset.x, offset.y);

                    const newNameMeasurer = generateCopiedName(feature.properties.name as string);
                    const newId = formerIdsToNewIds.get(feature.id as string) ?? generateShapeId();

                    const newMeasurer: CircuitMeasurer = {
                      id: newId,
                      type: 'Feature',
                      geometry: {
                        ...(feature.geometry as LineString),
                        coordinates: newCoordsMeasurer,
                      },
                      properties: {
                        ...(feature.properties as MeasurerProperties),
                        name: newNameMeasurer,
                        layerId: currentLayerId,
                        prio: getMaxDisplayPriority(),
                      },
                    };

                    const isLink0ToASelectedShape = features.find((feat) => feat.id === feature.properties?.link0?.id);

                    if (isLink0ToASelectedShape && newMeasurer.properties.link0) {
                      newMeasurer.properties.link0 = {
                        ...newMeasurer.properties.link0,
                        id: formerIdsToNewIds.get(feature.properties.link0.id as string) ?? generateShapeId(),
                      };
                    }

                    const isLink1ToASelectedShape = features.find((feat) => feat.id === feature.properties?.link1?.id);

                    if (isLink1ToASelectedShape && newMeasurer.properties.link1) {
                      newMeasurer.properties.link1 = {
                        ...newMeasurer.properties.link1,
                        id: formerIdsToNewIds.get(feature.properties.link1.id as string) ?? generateShapeId(),
                      };
                    }

                    store.dispatch(createMeasurerSuccessAction(structuredClone(newMeasurer)));

                    shapesToSelect.push({
                      id: newId,
                      type: ShapeTypes.MeasurerShape,
                    });
                    break;
                  }

                  case ShapeTypes.ZoneShape: {
                    let conveyor: CircuitZone['properties']['conveyor'] | undefined;
                    if ('conveyor' in feature?.properties && feature.properties.conveyor) {
                      // we ensure that the rack is in the clipboard otherwise we don't paste it
                      const conveyorToBePasted = features.find((f) => f.properties?.conveyor?.zone === feature.id);
                      if (!conveyorToBePasted) {
                        // eslint-disable-next-line no-console
                        console.error(`Conveyor ${feature.id} has no zone in the selection, we didn't pasted it it`);

                        break;
                      }

                      conveyor = formerIdsToNewIds.get(conveyorToBePasted.id as string) ?? generateShapeId();
                    }

                    const coordsZone = (feature.geometry as Polygon).coordinates;
                    const newCoordsZone = isPredefinedShapes
                      ? offsetPositionArray(convertPredefinedShapeCoordsArray(coordsZone), offset.x, offset.y)
                      : offsetPositionArray(coordsZone, offset.x, offset.y);

                    const newNameZone = generateCopiedName(feature.properties.name as string);
                    const newId = formerIdsToNewIds.get(feature.id as string) ?? generateShapeId();

                    const newZone: CircuitZone = {
                      id: newId,
                      type: 'Feature',
                      geometry: {
                        ...(feature.geometry as Polygon),
                        coordinates: newCoordsZone,
                      },
                      properties: {
                        ...(feature.properties as ZoneProperties),
                        name: newNameZone,
                        layerId: currentLayerId,
                        conveyor,
                        prio: getMaxDisplayPriority(),
                      },
                    };

                    store.dispatch(createZoneSuccessAction(structuredClone(newZone)));

                    shapesToSelect.push({
                      id: newId,
                      type: ShapeTypes.ZoneShape,
                    });
                    break;
                  }

                  case ShapeTypes.StockZoneShape: {
                    const coordsStockZone = (feature.geometry as Polygon).coordinates;
                    const newCoordsStockZone = isPredefinedShapes
                      ? offsetPositionArray(convertPredefinedShapeCoordsArray(coordsStockZone), offset.x, offset.y)
                      : offsetPositionArray(coordsStockZone, offset.x, offset.y);

                    const newNameStockZone = generateCopiedName(feature.properties.name as string);

                    const props = cloneDeep(feature.properties as StockZoneProperties);
                    const slotSample = props.slots[0].slots[0];
                    const palletPosition: PalletPosition = {
                      referenceX: props.referencePosX,
                      offsetX: props.referenceOffsetX,
                      referenceY: props.referencePosY,
                      offsetY: props.referenceOffsetY,
                    };
                    let slots = CircuitService.generateStockZoneSlots(
                      newCoordsStockZone[0],
                      props.slotSize,
                      props.gap,
                      props.length,
                      props.width,
                      props.palletTypes,
                      palletPosition,
                      props.palletSize,
                      slotSample.tolerancePosition,
                      props.cap,
                      undefined,
                      props.customGapSlots, //? offsetPositionLine(props.customGapSlots, offset.x, offset.y) : undefined,
                      props.customGapLines, //? offsetPosition(props.customGapLines, offset.x, offset.y) : undefined,
                      newNameStockZone,
                      {
                        lineName: true,
                      }
                    );

                    slots = CircuitService.computeSlotsGeometry(slots, props.cap);

                    const newId = formerIdsToNewIds.get(feature.id as string) ?? generateShapeId();

                    const newStockZone: CircuitStockZone = {
                      id: newId,
                      type: 'Feature',
                      geometry: {
                        ...(feature.geometry as Polygon),
                        coordinates: newCoordsStockZone,
                      },
                      properties: {
                        ...props,
                        name: newNameStockZone,
                        layerId: currentLayerId,
                        slots,
                        prio: getMaxDisplayPriority(),
                      },
                    };

                    store.dispatch(createStockZoneSuccessAction(structuredClone(newStockZone)));

                    shapesToSelect.push({
                      id: newId,
                      type: ShapeTypes.StockZoneShape,
                    });
                    break;
                  }

                  case ShapeTypes.TurnShape: {
                    if (onlyTurnsSelected) {
                      const turn = feature.properties as TurnProperties;
                      selectedTurns.forEach((selectedTurn) => {
                        store.dispatch(
                          updateTurnAction({
                            idToUpdate: selectedTurn.id,
                            radius: turn.radius,
                            turnType: turn.turnType,
                            maxOvershoot: turn.maxOvershoot,
                            startPointOffset: turn.startPointOffset,
                          })
                        );
                      });
                    } else {
                      turnsToPaste.push(feature as CircuitTurn);
                    }

                    break;
                  }

                  case ShapeTypes.RackShape: {
                    let conveyor: CircuitRack['properties']['conveyor'] | undefined;
                    const isConveyor = 'conveyor' in feature?.properties && !!feature.properties.conveyor;
                    if (isConveyor) {
                      conveyor = {
                        ...feature.properties.conveyor,
                        zone: formerIdsToNewIds.get(feature.properties.conveyor.zone) ?? undefined,
                      } as CircuitRack['properties']['conveyor'];
                    }

                    const coordsRack = (feature.geometry as Polygon).coordinates;
                    const newCoords = isPredefinedShapes
                      ? offsetPositionArray(convertPredefinedShapeCoordsArray(coordsRack), offset.x, offset.y)
                      : offsetPositionArray(coordsRack, offset.x, offset.y);

                    const newName = generateCopiedName(feature.properties.name as string);
                    const props = cloneDeep(feature.properties as CircuitRack['properties']);
                    const newId = formerIdsToNewIds.get(feature.id as string) ?? generateShapeId();

                    const newColumns: CircuitRack['properties']['columns'] = props.columns.map((column) => ({
                      ...column,
                      id: generateShapeId(),
                      cells: column.cells.map((cell) => ({
                        ...cell,
                        id: generateShapeId(),
                        names: [],
                      })),
                      extendedLengthSegments: [],
                    }));

                    const newRack: CircuitRack = {
                      id: newId,
                      type: 'Feature',
                      geometry: {
                        type: 'Polygon',
                        bbox: feature.geometry.bbox,
                        coordinates: newCoords,
                      },
                      properties: {
                        ...props,
                        name: newName,
                        columns: newColumns,
                        layerId: currentLayerId,
                        conveyor,

                        prio: getMaxDisplayPriority(),
                      },
                    };

                    store.dispatch(createRackSuccessAction(structuredClone(newRack)));

                    shapesToSelect.push({
                      id: newId,
                      type: ShapeTypes.RackShape,
                    });

                    break;
                  }

                  case ShapeTypes.DeviceShape: {
                    const coordsDevice = (feature.geometry as Point).coordinates;
                    const newCoordsDevice = isPredefinedShapes
                      ? offsetPosition(convertPredefinedShapeCoords(coordsDevice), offset.x, offset.y)
                      : offsetPosition(coordsDevice, offset.x, offset.y);

                    const newNameDevice = generateCopiedName(feature.properties.name as string);

                    const newId = formerIdsToNewIds.get(feature.id as string) ?? generateShapeId();

                    const newDevice: CircuitDevice = {
                      id: newId,
                      type: 'Feature',
                      geometry: {
                        ...(feature.geometry as Point),
                        coordinates: newCoordsDevice,
                      },
                      properties: {
                        ...(feature.properties as DeviceProperties),
                        name: newNameDevice,
                        displayName: newNameDevice,
                        layerId: currentLayerId,
                        IP: generateUnusedIP(),

                        prio: getMaxDisplayPriority(),
                      },
                    };

                    store.dispatch(createDeviceSuccessAction(structuredClone(newDevice)));

                    shapesToSelect.push({
                      id: newId,
                      type: ShapeTypes.DeviceShape,
                    });
                    break;
                  }

                  case ShapeTypes.NoteShape: {
                    const coordsNote = (feature.geometry as Point).coordinates;
                    const newCoordsNote = isPredefinedShapes
                      ? offsetPosition(convertPredefinedShapeCoords(coordsNote), offset.x, offset.y)
                      : offsetPosition(coordsNote, offset.x, offset.y);

                    const newNameNote = feature.properties.name as string;

                    const newId = formerIdsToNewIds.get(feature.id as string) ?? generateShapeId();

                    const newNote: CircuitNote = {
                      id: newId,
                      type: 'Feature',
                      geometry: {
                        ...(feature.geometry as Point),
                        coordinates: newCoordsNote,
                      },
                      properties: {
                        ...(feature.properties as NoteProperties),
                        name: newNameNote,
                        prio: getMaxDisplayPriority(),
                      },
                    };

                    store.dispatch(createNoteSuccessAction(structuredClone(newNote)));

                    shapesToSelect.push({
                      id: newId,
                      type: ShapeTypes.NoteShape,
                    });
                    break;
                  }

                  default: {
                    // eslint-disable-next-line no-console
                    console.error(`Unknown shape type: ${feature.properties.type}`, feature);

                    assert<Equals<typeof shapeType, never>>();
                  }
                }
              });

              turnsToPaste.forEach((turn) => {
                const offset = isShortcutAction ? noOffset : isPredefinedShapes ? offsetPredefinedShapes : offsetConfig;

                const coords = isPredefinedShapes
                  ? convertPredefinedShapeCoordsLine(turn.geometry.coordinates)
                  : turn.geometry.coordinates;
                const originCoords = offsetPosition(coords[0], offset.x, offset.y);
                const destCoords = offsetPosition(coords[coords.length - 1], offset.x, offset.y);

                const closestSegOrigin = CircuitService.getClosestSegment(originCoords, currentLayerId);
                const closestSegDest = CircuitService.getClosestSegment(destCoords, currentLayerId);

                if (
                  closestSegOrigin &&
                  closestSegDest &&
                  closestSegOrigin.id &&
                  closestSegDest.id &&
                  closestSegOrigin.id !== closestSegDest.id
                ) {
                  const originSegCoords = closestSegOrigin.geometry.coordinates;
                  const destSegCoords = closestSegDest.geometry.coordinates;

                  const [closestPointOrigin, tClosestPointOrigin] = getClosestPointInSegment(
                    originCoords,
                    originSegCoords[0],
                    originSegCoords[1]
                  );
                  const [closestPointDest, tClosestPointDest] = getClosestPointInSegment(
                    destCoords,
                    destSegCoords[0],
                    destSegCoords[1]
                  );

                  const distanceOriginAndAndTurn = getDistanceBetweenPoints(originCoords, closestPointOrigin);
                  const dMini = 1; // cm
                  if (distanceOriginAndAndTurn > dMini) {
                    // eslint-disable-next-line no-console
                    console.log(
                      `Distance between origin and turn is too big: ${distanceOriginAndAndTurn} cm (> ${dMini} cm), turn id: ${turn.id}, segment id: ${closestSegOrigin.id}, cancelling the turn pasting`
                    );

                    return;
                  }

                  const distanceDestAndAndTurn = getDistanceBetweenPoints(destCoords, closestPointDest);
                  if (distanceDestAndAndTurn > dMini) {
                    // eslint-disable-next-line no-console
                    console.log(
                      `Distance between dest and turn is too big: ${distanceDestAndAndTurn} cm (> ${dMini} cm), turn id: ${turn.id}, segment id: ${closestSegDest.id}, cancelling the turn pasting`
                    );

                    return;
                  }

                  const angleOriginSeg = findShapeOrientation(originSegCoords[0], originSegCoords[1]);
                  const angleDestSeg = findShapeOrientation(destSegCoords[0], destSegCoords[1]);

                  store.dispatch(
                    addTurnAction({
                      origin: {
                        id: closestSegOrigin.id as string,
                        position: tClosestPointOrigin,
                        orientation: angleOriginSeg,
                        coordinates: { x: closestPointOrigin[0], y: closestPointOrigin[1] },
                        segment: originSegCoords,
                      },
                      destination: {
                        id: closestSegDest.id as string,
                        position: tClosestPointDest,
                        orientation: angleDestSeg,
                        coordinates: { x: closestPointDest[0], y: closestPointDest[1] },
                        segment: destSegCoords,
                      },

                      radius: turn.properties.radius,
                      turnType: turn.properties.turnType,
                      maxOvershoot: turn.properties.maxOvershoot,
                      startPointOffset: turn.properties.startPointOffset,
                    })
                  );
                }
              });

              if (segmentsToUpdatePortions.size) {
                store.dispatch(
                  updateSegmentsPortionsAction({
                    segmentsIds: Array.from(segmentsToUpdatePortions),
                  })
                );
              }

              // we select the pasted shapes, it is more convenient for the user to move them afterward
              store.dispatch(selectMultipleCircuitShapesAction(shapesToSelect));

              return pasteSelectionSuccessAction({
                shapesData: [],
              });
            }

            return pasteSelectionFailureAction(new Error('JSON data of the clipboard not created by Road Editor'));
          } catch (err) {
            // eslint-disable-next-line no-console
            console.warn('Error during a pasting action', err);

            return pasteSelectionFailureAction(new Error('Failed to parse the clipboard data, no json data'));
          }
        }

        return pasteSelectionFailureAction(new Error('Empty clipboard'));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        return pasteSelectionFailureAction(err as Error);
      }
    })
  );
}

export function handlePastingErrors$(
  actions$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<PasteSelectionSuccess | PasteSelectionFailure> {
  return actions$.pipe(
    ofType<PasteSelectionFailure>(ClipboardTypes.PasteSelectionFailure),
    mergeMap((action) => {
      const payload = action.payload;
      const error = payload.error;

      // eslint-disable-next-line no-console
      console.log(`Pasting error: ${error.message} (${error.name})`);

      if (error.name === 'NotAllowedError') {
        SnackbarUtils.warning(
          `Road Editor didn't have permission to access the clipboard. Please, give the permission (button around your browser search bar)`
        );
      } else {
        SnackbarUtils.error(`An unknown error occurred while pasting: ${error.message}`);
      }

      return [];
    })
  );
}

export function combineCopyPasteEpics(): any {
  return combineEpics(copySelection$, pasteSelection$, handlePastingErrors$);
}

export function combineCopyPastePredefinedShapesEpics(): any {
  return combineEpics(copyPredefinedShapes$);
}

export function generateCopiedName(oldName: string): string {
  return CircuitService.generateDifferentName(oldName);
}

interface ClipboardMetaData {
  isRoadEditorData: boolean;
  nbShapes: number;
  nbTurns: number;
}

export async function getClipboardData(): Promise<ClipboardMetaData> {
  let clipboardTxt: string;
  try {
    clipboardTxt = await navigator.clipboard.readText();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);

    return { isRoadEditorData: false, nbShapes: NaN, nbTurns: NaN };
  }

  if (clipboardTxt) {
    const clipboardJson: FeatureCollectionWithProperties | null = (() => {
      try {
        return JSON.parse(clipboardTxt) as FeatureCollectionWithProperties;
      } catch (e) {
        return null;
      }
    })();

    if (clipboardJson && clipboardJson.properties && clipboardJson.properties.software === 'Balyo Road Editor') {
      let nbShapes = 0;
      let nbTurns = 0;

      clipboardJson.features.forEach((feature) => {
        if (feature && feature.properties && feature.properties.type) {
          nbShapes++;
          if (feature.properties.type === ShapeTypes.TurnShape) nbTurns++;
        }
      });

      return { isRoadEditorData: true, nbShapes, nbTurns };
    }
  }

  return { isRoadEditorData: false, nbShapes: NaN, nbTurns: NaN };
}
