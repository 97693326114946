import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import type { MenuProps } from '@mui/material';
import { Alert, alpha, Badge, Grid, InputAdornment, Menu, MenuItem, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import { TextFieldWithSubmitAndClear } from 'components/utils/textfields';
import { useConfirm } from 'material-ui-confirm';
import type { CellLoad } from 'models/circuit';
import React, { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { CellTemplate } from 'reducers/circuit/state';
import { generateUniqueLoadName, getEuroPallet } from 'utils/circuit/default-circuit-shapes';
import { computeWidthTakenByLoads, MINI_MARGIN_LOAD, nbLoadsMax, nbLoadsMin } from 'utils/circuit/racks';
import { epsilon, regexCellTemplateLoadName } from 'utils/circuit/utils';
import { theme } from 'utils/mui-theme';
import type { FocusInputs } from './rack-edition-celltemplate';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        // marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

interface CellTemplateLoadFormProps {
  /** load to edit */
  load: CellLoad;
  /** other loads of the cell template */
  loads: CellLoad[];
  /* function to call to delete the load */
  onDelete?: (loadIndex: number) => void;
  /** index of the load in the loads list */
  index: number;
  /** whether the load is selected */
  selected?: boolean;
  /** function to call to select the load */
  onSelect?: (loadIndex: number) => void;
  /** function to call to update the load properties */
  onUpdateLoad: (loadIndex: number, newLoad: CellLoad) => void;
  /** cell template that contains the load */
  cellTemplate: CellTemplate;
  /** which input is currently focused (in the preview), undefined if none are focused */
  focusedInputs?: FocusInputs;
  /** callback used when an input is focused */
  onChangeFocusedInputs?: (newState: FocusInputs) => void;
}

/**
 * This component defines parameters for just a load of a cell template and not the whole cell template
 * For example the pallet overflow
 * @param param0 all the props
 * @returns the jsx element
 */
export function CellTemplateLoadForm({
  load,
  loads,
  onDelete,
  index,
  selected,
  onSelect,
  onUpdateLoad,
  cellTemplate,
  focusedInputs,
  onChangeFocusedInputs,
}: CellTemplateLoadFormProps): JSX.Element {
  const [name, setName] = useState(load.name);
  const [N, setN] = useState(load.N.toFixed(0));
  const [a1, setA1] = useState((load.a1 * 1000).toFixed(0));
  const [W, setW] = useState((load.W * 1000).toFixed(0));

  const [errorName, setErrorName] = useState(false);

  const widthTakenByLoads = computeWidthTakenByLoads(load, true);

  const errorDimCellWidth = widthTakenByLoads - cellTemplate.width > epsilon;
  const leftObstacleTooLarge = cellTemplate.leftObstacle > load.a1;
  const rightObstacleTooLarge = cellTemplate.rightObstacle > (load.a2 || load.a1);

  const leftMarginTooSmall = !load.center && load.a1 < MINI_MARGIN_LOAD;
  const rightMarginTooSmall = !load.center && load.a2 !== undefined && load.a2 < MINI_MARGIN_LOAD;

  const confirm = useConfirm();

  /** update the values when the props are updated */
  useEffect(() => {
    setName(load.name);
  }, [load.name]);
  useEffect(() => {
    setN(load.N.toFixed(0));
  }, [load.N]);
  useEffect(() => {
    setA1((load.a1 * 1000).toFixed(0));
  }, [load.a1]);
  useEffect(() => {
    setW((load.W * 1000).toFixed(0));
  }, [load.W]);

  const resetLoad = useCallback(() => {
    setName(load.name);
    setN(load.N.toFixed(0));
    setA1((load.a1 * 1000).toFixed(0));
    setW((load.W * 1000).toFixed(0));
  }, [load.N, load.W, load.a1, load.name]);
  const updateLoad = useCallback(
    (center?: boolean) => {
      if (!onUpdateLoad) return;

      const newLoad: CellLoad = {
        ...load,
        name: name || load.name,
        N: parseInt(N, 10) || load.N,
        a1: parseFloat(a1) / 1000,
        W: parseFloat(W) / 1000 || load.W,
        center: (center && load.N === 1) || undefined,
      };

      if (newLoad.N !== load.N) {
        const newReferences = [...load.references];
        const dN = Math.abs(newLoad.N - load.N);

        if (newLoad.N > load.N) {
          for (let i = 0; i < dN; i++) {
            newReferences.splice(load.N - 1, 0, 'none');
          }
        } else {
          newReferences.splice(load.N - 1 - dN, dN);
        }

        newLoad.references = newReferences;
      }

      if (!name || !name.match(regexCellTemplateLoadName)) {
        setName(load.name);
      }

      onUpdateLoad(index, newLoad);
    },
    [N, W, a1, index, load, name, onUpdateLoad]
  );
  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        updateLoad();
      } else if (e.key === 'Escape') {
        e.stopPropagation();
        resetLoad();
        setTimeout(() => (e.target as HTMLInputElement).blur(), 0);
      }
    },
    [resetLoad, updateLoad]
  );

  const handleCenterLoad = useCallback(() => {
    updateLoad(!load.center);
  }, [load.center, updateLoad]);

  const checkDuplicateLoadNames = useCallback(() => {
    // checking the unicity of the load name in this cell template only
    const names = new Set<string>();

    loads.forEach((load, i) => {
      if (i !== index) names.add(load.name.toUpperCase());
    });

    return !names.has(name.toUpperCase());
  }, [index, loads, name]);

  useEffect(() => {
    startTransition(() => {
      setErrorName(!checkDuplicateLoadNames());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellTemplate, loads, load]);

  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickMore = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setMoreMenuAnchorEl(e.currentTarget);
  }, []);
  const handleCloseMoreMenu = useCallback(() => {
    setMoreMenuAnchorEl(null);
  }, []);

  const handleDeleteLoad = useCallback(() => {
    if (!onDelete) return;

    confirm({
      title: 'Load deletion confirmation',
      description: 'Are you sure you want to delete this load?',
      allowClose: false,
    })
      .then(() => {
        onDelete(index);

        handleCloseMoreMenu();
      })
      .catch(() => undefined);
  }, [confirm, handleCloseMoreMenu, index, onDelete]);

  const palletOverflow = (load.palletOverflow ?? cellTemplate.palletOverflow) * 1000; // mm
  const loadLength = (load.length ?? cellTemplate.loadLength ?? getEuroPallet().palletLength ?? 1.2) * 1000; // mm

  const refInputPalletOverflow = useRef<HTMLInputElement>(null);
  const refInputLoadLength = useRef<HTMLInputElement>(null);

  const resetPalletOverflowInputValue = useCallback(() => {
    if (!refInputPalletOverflow.current) return;

    refInputPalletOverflow.current.value = palletOverflow.toString();
  }, [palletOverflow]);

  const resetLoadLengthInputValue = useCallback(() => {
    if (!refInputLoadLength.current) return;

    refInputLoadLength.current.value = loadLength.toString();
  }, [loadLength]);

  const handleChangePalletOverflow = useCallback(() => {
    if (!refInputPalletOverflow.current) return;

    const newPalletOverflow = parseInt(refInputPalletOverflow.current.value, 10);
    if (isNaN(newPalletOverflow)) {
      resetPalletOverflowInputValue();

      return;
    }

    if (onUpdateLoad) {
      onUpdateLoad(index, {
        ...load,
        palletOverflow: newPalletOverflow / 1000, // m
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('onUpdateLoad is undefined');
    }
  }, [index, load, onUpdateLoad, resetPalletOverflowInputValue]);

  const handleChangeLoadLength = useCallback(() => {
    if (!refInputLoadLength.current) {
      // eslint-disable-next-line no-console
      console.error('refInputLoadLength.current is undefined');

      return;
    }

    const newLoadLength = parseInt(refInputLoadLength.current.value, 10);
    if (isNaN(newLoadLength)) {
      resetLoadLengthInputValue();

      return;
    }

    if (onUpdateLoad) {
      onUpdateLoad(index, {
        ...load,
        length: newLoadLength / 1000, // m
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('onUpdateLoad is undefined');
    }
  }, [index, load, onUpdateLoad, resetLoadLengthInputValue]);

  const handleChangeLinkPalletOverflow = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      if (!onUpdateLoad) return;

      onUpdateLoad(index, {
        ...load,
        palletOverflow: load.palletOverflow === undefined ? palletOverflow / 1000 : undefined,
      });

      // we update the associated input
      if (refInputPalletOverflow.current)
        refInputPalletOverflow.current.value = (
          load.palletOverflow === undefined ? palletOverflow : cellTemplate.palletOverflow * 1000
        ).toString();
    },
    [onUpdateLoad, index, load, palletOverflow, cellTemplate.palletOverflow]
  );

  const handleChangeLinkLoadLength = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      if (!onUpdateLoad) return;

      onUpdateLoad(index, {
        ...load,
        length: load.length === undefined ? loadLength / 1000 : undefined,
      });

      // we update the associated input
      if (refInputLoadLength.current)
        refInputLoadLength.current.value = (
          load.length === undefined
            ? loadLength
            : (cellTemplate.loadLength ?? getEuroPallet().palletLength ?? 1.2) * 1000
        ).toString();
    },
    [onUpdateLoad, index, load, loadLength, cellTemplate.loadLength]
  );

  const handleDuplicateLoad = useCallback(() => {
    if (!onUpdateLoad) return;

    const newLoad = {
      ...load,
      name: generateUniqueLoadName(loads.map((l) => l.name)),
    };

    onUpdateLoad(loads.length, newLoad);

    handleCloseMoreMenu();
  }, [handleCloseMoreMenu, load, loads, onUpdateLoad]);

  const shouldDisplayBadgeMoreMenu = useMemo(() => {
    return load.palletOverflow !== undefined || load.length !== undefined;
  }, [load.length, load.palletOverflow]);

  return (
    <>
      <Grid item xs={1} sx={{ textAlign: 'center', paddingTop: 0 }}>
        <Tooltip title="Select this load" placement="right" disableInteractive={true} leaveDelay={10}>
          {/* <IconButton
            aria-label="select"
            onClick={() => onSelect && onSelect(index)}
            color="primary"
            size="small"
            sx={{ padding: 0 }}
          > */}
          {/* {selected ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />} */}
          <Radio
            checked={selected}
            sx={{
              padding: 0,
              margin: 0,
              '& .MuiSvgIcon-root': {
                fontSize: 24,
              },
            }}
            onChange={() => onSelect && onSelect(index)}
          />
          {/* </IconButton> */}
        </Tooltip>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: 'center', paddingTop: 0 }}>
        <TextField
          variant="outlined"
          margin="none"
          size="small"
          title="Name of the load"
          sx={{
            '& input': {
              paddingTop: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
            },
          }}
          inputProps={{
            maxLength: 4,
          }}
          value={name}
          onChange={(e) => {
            const newVal = e.target.value;

            if (newVal.match(regexCellTemplateLoadName) || newVal === '') setName(newVal);

            // checkNameValidityDebounced();
            startTransition(() => {
              setErrorName(!checkDuplicateLoadNames());
            });
          }}
          onBlur={() => updateLoad()}
          onKeyDown={onKeyPress}
          error={errorName}
          // color={errorName ? 'secondary' : 'primary'}
        />
      </Grid>
      <Grid item xs={2} sx={{ paddingTop: 0 }}>
        <TextField
          type="number"
          variant="outlined"
          margin="none"
          size="small"
          title="Number of loads in the cell"
          sx={{
            '& input': {
              paddingTop: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
            },
          }}
          inputProps={{
            step: 1,
            min: nbLoadsMin,
            max: nbLoadsMax,
          }}
          value={N}
          onChange={(e) => {
            setN(e.target.value);
          }}
          onBlur={() => updateLoad()}
          onKeyDown={onKeyPress}
        />
      </Grid>
      <Grid item xs={3} sx={{ paddingTop: 0 }}>
        <TextField
          type="number"
          variant="outlined"
          margin="none"
          size="small"
          title="Minimum distance between a load and an upright [mm]"
          sx={{
            '& input': {
              paddingTop: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
            },
          }}
          inputProps={{
            step: 10,
            min: 0,
          }}
          disabled={load.center}
          InputProps={{
            endAdornment:
              load.N === 1 ? (
                <InputAdornment position="end">
                  <Tooltip title="Center the load" arrow disableInteractive>
                    <IconButton
                      color={load.center ? 'primary' : undefined}
                      sx={{ transform: 'rotate(90deg)', padding: 0 }}
                      size="small"
                      onClick={handleCenterLoad}
                    >
                      <VerticalAlignCenterIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ) : undefined,
          }}
          value={a1}
          onChange={(e) => setA1(e.target.value)}
          onBlur={() => {
            if (selected && onChangeFocusedInputs) onChangeFocusedInputs(undefined);

            updateLoad();
          }}
          onKeyDown={onKeyPress}
          onFocus={() => {
            selected && onChangeFocusedInputs && onChangeFocusedInputs('a1');
          }}
          focused={selected && focusedInputs && focusedInputs === 'a1'}
        />
      </Grid>
      <Grid item xs={3} sx={{ paddingTop: 0 }}>
        <TextField
          type="number"
          variant="outlined"
          margin="none"
          size="small"
          title="Width of a load [mm]"
          sx={{
            '& input': {
              paddingTop: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
            },
          }}
          inputProps={{
            step: 10,
            min: 1,
          }}
          value={W}
          onChange={(e) => setW(e.target.value)}
          onBlur={() => {
            if (selected && onChangeFocusedInputs) onChangeFocusedInputs(undefined);

            updateLoad();
          }}
          onKeyDown={onKeyPress}
          onFocus={() => {
            selected && onChangeFocusedInputs && onChangeFocusedInputs('loadWidth');
          }}
          focused={selected && focusedInputs && focusedInputs === 'loadWidth'}
        />
      </Grid>
      <Grid item xs={1} sx={{ paddingTop: 0, marginLeft: theme.spacing(-1) }}>
        <IconButton onClick={handleClickMore}>
          <Badge
            color="primary"
            variant="dot"
            sx={{ '& .MuiBadge-badge': { transform: `scale(${shouldDisplayBadgeMoreMenu ? '0.7' : '0'})` } }}
          >
            <MoreVertIcon />
          </Badge>
        </IconButton>
        <StyledMenu anchorEl={moreMenuAnchorEl} open={!!moreMenuAnchorEl} onClose={handleCloseMoreMenu}>
          <MenuItem onClick={handleDeleteLoad} disabled={!onDelete}>
            <DeleteIcon sx={{ marginRight: theme.spacing(2) }} /> Delete
          </MenuItem>
          <MenuItem onClick={handleDuplicateLoad}>
            <FileCopyIcon sx={{ marginRight: theme.spacing(2) }} /> Duplicate
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Grid xs={6} sx={{ paddingTop: 0, marginRight: theme.spacing(2) }}>
              <TextFieldWithSubmitAndClear
                type="number"
                defaultValue={palletOverflow}
                size="small"
                label="Pallet Overflow"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleChangeLinkPalletOverflow}>
                        {load.palletOverflow === undefined ? <LinkIcon /> : <LinkOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
                inputProps={{
                  min: 0,
                  step: 10,
                }}
                inputRef={refInputPalletOverflow}
                onClear={resetPalletOverflowInputValue}
                onValidate={handleChangePalletOverflow}
                sx={{
                  marginTop: theme.spacing(0.5),
                  marginBottom: theme.spacing(0.5),
                }}
                disabled={load.palletOverflow === undefined}
              />
            </Grid>
            <Grid xs={6} sx={{ paddingTop: 0 }}>
              <TextFieldWithSubmitAndClear
                type="number"
                defaultValue={loadLength}
                size="small"
                label="Pallet Length"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleChangeLinkLoadLength}>
                        {load.length === undefined ? <LinkIcon /> : <LinkOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),

                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
                inputProps={{
                  min: 0,
                  step: 0.1,
                }}
                inputRef={refInputLoadLength}
                onBlur={handleChangeLoadLength}
                onClear={resetLoadLengthInputValue}
                onValidate={handleChangeLoadLength}
                sx={{
                  marginTop: theme.spacing(0.5),
                  marginBottom: theme.spacing(0.5),
                }}
                disabled={load.length === undefined}
              />
            </Grid>
          </MenuItem>
        </StyledMenu>
      </Grid>
      {errorDimCellWidth && (
        <Grid item xs={12} sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
          <Alert severity="warning">
            The width of the loads ({(widthTakenByLoads * 1000).toFixed(2)} mm) is greater than the available width (
            {(cellTemplate.width * 1000).toFixed(2)} mm).
          </Alert>
        </Grid>
      )}
      {(leftObstacleTooLarge || rightObstacleTooLarge) && (
        <Grid item xs={12} sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
          <Alert severity="warning">
            The distance to the upright is too small compared to the obstacle
            {leftObstacleTooLarge && rightObstacleTooLarge && 's (left and right)'}
            {leftObstacleTooLarge && !rightObstacleTooLarge && ' (left)'}
            {!leftObstacleTooLarge && !rightObstacleTooLarge && ' (right)'}.
          </Alert>
        </Grid>
      )}
      {(leftMarginTooSmall || rightMarginTooSmall) && (
        <Grid item xs={12} sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
          <Alert severity="warning">
            {leftMarginTooSmall &&
              rightMarginTooSmall &&
              `The distances between the loads and the uprights are too small, both on the left and right sides (a1 = ${(
                load.a1 * 1000
              ).toFixed(0)} mm, a2 = ${((load.a2 ?? 0) * 1000).toFixed(0)} mm, minimum = ${(
                MINI_MARGIN_LOAD * 1000
              ).toFixed(0)} mm).`}
            {leftMarginTooSmall &&
              !rightMarginTooSmall &&
              `The distance between the load and the left upright is too small (a1 = ${(load.a1 * 1000).toFixed(
                0
              )} mm, minimum = ${(MINI_MARGIN_LOAD * 1000).toFixed(0)} mm).`}
            {!leftMarginTooSmall &&
              rightMarginTooSmall &&
              `The distance between the load and the right upright is too small (a2 = ${((load.a2 ?? 0) * 1000).toFixed(
                0
              )} mm, minimum = ${(MINI_MARGIN_LOAD * 1000).toFixed(0)} mm).`}
          </Alert>
        </Grid>
      )}
    </>
  );
}
