import createConfig from 'react-runtime-config';
import { userPermissions } from 'shared';
import packageJson from '../../package.json';
import { defaultMaxOvershoot, defaultTurnRadius } from './circuit/default-values';

const {
  VITE_AUTH0_AUDIENCE,
  VITE_AUTH0_CLIENT_ID,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_REDIRECT_URI,
  VITE_AUTH0_RESPONSE_TYPE,
  VITE_AUTH0_SCOPE,
  VITE_JWT_DOMAIN,
  VITE_ONLINE,
} = import.meta.env;

export interface AppConfig {
  auth0Audience: string;
  auth0ClientId: string;
  auth0Domain: string;
  auth0RedirectUri: string;
  auth0ResponseType: string;
  auth0Scope: string;
  jwtDomain: string;

  online: string;
  version: string;
  roadEditorVersion: string;
  editor: {
    minimumShapeLength: number;
    grid: {
      tickLabelsSpacingFromEdges: string;
    };
    zoom: {
      min: string;
      max: string;
      translateArrowKeys: number;
      scaleByButton: number;
    };
    elementWidth: {
      interestPoint: string;
      handle: string;
      orientationStroke: string;
      itineraryStroke: string;
      itineraryPoint: string;
      segmentStroke: string;
      turnStroke: string;
      zoneStroke: string;
      measurerStroke: number;
      zoomScale: {
        points: {
          min: number;
          max: number;
        };
      };
    };
    properties: {
      maxHeight: string;
    };
    copyPaste: {
      offset: {
        x: number;
        y: number;
      };
      separatorNewName: string;
    };
  };

  defaultValues: {
    turnRadius: number;
    maxOvershoot: number;
  };
}

interface SettingsConfig {
  autoSaveCircuit: boolean;
  autoSaveCircuitInterval: number;
  showDynamicShortcuts: boolean;
}

export const { getConfig } = createConfig<AppConfig>({
  namespace: 'BALYO_CONFIG',
  defaultConfig: {
    auth0Audience: VITE_AUTH0_AUDIENCE,
    auth0ClientId: VITE_AUTH0_CLIENT_ID,
    auth0Domain: VITE_AUTH0_DOMAIN,
    auth0RedirectUri: VITE_AUTH0_REDIRECT_URI,
    auth0ResponseType: VITE_AUTH0_RESPONSE_TYPE,
    auth0Scope: `${VITE_AUTH0_SCOPE} ${userPermissions.join(' ')}`,
    jwtDomain: VITE_JWT_DOMAIN,
    online: VITE_ONLINE,
    version: import.meta.env.VITE_VERSION || packageJson.version,
    roadEditorVersion: import.meta.env.VITE_VERSION || packageJson.version,
    editor: {
      minimumShapeLength: 10,
      grid: {
        tickLabelsSpacingFromEdges: '10',
      },
      zoom: {
        min: '0.005',
        max: '2500',
        translateArrowKeys: 50, // cm, so half a meter when the zoom scale is at 1
        scaleByButton: 1.5,
      },
      elementWidth: {
        interestPoint: '6',
        handle: '5',
        orientationStroke: '1',
        itineraryStroke: '2',
        itineraryPoint: '3',
        segmentStroke: '2',
        turnStroke: '2',
        zoneStroke: '2',
        measurerStroke: 2,
        zoomScale: {
          points: {
            min: 1.0,
            max: 3.5,
          },
        },
      },
      properties: {
        maxHeight: '95%',
      },
      copyPaste: {
        offset: {
          x: 10,
          y: 10,
        },
        separatorNewName: '_',
      },
    },

    defaultValues: {
      turnRadius: defaultTurnRadius,
      maxOvershoot: defaultMaxOvershoot,
    },
  },
});

const settingsConfig = createConfig<SettingsConfig>({
  namespace: 'settings',
  defaultConfig: {
    autoSaveCircuit: true,
    autoSaveCircuitInterval: 5,
    showDynamicShortcuts: true,
  },
});

export const getSettings = settingsConfig.getConfig;
export const setSettings = settingsConfig.setConfig;

/**
 * The link to the user guide
 */
export const TRAINING_URL =
  'https://docs.google.com/presentation/d/1heOC32MU60WjLMhNkVzj-wwi3dVjpnj13R5FWgRjP0c/edit#slide=id.p1';

/**
 * The link to the circuit library guide
 */
export const CIRCUIT_LIBRARY_URL =
  'https://docs.google.com/presentation/d/e/2PACX-1vQi3Fo3J3S9pAtJyPI7kkbouXqiAbylMTX_bEmtGliZgB0Z1d-NKqbng-dK9Lsk9b53uSD8I88OC-Z5/pub?start=false&loop=false&delayms=3000&slide=id.p1';

/**
 * The link to the pallet detection functionality training in Balyo Doc
 */
export const PALLET_DETECTION_SCAN_TRAINING =
  'https://operations.docs.balyo.io/docs/commonDocs/Commissioning/Pallet%20Detection/2D%20Scanning/09.15%20-%20Pallet%20Detection%20Functionality';

/**
 * The link to map editor
 */
export const MAP_EDITOR_URL = 'https://map-editor.balyo.io/';

/**
 * The devices (comboxes) are exported in the install.xml file only if the NEW4X version detected is >= to this version
 *
 * https://redmine.balyo.com/issues/35039
 * */
export const MIN_VERSION_NEW_EXPORT_DEVICES = 25;
/**
 * Former version of new do not read the roof height data from the TRACK library
 *
 * https://redmine.balyo.com/issues/39705
 * https://redmine.balyo.com/issues/34939
 * https://redmine.balyo.com/issues/45118
 */
export const MIN_VERSION_NEW_ROOF_HEIGHT = 30;

/**
 * Length of the arrows at the extremities of a segment, in px
 */
export const SEGMENT_ARROW_LENGTH = 10;
/**
 * Thickness of the arrows at the extremities of a segment, in px
 */
export const SEGMENT_ARROW_THICKNESS = 5;

/**
 * Default frequency parameters for the devices (ms)
 */
export const frequencyDefaultValue = '100';

/**
 * Default max roof height value [m]
 */
export const maxRoofHeightDefaultValue = 50;
/**
 * Minimum value for the max roof height [m]
 */
export const maxRoofHeightMinValue = 0;

/**
 * Default roof clearance value [m]
 */
export const roofClearanceDefaultValue = 0.2;
/**
 * Minimum value for the roof clearance [m]
 */
export const roofClearanceMinValue = 0;
