import { localDoc, remoteDoc } from 'multiplayer/globals';
import { applyUpdate, encodeStateAsUpdate, encodeStateVector } from 'yjs';

export function syncYJSLocalToRemote(): void {
  if (!remoteDoc) return;

  const remoteStateVector = encodeStateVector(remoteDoc);
  const update = encodeStateAsUpdate(localDoc, remoteStateVector);
  applyUpdate(remoteDoc, update, 'local');

  //! Not available for now using our provider y-websocket
  //! https://discuss.yjs.dev/t/update-ydoc-connected-to-y-websocket-using-applyupdatev2/2542
  // const remoteStateVector = encodeStateVector(remoteDoc);
  // const update = encodeStateAsUpdateV2(localDoc, remoteStateVector);

  // applyUpdateV2(remoteDoc, update, 'local');
}
