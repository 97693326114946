import { useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import DisplayExtendedLength from './filter-extended-length';

export function CircuitComputedStyles(): JSX.Element {
  return (
    <>
      <SelectShapeHelperStyle />
      <HighlightTurnsStyle />
      <DisplayBBoxSelection />
      <DisplayPointNames />
      <DisplayExtendedLength />
      <DisplayStockLineNames />
    </>
  );
}

function SelectShapeHelperStyle(): JSX.Element {
  const selectStrokeWidth = useAppSelector((state) => state.tool.selectStrokeWidth);

  return (
    <style id="select-shape-helper-style">
      {`
    .select-shape-helper {
      stroke-width: ${selectStrokeWidth > 0 ? selectStrokeWidth : 0}px;
      stroke: transparent;
      fill: transparent;
      cursor: pointer;
    }
    `}
    </style>
  );
}

function HighlightTurnsStyle(): JSX.Element {
  const highlightTurns = useAppSelector((state) => state.tool.highlightTurns);

  return (
    <style id="highlight-turn-style">
      {highlightTurns
        ? `
    g[type=TURN] path {
      filter:
       invert(1);
    }
    `
        : ''}
    </style>
  );
}

function DisplayPointNames(): JSX.Element {
  const displayPointNames = useAppSelector((state) => state.local.filters.displayPointNames);

  return (
    <style id="display-point-name">
      {`
    .interest-point-name {
      display: ${displayPointNames ? 'block' : 'none'};
      pointer-events: none;
    }`}
    </style>
  );
}

function DisplayStockLineNames(): JSX.Element {
  const displayStockLineNames = useAppSelector((state) => state.local.filters.displayStockLineNames);

  return (
    <style id="display-stockline-names">
      {`
    text.stock-line-name{
      display: ${displayStockLineNames ? 'block' : 'none'};
    }
    rect.stock-line-name-background{
      display: ${displayStockLineNames ? 'block' : 'none'};
    }`}
      ,
    </style>
  );
}

function DisplayBBoxSelection(): JSX.Element {
  const displayBBoxSelection = useAppSelector((state) => state.tool.displayBBoxSelection);

  return (
    <style id="display-bbox-selection">
      {displayBBoxSelection
        ? `
          .active [main-shape], .dragging [main-shape] {
            outline: 1px solid ${theme.palette.primary.light};
            outline-offset: 10px;
          }
          .active [main-shape-outline], .dragging [main-shape-outline] {
            outline: 1px solid ${theme.palette.primary.light};
            outline-offset: 5px;
          }
           g.active[type=ZONE] [main-shape], g.dragging[type=ZONE] [main-shape], g.active[type=RACK] [main-shape], g.dragging[type=RACK] [main-shape], g.active[type=STOCK] [main-shape], g.dragging[type=STOCK] [main-shape], g.active[type=NOTE] [main-shape], g.dragging[type=NOTE] [main-shape], g.active[type=DEVICE] [main-shape], g.dragging[type=DEVICE] [main-shape]
            {
              ${
                /* we disable the outline for the zones, racks, and stock zones because they already have their handles. */ ''
              }
              outline: unset;
            }
    `
        : ''}
    </style>
  );
}
