import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import type { Dictionary } from '@reduxjs/toolkit';
import { alignElementAction, saveCircuitToHistoryAction } from 'actions/circuit';
import type { AlignAction } from 'drawings/elements/align';
import { findShapeOrientation } from 'drawings/helpers';
import type { CircuitShape } from 'models/circuit';
import { useCallback, useRef, useState } from 'react';
import type { LoadedSegment } from 'reducers/circuit/state';
import type { SelectedShapesData } from 'reducers/local/state';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch } from 'store';
import { isCircuitPoint, isCircuitRack, isCircuitStockZone } from 'utils/circuit/shape-guards';
import { isDefined } from 'utils/ts/is-defined';

const useStyles = makeStyles((theme) =>
  createStyles({
    listItemIconOverride: {
      minWidth: '28px',
    },
  })
);

type ActionDirection = 'Left' | 'Right' | 'Center' | 'Top' | 'Bottom' | 'vertically' | 'horizontally';

interface AlignElementsProps {
  selectedShapes: SelectedShapesData;
  handleClose: VoidFunction;
}

export function AlignElements({ selectedShapes, handleClose }: AlignElementsProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const alignHorizontallyListItemRef = useRef<HTMLLIElement | null>(null);
  const [openMenuAlignHorizontally, setOpenMenuAlignHorizontally] = useState(false);

  const handleCloseMenuAlignHorizontally = useCallback(() => {
    setOpenMenuAlignHorizontally(false);
  }, []);

  const handleClickAlignHorizontally = useCallback(() => {
    setOpenMenuAlignHorizontally(true);
  }, []);

  const alignVerticallyListItemRef = useRef<HTMLLIElement | null>(null);
  const [openMenuAlignVertically, setOpenMenuAlignVertically] = useState(false);

  const handleCloseMenuAlignVertically = useCallback(() => {
    setOpenMenuAlignVertically(false);
  }, []);

  const handleClickAlignVertically = useCallback(() => {
    setOpenMenuAlignVertically(true);
  }, []);

  const segments = store.getState().circuit.present.segments.entities;

  const handleAlignElement = useCallback(
    (alignAction: AlignAction) => {
      if (
        alignAction === 'horizontallyLeft' ||
        alignAction === 'horizontallyCenter' ||
        alignAction === 'horizontallyRight'
      ) {
        handleCloseMenuAlignHorizontally();
      } else {
        handleCloseMenuAlignVertically();
      }

      handleClose();

      const shapes = selectedShapes.map((shape) => {
        return CircuitService.getShape(shape.id, shape.type);
      });

      const lockedShape = shapes
        .filter((shape) => {
          if (isCircuitRack(shape) || isCircuitStockZone(shape)) {
            if (shape.properties.locked) {
              return shape;
            }
          }

          if (isCircuitPoint(shape)) {
            if (shape.properties.locked) {
              return shape;
            }

            const snappedSegment = shape.properties.segment?.id;

            if (snappedSegment && segments[snappedSegment].properties.locked) {
              return shape;
            }
          }

          return undefined;
        })
        .filter(isDefined);

      function getPointsOnSegmentAtAngle(
        shapes: (CircuitShape | undefined)[],
        segments: Dictionary<LoadedSegment>,
        angleCheckFn: (angle: number) => boolean
      ): CircuitShape[] {
        return shapes
          .filter((shape) => {
            if (isCircuitPoint(shape)) {
              const snappedSegment = shape.properties.segment?.id;

              if (!snappedSegment) {
                return undefined;
              }

              const segment = segments[snappedSegment];

              if (!segment) {
                return undefined;
              }

              const segmentCoords = segment.geometry.coordinates;
              const computedAngle = findShapeOrientation(
                [segmentCoords[0][0], segmentCoords[0][1]],
                [segmentCoords[1][0], segmentCoords[1][1]]
              );

              if (angleCheckFn(computedAngle) && !segment.properties.locked) {
                return shape;
              }
            }

            return undefined;
          })
          .filter(isDefined);
      }

      const is90DegreeSegment = (angle: number): boolean => angle % 90 === 0 && angle % 180 !== 0;
      const is180DegreeSegment = (angle: number): boolean => angle % 180 === 0;

      const pointsOnSegmentAt90Deg = getPointsOnSegmentAtAngle(shapes, segments, is90DegreeSegment);
      const pointsOnSegmentAt180Deg = getPointsOnSegmentAtAngle(shapes, segments, is180DegreeSegment);

      dispatch(saveCircuitToHistoryAction());

      dispatch(
        alignElementAction({
          alignAction,
          selectedShapes: shapes,
        })
      );

      if (lockedShape.length > 0) {
        SnackbarUtils.info(
          `${lockedShape.length} shapes have not moved because they are locked (or the associated segment is locked)`
        );
      }

      if (
        (alignAction === 'horizontallyLeft' ||
          alignAction === 'horizontallyCenter' ||
          alignAction === 'horizontallyRight') &&
        pointsOnSegmentAt90Deg.length > 0
      ) {
        SnackbarUtils.info(
          `${pointsOnSegmentAt90Deg.length} points have not moved because the action would have unsnapped them from their segment`
        );
      }

      if (
        (alignAction === 'verticallyTop' || alignAction === 'verticallyCenter' || alignAction === 'verticallyBottom') &&
        pointsOnSegmentAt180Deg.length > 0
      ) {
        SnackbarUtils.info(
          `${pointsOnSegmentAt180Deg.length} points have not moved because the action would have unsnapped them from their segment`
        );
      }
    },
    [dispatch, handleClose, handleCloseMenuAlignHorizontally, handleCloseMenuAlignVertically, segments, selectedShapes]
  );

  const alignHorizontally = ['Left', 'Center', 'Right'];
  const alignVertically = ['Top', 'Center', 'Bottom'];

  return (
    <>
      <Box component="div" onMouseLeave={handleCloseMenuAlignHorizontally}>
        <MenuItem onMouseEnter={handleClickAlignHorizontally} dense={true} ref={alignHorizontallyListItemRef}>
          <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
            <AlignHorizontalLeftIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<>Align horizontally</>}></ListItemText>
          <ArrowRightIcon />
        </MenuItem>
        <Menu
          anchorEl={alignHorizontallyListItemRef.current}
          open={openMenuAlignHorizontally}
          onClose={handleCloseMenuAlignHorizontally}
          onMouseLeave={handleCloseMenuAlignHorizontally}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            maxHeight: '50vh',
          }}
          hideBackdrop
        >
          {alignHorizontally.map((action, i) => {
            return (
              <ListItemAlignElement
                direction={'horizontally'}
                action={action as ActionDirection}
                handleAlignElement={handleAlignElement}
                key={i}
              />
            );
          })}
        </Menu>
      </Box>

      <Box component="div" onMouseLeave={handleCloseMenuAlignVertically}>
        <MenuItem onMouseEnter={handleClickAlignVertically} dense={true} ref={alignVerticallyListItemRef}>
          <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
            <AlignVerticalTopIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<>Align vertically</>}></ListItemText>
          <ArrowRightIcon />
        </MenuItem>

        <Menu
          anchorEl={alignVerticallyListItemRef.current}
          open={openMenuAlignVertically}
          onClose={handleCloseMenuAlignVertically}
          onMouseLeave={handleCloseMenuAlignVertically}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            maxHeight: '50vh',
          }}
          hideBackdrop
        >
          {alignVertically.map((action, i) => {
            return (
              <ListItemAlignElement
                direction={'vertically'}
                action={action as ActionDirection}
                handleAlignElement={handleAlignElement}
                key={i}
              />
            );
          })}
        </Menu>
      </Box>
    </>
  );
}

interface ListItemAlignElementProps {
  direction: ActionDirection;
  action: ActionDirection;
  handleAlignElement: (alignAction: AlignAction) => void;
}

export function ListItemAlignElement({
  direction,
  action,
  handleAlignElement,
}: ListItemAlignElementProps): JSX.Element {
  const classes = useStyles();

  return (
    <MenuItem
      onClick={() => {
        handleAlignElement(`${direction}${action}` as AlignAction);
      }}
      dense={true}
    >
      <ListItemIcon classes={{ root: classes.listItemIconOverride }}>
        {action === 'Top' ? (
          <AlignVerticalTopIcon fontSize="small" />
        ) : action === 'Center' && direction === 'vertically' ? (
          <AlignVerticalCenterIcon fontSize="small" />
        ) : action === 'Bottom' ? (
          <AlignVerticalBottomIcon fontSize="small" />
        ) : action === 'Left' ? (
          <AlignHorizontalLeftIcon fontSize="small" />
        ) : action === 'Right' ? (
          <AlignHorizontalRightIcon fontSize="small" />
        ) : (
          <AlignHorizontalCenterIcon fontSize="small" />
        )}
      </ListItemIcon>
      {action}
    </MenuItem>
  );
}
