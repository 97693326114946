/**
 * Clears the entire content of the navigator.storage.
 * This function removes all files and directories from the root directory.
 */
export async function clearNavigatorStorage(): Promise<void> {
  // eslint-disable-next-line no-console
  console.log('Clearing navigator.storage');
  try {
    const rootDir = await navigator.storage.getDirectory();

    for await (const entry of rootDir.values()) {
      if (entry.kind === 'file') {
        await rootDir.removeEntry(entry.name);
      } else if (entry.kind === 'directory') {
        await rootDir.removeEntry(entry.name, { recursive: true });
      }
    }

    // eslint-disable-next-line no-console
    console.log('Cleared navigator.storage');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error clearing navigator.storage:', error);
  }
}
