import { Shuffle, ShuffleOn } from '@mui/icons-material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import type { Theme } from '@mui/material';
import { Button, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import type { SxProps } from '@mui/system';
import { Box } from '@mui/system';
import type { Station } from 'flows/flows';
import {
  removePositionFromStation,
  setPositionInLine,
  stationPositionTypesIcons,
  togglePositionInLineRandom,
} from 'flows/flows';
import { positionIdToPositionName } from 'flows/position-id-to-position-name';
import { capitalize } from 'lodash';
import { ShapeTypes, SlotTypes } from 'models/circuit';
import { useCallback, useRef } from 'react';
import { ViewportList } from 'react-viewport-list';
import { useAppDispatch } from 'store';
import { theme } from 'utils/mui-theme';

interface ListPositionOfStationProps {
  station: Station;
  sx?: SxProps<Theme>;
  displayRemovePositionBtn?: boolean;
  displayStockLineShuffleMode?: boolean;
}
export function ListPositionOfStation(props: ListPositionOfStationProps): JSX.Element {
  const { station, displayRemovePositionBtn = true, displayStockLineShuffleMode = true } = props;
  const dispatch = useAppDispatch();

  const handleRemovePosition = useCallback(
    (positionId: string) => {
      dispatch(
        removePositionFromStation({
          positionId,
          stationId: station.id,
        })
      );
    },
    [dispatch, station]
  );

  const listRef = useRef<HTMLUListElement>(null);

  const handleChangeRandomPositionStation = useCallback(
    (positionId: string) => {
      dispatch(togglePositionInLineRandom({ positionId, stationId: station.id }));
    },
    [dispatch, station.id]
  );

  const handlePropragateRandomPositionStation = useCallback(
    (newPositionInLine: (typeof station.positions)[0]['positionInLine']) => {
      const positionsIds = station.positions
        .filter((position) => {
          return position.type === SlotTypes.StockLine || position.type === ShapeTypes.StockZoneShape;
        })
        .map((position) => position.id);

      dispatch(setPositionInLine({ stationId: station.id, positionsIds, positionInLine: newPositionInLine }));
    },
    [dispatch, station]
  );

  if (station.positions.length === 0) {
    return <Typography variant="caption">Missing position</Typography>;
  }

  return (
    <List
      dense={true}
      sx={{
        maxHeight: '50vh',
        overflowY: 'auto',
        ...props.sx,
      }}
      ref={listRef}
    >
      <ViewportList viewportRef={listRef} items={station.positions}>
        {(position) => {
          const Icon = stationPositionTypesIcons[position.type];
          const positionName = positionIdToPositionName(position.id, position.type);

          const isStockLineOrStockZone =
            position.type === SlotTypes.StockLine || position.type === ShapeTypes.StockZoneShape;
          const isShuffleEnabled = isStockLineOrStockZone && position.positionInLine === 'random';

          return (
            <ListItem
              key={position.id}
              secondaryAction={
                <>
                  {displayStockLineShuffleMode && isStockLineOrStockZone && (
                    <Tooltip
                      arrow
                      title={
                        <Box component="div">
                          Go to random position in line: {isShuffleEnabled ? 'enabled' : 'disabled'}
                          <br />
                          <Button
                            variant="text"
                            onClick={() =>
                              handlePropragateRandomPositionStation(isShuffleEnabled ? 'random' : undefined)
                            }
                            sx={{
                              textTransform: 'none',
                              color: 'inherit',
                            }}
                          >
                            {isShuffleEnabled ? 'Enable' : 'Disable'} for all
                          </Button>
                        </Box>
                      }
                    >
                      <IconButton
                        className="display-parent-hover"
                        edge="end"
                        aria-label="delete"
                        sx={{
                          opacity: '5%',
                          marginRight: theme.spacing(0.5),
                        }}
                        onClick={() => handleChangeRandomPositionStation(position.id)}
                      >
                        {isShuffleEnabled ? <ShuffleOn /> : <Shuffle />}
                      </IconButton>
                    </Tooltip>
                  )}
                  {displayRemovePositionBtn ? (
                    <Tooltip title={`Remove "${positionName}" from the station`} placement="left">
                      <IconButton
                        className="display-parent-hover"
                        edge="end"
                        aria-label="delete"
                        sx={{
                          opacity: '5%',
                        }}
                        onClick={() => handleRemovePosition(position.id)}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Tooltip>
                  ) : undefined}
                </>
              }
              sx={{
                '&:hover .display-parent-hover': {
                  opacity: '100%',
                },
              }}
            >
              <Tooltip title={capitalize(position.type)}>
                <ListItemIcon sx={{ display: 'block' }}>
                  <Icon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={positionName} />
            </ListItem>
          );
        }}
      </ViewportList>
    </List>
  );
}
