import { findVectorsAngle } from '../utils';
import { getUnitaryVector } from './vectors';

/**
 * Get the snapped coordinates of a line, snapped to the horizontal of the vertical
 * @param coords coordinates of the line to snap
 * @param pointToKeep point to keep unchanged, we will move the other one if needed
 * @return snapped coordinates
 */
export function snap90degLine(coords: GeoJSON.Position[], pointToKeep = 0): GeoJSON.Position[] {
  if (coords.length !== 2) return [];

  const horizontalVect = [1, 0]; // horizontal unitary vector
  const verticalVect = [0, 1]; // vertical unitary vector]

  const vect = getUnitaryVector({ start: coords[0], end: coords[1] });

  let horizontalAngle = Math.abs(findVectorsAngle(vect, horizontalVect) % 180);

  horizontalAngle =
    Math.abs(horizontalAngle - 180) < horizontalAngle ? Math.abs(horizontalAngle - 180) : horizontalAngle;
  if (horizontalAngle !== 0) {
    let verticalAngle = Math.abs(findVectorsAngle(vect, verticalVect) % 180);

    verticalAngle = Math.abs(verticalAngle - 180) < verticalAngle ? Math.abs(verticalAngle - 180) : verticalAngle;

    if (verticalAngle === 0) return coords;

    const res = [...coords];

    const pointToMove = pointToKeep === 0 ? 1 : 0;
    if (horizontalAngle < verticalAngle) {
      // we snap to the horizontal direction
      res[pointToMove][1] = res[pointToKeep][1];
    } else {
      // we snap to the vertical direction
      res[pointToMove][0] = res[pointToKeep][0];
    }

    return res;
  }

  return coords;
}
