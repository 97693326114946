import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Alert, Button, DialogContent, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useRef, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import { calculateCRCOfString } from 'utils/crc/crc-calculate';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import { CSVData } from './csv-data';
import { dataLineCsvSchemaSafeParseSuccess, type Csv } from './models';
import { rackAnalysisCSVToJson } from './utils';

interface StepImportProps {
  handleImportCsv: (importedCsv: Csv[]) => void;
  handleClose: () => void;
}

export function StepImport(props: StepImportProps): JSX.Element {
  const { handleImportCsv, handleClose } = props;

  const rackAnalysisProperty = `rackAnalysisCsv-${PreferencesService.getProjectName()}`;
  const rackAnalysisItem = sessionStorage.getItem(rackAnalysisProperty);
  const [importedCsv, setImportedCsv] = useState<Csv[]>(rackAnalysisItem ? JSON.parse(rackAnalysisItem) : []);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onClickHandler = useCallback((): void => {
    const el = inputRef.current;
    if (el) {
      el.value = '';
      el.click();
    }
  }, [inputRef]);

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { files } = e.target;

      if (files && files.length > 0) {
        Array.from(files).forEach((file) => {
          const fileReader = new FileReader();
          fileReader.onloadend = () => {
            const textContent = fileReader.result?.toString();

            if (!textContent) return;

            const json = rackAnalysisCSVToJson(textContent)
              .filter(dataLineCsvSchemaSafeParseSuccess)
              .filter(
                (v) =>
                  v['Move circuit away or further from truck'] !== 'Occupied' &&
                  v['Move circuit left or right'] !== 'Occupied'
              );

            const csvId = calculateCRCOfString(JSON.stringify(json));

            if (importedCsv.some((v) => v.id === csvId)) {
              // eslint-disable-next-line no-console
              console.warn('A CSV file has already been added and thereby ignored (duplicate content)');
              SnackbarUtils.warning('A CSV file has already been added and thereby ignored (duplicate content)');

              return;
            }

            setImportedCsv((prev) => [...prev, { name: file.name, id: csvId, data: json }]);
          };

          fileReader.readAsText(file);
        });
      }
    },
    [importedCsv]
  );

  return (
    <DialogContent sx={{ display: 'flex', flexDirection: 'column', rowGap: theme.spacing(2) }}>
      <input
        type="file"
        onChange={onChangeHandler}
        id="import-csv-input"
        accept=".csv"
        multiple={true}
        ref={inputRef}
        style={{ display: 'none' }}
      />
      <Button variant="outlined" onClick={onClickHandler} startIcon={<AttachFileIcon />}>
        Import CSV
      </Button>
      {importedCsv.length > 0 && (
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', rowGap: theme.spacing(1) }}>
          {importedCsv?.map((CSVFile: Csv) => {
            if (CSVFile.data.length !== 0) {
              return <CSVData CSVFile={CSVFile} setImportedCsv={setImportedCsv} key={CSVFile.id} />;
            }

            return (
              <Alert severity="error" sx={{ width: '100%' }}>
                <CSVData CSVFile={CSVFile} setImportedCsv={setImportedCsv} key={CSVFile.id} />
              </Alert>
            );
          })}
        </Box>
      )}
      <Box component="div" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant="body2" color="grey">
          {importedCsv.reduce((acc, v) => acc + v.data.length, 0)} measures imported
        </Typography>
        <Box component="div" sx={{ display: 'flex', flexDirection: 'row', columnGap: theme.spacing(1) }}>
          <Button onClick={handleClose} color="error">
            Close
          </Button>
          <Tooltip title={!importedCsv.length ? 'Import CSV to analyse' : ''} placement="top" arrow>
            <Box component="div">
              <Button
                variant="contained"
                onClick={() => handleImportCsv(importedCsv)}
                disabled={importedCsv.every((v) => !v.data.length) || !importedCsv.length}
              >
                Analyse
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </DialogContent>
  );
}
