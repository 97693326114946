import type { ErrorMessage, FetchAction } from 'fetchMapTiles';
import type { Lidar, LidarPosition, MapImageScalingSourceData, Scale } from 'models/maps';
import type { LidarState } from 'reducers/maps';
import type { Action } from 'redux';
import type { MapImage } from './../models/maps';
import type { Failure } from './shared';
import { failureAction } from './shared';

export enum MapsActionTypes {
  LoadLidarFromYJS = '[Map] Load Lidar From YJS',
  LoadMapImageFromYJS = '[Map] Load Map image From YJS',
  LoadMapImagePropertiesFromYJS = '[Map] Load Map image properties From YJS',
  ImportLidar = '[Maps] Import Lidar',
  ImportLidarSuccess = '[Maps] Import Lidar Success',
  ImportLidarFailure = '[Maps] Import Lidar Failure',
  ImportMapImage = '[Maps] Import Map Image',
  ImportMapImageSuccess = '[Maps] Import Map Image Success',
  ImportMapImageFailure = '[Maps] Import Map Image Failure',
  SetImageHeight = '[Maps] Set Image Height',
  ClearMapImage = '[Maps] Clear Map Image',
  ClearLidarMap = '[Maps] Clear lidar Background',
  ChangeDisplayStateMapImageProperties = '[Maps] Change Display of Map Image Properties',
  UpdateMapImageProperties = '[Maps] Update Map Image Properties',
  ImportMapImageTiles = '[Maps] Import Map Image Tiles',
  ImportMapImageTilesReleaseVersion = '[Maps] Import all the available release version for the map tiles',
  UpdateMapImageTilesReleaseVersion = '[Maps] Update the release version',
  UpdateMapImageTilesName = '[Maps] Update the name (project name)',
  ImportFullImgURL = '[Maps] Import the URL of the full map',
  ImportErrorMessage = '[Maps] Import the error message when the fetch action fails',
}

export interface LoadLidarFromYJS extends Action {
  type: MapsActionTypes.LoadLidarFromYJS;
  payload: {
    lidar: LidarState;
  };
}

export function loadLidarFromYJSAction(payload: LoadLidarFromYJS['payload']): LoadLidarFromYJS {
  return { type: MapsActionTypes.LoadLidarFromYJS, payload };
}

export interface LoadMapImageFromYJS extends Action {
  type: MapsActionTypes.LoadMapImageFromYJS;
  payload: {
    name: string;
  };
}

export function loadMapImageFromYJSAction(payload: LoadMapImageFromYJS['payload']): LoadMapImageFromYJS {
  return { type: MapsActionTypes.LoadMapImageFromYJS, payload };
}

export interface LoadMapImagePropertiesFromYJS extends Action {
  type: MapsActionTypes.LoadMapImagePropertiesFromYJS;
  payload: {
    data: Partial<MapImage>;
  };
}

export function loadMapImagePropertiesFromYJSAction(
  payload: LoadMapImagePropertiesFromYJS['payload']
): LoadMapImagePropertiesFromYJS {
  return { type: MapsActionTypes.LoadMapImagePropertiesFromYJS, payload };
}

export interface ImportLidar extends Action {
  type: MapsActionTypes.ImportLidar;
  payload: { coords: string[]; position: LidarPosition; name?: string; saveBackgroundLidar?: boolean };
}

export function importLidarAction(payload: ImportLidar['payload']): ImportLidar {
  return { type: MapsActionTypes.ImportLidar, payload };
}

export interface ImportLidarSuccess extends Action {
  type: MapsActionTypes.ImportLidarSuccess;
  payload: { lidar: Lidar; position: LidarPosition; saveBackgroundLidar?: boolean };
}

export function importLidarSuccessAction(payload: ImportLidarSuccess['payload']): ImportLidarSuccess {
  return { type: MapsActionTypes.ImportLidarSuccess, payload };
}

export interface ImportLidarFailure extends Failure<MapsActionTypes.ImportLidarFailure> {}

export function importLidarFailureAction(error: Error): ImportLidarFailure {
  return failureAction(MapsActionTypes.ImportLidarFailure, error);
}

export interface ImportMapImage extends Action {
  type: MapsActionTypes.ImportMapImage;
  payload: { imageURL: string; name: string; originalHeight: number; originalWidth: number };
}

export function importMapImageAction(payload: ImportMapImage['payload']): ImportMapImage {
  return { type: MapsActionTypes.ImportMapImage, payload };
}

export interface ImportMapImageSuccess extends Action {
  type: MapsActionTypes.ImportMapImageSuccess;
  payload: {
    imageURL: string;
    name: string;
    originalHeight: number;
    originalWidth: number;
  };
}

export function importMapImageSuccessAction(payload: ImportMapImageSuccess['payload']): ImportMapImageSuccess {
  return { type: MapsActionTypes.ImportMapImageSuccess, payload };
}

export interface ImportMapImageFailure extends Failure<MapsActionTypes.ImportMapImageFailure> {}

export function importMapImageFailureAction(error: Error): ImportMapImageFailure {
  return failureAction(MapsActionTypes.ImportMapImageFailure, error);
}

export interface SetImageHeight extends Action {
  type: MapsActionTypes.SetImageHeight;
  payload: { imageHeight: number; scaling?: MapImageScalingSourceData; name: string };
}

export function setImageHeightAction(payload: SetImageHeight['payload']): SetImageHeight {
  return { type: MapsActionTypes.SetImageHeight, payload };
}

export interface ClearMapImage extends Action {
  type: MapsActionTypes.ClearMapImage;
  payload: {
    name?: string;
  };
}

export function clearMapImageAction(payload: ClearMapImage['payload']): ClearMapImage {
  return { type: MapsActionTypes.ClearMapImage, payload };
}

export interface ClearLidarMap extends Action {
  type: MapsActionTypes.ClearLidarMap;
  payload: {
    name: LidarPosition;
  };
}

export function clearLidarMapAction(payload: ClearLidarMap['payload']): ClearLidarMap {
  return { type: MapsActionTypes.ClearLidarMap, payload };
}

export interface ChangeDisplayStateImageProperties extends Action {
  type: MapsActionTypes.ChangeDisplayStateMapImageProperties;
  payload: {
    openIndex: number | undefined;
  };
}

export function changeDisplayStateMapImagePropertiesAction(
  payload: ChangeDisplayStateImageProperties['payload']
): ChangeDisplayStateImageProperties {
  return { type: MapsActionTypes.ChangeDisplayStateMapImageProperties, payload };
}

type PayloadMapImageTiles = {
  version: string;
  name: string;
  release: string;
  tilePixelSize: number;
  tiles: {
    [K in Scale]: {
      file: string;
      x: number;
      y: number;
    }[];
  };
};

export interface ImportMapImageTiles extends Action {
  type: MapsActionTypes.ImportMapImageTiles;
  payload: PayloadMapImageTiles;
}

export function importMapImageTilesAction(payload: ImportMapImageTiles['payload']): ImportMapImageTiles {
  return { type: MapsActionTypes.ImportMapImageTiles, payload };
}

export interface ImportMapImageTilesReleaseVersion extends Action {
  type: MapsActionTypes.ImportMapImageTilesReleaseVersion;
  payload: {
    releaseVersion: string[];
  };
}

export function importMapImageTilesReleaseVersionAction(
  payload: ImportMapImageTilesReleaseVersion['payload']
): ImportMapImageTilesReleaseVersion {
  return { type: MapsActionTypes.ImportMapImageTilesReleaseVersion, payload };
}

export interface UpdateMapImageTilesReleaseVersion extends Action {
  type: MapsActionTypes.UpdateMapImageTilesReleaseVersion;
  payload: {
    newRelease: string;
  };
}

export function updateMapImageTilesReleaseVersionAction(
  payload: UpdateMapImageTilesReleaseVersion['payload']
): UpdateMapImageTilesReleaseVersion {
  return { type: MapsActionTypes.UpdateMapImageTilesReleaseVersion, payload };
}

export interface UpdateMapImageTilesName extends Action {
  type: MapsActionTypes.UpdateMapImageTilesName;
  payload: {
    newName: string;
  };
}

export function updateMapImageTilesNameAction(payload: UpdateMapImageTilesName['payload']): UpdateMapImageTilesName {
  return { type: MapsActionTypes.UpdateMapImageTilesName, payload };
}

export interface UpdateMapImageProperties extends Action {
  type: MapsActionTypes.UpdateMapImageProperties;
  payload: { properties: Partial<MapImage>; targetName?: string };
}

export function updateMapImagePropertiesAction(payload: UpdateMapImageProperties['payload']): UpdateMapImageProperties {
  return { type: MapsActionTypes.UpdateMapImageProperties, payload };
}

export interface ImportFullImgURL extends Action {
  type: MapsActionTypes.ImportFullImgURL;
  payload: { fullImgURL: string };
}

export function importFullImgURLAction(payload: ImportFullImgURL['payload']): ImportFullImgURL {
  return { type: MapsActionTypes.ImportFullImgURL, payload };
}

export interface ImportErrorMessage extends Action {
  type: MapsActionTypes.ImportErrorMessage;
  payload: { errorMessage: ErrorMessage | undefined; fetchAction: FetchAction };
}

export function importErrorMessageAction(payload: ImportErrorMessage['payload']): ImportErrorMessage {
  return { type: MapsActionTypes.ImportErrorMessage, payload };
}

export type MapsActions =
  | LoadLidarFromYJS
  | LoadMapImageFromYJS
  | LoadMapImagePropertiesFromYJS
  | ImportLidar
  | ImportLidarSuccess
  | ImportLidarFailure
  | ImportMapImage
  | ImportMapImageSuccess
  | ImportMapImageFailure
  | SetImageHeight
  | ClearMapImage
  | ClearLidarMap
  | ChangeDisplayStateImageProperties
  | UpdateMapImageProperties
  | ImportMapImageTiles
  | ImportMapImageTilesReleaseVersion
  | UpdateMapImageTilesReleaseVersion
  | UpdateMapImageTilesName
  | ImportFullImgURL
  | ImportErrorMessage;
