import { localDoc, remoteDoc } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import { initializeLayersAndLayerGroups } from 'utils/circuit/initialize-layer-groups';
import type { YMap } from 'yjs/dist/src/internals';
import { syncYJSLocalToRemote } from '../utils/syncYjsDoc';

export const useRoomInitByServerSubscription = (): void => {
  const roomInitByServerMap = remoteDoc?.getMap('roomInitByServer') as YMap<boolean>;
  const prefMap = remoteDoc?.getMap('pref');

  useLayoutEffect(() => {
    const observerHandle = (event, transaction): void => {
      if (!roomInitByServerMap) return;

      const isFirstInit = roomInitByServerMap.get('bool');
      const isTransactionLocal = transaction.origin === 'local';
      if (isTransactionLocal || !isFirstInit || !prefMap) return;

      initializeLayersAndLayerGroups();

      const roomInitLocal = localDoc.getMap('roomInitByServer');
      roomInitLocal.set('bool', false);

      syncYJSLocalToRemote();
    };

    roomInitByServerMap.observe(observerHandle);

    return () => {
      roomInitByServerMap.unobserve(observerHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
