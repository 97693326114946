import { SmartToy } from '@mui/icons-material';
import { Avatar, Checkbox, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { CollapseMore } from 'components/utils/collapse-more';
import { setFlow, type Flow } from 'flows/flows';
import { useCallback } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch } from 'store';
import { PreferencesService } from 'utils/preferences';
import { trucksEmulationPrefName, useRobotsEmulation } from './use-set-robots';

type RobotsListMode = 'emulation-enabled' | 'flow-assignation';

interface RobotsListPropsGeneral {
  useCollapse?: boolean;
  mode?: RobotsListMode;
  flow?: Flow;
}

interface RobotsListPropsGeneralFlowAssignation extends RobotsListPropsGeneral {
  mode: 'flow-assignation';
  flow: Flow;
}
type RobotsListProps = RobotsListPropsGeneralFlowAssignation | RobotsListPropsGeneral;

export function RobotsList(props: RobotsListProps): JSX.Element {
  const { useCollapse = true, mode = 'emulation-enabled', flow } = props;

  return useCollapse ? (
    <CollapseMore
      title={
        <>
          Robots{' '}
          <SmartToy
            sx={{
              verticalAlign: 'middle',
            }}
          />
        </>
      }
    >
      <DisplayListRobots flow={flow} mode={mode} />
    </CollapseMore>
  ) : (
    <DisplayListRobots flow={flow} mode={mode} />
  );
}

function DisplayListRobots(props: RobotsListProps): JSX.Element {
  const { mode = 'emulation-enabled', flow } = props;
  const dispatch = useAppDispatch();

  const flowId = flow?.id;

  const [robots, setRobots] = useRobotsEmulation();

  const handleChangeEmulationEnabled = useCallback(
    async (index: number | 'all', newState: boolean): Promise<void> => {
      const newRobots = [...robots];
      if (index === 'all') {
        newRobots.forEach((robot) => {
          robot.emulationEnabled = newState;
        });
      } else {
        newRobots[index].emulationEnabled = newState;
      }

      setRobots(newRobots);

      const [ok] = await PreferencesService.setPreferenceValue(
        trucksEmulationPrefName,
        newRobots.map((robot) => (robot.emulationEnabled ? '1' : '0')),
        true
      );

      if (!ok) {
        // eslint-disable-next-line no-console
        console.error('Failed to save trucks emulation preferences');

        setRobots(robots);

        SnackbarUtils.error('Failed to save trucks emulation preferences');
      }
    },
    [robots, setRobots]
  );

  const handleChangeFlowAssignation = useCallback(
    (index: number | 'all', newState: boolean) => {
      const newFlow = structuredClone(store.getState().flows.flows.find((flow) => flow.id === flowId));
      if (!newFlow) {
        // eslint-disable-next-line no-console
        console.error(`Flow with id ${flowId} not found`);

        return;
      }

      if (index === 'all') {
        newFlow.robotsAssigned = newState ? 'all' : [];
      } else {
        let newRobotsAssigned = newFlow.robotsAssigned ?? 'all';
        if (newRobotsAssigned === 'all') {
          newRobotsAssigned = robots.map((robot) => robot.serial);
        }

        if (newState) {
          newRobotsAssigned.push(robots[index].serial);
        } else {
          const indexToRemove = newRobotsAssigned.indexOf(robots[index].serial);
          if (indexToRemove !== -1) {
            newRobotsAssigned.splice(indexToRemove, 1);
          }
        }

        newRobotsAssigned = Array.from(new Set(newRobotsAssigned));

        newFlow.robotsAssigned = newRobotsAssigned;
      }

      dispatch(setFlow(newFlow));
    },
    [dispatch, flowId, robots]
  );

  const allRobotsChecked =
    mode === 'emulation-enabled' ? robots.every((robot) => robot.emulationEnabled) : flow?.robotsAssigned === 'all';
  const someRobotsChecked =
    mode === 'emulation-enabled'
      ? robots.some((robot) => robot.emulationEnabled)
      : flow?.robotsAssigned !== 'all' && (flow?.robotsAssigned?.length ?? 0) > 0;

  return (
    <List
      dense
      sx={{
        maxHeight: mode === 'flow-assignation' ? '300px' : '100%',
        overflowY: mode === 'flow-assignation' ? 'auto' : 'unset',
      }}
    >
      <ListItem
        sx={{ justifyContent: 'end' }}
        secondaryAction={
          <Tooltip
            title={
              mode === 'emulation-enabled'
                ? 'Enable/disable all robots emulation'
                : 'Assign/unassign all robots to this flow'
            }
            placement="left"
          >
            <Checkbox
              edge="end"
              checked={allRobotsChecked}
              indeterminate={someRobotsChecked && !allRobotsChecked}
              onChange={(e, newValue) => {
                mode === 'emulation-enabled' && handleChangeEmulationEnabled('all', newValue);
                mode === 'flow-assignation' && handleChangeFlowAssignation('all', newValue);
              }}
            />
          </Tooltip>
        }
      ></ListItem>

      {robots.map((robot, robotIndex) => (
        <ListItem
          key={robot.serial}
          secondaryAction={
            <Tooltip
              title={
                mode === 'emulation-enabled'
                  ? 'Enable/disable robot emulation'
                  : 'Assign/unassign the robot to the flow'
              }
              disableInteractive
            >
              <Checkbox
                edge="end"
                checked={
                  mode === 'emulation-enabled'
                    ? robot.emulationEnabled
                    : flow?.robotsAssigned === 'all' || flow?.robotsAssigned?.includes(robot.serial)
                }
                onChange={(e, newValue) => {
                  mode === 'emulation-enabled' && handleChangeEmulationEnabled(robotIndex, newValue);
                  mode === 'flow-assignation' && handleChangeFlowAssignation(robotIndex, newValue);
                }}
              />
            </Tooltip>
          }
        >
          <ListItemIcon>
            <Avatar src={`/img/trucks/${robot.picturePath}-icon.png`} sx={{ width: 24, height: 24 }} />
          </ListItemIcon>
          <ListItemText primary={robot.name} secondary={robot.modelName} />
        </ListItem>
      ))}
    </List>
  );
}
