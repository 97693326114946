import { Box, Stack, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import { theme } from 'utils/mui-theme';

interface DisplayFlowDataProps {
  valueToDisplay?: number;
  title: string | ReactNode;
  unit: string;
}
export function DisplayFlowData(props: DisplayFlowDataProps): JSX.Element {
  const { valueToDisplay, title, unit } = props;

  return (
    <Box component="div" sx={{ flex: 1 }}>
      <Typography variant="caption" sx={{ fontWeight: 600, color: theme.palette.grey[700] }}>
        <Stack direction="row" spacing={1} justifyContent="center" sx={{ height: '50px' }}>
          {title}
        </Stack>
      </Typography>
      {valueToDisplay ? (
        <Typography variant="h6">{valueToDisplay}</Typography>
      ) : (
        <Typography variant="h6" sx={{ color: theme.palette.grey[300] }}>
          Ø
        </Typography>
      )}
      <Typography variant="caption" sx={{ color: theme.palette.grey[700] }}>
        {unit}
      </Typography>
    </Box>
  );
}
