import HistoryIcon from '@mui/icons-material/History';
import { FormControl, IconButton, TextField, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import type { Flow } from 'flows/flows';
import { setFlowName } from 'flows/flows';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'store';

/**
 * @link https://redmine.balyo.com/issues/48263
 */
const maximumFlowNameLength = 30;

interface RenameFlowProps {
  flow: Flow;
  formerState?: Flow;
  isInvalidName: (newName: string) => boolean;
}
export function RenameFlow(props: RenameFlowProps): JSX.Element {
  const { flow, formerState, isInvalidName } = props;

  const forbiddenCharacterMsg = `The character '/' is not allowed`;
  const incorrectNameMsg = 'This name is incorrect';
  const duplicateNameMsg = 'This name is already used';

  const dispatch = useAppDispatch();

  const [helperTextMsg, setHelperTextMsg] = useState('');

  const handleRevertName = useCallback(() => {
    if (!formerState) return;

    dispatch(
      setFlowName({
        id: flow.id,
        name: formerState.name,
      })
    );
  }, [dispatch, flow.id, formerState]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        handleRevertName();
      }
    },
    [handleRevertName]
  );

  const setErrorMsg = useCallback(
    (newName: string) => {
      if (!newName || (newName?.length && newName.length > maximumFlowNameLength)) {
        setHelperTextMsg(incorrectNameMsg);

        return;
      }

      // Check if the name contains the forbidden character '/':  Name with '/' break the simulation
      if (newName?.includes('/')) {
        setHelperTextMsg(forbiddenCharacterMsg);

        return;
      }

      setHelperTextMsg(duplicateNameMsg);
    },
    [forbiddenCharacterMsg]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (helperTextMsg) setHelperTextMsg('');

      const flowName = e.target.value;
      dispatch(
        setFlowName({
          id: flow.id,
          name: flowName,
        })
      );

      if (isInvalidName(flowName)) setErrorMsg(flowName);
    },
    [dispatch, flow.id, helperTextMsg, isInvalidName, setErrorMsg]
  );

  return (
    <>
      <FormControl fullWidth size="small">
        <TextField
          id="flow-name"
          label="Flow name"
          variant="outlined"
          size="small"
          value={flow.name}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          error={helperTextMsg.length !== 0}
          helperText={helperTextMsg}
          inputProps={{
            maxLength: maximumFlowNameLength,
          }}
        />
      </FormControl>

      {formerState && (
        <Tooltip title="Revert">
          <Box component="span">
            <IconButton aria-label="revert" onClick={handleRevertName} disabled={flow.name === formerState.name}>
              <HistoryIcon fontSize="small" />
            </IconButton>
          </Box>
        </Tooltip>
      )}
    </>
  );
}
