import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { writeToFile } from 'utils/preferences';

export async function saveNoteImages(circuitsDirHandle: FileSystemDirectoryHandle): Promise<void> {
  /** Save Circuit Image Files (Note Image) */
  if (!circuitsDirHandle) return;

  const circuitImages = store.getState().images.circuitImages;

  const circuitImageFolderHandle = await circuitsDirHandle.getDirectoryHandle('Images', { create: true });

  if (circuitImages && circuitImages?.length > 0) {
    circuitImages.forEach(async (circuitImage) => {
      if (circuitImage.url) {
        const img = new Image();
        img.src = circuitImage.url;
        let imageDecodeOk = false;

        try {
          await img.decode();
          imageDecodeOk = true;
        } catch (e) {
          SnackbarUtils.error(`The image could not be saved. It may be corrupted or too large.`);
        }

        if (imageDecodeOk) {
          try {
            // File already exists. No action needed.
            await circuitImageFolderHandle.getFileHandle(`${circuitImage.name}`);
          } catch (error) {
            if (error instanceof Error) {
              if (error.name === 'NotFoundError') {
                //  Creating a new file.
                const newFileHandle = await circuitImageFolderHandle.getFileHandle(`${circuitImage.name}`, {
                  create: true,
                });
                try {
                  await writeToFile(newFileHandle, await (await fetch(circuitImage.url)).blob());
                  // eslint-disable-next-line no-console
                  console.log(`New file created and image copied in the CIRCUIT/IMAGES folder.`);
                } catch (e) {
                  // eslint-disable-next-line no-console
                  console.error(`Failed to write image on disk, error: ${e}`);
                }
              } else {
                // eslint-disable-next-line no-console
                console.error(`Error occurred while checking file existence:`, error);
              }
            }
          }
        }
      }
    });
  }
}
