import { CircuitService, getMaxDisplayPriority } from './circuit.service';
import type { GetTurnFeatureServiceProps } from './trajectory.service';
import { TrajectoryService } from './trajectory.service';

// TODO: Upgrade turn worker
// const worker = new Worker(new URL('./trajectory.service.ts', import.meta.url), {
//   name: 'trajectory-service-worker',
// });

// const trajectoryProxy = Comlink.wrap<{
//   getTurnFeature: GetTurnFeature;
// }>(worker);

type GetTurnFeatureProps = Omit<GetTurnFeatureServiceProps, 'layerId' | 'prio'>;

export function getTurnFeature(props: GetTurnFeatureProps): ReturnType<(typeof TrajectoryService)['getTurnFeature']> {
  const layerId = CircuitService.getSelectedLayer();
  const prio = getMaxDisplayPriority();

  const p = TrajectoryService.getTurnFeature({ layerId, prio, ...props });

  return p;
}
