import { updateTurnAction } from 'actions/circuit';
import { pDistance } from 'drawings/helpers';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { isDefined } from 'utils/ts/is-defined';
import { estimateRadiusOfTurn } from './estimate-radius-of-turn';

export async function computeAgainAllTurns(): Promise<void> {
  const turnsIds = store.getState().circuit.present.turns.ids;

  let i = 0;
  const waitEverySteps = 10;
  for (const turnId of turnsIds) {
    store.dispatch(
      updateTurnAction({
        idToUpdate: turnId,
      })
    );

    if (++i % waitEverySteps === 0) {
      // eslint-disable-next-line no-console
      console.log(`computeAgainAllTurns: ${i} turns computed (${Math.round((i / turnsIds.length) * 100)}%)`);

      await new Promise((resolve) => setTimeout(resolve, 0));
    }
  }

  // eslint-disable-next-line no-console
  console.log(`computeAgainAllTurns: ${turnsIds.length} turns computed (100%)`);
}

/**Function to check if all turns are correctly computed
 * @returns Array of turns that need to be recomputed
 */
export function checkIfTurnsAreNotComputedCorrectly(): string[] {
  const turns = store.getState().circuit.present.turns.entities;
  const turnsIds = store.getState().circuit.present.turns.ids;

  const segments = store.getState().circuit.present.segments.entities;

  const turnsToComputeAgain = turnsIds
    .map((turnId) => {
      const turn = turns[turnId];

      const segmentOrigin = turn && turn.properties.originId ? segments[turn.properties.originId] : undefined;
      const segmentDest = turn && turn.properties.destinationId ? segments[turn.properties.destinationId] : undefined;

      const turnCoord = turn?.geometry.coordinates;
      const endCoord = turnCoord?.[(turn?.geometry?.coordinates?.length || 1) - 1];
      const startCoord = turnCoord?.[0];

      const segmentDestCoord = segmentDest?.geometry.coordinates;
      const segmentOriginCoord = segmentOrigin?.geometry.coordinates;

      if (segmentOriginCoord && segmentDestCoord) {
        const distanceOrigin = pDistance(
          startCoord[0],
          startCoord[1],
          segmentOriginCoord[0][0],
          segmentOriginCoord[0][1],
          segmentOriginCoord[1][0],
          segmentOriginCoord[1][1]
        );
        const distanceDest = pDistance(
          endCoord[0],
          endCoord[1],
          segmentDestCoord[0][0],
          segmentDestCoord[0][1],
          segmentDestCoord[1][0],
          segmentDestCoord[1][1]
        );

        const errorMargin = 0.1;

        if (distanceDest > errorMargin || distanceOrigin > errorMargin) {
          return turnId;
        }
      }

      return undefined;
    })
    .filter(isDefined);

  return turnsToComputeAgain;
}

/**
 * It happens that turns have been incorrectly computed and saved in the geojson which can create bugs (they are not well connected to their segments).
 * This function is there to ensure that all turns are correctly computed and if this is not the case, recompute them when launching the app.
 */
export async function checkAndComputeAgainWrongTurns(): Promise<void> {
  let i = 0;
  const waitEverySteps = 10;

  const turnsToComputeAgain = checkIfTurnsAreNotComputedCorrectly();

  if (turnsToComputeAgain.length > 0) {
    SnackbarUtils.info(`${turnsToComputeAgain.length} turns are not ok and will be recomputed`);
  }

  if (!turnsToComputeAgain.length) {
    return;
  }

  for (const turnId of turnsToComputeAgain) {
    store.dispatch(
      updateTurnAction({
        idToUpdate: turnId,
      })
    );

    if (++i % waitEverySteps === 0) {
      // eslint-disable-next-line no-console
      console.log(
        `computeAgainWrongTurns: ${i} turns computed (${Math.round((i / turnsToComputeAgain.length) * 100)}%)`
      );

      await new Promise((resolve) => setTimeout(resolve, 0));
    }
  }

  // eslint-disable-next-line no-console
  console.log(`computeAgainWrongTurns: ${turnsToComputeAgain.length} turns computed (100%)`);

  SnackbarUtils.info(`${turnsToComputeAgain.length} turns have been computed again`);
}

export function exportAllTurns(): void {
  const turns = store.getState().circuit.present.turns.entities;
  const turnsIds = store.getState().circuit.present.turns.ids;

  const header = 'id;layerId;startPointOffset;radius;stopBeforeTurn;originId;destinationId';

  const lines = turnsIds.map((turnId) => {
    const turn = turns[turnId];

    const radius = estimateRadiusOfTurn(turn) ?? turn.properties.radius;

    return `${turn.id};${turn.properties.layerId};${turn.properties.startPointOffset ?? ''};${radius};${
      turn.properties.turnType === 'StopBeforeTurn' ? 1 : 0
    };${turn.properties.originId};${turn.properties.destinationId}`;
  });

  const csv = [header, ...lines].join('\n');

  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = 'turns.csv';
  a.click();
}
