import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, IconButton, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import type { Flow, Station, StationPosition } from 'flows/flows';
import { removeStationFromFlow, reorderStationsInFlow, setSelectedStationId } from 'flows/flows';
import { getSteps } from 'flows/get-steps-from-flow';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { clearAllItineraries } from 'routes/routes';
import { checkPermission } from 'services/check-permission';
import { useAppDispatch, useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';
import { theme } from 'utils/mui-theme';
import { isDefined } from 'utils/ts/is-defined';
import { FlowConfigurationTab } from '../flow-configuration';
import { EditStationMenu } from './edit-station-menu';
import { EditStepBtn } from './edit-step-btn';
import { FlowConfigDisplay } from './flow';
import { ListPositionOfMultipleStations } from './list-position-of-multiple-stations';

interface StepperOfStationProps {
  flowConfigDisplay: FlowConfigDisplay;
  stations: Station[];
  flow: Flow;
  setSelectedTab: (tabToSelect: string) => void;
}
export function StepperOfStation(props: StepperOfStationProps): JSX.Element {
  const { flowConfigDisplay, stations, flow, setSelectedTab } = props;

  const dispatch = useAppDispatch();

  const selectedFlowId = useAppSelector((state) => state.flows.selectedFlowId);

  const [activeStep, setActiveStep] = useState<number>(0);

  const handleClickRemoveStation = useCallback(
    (i: number) => {
      if (!selectedFlowId) return;

      dispatch(
        removeStationFromFlow({
          flowId: selectedFlowId,
          index: i,
        })
      );
    },
    [dispatch, selectedFlowId]
  );

  const handleEditStation = useCallback(
    (stationId: string) => {
      dispatch(setSelectedStationId(stationId));

      setSelectedTab(FlowConfigurationTab.Station);
    },
    [dispatch, setSelectedTab]
  );

  const handleReorderStations = useCallback(
    (i: number, direction: 'up' | 'down') => {
      if (!selectedFlowId) return;

      const newActiveStep = activeStep + (direction === 'up' ? -1 : +1);
      setActiveStep(-1);

      setTimeout(() => {
        dispatch(
          reorderStationsInFlow({
            flowId: selectedFlowId,
            index: i,
            direction,
          })
        );

        setActiveStep(newActiveStep);
      }, 500);
    },
    [activeStep, dispatch, selectedFlowId]
  );

  const editStationPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:stations');
  }, []);

  const handleClickStep = useCallback((step: number) => {
    setActiveStep(step);
  }, []);

  const stationsWithStep = useMemo(() => {
    const steps = getSteps(flow);

    return flow.stations.map((stationFlow, index) => {
      const step = steps?.[index];

      const ids = typeof stationFlow.id === 'string' ? [stationFlow.id] : stationFlow.id;
      const names = stations
        .map((station) => {
          if (ids.includes(station.id)) {
            return station.name;
          }

          return undefined;
        })
        .filter(isDefined);

      const positions: StationPosition[] = [];

      for (let i = 0; i < stations.length; i++) {
        if (ids.includes(stations[i].id)) {
          stations[i].positions.forEach((stationPosition) => {
            positions.push(stationPosition);
          });
        }
      }

      return {
        step: step,
        id: ids,
        name: names,
        positions: positions,
      };
    });
  }, [flow, stations]);

  useEffect(() => {
    return () => {
      dispatch(clearAllItineraries());
    };
  }, [dispatch]);

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      sx={{
        marginTop: theme.spacing(2),
      }}
    >
      {stationsWithStep.map((station, i) => {
        const step = 'step' in station ? station.step : undefined;

        return (
          <Step key={`${i}-step`} completed={false}>
            <StepLabel
              onClick={() => handleClickStep(i)}
              sx={{
                cursor: activeStep !== i ? 'pointer' : undefined,
                width: '100%',
                textAlign: 'justify',
              }}
              optional={
                step && (
                  <EditStepBtn
                    step={step}
                    station={station}
                    flow={flow}
                    stepIndex={i}
                    disableSelect={flowConfigDisplay === FlowConfigDisplay.Default}
                  />
                )
              }
            >
              {station.name.join(', ')}
            </StepLabel>

            <StepContent>
              {station.positions?.length > 0 ? (
                <ListPositionOfMultipleStations
                  stations={station}
                  sx={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                  }}
                />
              ) : (
                <p>No positions in this station</p>
              )}

              {flowConfigDisplay !== FlowConfigDisplay.Default && (
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() => handleClickRemoveStation(i)}
                  sx={{
                    textTransform: 'none',
                  }}
                  size="small"
                >
                  Remove
                </Button>
              )}

              <EditStationMenu
                selectedStations={station}
                stations={stations}
                editStationPerm={editStationPerm}
                handleEditStation={handleEditStation}
                key={i}
              />

              <IconButton disabled={i === 0} onClick={() => handleReorderStations(i, 'up')}>
                <KeyboardArrowUpIcon />
              </IconButton>

              <IconButton disabled={i === stationsWithStep.length - 1} onClick={() => handleReorderStations(i, 'down')}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
}
