import type { ToolInfoWithExtraData } from 'components/menu-bar/tool-info';
import type { ReturnToMoveToolType, lineStrokeSize, pointSize } from 'models/drawings';
import type { Tools } from 'models/tools';

export interface ToolState {
  /** the currently selected tool */
  activeTool: Tools;
  /** the last selected tool */
  lastActiveTool: Tools;
  /** the list of tools available to the user in the circuit tab */
  circuitTools: ToolInfoWithExtraData[];
  /** the list of tools available to the user in the traffic tab */
  trafficTools: ToolInfoWithExtraData[];
  /** the list of tools available to the user in the flow tab */
  flowTools: ToolInfoWithExtraData[];
  /** when drawing a line, must it be snapped at 90 degrees? (snap tool) */
  snap90deg: boolean;
  /** when drawing a turn, what type of turns should we draw? */
  turnType: 'Normal' | 'StopBeforeTurn' | 'Tesseract';
  /** when drawing a zone, what type of zone should we draw? */
  zoneType: 'Rectangle' | 'Triangle';
  /** the opacity of the map included in the interval [0, 100] (%) */
  mapOpacity: number;
  /** Stroke width of the invisible shape around the segments and other shapes, to help the user click on the shape [px] */
  selectStrokeWidth: number;
  /** size of points as displayed on Road Editor */
  pointsSize: pointSize;
  /** stroke width of the lines (segments, turns, etc.) as displayed on Road Editor */
  lineStrokeSize: lineStrokeSize;
  /** the opacity of the mapObstacleOpacity included in the interval [0, 100] (%) */
  mapObstacleOpacity: number;
  /**
   * The user can choose if he wants that the tool goes back to the move tool
   * automatically after drawing a shape
   * always: always return to the move tool
   * never: keep the same tool
   * somtimes: return to the move tool only after creating a rack or a stock zone or using the draw shape tool
   */
  returnToMoveTool: ReturnToMoveToolType;
  /** whether or not we highlight the turns */
  highlightTurns: boolean;
  /** whether or not we do not authorize the orientation of the element and just change their length */
  lockOrientation: boolean;
  /** whether or not we always keep the racks and stock zones active */
  alwaysDisplayCommon: boolean;
  /** whether or not we always display the bbox selection when selecting a shape */
  displayBBoxSelection: boolean;
  /** zoom level to display tiles */
  zoomLevel: string | undefined;
  /** grid coordinates */
  gridCoordinate: {
    /**left top corner*/
    x0: number;
    /**left top corner*/
    y0: number;
    /**right top corner*/
    x1: number;
    /**left bottom corner*/
    y1: number;
  };
}

export const alwaysDisplayCommonLocalStorageKey = 'settings.always-display-common';
export const displayBBoxSelectionLocalStorageKey = 'settings.display-bbox-selection';
