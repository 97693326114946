import { KeyboardArrowLeft, KeyboardArrowRight, PendingActions, SlowMotionVideo } from '@mui/icons-material';
import AnimationIcon from '@mui/icons-material/Animation';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RefreshIcon from '@mui/icons-material/Refresh';
import { default as Speed, default as SpeedIcon } from '@mui/icons-material/Speed';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import type { SelectChangeEvent } from '@mui/material';
import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { openDialogAction } from 'actions';
import type { ProxyMethods, RemoteObject } from 'comlink';
import { TOOL_LIST_ALL_TOOLS } from 'components/menu-bar/tool-info';
import type { CustomStep, SimulationCustomConfigSchema } from 'flows/custom-steps.model';
import type { StationPositionWithName } from 'flows/flows';
import { getSteps } from 'flows/get-steps-from-flow';
import { DialogTypes } from 'models';
import type { CantonAtPoint, DeadendsAtPoint } from 'models/circuit';
import type {
  NewTaskDispatcher,
  NewTasksDispatcherWithTime,
  NewTasksDispatcherWithTimeAndSerials,
  RobotDataSimulation,
  RobotTimers,
  SimulationThreadInfo,
} from 'models/simulation';
import {
  isRobotTimers,
  isRobotsDataSimulation,
  isSimulationTasksEvents,
  isSimulationThreadInfo,
} from 'models/simulation.guard';
import { Tools } from 'models/tools';
import type { MutableRefObject } from 'react';
import { startTransition, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { Robot } from 'robots/robots';
import { setRobots, unselectAllRobots } from 'robots/robots';
import type { Itinerary } from 'routes/routes';
import { clearAllItineraries, setItineraries } from 'routes/routes';
import { checkPermission } from 'services/check-permission';
import { loadPreferencesToLibTrack } from 'services/project';
import { SnackbarUtils } from 'services/snackbar.service';
import { staticBatteryDataSchema } from 'simulation/battery-data';
import {
  MIN_REFRESH_SIMU_ROBOTS_INTERVAL,
  REFRESH_SIMU_ROBOTS_INTERVAL,
  isRobotSmoothingEnabled,
} from 'simulation/config';
import type { DispatcherConfig } from 'simulation/dispatcher-config';
import { displayAdvancedTraffic, displayTraffic } from 'simulation/displayTraffic';
import { loadCircuitSimulation } from 'simulation/load-circuit';
import { getDefaultOPCFlow, getOpcConfig } from 'simulation/opc';
import type { PerfoRecordData } from 'simulation/perfo-data';
import { perfoDataSchema } from 'simulation/perfo-data';
import type { SchedulerConfig } from 'simulation/scheduler/scheduler-config';
import { schedulerConfigFilePath, schedulerConfigSchema } from 'simulation/scheduler/scheduler-config';
import { getNbTasksDone } from 'simulation/scheduler/tasks-utils';
import type { RobotsTimersHistory, SimulationTask } from 'simulation/simulation';
import {
  addRobotsTimersToHistory,
  addSeveralPerfoRecordsToTasks,
  resetLoadingState,
  resetSimulation,
  setHasSimulationStarted,
  setIsSimulationPaused,
  setIsSimulationRunning,
  setLoadingState,
  setMultipleTasks,
  setSimulatedTimeAndCurrentSpeed,
  setSimulationLoadedOnce,
  setSpeedFactor,
  setStartEpochTime,
} from 'simulation/simulation';
import type { Simulation } from 'simulation/simulation.model';
import { taskStrToNumber } from 'simulation/states';
import { retrieveAndSaveTrafficSwalFile } from 'simulation/traffic-cache-file';
import { checkTriggers } from 'simulation/triggers/check-triggers';
import store, { useAppDispatch, useAppSelector } from 'store';
import type { CantonsDict } from 'traffic/traffic';
import { clearAllCantons, setMultipleCantonsAtPoint, setOccupiedCantons, setReservedCantons } from 'traffic/traffic';
import { useAsyncMemo } from 'use-async-memo';
import { AnimatedIcon } from 'utils/animated-icon';
import { cpxStrToPosition } from 'utils/circuit';
import { getRobotLayerGroup } from 'utils/circuit/get-robot-layer-group';
import { epsilon } from 'utils/circuit/utils';
import { theme } from 'utils/mui-theme';
import { PreferencesService, getPreferenceValue } from 'utils/preferences';
import { timeoutAfter } from 'utils/promises';
import { formatSecondsToHHMMSS } from 'utils/time';
import { canBeUndefined, isDefined } from 'utils/ts/is-defined';
import { DisplayTrafficCollapse } from './display-traffic-collapse';
import { CreateReplayTriggerBtn } from './flow-configuration/triggers/create-replay-trigger-btn';
import { AlertOldDispatcherEnabled } from './simulation/alert-isOldDispatcher-enabled';
import { AlertUsedEmptyStation } from './simulation/alert-used-empty-station';
import { AlertUsedNotAllowedName } from './simulation/alert-used-not-allowed-name';
import { CheckMissingTaxiPoints } from './simulation/check-missing-taxi';
import { ConfigureSimulationParameters } from './simulation/configure-simulation-parameters';
import { generateTaskData } from './simulation/create-task-factory';
import { DisplayErrorTrucksNameWhenNeeded } from './simulation/display-error-trucks-name-when-needed';
import { LoadingSimulationIndicator } from './simulation/loading-simulation';
import { PowerSavingIcon } from './simulation/power-saving-icon';
import { PresetSimulationToolbox } from './simulation/preset-simulation';
import type { SimulationReportData } from './simulation/report-model';
import { TriggersList } from './simulation/triggers-list';
import { getRobotsEmulation } from './simulation/use-set-robots';

const defaultPicturePath = 'Balyo/Lowy';

const trucksEmulationPrefName = 'general/trucksEmulation';

const availableSpeedFactors = [1, 2, 5, 10, 50, 10000] as const;
const maxSpeedFactorValue = availableSpeedFactors[availableSpeedFactors.length - 1];

const minimumDistanceBetweenPointsRobotItinerary = 0.025; // m

export let getSimulationDataHandle: ((force?: boolean) => Promise<void>) | undefined = undefined;
export let waitForGetSimulationDataHandle: MutableRefObject<Promise<void> | null> | undefined = undefined;
const globalFont = {
  color: theme.palette.grey[700],
  fontWeight: 600,
};

export type SimulationService = RemoteObject<Simulation> & ProxyMethods;

export function ConfigureSimulationToolbox(): JSX.Element {
  const dispatch = useAppDispatch();

  const launchSimulationPermission = useAsyncMemo(async () => {
    return await checkPermission('launch:simulation');
  }, []);

  const speedFactor = useAppSelector((state) => state.simulation.speedFactor);
  const currentSpeed = useAppSelector((state) => state.simulation.currentSpeed);
  const simulatedTime = useAppSelector((state) => state.simulation.simulatedTime);
  const schedulerTasks = useAppSelector((state) => state.simulation.tasks);
  const logsEnabled = useAppSelector((state) => state.simulation.enableLogs);
  const simulationEnableDisplay = useAppSelector((state) => state.simulation.enableDisplay);
  const maxDuration = useAppSelector((state) => state.simulation.maxDuration);
  const flows = useAppSelector((state) => state.flows.flows);
  const fastRefreshEnabled = useAppSelector((state) => state.simulation.enableFastRefresh);
  const enableAdvancedDisplayTraffic = useAppSelector((state) => state.simulation.enableAdvancedDisplayTraffic);
  const isSimulationPaused = useAppSelector((state) => state.simulation.isSimulationPaused);
  const isSimulationRunning = useAppSelector((state) => state.simulation.isSimulationRunning);

  const [selectedFlowId, setSelectedFlowId] = useState<string | null>(null);

  const [startSimulationRequested, setStartSimulationRequested] = useState(false);
  const isSimulationPausedInstant = useRef(isSimulationPaused);
  useLayoutEffect(() => {
    isSimulationPausedInstant.current = isSimulationPaused;
  }, [isSimulationPaused]);

  const [circuitXmlDoc, setCircuitXmlDoc] = useState<Document | null>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorXml, setErrorXml] = useState(false);

  const [tasksHistory, setTasksHistory] = useState<
    (NewTasksDispatcherWithTimeAndSerials | NewTasksDispatcherWithTime)[]
  >([]);

  const loadingState = useAppSelector((state) => state.simulation.loading);

  const trucks = useMemo(() => {
    return PreferencesService.getTrucks();
  }, []);

  const robots = useMemo(() => {
    return getRobotsEmulation();
  }, []);

  const simulationServiceRef = useRef<(RemoteObject<Simulation> & ProxyMethods) | null>(null);
  const circuitPtr = useRef<number | null>(null);

  const dispatcherConfigRef = useRef<DispatcherConfig>();

  const tasksWaitingForCreation = useRef<Record<string, number | undefined>>({}); // key: taskName, value: number of tasks waiting for creation

  const deinizializeSimulation = useCallback(async (): Promise<void> => {
    const simulationService = simulationServiceRef.current;
    if (!simulationService) return;

    // eslint-disable-next-line no-console
    console.log('Deinitializing the simulation...');

    await simulationService._WEBSIMU_WasmWrapper_setSpeed(0);

    setError(false);
    dispatch(setRobots([]));
    dispatch(resetSimulation());
    dispatch(unselectAllRobots());
    dispatch(resetLoadingState());
    dispatch(setHasSimulationStarted(false));

    await simulationService._WEBSIMU_WasmWrapper_deinitializeSimulation();
    await simulationService._OPC_WasmWrapper_deinitialize();
    await simulationService._DISPATCHER_WasmWrapper_deinitialize();
    await simulationService._SCHEDULER_WasmWrapper_deinitialize();
    await simulationService._WEBSIMU_WasmWrapper_resetCounter();

    setCircuitXmlDoc(null);
  }, [dispatch]);

  useEffect(() => {
    return () => {
      deinizializeSimulation();
    };
  }, [deinizializeSimulation]);

  const createTask = useCallback(
    async (
      flowId?: string | string[],
      time?: number,
      options?: {
        robAuth?: number[];
        steps?: StationPositionWithName[];
        priority?: number;
      }
    ): Promise<void> => {
      const simulationService = simulationServiceRef.current;
      if (!simulationService) return;

      if (!circuitXmlDoc) {
        // eslint-disable-next-line no-console
        console.error(`XML circuit not initialized`);

        return;
      }

      if (selectedFlowId === null && !flowId) {
        // eslint-disable-next-line no-console
        console.log('Creating a temporary fake task (random)...');
        await simulationService._DISPATCHER_WasmWrapper_createRandomTasks(1);
      } else {
        if (!flowId && selectedFlowId) {
          flowId = selectedFlowId;
        }

        const flowsId = Array.isArray(flowId) ? flowId : [flowId];
        const newTasks = flowsId.reduce((acc, flowIdLocal) => {
          if (!flowIdLocal) {
            // eslint-disable-next-line no-console
            console.warn('flowIdLocal is undefined');

            return acc;
          }

          const previousTask = canBeUndefined(acc[acc.length - 1]);

          const flow = flows.find((f) => f.id === flowIdLocal);
          const maxTimeForThisFlow = flow?.maximumTaskTime;

          const taskData = generateTaskData({
            flowId: flowIdLocal,
            selectedFlowId,
            priority: options?.priority,
            robAuth: options?.robAuth,
            steps: options?.steps,
            previousTask,
            maxExpectedTaskDuration: maxTimeForThisFlow,
          });

          if (taskData) {
            acc.push(taskData);
          } else {
            // eslint-disable-next-line no-console
            console.error(`Error while generating task data for flow ${flowIdLocal}`);
          }

          return acc;
        }, [] as NewTaskDispatcher[]);

        // eslint-disable-next-line no-console
        console.log('newTasks', newTasks, time);

        if (!newTasks[0]) {
          // eslint-disable-next-line no-console
          console.error('No task to create');

          return;
        }

        const newTaskStrPtr = await simulationService.allocateUTF8(
          JSON.stringify(newTasks.length > 1 ? newTasks : newTasks[0])
        );

        const simulationTime = store.getState().simulation.simulatedTime;
        if (time === undefined && !Array.isArray(newTasks)) {
          // create task now
          await simulationService._DISPATCHER_WasmWrapper_newTask(newTaskStrPtr);
        } else {
          // create the task in the future
          await simulationService._DISPATCHER_WasmWrapper_newTaskInFuture(
            newTaskStrPtr,
            Math.round(time ?? simulationTime)
          );
        }

        startTransition(() => {
          setTasksHistory((prevTasksHistory) => {
            const newTasksHistory = [...prevTasksHistory];

            const newTasksWithSerials = newTasks.map((task) => {
              const serials = task?.robAuth
                ?.map((robId) => {
                  if (robId === -1) return null;

                  const robot = store.getState().robots.robots.find((robot) => robot.id === robId);

                  return robot?.serial;
                })
                .filter(isDefined);

              return {
                ...task,
                serials,
              };
            });

            newTasksHistory.push({
              tasks: newTasksWithSerials,
              time: time ?? simulationTime,
            });

            return newTasksHistory;
          });
        });

        await simulationService._OS_WasmWrapper_free(newTaskStrPtr);
      }
    },
    [circuitXmlDoc, flows, selectedFlowId]
  );

  const changeLogsStateInSimu = useCallback(async (newLogState: boolean) => {
    const simulationService = simulationServiceRef.current;
    if (!simulationService) return;

    await simulationService._OS_WasmWrapper_enableLog(newLogState);
  }, []);

  const nbTasksDone = useMemo(() => {
    return getNbTasksDone(schedulerTasks);
  }, [schedulerTasks]);

  const tasksPerFlow = useMemo(() => {
    const schedulerTasksArr = Object.values(schedulerTasks);

    return flows.map((flow) => {
      return {
        flowId: flow.id,
        flowName: flow.name,
        tasks: schedulerTasksArr.filter((task) => task.taskName === flow.name),
      };
    });
  }, [flows, schedulerTasks]);

  const nbRemainingTasksPerFlow = useMemo(() => {
    const taskStatusDone = taskStrToNumber('done');

    return tasksPerFlow.map(({ flowName, flowId, tasks }) => ({
      flowId,
      flowName,
      count: tasks.filter((task) => task.state !== taskStatusDone).length,
      countPerStep: tasks.reduce((acc, task) => {
        if (taskStatusDone === task.state) return acc;

        const stepNb = task.stepEndDate?.length ?? 0;

        if (!acc[stepNb]) acc[stepNb] = 0;
        acc[stepNb] += 1;

        return acc;
      }, [] as number[]),
    }));
  }, [tasksPerFlow]);

  const nbRemainingTasks = useMemo(() => {
    return nbRemainingTasksPerFlow.reduce((acc, { count }) => acc + count, 0);
  }, [nbRemainingTasksPerFlow]);

  const triggersExecution = useRef<Record<string, number | undefined>>({}); // key: triggerId, value: lastExecutionTime
  const checkingTriggers = useRef(false);

  useEffect(() => {
    changeLogsStateInSimu(logsEnabled);
  }, [changeLogsStateInSimu, logsEnabled]);

  const loadSimulation = useCallback(async (): Promise<void> => {
    store.dispatch(setSimulationLoadedOnce());

    setLoading(true);

    // eslint-disable-next-line no-console
    if (window.balyoSimulationVersion) console.log(window.balyoSimulationVersion);

    const simulationServiceModule = await import('../../services/simulation.service.ts');
    await simulationServiceModule.waitForSimulationService;

    const simulationServiceTmp = simulationServiceModule.simulationService;
    if (simulationServiceTmp) {
      // reload the preferences
      await simulationServiceTmp._WEBSIMU_WasmWrapper_deinitializeSimulation();
      await simulationServiceTmp._TRAFFIC_WasmWrapper_destroy();
      await simulationServiceTmp._RTE_WasmWrapper_Deinitialize();
    }

    await loadPreferencesToLibTrack();

    dispatch(
      setLoadingState({
        brief: 'Loading the circuit',
        title: 'Loading the circuit',
        stepNb: store.getState().simulation.loading?.stepNb ?? 0,
        percent: undefined,
      })
    );

    const resLoadingCircuit = await loadCircuitSimulation({
      circuitPtr,
    });

    if (
      !resLoadingCircuit.circuitXml ||
      resLoadingCircuit.errorCircuitXml ||
      resLoadingCircuit.errorSimulationService ||
      resLoadingCircuit.errorLoadingCircuit ||
      !resLoadingCircuit.simulationService
    ) {
      setLoading(false);
      if (resLoadingCircuit.errorCircuitXml) {
        setErrorXml(true);
      } else {
        setError(true);
      }

      return;
    }

    const { simulationService } = resLoadingCircuit;
    simulationServiceRef.current = simulationService;

    await retrieveAndSaveTrafficSwalFile({ simulationService });

    let stopSimulation = false;
    // eslint-disable-next-line no-console
    console.log('Initializing the traffic simulation...');
    try {
      await simulationService._TRAFFIC_WasmWrapper_initialize();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while initializing the traffic simulation', e);
      setError(true);
    }

    // eslint-disable-next-line no-console
    console.log('Loading the dispatcher...');
    const dispatcherConfigDebug = sessionStorage.getItem('dispatcherConfigDebug')
      ? (JSON.parse(sessionStorage.getItem('dispatcherConfigDebug') ?? '') as DispatcherConfig)
      : undefined;

    const maxNbSteps = flows.reduce((acc, flow) => {
      return Math.max(acc, flow.stations.length);
    }, 0);

    const stepsCustomFields: `step${number}`[] = new Array(maxNbSteps)
      .fill(0)
      .map((_, i) => `step${i + 1}`) as `step${number}`[];
    const positionInLineCustomFields: `positionInLine${number}`[] = new Array(maxNbSteps)
      .fill(0)
      .map((_, i) => `positionInLine${i + 1}`) as `positionInLine${number}`[];
    const customFields = [...stepsCustomFields, ...positionInLineCustomFields];
    const taskDefinition: DispatcherConfig['taskDefinition'] = {
      startPoint: 'unknown',
      destinationPoint: 'unknown',
      src: 0,
      dst: 0,
    };
    customFields.forEach((customField) => (taskDefinition[customField] = 0));

    const opcFlow = getDefaultOPCFlow();

    const customSteps = store.getState().flows.customSteps;

    const customStepsForSimu: Record<string, CustomStep> = {
      ...Object.fromEntries(
        customSteps.map((step) => {
          return [step.name, step];
        })
      ),
    };

    const dispatcherConfig: DispatcherConfig = dispatcherConfigDebug || {
      taskDefinition,
      options: {
        customFields: [],
        autoAssign: false, // when the scheduler is enabled, the scheduler assigns the tasks, so we set it to false
        toDone: 1,
      },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      flows: {
        ...opcFlow,
        ...Object.fromEntries(
          flows
            .map((flow) => {
              let robotsAssigned =
                flow.robotsAssigned === 'all' || flow.robotsAssigned === undefined
                  ? [-1]
                  : flow.robotsAssigned
                      .map((robotSerial) => {
                        const robot = robots.find((robot) => robot.serial === robotSerial);
                        if (!robot) {
                          // eslint-disable-next-line no-console
                          console.warn(`Robot not found for flow ${flow.name}, serial: ${robotSerial}`, {
                            robotsData: trucks,
                          }); // robot can be not found if the user assigned the flow to a robot and then removed the robot from the project

                          return null;
                        }

                        return robot.ID;
                      })
                      .filter(isDefined);

              // because no robot assigned = [0]
              if (robotsAssigned.length === 0) robotsAssigned = [0];

              const steps = getSteps(flow);
              // Eject Flow from dispatcher if this one have station without positions
              if (!steps) return undefined;

              const missionList = flow.stations.map((stationWithId, stationIndex) => {
                const args = [`step${stationIndex + 1}`];
                const step = steps?.[stationIndex];
                const stations = store.getState().flows.stations.filter((s) => stationWithId.id.includes(s.id));
                if (!stations) {
                  // eslint-disable-next-line no-console
                  console.error(`Station not found, id: ${stationWithId.id}`);

                  return null;
                }

                if (!step) {
                  // eslint-disable-next-line no-console
                  console.error(`Step not found, index: ${stationIndex}`);

                  return null;
                }

                stations.forEach((station) => {
                  if (station.positions[stationIndex]?.positionInLine !== undefined) {
                    args.push(`positionInLine${stationIndex + 1}`);
                  }
                });

                return {
                  missionName: step.name,
                  args,
                };
              });

              return [
                flow.name,
                {
                  priority: 1,
                  state: 1,
                  robAuth: [-1],
                  robAssigned: robotsAssigned,

                  defaultMissionList: missionList,

                  customSteps: Object.fromEntries(
                    missionList
                      .map((mission) => {
                        const customStep = customStepsForSimu[mission?.missionName ?? ''];
                        if (!customStep) return null;

                        return [customStep.name, customStep];
                      })
                      .filter(isDefined)
                  ) as Record<string, CustomStep>,
                },
              ];
            })
            .filter(isDefined)
        ),
      },
    };
    // eslint-disable-next-line no-console
    console.log({
      dispatcherConfig,
    });
    dispatcherConfigRef.current = dispatcherConfig;
    try {
      if (flows.length || dispatcherConfigDebug) {
        // eslint-disable-next-line no-console
        console.log(`${flows.length} flows in the project, loading the custom dispatcher config...`);

        const dispatcherConfigStrPtr = await simulationService.allocateUTF8(JSON.stringify(dispatcherConfig));
        await simulationService._DISPATCHER_WasmWrapper_initialize(dispatcherConfigStrPtr);
        await simulationService._OS_WasmWrapper_free(dispatcherConfigStrPtr);
      } else {
        // eslint-disable-next-line no-console
        console.log('No flows in the project, loading the default dispatcher config...');

        await simulationService._DISPATCHER_WasmWrapper_initialize(0);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while loading the dispatcher', e);
      setError(true);
    }

    dispatch(
      setLoadingState({
        brief: 'Initializing the scheduler',
        title: 'Initializing the scheduler',
        stepNb: (store.getState().simulation.loading?.stepNb ?? 0) + 1,
        percent: undefined,
      })
    );

    // eslint-disable-next-line no-console
    console.log('Getting the scheduler configuration...');
    let schedulerConfig: SchedulerConfig | undefined = undefined;
    const schedulerConfigFile = await PreferencesService.getFileByPath(`${schedulerConfigFilePath}`);
    let schedulerConfigContent: string | undefined = undefined;
    if (schedulerConfigFile) {
      schedulerConfigContent = await schedulerConfigFile.text();

      try {
        const schedulerConfigZod = schedulerConfigContent
          ? schedulerConfigSchema.safeParse(JSON.parse(schedulerConfigContent))
          : undefined;
        if (schedulerConfigZod && schedulerConfigZod.success) {
          schedulerConfig = schedulerConfigZod.data;
        } else {
          SnackbarUtils.error('The scheduler configuration file is corrupted. It has been ignored.');
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Error while parsing the scheduler configuration file', e);

        SnackbarUtils.error('The scheduler configuration file is corrupted. It has been ignored.');
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('No scheduler configuration file found, loading the default scheduler config...');
    }

    // eslint-disable-next-line no-console
    console.log('Initializing the scheduler...');
    try {
      let schedulerConfigStrPtr: number | undefined = undefined;
      if (schedulerConfig && schedulerConfigContent) {
        const schedulerConfigJson = schedulerConfigContent;
        schedulerConfigStrPtr = await simulationService.allocateUTF8(schedulerConfigJson);

        // eslint-disable-next-line no-console
        console.log('Scheduler config loaded:', schedulerConfigJson);
      }

      await simulationService._SCHEDULER_WasmWrapper_initialize(schedulerConfigStrPtr);
      if (schedulerConfigStrPtr) await simulationService._OS_WasmWrapper_free(schedulerConfigStrPtr);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while initializing the scheduler', e);
      setError(true);
    }

    // eslint-disable-next-line no-console
    console.log('Initializing the simulation...');

    try {
      const simuCustomConfig: SimulationCustomConfigSchema = {
        perceptionTime: 10,
        customSteps: customStepsForSimu,
      };

      // eslint-disable-next-line no-console
      console.log('Custom config for the simulation:', simuCustomConfig);

      const simuCustomConfigStrPtr = simuCustomConfig
        ? await simulationService.allocateUTF8(JSON.stringify(simuCustomConfig))
        : undefined;

      await simulationService._WEBSIMU_WasmWrapper_initializeSimulation(simuCustomConfigStrPtr);
      if (simuCustomConfigStrPtr) await simulationService._OS_WasmWrapper_free(simuCustomConfigStrPtr);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while initializing the simulation', e);
      stopSimulation = true;
      setError(true);
    }

    const startEpochTime = await simulationService._WEBSIMU_WasmWrapper_getStartEpoch();
    // eslint-disable-next-line no-console
    console.log(`Start epoch time: ${startEpochTime}`);
    dispatch(setStartEpochTime(startEpochTime));

    dispatch(
      setLoadingState({
        brief: 'Initializing the OPC',
        title: 'Initializing the OPC',
        stepNb: (store.getState().simulation.loading?.stepNb ?? 0) + 1,
        percent: undefined,
      })
    );

    // eslint-disable-next-line no-console
    console.log('Retrieve the OPC configuration...');

    const opcConfig = await getOpcConfig();
    const opcConfigStrPtr = await simulationService.allocateUTF8(JSON.stringify(opcConfig));

    // eslint-disable-next-line no-console
    console.log('Initializing the OPC...', opcConfig);
    try {
      await simulationService._OPC_WasmWrapper_initialize(opcConfigStrPtr);
      await simulationService._OS_WasmWrapper_free(opcConfigStrPtr);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while initializing the OPC', e);
      stopSimulation = true;
      setError(true);
    }

    dispatch(
      setLoadingState({
        brief: 'Finalizing the initialization',
        title: 'Finalizing the initialization',
        stepNb: (store.getState().simulation.loading?.stepNb ?? 0) + 1,
        percent: undefined,
      })
    );

    // eslint-disable-next-line no-console
    console.log('Enabling the libPerfo...');
    try {
      await simulationService._ROBEMU_WasmWrapper_enablePerfo();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while enabling the libPerfo', e);
      stopSimulation = true;
      setError(true);
    }

    // we stop the simulation, the user will start it manually
    await simulationService._WEBSIMU_WasmWrapper_setSpeed(0);

    const xmlDoc = new DOMParser().parseFromString(resLoadingCircuit.circuitXml, 'text/xml');
    setCircuitXmlDoc(xmlDoc);

    // set the initial robot battery levels
    const initialRobotsBatteryLevel = store.getState().simulation.robotsBatteryLevel;
    for (let i = 0; i < initialRobotsBatteryLevel.length; i++) {
      const robot = robots.find((robot) => robot.ID === parseInt(initialRobotsBatteryLevel[i].id, 10));
      if (!robot || !robot.emulationEnabled) continue;

      await simulationService._ROBEMU_WasmWrapper_setBattLevel(
        Number(initialRobotsBatteryLevel[i].id),
        initialRobotsBatteryLevel[i].batteryLevel
      );
    }

    if (stopSimulation) {
      return;
    }

    setLoading(false);
  }, [dispatch, flows, robots, trucks]);

  const getRobotsTimersAndAddToHistory = useCallback(async () => {
    const simulationService = simulationServiceRef.current;
    if (!simulationService) return;

    const robotsTimers: RobotTimers[] = [];

    for (let i = 0; i < robots.length; i++) {
      const robot = robots[i];
      if (!robot.emulationEnabled) continue;

      const robotTimersStrPtr = await simulationService._WEBSIMU_WasmWrapper_getRobotTimers(robot.ID);
      const robotTimersStr = robotTimersStrPtr ? await simulationService.UTF8ToString(robotTimersStrPtr) : '';

      let robotTimer: unknown | undefined;
      try {
        robotTimer = JSON.parse(robotTimersStr) as unknown;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error while parsing the robot timer', e, robotTimersStr);
      }

      if (robotTimer) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        if (isRobotTimers(robotTimer)) {
          robotsTimers.push(robotTimer);
        } else {
          // eslint-disable-next-line no-console
          console.error('robotTimer is not a RobotTimers', robotTimer);
        }
      }
    }

    const allRobotsTimersOk = robotsTimers.every((robotTimer) => !!robotTimer);

    if (!allRobotsTimersOk) {
      // eslint-disable-next-line no-console
      console.error('Not all robots have a robotTimers data');

      return;
    }

    const robotTimersHistory: RobotsTimersHistory = {
      simulationTime: simulatedTime,
      robotsTimers: robotsTimers.map((robotTimers, i) => ({
        robId: robots[i].ID,
        timers: robotTimers,
      })),
    };

    dispatch(addRobotsTimersToHistory({ robotTimersHistory: robotTimersHistory }));
  }, [dispatch, robots, simulatedTime]);

  const lastRobotsTimersHistory = useRef<number | undefined>(undefined);
  const robotsTimersHistoryPeriod = 300.0; // [s]

  const elapsedTimeSinceLastRobotsTimersHistory = simulatedTime - (lastRobotsTimersHistory.current ?? 0);

  if (simulatedTime > 0 && elapsedTimeSinceLastRobotsTimersHistory > robotsTimersHistoryPeriod) {
    lastRobotsTimersHistory.current = simulatedTime;

    getRobotsTimersAndAddToHistory();
  }

  const handleStart = useCallback(
    async (force = false) => {
      if (!isSimulationRunning && !force) {
        setStartSimulationRequested(true);
      } else {
        const simulationService = simulationServiceRef.current;
        if (!simulationService) return;

        const newSpeedFactor = speedFactor || 1;
        if (!speedFactor) {
          dispatch(setSpeedFactor(newSpeedFactor));
        }

        dispatch(setIsSimulationPaused(false));
        await simulationService._WEBSIMU_WasmWrapper_setSpeed(newSpeedFactor);
        dispatch(setHasSimulationStarted(true));

        const enableBatt = store.getState().simulation.enableCharging;

        // enable the discharge of the robots
        await simulationService._ROBEMU_WasmWrapper_setBatt(enableBatt ? 1 : 0);
      }
    },
    [dispatch, isSimulationRunning, speedFactor]
  );

  const handlePause = useCallback(async () => {
    const simulationService = simulationServiceRef.current;
    if (!simulationService) return;

    dispatch(setIsSimulationPaused(true));
    await simulationService._WEBSIMU_WasmWrapper_setSpeed(0);
  }, [dispatch]);

  useLayoutEffect(() => {
    const conditionsToStartTheSimu =
      !isSimulationRunning && startSimulationRequested && !loading && !error && !errorXml;

    const simulationService = simulationServiceRef.current;
    if (!simulationService) return;

    if (conditionsToStartTheSimu) {
      dispatch(setIsSimulationRunning(true));
      handleStart(true);
      dispatch(setHasSimulationStarted(true));
    }
  }, [
    createTask,
    dispatch,
    error,
    errorXml,
    handleStart,
    isSimulationRunning,
    loading,
    speedFactor,
    startSimulationRequested,
  ]);

  const alreadyLoadedSimulation = useRef(false);

  const robotNames = useRef<Record<string, string>>({});

  const waitForGetSimulationData = useRef<Promise<void> | null>(null);
  const getSimulationData = useCallback(
    async (force = false) => {
      const simulationService = simulationServiceRef.current;

      if (!simulationService) return;

      if (!force && (!isSimulationRunning || isSimulationPaused)) return;

      let resolveWaitForGetSimulationData: (() => void) | undefined = undefined;
      waitForGetSimulationData.current = new Promise((resolve) => {
        resolveWaitForGetSimulationData = resolve;
      });
      waitForGetSimulationDataHandle = waitForGetSimulationData;

      const robots: Robot[] = [];
      let itineraries: Itinerary[] | undefined = undefined;
      let occupiedCantons: CantonsDict | undefined = undefined;
      let reservedCantons: CantonsDict | undefined = undefined;
      let threadInfo: SimulationThreadInfo | undefined = undefined;
      if (simulationEnableDisplay || force) {
        const robotDataStrPtr = await Promise.race([
          simulationService._RTE_WasmWrapper_getRobotsData(),
          timeoutAfter(REFRESH_SIMU_ROBOTS_INTERVAL / 1.5),
        ]);
        const robotDataStr = robotDataStrPtr ? await simulationService.UTF8ToString(robotDataStrPtr) : '';
        let robotsData: unknown | undefined;
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          robotsData = JSON.parse(robotDataStr) as unknown;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error while parsing the robot data', e, robotDataStr);
        }

        if (robotsData) {
          if (isRobotsDataSimulation(robotsData)) {
            // eslint-disable-next-line no-console
            // console.log('robotsData', robotsData);

            const keys = Object.keys(robotsData);

            const allPicturesPath = keys.length
              ? PreferencesService.getPreferenceValue('general/trucksPicturePath')
              : [];
            if (!Array.isArray(allPicturesPath)) {
              // eslint-disable-next-line no-console
              console.error('allPicturesPath is not an array', allPicturesPath);

              return;
            }

            const formerRobots = store.getState().robots.robots;

            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              const robotData: RobotDataSimulation | undefined = robotsData[key];
              if (!robotData) {
                // eslint-disable-next-line no-console
                console.error(`robotData is undefined for key ${key}`);
                continue;
              }

              const robotId = parseInt(key.split('-').pop() ?? '', 10);
              if (isNaN(robotId)) {
                // eslint-disable-next-line no-console
                console.error(`robotId is NaN for key ${key}`);
                continue;
              }

              let picturePath = allPicturesPath[robotId - 1];
              if (!picturePath) {
                // eslint-disable-next-line no-console
                console.warn(`picturePath is undefined for robotId ${robotId}, fallback to ${defaultPicturePath}`);
                picturePath = defaultPicturePath;
              }

              let robotName = robotNames.current[key];
              if (!robotName) {
                try {
                  const allTruckNames = PreferencesService.getPreferenceValue('general/trucksName');
                  if (!Array.isArray(allTruckNames)) {
                    throw new Error(`robotNameFromPref is not an array`);
                  }

                  robotName = allTruckNames[robotId - 1];

                  robotNames.current[key] = robotName;
                } catch (e) {
                  // eslint-disable-next-line no-console
                  console.error('Error while getting the robot name', e);
                  robotName = key;
                }
              }

              const formerRobot = formerRobots.find((r) => r.name === robotName);

              let robotLength: number | undefined = formerRobot?.robotLength;
              let robotWidth: number | undefined = formerRobot?.robotWidth;
              let distanceXWheelTop: number | undefined = formerRobot?.distanceXWheelTop;
              let distanceYWheelTop: number | undefined = formerRobot?.distanceYWheelTop;
              let truckSerial: string | undefined = formerRobot?.serial;
              let layerGroup: string | undefined = formerRobot?.layerGroup;

              if (
                !formerRobot ||
                robotLength === undefined ||
                robotWidth === undefined ||
                distanceXWheelTop === undefined ||
                distanceYWheelTop === undefined
              ) {
                if (!truckSerial) {
                  truckSerial = PreferencesService.getPreferenceValue('general/trucksSerial')[robotId - 1];
                }

                if (!truckSerial) {
                  // eslint-disable-next-line no-console
                  console.warn(`truckSerial is undefined for robotId ${robotId}`);
                }

                try {
                  const shape = PreferencesService.getPreferenceValue('general/shape', truckSerial);
                  if (Array.isArray(shape)) {
                    let maxX = 0;
                    let maxY = 0;
                    let minX = 0;
                    let minY = 0;

                    for (let j = 0; j < shape.length; j++) {
                      const pointStr = shape[j];
                      const point = cpxStrToPosition(pointStr, false);

                      if (point[0] > maxX) {
                        maxX = point[0];
                      }

                      if (point[0] < minX) {
                        minX = point[0];
                      }

                      if (point[1] > maxY) {
                        maxY = point[1];
                      }

                      if (point[1] < minY) {
                        minY = point[1];
                      }
                    }

                    robotLength = maxX - minX;
                    robotWidth = maxY - minY;
                    distanceXWheelTop = maxX;
                    distanceYWheelTop = maxY - minY;
                  } else {
                    // eslint-disable-next-line no-console
                    console.error('shape should be an array', shape);
                  }
                } catch (e) {
                  // eslint-disable-next-line no-console
                  console.error('Error while getting the shape', e);
                }
              }

              if (!layerGroup) {
                const trucks = PreferencesService.getTrucks();
                const truck = trucks.find((t) => t.serial === truckSerial);
                layerGroup = truck ? getRobotLayerGroup(truck)?.id : undefined;

                if (!layerGroup) {
                  // eslint-disable-next-line no-console
                  console.error(`layerGroup is undefined for robotId ${robotId}`);
                  continue;
                }
              }

              if (truckSerial) {
                const robot: Robot = {
                  id: robotId,
                  name: robotName,
                  position: robotData.position,
                  picturePath,
                  distanceXWheelTop,
                  distanceYWheelTop,
                  robotLength,
                  robotWidth,
                  state: robotData.state,
                  carry: !!robotData.carry,
                  battery: robotData.battery,
                  action: robotData.action,
                  odometry: robotData.odometry,
                  speed: robotData.speed,
                  forksHeight: robotData.forksHeight,
                  trafficData: formerRobot?.trafficData,
                  serial: truckSerial,
                  layerGroup,
                };

                robots.push(robot);
              }
            }
          } else {
            // eslint-disable-next-line no-console
            console.error('robotsData is not a RobotsDataSimulation', robotsData);
          }
        }

        const enableDisplayTraffic = store.getState().simulation.enableDisplayTraffic;
        if ((simulationEnableDisplay || force) && enableDisplayTraffic) {
          itineraries = [];

          occupiedCantons = {};
          reservedCantons = {};

          for (let i = 0; i < robots.length; i++) {
            const robot = robots[i];

            const [computedItineraries, computedOccupiedCantons, computedReservedCantons] = await displayTraffic(
              robot,
              {
                simulationService,
                minimumDistanceBetweenPointsRobotItinerary: minimumDistanceBetweenPointsRobotItinerary,
                robots: robots,
              }
            );

            if (computedItineraries) {
              computedItineraries.robotIndex = i;

              itineraries.push(computedItineraries);
            }

            Object.keys(computedOccupiedCantons).forEach((key) => {
              const canton = computedOccupiedCantons[key];
              if (canton) {
                canton.robotIndex = i;
              }
            });

            Object.keys(computedReservedCantons).forEach((key) => {
              const canton = computedReservedCantons[key];
              if (canton) {
                canton.robotIndex = i;
              }
            });

            occupiedCantons = Object.assign(occupiedCantons, computedOccupiedCantons);
            reservedCantons = Object.assign(reservedCantons, computedReservedCantons);
          }
        }
      }

      const enableAdvancedDisplayTraffic = store.getState().simulation.enableAdvancedDisplayTraffic;
      const cantonsAtPoints: (CantonAtPoint & DeadendsAtPoint)[] = [];
      if ((simulationEnableDisplay || force) && enableAdvancedDisplayTraffic) {
        for (let i = 0; i < robots.length; i++) {
          const robot = robots[i];

          const cantonsAtPoint = await displayAdvancedTraffic(robot, {
            simulationService,
          });

          if (cantonsAtPoint) cantonsAtPoints.push(cantonsAtPoint);
        }
      }

      const threadInfoStrPtr = await Promise.race([
        simulationService._WEBSIMU_WasmWrapper_getThreadData(),
        timeoutAfter(REFRESH_SIMU_ROBOTS_INTERVAL / 1.5),
      ]);
      const threadInfoStr = threadInfoStrPtr ? await simulationService.UTF8ToString(threadInfoStrPtr) : '';
      let threadInfoTmp: unknown | undefined;
      try {
        threadInfoTmp = JSON.parse(threadInfoStr) as unknown;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error while parsing the thread info', e, threadInfoStr);
      }

      let simulationTime: number | undefined;
      if (threadInfoTmp) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        if (isSimulationThreadInfo(threadInfoTmp)) {
          threadInfo = threadInfoTmp;
          simulationTime = threadInfoTmp.simulatedTime;

          if (maxDuration !== undefined && maxDuration > 0 && threadInfo.simulatedTime > maxDuration) {
            await handlePause();
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('threadInfo is not a ThreadInfoSimulation', threadInfo);
        }
      }

      const libPerfoEnabled = store.getState().simulation.enableLibPerfo;
      let records: unknown | undefined;
      if (libPerfoEnabled) {
        const recordsStrPtr = await Promise.race([
          simulationService._PERFO_WasmWrapper_getData(),
          timeoutAfter(REFRESH_SIMU_ROBOTS_INTERVAL / 1.5),
        ]);
        const recordsStr = recordsStrPtr ? await simulationService.UTF8ToString(recordsStrPtr) : '';
        try {
          records = JSON.parse(recordsStr) as unknown;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error while parsing the records', e, recordsStr);
        }
      }

      const tasksEventsStrPtr = await Promise.race([
        simulationService._DISPATCHER_WasmWrapper_getTaskEvents(),
        timeoutAfter(REFRESH_SIMU_ROBOTS_INTERVAL / 1.5),
      ]);
      const tasksEventsStr = tasksEventsStrPtr ? await simulationService.UTF8ToString(tasksEventsStrPtr) : '';
      let tasksEvents: unknown | undefined;
      try {
        tasksEvents = JSON.parse(tasksEventsStr) as unknown;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error while parsing the task events', e, tasksEventsStr);
      }

      const tasksToSet: Record<string, SimulationTask> = {};
      if (tasksEvents) {
        if (isSimulationTasksEvents(tasksEvents)) {
          const tasksEventsKeys = Object.keys(tasksEvents);
          if (tasksEventsKeys.length > 0) {
            for (let i = 0; i < tasksEventsKeys.length; i++) {
              const taskEventKey = tasksEventsKeys[i];
              const taskEvent = tasksEvents[taskEventKey];
              const taskEventData = taskEvent.data;

              if (!taskEventData) continue;

              const taskFormerState = canBeUndefined(store.getState().simulation.tasks[taskEventKey]);

              const nbWaitingForCreation = tasksWaitingForCreation.current[taskEventData.taskName] ?? 0;
              if (!taskFormerState && nbWaitingForCreation) {
                let newNbWaitingForCreation = nbWaitingForCreation - 1;
                if (newNbWaitingForCreation < 0) newNbWaitingForCreation = 0;
                tasksWaitingForCreation.current[taskEventData.taskName] = newNbWaitingForCreation;
              }

              const taskToSet: SimulationTask = {
                ...taskFormerState,
                uniqueId: taskEventData.uniqueId ?? taskFormerState?.uniqueId,
                id: taskEventKey,
                state: taskEventData.state ?? taskFormerState?.state,
                robotID: taskEventData.rob ?? taskFormerState?.robotID,
                taskName: taskEventData.taskName ?? taskFormerState?.taskName,
                creationDate: taskEventData.creationDate ?? taskFormerState?.creationDate,
                startDate: taskEventData.startDate ?? taskFormerState?.startDate,
                endDate: taskEventData.endDate ?? taskFormerState?.endDate,
                stepEndDate: taskEventData.stepEndDate ?? taskFormerState?.stepEndDate,
                stepNames: taskEventData.stepNames ?? taskFormerState?.stepNames,
                src: taskEventData.src ?? taskFormerState?.src,
                dst: taskEventData.dst ?? taskFormerState?.dst,
                robotAuthorised: taskEventData.robAuth ?? taskFormerState?.robotAuthorised,
                priority: taskEventData.priority ?? taskFormerState?.priority,
                dueDate: taskEventData.dueDate ?? taskFormerState?.dueDate,
                startPoint: taskEventData.startPoint ?? taskFormerState?.startPoint,
                destinationPoint: taskEventData.destinationPoint ?? taskFormerState?.destinationPoint,
                stepLabel: taskEventData.stepLabel ?? taskFormerState?.stepLabel,
              };

              tasksToSet[taskEventKey] = taskToSet;
            }
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('taskEvents is not a TaskEventsSimulation', tasksEvents);
        }
      }

      let recordsToAdd: PerfoRecordData[] | undefined = undefined;
      if (records) {
        const isRecordsData = perfoDataSchema.safeParse(records);
        if (isRecordsData.success) {
          const recordsData = isRecordsData.data;

          if (recordsData.Records.length) {
            recordsToAdd = recordsData.Records;
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('records is not a RecordsData', records);
        }
      }

      if (threadInfo) {
        dispatch(
          setSimulatedTimeAndCurrentSpeed({
            simulatedTime: threadInfo.simulatedTime,
            currentSpeed: threadInfo.currentSpeed,
            meanSpeed: threadInfo.meanSpeed,
          })
        );
      }

      const tasksKeys = Object.keys(tasksToSet);
      if (tasksKeys.length) dispatch(setMultipleTasks(tasksToSet));

      if (simulationTime !== undefined) {
        await checkTriggers({
          checkingTriggers,
          createTask,
          isSimulationPausedInstant,
          simulationServiceRef,
          simulationTime,
          tasksWaitingForCreation,
          triggersExecution,
        });
      }

      dispatch(setRobots(robots));

      if (itineraries) {
        dispatch(setItineraries(itineraries));
      } else {
        dispatch(clearAllItineraries());
      }

      dispatch(clearAllCantons());
      if (occupiedCantons || reservedCantons) {
        const occupiedCantonsKeys = Object.keys(occupiedCantons ?? {});
        const reservedCantonsKeys = Object.keys(reservedCantons ?? {});

        if (occupiedCantonsKeys.length) dispatch(setOccupiedCantons(occupiedCantons ?? {}));
        if (reservedCantonsKeys.length) dispatch(setReservedCantons(reservedCantons ?? {}));
      }

      if (cantonsAtPoints?.length) {
        dispatch(setMultipleCantonsAtPoint(cantonsAtPoints));
      }

      if (recordsToAdd)
        dispatch(
          addSeveralPerfoRecordsToTasks({
            records: recordsToAdd,
          })
        );

      // need to ignore the typescript error because the function is well defined
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolveWaitForGetSimulationData();
    },
    [createTask, dispatch, handlePause, isSimulationPaused, isSimulationRunning, maxDuration, simulationEnableDisplay]
  );
  getSimulationDataHandle = getSimulationData;

  const intervalRefreshSimulationData = useRef<NodeJS.Timeout | null>(null);
  const refreshSimulationDataRef = useRef<(() => Promise<void>) | null>(null);
  const isCreatingTask = useRef(false);

  const refreshSimulationData = useCallback(async () => {
    const actuallyRetrieveData = !isCreatingTask.current; // prevent to retrieve the data when creating tasks (otherwise it may timeout on large projects)

    const dateStart = new Date();
    if (actuallyRetrieveData) await getSimulationData();
    const dateEnd = new Date();
    const deltaTime = dateEnd.getTime() - dateStart.getTime();

    const refreshDuration = fastRefreshEnabled ? MIN_REFRESH_SIMU_ROBOTS_INTERVAL : REFRESH_SIMU_ROBOTS_INTERVAL;

    let interval = Math.max(Math.floor(deltaTime / 4), refreshDuration);
    if (!fastRefreshEnabled) {
      interval = REFRESH_SIMU_ROBOTS_INTERVAL - deltaTime;
    }

    if (intervalRefreshSimulationData.current) {
      clearTimeout(intervalRefreshSimulationData.current);
    }

    if (refreshSimulationDataRef.current) {
      intervalRefreshSimulationData.current = setTimeout(refreshSimulationDataRef.current, interval);
    }
  }, [fastRefreshEnabled, getSimulationData]);
  refreshSimulationDataRef.current = refreshSimulationData;

  useEffect(() => {
    intervalRefreshSimulationData.current = setTimeout(refreshSimulationData, MIN_REFRESH_SIMU_ROBOTS_INTERVAL);

    return () => {
      if (intervalRefreshSimulationData.current) clearTimeout(intervalRefreshSimulationData.current);
    };
  }, [dispatch, refreshSimulationData]);

  useEffect(() => {
    if (!isSimulationRunning) return;

    if (speedFactor < epsilon && !isSimulationPaused) {
      dispatch(setIsSimulationPaused(true));

      (async () => {
        await simulationServiceRef.current?._WEBSIMU_WasmWrapper_setSpeed(0);
        await waitForGetSimulationData.current;
        await getSimulationData(true);
      })();
    }
  }, [dispatch, getSimulationData, isSimulationPaused, isSimulationRunning, speedFactor]);

  useEffect(() => {
    if (alreadyLoadedSimulation.current) return;
    alreadyLoadedSimulation.current = true;

    loadSimulation();
  }, [loadSimulation]);

  const AvatarIcon = useMemo(() => {
    return TOOL_LIST_ALL_TOOLS.find((tool) => tool.tool === Tools.SimulationConfiguration)?.icon;
  }, []);

  const isLoadingAndNoError = loading && !error && !errorXml;

  const [creatingTasks, setCreatingTasks] = useState<null | number>(null);
  const handleCreateTasks = useCallback(
    async (nbTasksToCreate = 1) => {
      if (isCreatingTask.current) return;

      const simulationService = simulationServiceRef.current;

      if (!simulationService) return;

      const wasPaused = isSimulationPaused;

      isCreatingTask.current = true;
      setCreatingTasks(nbTasksToCreate);

      //to avoid uncaught promises
      if (!wasPaused) await simulationService._WEBSIMU_WasmWrapper_setSpeed(0);

      await waitForGetSimulationData.current;

      let nbRemainingTasksToCreate = nbTasksToCreate;
      while (nbRemainingTasksToCreate-- > 0) {
        await createTask();
      }

      isCreatingTask.current = false;
      setCreatingTasks(null);

      SnackbarUtils.toast(nbTasksToCreate > 1 ? `${nbTasksToCreate} tasks created` : `Task created`);

      if (!wasPaused) await handleStart();
    },
    [createTask, handleStart, isSimulationPaused]
  );

  const handleChangeSpeedFactor = useCallback(
    (newSpeedFactor: number) => {
      dispatch(setSpeedFactor(newSpeedFactor));
    },
    [dispatch]
  );

  const reloadSimulationToolbox = useCallback(
    async (reloadPreferences = false): Promise<void> => {
      const simulationService = simulationServiceRef.current;
      if (!simulationService) return;

      dispatch(setIsSimulationRunning(false));
      setStartSimulationRequested(false);
      dispatch(setHasSimulationStarted(false));

      await handlePause();

      dispatch(clearAllItineraries());
      dispatch(clearAllCantons());

      await deinizializeSimulation();

      dispatch(setIsSimulationPaused(false));

      if (reloadPreferences) {
        const prefLoaded = await loadPreferencesToLibTrack();
        if (!prefLoaded) {
          // eslint-disable-next-line no-console
          console.error('Error while loading the preferences');

          SnackbarUtils.error('Error while loading the new preferences');
        }
      }

      triggersExecution.current = {};
      lastRobotsTimersHistory.current = 0;

      await loadSimulation();
    },
    [deinizializeSimulation, dispatch, handlePause, loadSimulation]
  );

  useEffect(() => {
    return () => {
      dispatch(clearAllItineraries());
      dispatch(clearAllCantons());
    };
  }, [dispatch]);

  const handleGenerateReport = useCallback(async () => {
    const simulationService = simulationServiceRef.current;
    if (!simulationService) return;

    const trucks = PreferencesService.getTrucks();
    let emulatedRobots = trucks.map((truck) => 0);
    try {
      const emulatedRobotsValue = getPreferenceValue(trucksEmulationPrefName);
      if (emulatedRobotsValue && Array.isArray(emulatedRobotsValue)) {
        emulatedRobots = emulatedRobotsValue.map((value) => parseInt(value, 10));
      }
    } catch (e) {}

    // const nbRobots = emulatedRobots.reduce((acc, cur) => (acc + cur >= 1 ? 1 : 0), 0);

    const robotsTimers: (RobotTimers | null)[] = [];
    const liveRobots = store.getState().robots.robots;

    for (let i = 0; i < trucks.length; i++) {
      const isEmulated = emulatedRobots[i] >= 1;
      if (!isEmulated) {
        robotsTimers.push(null);
        continue;
      }

      const robotTimersStrPtr = await simulationService._WEBSIMU_WasmWrapper_getRobotTimers(i + 1);
      const robotTimersStr = robotTimersStrPtr ? await simulationService.UTF8ToString(robotTimersStrPtr) : '';

      let robotTimer: unknown | undefined;
      try {
        robotTimer = JSON.parse(robotTimersStr) as unknown;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error while parsing the robot timer', e, robotTimersStr);
      }

      if (robotTimer) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        if (isRobotTimers(robotTimer)) {
          robotsTimers.push(robotTimer);
        } else {
          // eslint-disable-next-line no-console
          console.error('robotTimer is not a RobotTimers', robotTimer);
        }
      }
    }

    let trucksName: string[] = [];
    try {
      const trucksNameValue = getPreferenceValue('general/trucksName');
      if (trucksNameValue && Array.isArray(trucksNameValue)) {
        trucksName = trucksNameValue;
      }
    } catch (e) {}

    // eslint-disable-next-line no-console
    console.log({
      robotsTimers,
    });

    const robots: SimulationReportData['robots'] = [];

    for (let i = 0; i < trucks.length; i++) {
      const isEmulated = emulatedRobots[i] >= 1;
      if (!isEmulated) continue;

      const truck = trucks[i];

      const robotName = trucksName[i] ?? 'unknown';

      const liveRobot = liveRobots.find((r) => r.id === i + 1);

      let batteryModel = 'unknown';
      try {
        const batteryType = getPreferenceValue('battery/batteryType', truck.serial);
        if (batteryType && typeof batteryType === 'string') batteryModel = batteryType;
      } catch (e) {}

      let maxForwardSpeed: number | undefined = undefined;
      try {
        const maxForwardSpeedValue = getPreferenceValue('trajectoryDriver/speedLimit/maxForwardSpeed', truck.serial);
        if (maxForwardSpeedValue && typeof maxForwardSpeedValue === 'string')
          maxForwardSpeed = parseFloat(maxForwardSpeedValue);
      } catch (e) {}

      if (maxForwardSpeed !== undefined && isNaN(maxForwardSpeed)) maxForwardSpeed = undefined;

      let maxBackwardSpeed: number | undefined = undefined;
      try {
        const maxBackwardSpeedValue = getPreferenceValue('trajectoryDriver/speedLimit/maxBackwardSpeed', truck.serial);
        if (maxBackwardSpeedValue && typeof maxBackwardSpeedValue === 'string')
          maxBackwardSpeed = parseFloat(maxBackwardSpeedValue);
      } catch (e) {}

      if (maxBackwardSpeed !== undefined && isNaN(maxBackwardSpeed)) maxBackwardSpeed = undefined;

      let maxLateralSpeed: number | undefined = undefined;
      try {
        const maxLateralSpeedValue = getPreferenceValue('trajectoryDriver/speedLimit/maxLateralSpeed', truck.serial);
        if (maxLateralSpeedValue && typeof maxLateralSpeedValue === 'string')
          maxLateralSpeed = parseFloat(maxLateralSpeedValue);
      } catch (e) {}

      if (maxLateralSpeed !== undefined && isNaN(maxLateralSpeed)) maxLateralSpeed = undefined;

      let dischargeTime: undefined | number;
      let chargeTime: undefined | number;
      let batteryCapacity: undefined | number;

      const staticBatteryDataPtr = await simulationService._ROBEMU_WasmWrapper_getBatteryData(i + 1, false);
      const staticBatteryDataStr = staticBatteryDataPtr
        ? await simulationService.UTF8ToString(staticBatteryDataPtr)
        : '';
      const staticBatteryDataToValidate = JSON.parse(staticBatteryDataStr) as unknown;
      const isStaticBatteryData = staticBatteryDataSchema.safeParse(staticBatteryDataToValidate);
      if (isStaticBatteryData.success) {
        const staticBatteryData = isStaticBatteryData.data;

        dischargeTime = (staticBatteryData.capacity / staticBatteryData.meanConsumption) * 60 * 60;
        chargeTime = staticBatteryData.chargeTime / 1000;
        batteryCapacity = staticBatteryData.capacity;
      } else {
        // eslint-disable-next-line no-console
        console.error('staticBatteryData is not a StaticBatteryData', staticBatteryDataToValidate);
      }

      const robotTimer = robotsTimers[i];

      robots.push({
        robotId: truck.ID,
        name: robotName,
        batteryModel,
        serial: truck.serial,
        modelName: truck.modelName,
        maxForwardSpeed,
        maxBackwardSpeed,
        maxLateralSpeed,
        timers: robotTimer ? robotTimer : undefined,
        traveledDistance: liveRobot?.odometry,
        chargeTime,
        dischargeTime,
        batteryCapacity,
      });
    }

    const circuitName = PreferencesService.getCircuitName();
    const circuitVersion = store.getState().project.circuitVersion;
    const projectName = PreferencesService.getProjectName();
    const sdkVersion = PreferencesService.getSDKVersion();

    const chargingEnabled = store.getState().simulation.enableCharging;

    const simulationTime = store.getState().simulation.simulatedTime;
    const startSimuEpochTime = store.getState().simulation.startEpochTime;
    const simulationEpoch = startSimuEpochTime + simulationTime;

    const schedulerConfigName = store.getState().simulation.schedulerConfiguration;
    const schedulerConfigFile = await PreferencesService.getFileByPath(`${schedulerConfigFilePath}`);
    const schedulerConfigContent = schedulerConfigFile ? await schedulerConfigFile.text() : undefined;
    const schedulerConfig = schedulerConfigContent
      ? schedulerConfigSchema.safeParse(JSON.parse(schedulerConfigContent))
      : undefined;

    dispatch(
      openDialogAction({
        type: DialogTypes.SimulationReport,
        payload: {
          isSimulationReportData: true,
          robots,
          circuitName,
          circuitVersion,
          projectName,
          time: new Date(),
          sdkVersion: sdkVersion?.toString() ?? 'unknown',
          battery: {
            enabled: chargingEnabled,
            OPC: chargingEnabled,
          },
          simulationEpoch,
          schedulerConfigName,
          schedulerConfig: schedulerConfig?.success ? schedulerConfig.data : undefined,
        },
      })
    );
  }, [dispatch]);

  const robotSmoothingEnabled = isRobotSmoothingEnabled(speedFactor, fastRefreshEnabled);

  const selectedSpeedFactorIndex = useMemo(() => {
    return availableSpeedFactors.findIndex((availableSpeedFactor) => availableSpeedFactor === speedFactor);
  }, [speedFactor]);

  const hhmmssSimulatedTime = useMemo(() => {
    return formatSecondsToHHMMSS(simulatedTime);
  }, [simulatedTime]);

  const isSpeedSlowedDown = useMemo(() => {
    return currentSpeed < speedFactor;
  }, [currentSpeed, speedFactor]);

  let currentThroughput = nbTasksDone / (simulatedTime / 3600); // moved pallets per hours
  if (isNaN(currentThroughput)) currentThroughput = 0;

  const selectedFlow = useMemo(() => {
    const f = flows.find((flow) => flow.id === selectedFlowId);

    return f;
  }, [flows, selectedFlowId]);

  useLayoutEffect(() => {
    if (!selectedFlow && flows.length > 0 && flows[0].id) {
      setSelectedFlowId(flows[0].id);
    }
  }, [flows, selectedFlow, selectedFlowId]);

  const handleSelectFLowId = useCallback((e: SelectChangeEvent<string>) => {
    const value = e.target.value !== 'random' ? e.target.value : null;
    setSelectedFlowId(value);
  }, []);

  const [showSimulationCard, setShowSimulationCard] = useState(true);

  const handleClickShowSimulation = useCallback(
    (e): void => {
      setShowSimulationCard(!showSimulationCard);
    },
    [showSimulationCard]
  );

  const hasFlowStationsWithoutPositions = useMemo((): boolean => {
    const flow = selectedFlow;
    if (!flow) return false;

    return !getSteps(flow);
  }, [selectedFlow]);

  useEffect(() => {
    document.addEventListener('refresh-simulation', () => {
      getSimulationData();
    });

    return () => {
      document.removeEventListener('refresh-simulation', () => {
        getSimulationData();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component={'span'}>
      <Box
        component={'span'}
        sx={{
          position: 'absolute',
          backgroundColor: isLoadingAndNoError ? theme.palette.grey[100] : 'white',
          height: '50px',
          width: '50px',
          top: theme.spacing(2),
          right: '465px',
          transform: showSimulationCard ? undefined : `translateX(467px)`,
          transition: 'transform 0.5s ease',
          boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), -0.5px 1px 1px 0px rgba(0,0,0,0.14), -3px 1px 3px 0px rgba(0,0,0,0.12)',
          borderRadius: '4px 0px 0px 4px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        <IconButton
          id="icon-button-arrow-collapse-simu"
          title={showSimulationCard ? 'Hide' : 'Show'}
          aria-label="Show layers"
          onClick={handleClickShowSimulation}
          size="large"
        >
          {!showSimulationCard ? <KeyboardArrowLeft></KeyboardArrowLeft> : <KeyboardArrowRight></KeyboardArrowRight>}
        </IconButton>
      </Box>

      <Card
        sx={{
          position: 'absolute',
          right: theme.spacing(2),
          top: theme.spacing(2),
          bottom: theme.spacing(2),
          maxWidth: '450px',
          overflowY: 'auto',
          transition: 'background-color,transform 0.5s ease',
          backgroundColor: isLoadingAndNoError ? theme.palette.grey[100] : undefined,
          transform: showSimulationCard ? undefined : `translateX(470px)`,
          borderRadius: '0px 4px 4px 4px',
        }}
      >
        <Box component="div">
          <Collapse in={isLoadingAndNoError} unmountOnExit>
            <LoadingSimulationIndicator loadingState={loadingState} />
          </Collapse>

          <CardHeader
            title={
              <>
                Simulation Configuration
                <PowerSavingIcon sx={{ marginLeft: theme.spacing(1), verticalAlign: 'middle' }} />
              </>
            }
            avatar={!!AvatarIcon ? <AvatarIcon /> : undefined}
            action={
              <Tooltip title="Reload the simulation">
                <IconButton aria-label="reload" onClick={() => reloadSimulationToolbox()} disabled={loading}>
                  <AnimatedIcon triggerOnClick={true} defaultAnimation="turn-on-itself-reverse">
                    <RefreshIcon />
                  </AnimatedIcon>
                </IconButton>
              </Tooltip>
            }
            sx={{
              paddingBottom: theme.spacing(0.5),
            }}
          ></CardHeader>
          <CardContent
            sx={{
              textAlign: 'left',
            }}
          >
            {error ? (
              <Alert severity="error" sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
                An error happened during the initialization of the simulation
              </Alert>
            ) : null}
            {errorXml ? (
              <Alert severity="error" sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
                An error happened during the computation of the XML circuit, please fix the circuit (it is likely due to
                your layer configuration)
              </Alert>
            ) : null}
            <DisplayErrorTrucksNameWhenNeeded />
            <AlertUsedEmptyStation />
            <AlertUsedNotAllowedName />
            {/* preset simulation  */}
            <PresetSimulationToolbox />

            {(!isSimulationRunning || isSimulationPaused) && (
              <Button
                variant="contained"
                endIcon={<PlayArrowIcon />}
                disabled={(startSimulationRequested && !isSimulationPaused) || !launchSimulationPermission}
                onClick={() => handleStart()}
                fullWidth
              >
                {isSimulationPaused
                  ? 'Resume'
                  : startSimulationRequested && !isSimulationPaused
                    ? 'Will start once loaded'
                    : 'Start'}
              </Button>
            )}
            {isSimulationRunning && !isSimulationPaused && (
              <Button variant="contained" endIcon={<PauseIcon />} onClick={handlePause} fullWidth>
                Pause
              </Button>
            )}
            {!loading && <EnableRobotSimulation reloadSimulation={reloadSimulationToolbox} />}
            <AlertOldDispatcherEnabled reloadSimulationToolbox={reloadSimulationToolbox} />
            <CheckMissingTaxiPoints />
            <Collapse in={robotSmoothingEnabled}>
              <Alert
                severity="info"
                icon={<AnimationIcon fontSize="inherit" />}
                sx={{
                  marginTop: theme.spacing(2),
                }}
              >
                Robot smoothing enabled
              </Alert>
            </Collapse>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(2),
                textAlign: 'center',
              }}
            >
              <Tooltip title="Number of remaining tasks" disableInteractive>
                <Grid item xs={3}>
                  <SlowMotionVideo />
                  <br />
                  <Box component="span" data-testid="remaining-tasks-count">
                    {nbRemainingTasks}
                  </Box>
                </Grid>
              </Tooltip>
              <Tooltip title="Number of tasks done" disableInteractive>
                <Grid item xs={3}>
                  <DoneAllIcon />
                  <br />
                  <Box component="span" data-testid="tasks-done-count">
                    {nbTasksDone}
                  </Box>
                </Grid>
              </Tooltip>
              <Tooltip title="Time elapsed since the beginning of the simulation" disableInteractive>
                <Grid item xs={3}>
                  <TimelapseIcon />
                  <br />
                  <Box component="span" data-testid="simulation-duration">
                    {hhmmssSimulatedTime}
                  </Box>
                </Grid>
              </Tooltip>
              <Tooltip
                title="Instant throughput representing 100% of robot utilisation rate. It is different from the estimated operation throughput displayed in the report."
                disableInteractive
              >
                <Grid item xs={3}>
                  <OnlinePredictionIcon />
                  <br />
                  {currentThroughput.toFixed(1)} t/hr
                </Grid>
              </Tooltip>
            </Grid>
            <Collapse in={isSimulationPaused}>
              <Stack direction="row" spacing={2} justifyContent={'center'}>
                <Button
                  variant="contained"
                  endIcon={<QueryStatsIcon />}
                  onClick={handleGenerateReport}
                  disabled={!launchSimulationPermission}
                >
                  Report
                </Button>
                {isSimulationPaused && <CreateReplayTriggerBtn tasks={tasksHistory} />}
              </Stack>
            </Collapse>
            <Collapse in={isSpeedSlowedDown && speedFactor !== maxSpeedFactorValue && isSimulationRunning}>
              <Alert
                severity="warning"
                icon={<SpeedIcon fontSize="inherit" />}
                sx={{
                  marginTop: theme.spacing(2),
                }}
              >
                The simulation computation is not running at the desired speed.
              </Alert>
            </Collapse>
            <Stack
              rowGap={theme.spacing(1)}
              sx={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
            >
              <Divider sx={{ mt: 1, mb: 1 }} />
              <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                <Speed fontSize="small" />
                <Typography variant="body2" sx={globalFont}>
                  Speed
                </Typography>
              </Stack>
              <ToggleButtonGroup
                value={speedFactor}
                exclusive
                fullWidth
                disabled={!launchSimulationPermission || loading}
              >
                {availableSpeedFactors.map((availableSpeedFactor, i) => (
                  <ToggleButton
                    key={availableSpeedFactor.toString()}
                    value={availableSpeedFactor}
                    onClick={() => handleChangeSpeedFactor(availableSpeedFactor)}
                  >
                    {selectedSpeedFactorIndex === i && currentSpeed < speedFactor ? (
                      <Box component="span" sx={{ color: 'orange' }}>
                        ×{currentSpeed.toFixed(0)}
                      </Box>
                    ) : availableSpeedFactor !== maxSpeedFactorValue ? (
                      `×${availableSpeedFactor}`
                    ) : (
                      'Max'
                    )}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
            <Divider sx={{ mt: 3, mb: 2 }} />
            <TriggersList
              sx={{
                marginTop: theme.spacing(1),
              }}
              defaultExpended
            />
            <Stack rowGap={theme.spacing(1)}>
              <Divider sx={{ mt: 2, mb: 1 }} />
              <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                <PendingActions fontSize="small" />
                <Typography variant="body2" sx={globalFont}>
                  Create task
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 1 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="flows-list">Flows</InputLabel>
                  <Select
                    labelId="flows-list"
                    label="Flows"
                    id="flows-list"
                    value={selectedFlowId || 'random'}
                    onChange={handleSelectFLowId}
                    size="small"
                    disabled={!launchSimulationPermission || loading}
                    fullWidth
                  >
                    {flows.length === 0 ? <MenuItem value={'random'}>Random</MenuItem> : null}
                    {flows.map((flow) => (
                      <MenuItem key={flow.id} value={flow.id}>
                        {flow.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Tooltip
                  title={hasFlowStationsWithoutPositions ? 'This flow have stations without position' : ''}
                  arrow
                >
                  <ButtonGroup
                    variant="outlined"
                    disabled={
                      !launchSimulationPermission || loading || !!creatingTasks || hasFlowStationsWithoutPositions
                    }
                  >
                    <Button onClick={() => handleCreateTasks(1)}>+1</Button>
                    <Button onClick={() => handleCreateTasks(100)}>
                      {creatingTasks === 100 ? (
                        <CircularProgress sx={{ color: theme.palette.action.disabled }} size={14} />
                      ) : (
                        '+100'
                      )}
                    </Button>
                  </ButtonGroup>
                </Tooltip>
              </Stack>
            </Stack>
            <Divider sx={{ mt: 3, mb: 2 }} />
            <ConfigureSimulationParameters
              isSimulationPaused={isSimulationPaused}
              isSimulationRunning={isSimulationRunning}
            />
            {enableAdvancedDisplayTraffic && <DisplayTrafficCollapse getSimulationData={getSimulationData} />}
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
}

interface EnableRobotSimulationProps {
  reloadSimulation?: (reloadPreferences?: boolean) => void;
}
function EnableRobotSimulation(props: EnableRobotSimulationProps): JSX.Element {
  const { reloadSimulation } = props;

  const [atLeastOneRobotSimulated, setAtLeastOneRobotSimulated] = useState<true | false | null>(null);
  const [pendingChange, setPendingChange] = useState(false);

  useEffect(() => {
    if (atLeastOneRobotSimulated === null) {
      let trucksEmulation: string | string[] | undefined;
      try {
        trucksEmulation = PreferencesService.getPreferenceValue(trucksEmulationPrefName);
      } catch (e) {}

      const isTrucksEmulationArray = Array.isArray(trucksEmulation);
      const hasTrucksEmulation = !!(trucksEmulation && isTrucksEmulationArray && trucksEmulation.length > 0);
      const hasAtLeastOneRobotSimulated =
        hasTrucksEmulation && Array.isArray(trucksEmulation) && trucksEmulation.some((el) => el === '1');

      // set the state of atLeastOneRobotSimulated based on the presence of at least one simulated robot
      setAtLeastOneRobotSimulated(hasAtLeastOneRobotSimulated);
    }
  }, [atLeastOneRobotSimulated]);

  const handleEnableAllRobotsSimulation = useCallback(async (): Promise<void> => {
    setPendingChange(true);

    const [ok] = await PreferencesService.setPreferenceValue(
      trucksEmulationPrefName,
      Array(PreferencesService.getTrucks().length).fill('1'),
      true,
      'pref'
    );

    if (ok) {
      setAtLeastOneRobotSimulated(true);

      if (reloadSimulation) {
        reloadSimulation(true);
      }

      SnackbarUtils.success(`Simulation has been enabled for all robots, reloading the simulation module...`);
    } else {
      SnackbarUtils.error(`Error while enabling the simulation for all robots`);
    }

    setPendingChange(false);
  }, [reloadSimulation]);

  if (atLeastOneRobotSimulated === null || atLeastOneRobotSimulated === true) return <></>;

  return (
    <Alert
      severity="warning"
      sx={{
        marginTop: theme.spacing(2),
      }}
    >
      <AlertTitle>No robots have the simulation enabled</AlertTitle>
      <Button
        variant="contained"
        color="warning"
        onClick={handleEnableAllRobotsSimulation}
        disabled={pendingChange}
        sx={{
          textTransform: 'none',
        }}
      >
        Enable simulation for all robots
      </Button>
    </Alert>
  );
}
