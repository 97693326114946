import type { CircuitRack } from 'models/circuit';
import store from 'store';
import { PreferencesService } from 'utils/preferences';

/**Function to get the maximum possible value of pallet overflow in a rack*/
export function getMaxPalletOverflow(rack: CircuitRack | undefined): number {
  if (!rack) return 0;

  const cellTemplates = store.getState().circuit.present.cellTemplates.entities;

  const maxPalletOverflow = rack.properties.columns.reduce((acc, column) => {
    const columnMaxPalletOverflow = column.cells.reduce((acc2, cell) => {
      if (!cell.cellTemplate) return acc2;

      const cellTemplate: (typeof cellTemplates)[0] | undefined = cellTemplates[cell.cellTemplate];
      if (!cellTemplate) {
        // eslint-disable-next-line no-console
        console.error(`Cell template ${cell.cellTemplate} not found`);

        return acc2;
      }

      if (cellTemplate.palletOverflow > acc2) return cellTemplate.palletOverflow;

      return acc2;
    }, 0);
    if (columnMaxPalletOverflow > acc) return columnMaxPalletOverflow;

    return acc;
  }, 0);

  return maxPalletOverflow;
}

/**Function to get the maximum width of pallets in a rack*/
export function getMaxPalletWidth(rack: CircuitRack | undefined): number | undefined {
  if (!rack) return undefined;

  const cellTemplates = store.getState().circuit.present.cellTemplates.entities;

  const palletWidth = rack.properties.columns.reduce((acc, column) => {
    const columnMaxPalletWidth = column.cells.reduce((acc2, cell) => {
      if (!cell.cellTemplate) return acc2;

      const cellTemplate: (typeof cellTemplates)[0] | undefined = cellTemplates[cell.cellTemplate];
      if (!cellTemplate) {
        // eslint-disable-next-line no-console
        console.error(`Cell template ${cell.cellTemplate} not found`);

        return acc2;
      }

      const load = cellTemplate.loads.reduce((acc3, load) => {
        if (load.W > acc3.W) {
          return load;
        }

        return acc3;
      });

      if (load.W > acc2) return load.W;

      return acc2;
    }, 0);
    if (columnMaxPalletWidth > acc) return columnMaxPalletWidth;

    return acc;
  }, 0);

  return palletWidth;
}

/**Function to get the maximum distance beetween a pallet to an upright in a rack*/
export function getMaxPalletToUpright(rack: CircuitRack | undefined): number | undefined {
  if (!rack) return undefined;

  const cellTemplates = store.getState().circuit.present.cellTemplates.entities;

  const palletToUpright = rack.properties.columns.reduce((acc, column) => {
    const columnMaxPalletToUpright = column.cells.reduce((acc2, cell) => {
      if (!cell.cellTemplate) return acc2;

      const cellTemplate: (typeof cellTemplates)[0] | undefined = cellTemplates[cell.cellTemplate];
      if (!cellTemplate) {
        // eslint-disable-next-line no-console
        console.error(`Cell template ${cell.cellTemplate} not found`);

        return acc2;
      }

      const load = cellTemplate.loads.reduce((acc3, load) => {
        if (load.a2 && acc3.a2) {
          if ((load.a1 > acc3.a1 && load.a1 > acc3.a2) || (load.a2 > acc3.a1 && load.a2 > acc3.a2)) {
            return load;
          }
        }

        if (load.a2 && !acc3.a2) {
          if (load.a1 > acc3.a1 || load.a2 > acc3.a1) {
            return load;
          }
        }

        if (!load.a2 && acc3.a2) {
          if (load.a1 > acc3.a1 || load.a1 > acc3.a2) {
            return load;
          }
        }

        if (!load.a2 && !acc3.a2) {
          if (load.a1 > acc3.a1) {
            return load;
          }
        }

        return acc3;
      });

      if (load.a1 > acc2) return load.a1;

      if (load.a2 && load.a2 > acc2) return load.a2;

      return acc2;
    }, 0);
    if (columnMaxPalletToUpright > acc) return columnMaxPalletToUpright;

    return acc;
  }, 0);

  return palletToUpright;
}

/**Function to get the maximum upright Y tolerance in a rack*/
export function getMaxUprightYTolerance(rack: CircuitRack | undefined): number | undefined {
  if (!rack) return undefined;

  const cellTemplates = store.getState().circuit.present.cellTemplates.entities;

  const uprightYTolerance = rack.properties.columns.reduce((acc, column) => {
    const columnMaxUprightYTolerance = column.cells.reduce((acc2, cell) => {
      if (!cell.cellTemplate) return acc2;

      const cellTemplate: (typeof cellTemplates)[0] | undefined = cellTemplates[cell.cellTemplate];
      if (!cellTemplate) {
        // eslint-disable-next-line no-console
        console.error(`Cell template ${cell.cellTemplate} not found`);

        return acc2;
      }

      if (cellTemplate.perception.uprightYTolerance > acc2) return cellTemplate.perception.uprightYTolerance;

      return acc2;
    }, 0);
    if (columnMaxUprightYTolerance > acc) return columnMaxUprightYTolerance;

    return acc;
  }, 0);

  return uprightYTolerance;
}

/**Function to get the right camera fov angle depending on the camera model on the robot*/
export function getCameraFovAngle(serial: string): number {
  const cameraFovAngles = {
    IFM_O3D303: 60,
    Sick_Tmini: 70,
  } as const;
  const defaultCameraFovAngle = cameraFovAngles['IFM_O3D303'];

  let cameraFovAngle: number = defaultCameraFovAngle;
  try {
    const cameraType = PreferencesService.getPreferenceValue('perception/forksCamera/modelName', serial);

    if (typeof cameraType === 'string' && cameraType in cameraFovAngles)
      cameraFovAngle = cameraFovAngles[cameraType as keyof typeof cameraFovAngles];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Could not get camera modelName from preferences, so we will use the default one (IFM)', e);
  }

  return cameraFovAngle;
}
