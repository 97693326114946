import { Bolt, Checklist } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import type { SelectChangeEvent } from '@mui/material';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { CollapseMore } from 'components/utils/collapse-more';
import type { Flow, Station } from 'flows/flows';
import { addStationToFlow } from 'flows/flows';
import { useCallback, useEffect, useState } from 'react';
import { clearAllItineraries } from 'routes/routes';
import { SnackbarUtils } from 'services/snackbar.service';
import {
  computeFlowItineraries,
  getComputingProgressFlowItineraries,
  stopComputingFlowItineraries,
} from 'simulation/check-flows';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { isDefined } from 'utils/ts/is-defined';
import { type FlowCheckResult } from '../flow-configuration';
import { CheckResultAlert } from './check-result-alert';
import { DisplayFlowRobots } from './display-flow-robots';
import { FlowConfigDisplay } from './flow';
import { StepperOfStation } from './stepper-of-station';

interface ListStationsOfFlowProps {
  stations: Station[];
  flow: Flow;
  flowConfigDisplay: FlowConfigDisplay;
  setSelectedTab: (tabToSelect: string) => void;
}
export function ListStationsOfFlow(props: ListStationsOfFlowProps): JSX.Element {
  const { stations, flow, flowConfigDisplay, setSelectedTab } = props;

  const dispatch = useAppDispatch();

  const selectedFlowId = useAppSelector((state) => state.flows.selectedFlowId);
  const availableStations = useAppSelector((state) => state.flows.stations);

  const [selectedStationId, setSelectedStationIdLocal] = useState<string[]>([]);

  const handleSelectChange = useCallback((e: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = e;
    setSelectedStationIdLocal(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  }, []);

  const handleAddStation = useCallback(() => {
    if (selectedStationId.length < 1) return;
    if (!selectedFlowId) return;

    dispatch(
      addStationToFlow({
        flowId: selectedFlowId,
        stationsId: selectedStationId,
      })
    );

    setSelectedStationIdLocal([]);
  }, [dispatch, selectedFlowId, selectedStationId]);

  const [checkingFlow, setCheckingFlow] = useState(false);
  const [checkingFlowProgress, setCheckingFlowProgress] = useState<undefined | number>(undefined);
  const [previousCheckResult, setPreviousCheckResult] = useState<FlowCheckResult | undefined>(undefined);

  useEffect(() => {
    if (!selectedFlowId) return;

    setPreviousCheckResult(undefined);
  }, [selectedFlowId]);

  const updateCheckingFlowProgress = useCallback(() => {
    if (!selectedFlowId) return;

    const progress = getComputingProgressFlowItineraries(selectedFlowId);
    setCheckingFlowProgress(progress);
  }, [selectedFlowId]);

  const checkFlow = useCallback(
    async (displayItineraries: boolean) => {
      if (!selectedFlowId) return;

      setCheckingFlow(true);
      setPreviousCheckResult(undefined);

      const checkProgressInterval = setInterval(updateCheckingFlowProgress, 1000);

      try {
        const res = await computeFlowItineraries({
          flowId: selectedFlowId,
          displayItineraries,
        });

        const itineraries = res?.itineraries?.flat() ?? [];

        const nbItineraries = itineraries.length;
        const issues = itineraries.filter((itinerary) => !itinerary.ok);

        if (res) {
          setPreviousCheckResult({
            nbItineraries,
            issues,
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);

        SnackbarUtils.error('An error occured while checking the flow');
      }

      clearInterval(checkProgressInterval);

      setCheckingFlow(false);
      setCheckingFlowProgress(undefined);
    },
    [selectedFlowId, updateCheckingFlowProgress]
  );
  const stopCheckingFlow = useCallback(() => {
    stopComputingFlowItineraries();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearAllItineraries());
    };
  }, [dispatch]);

  return (
    <>
      {flowConfigDisplay === FlowConfigDisplay.Default ? (
        <>
          <CollapseMore
            title={
              <Stack direction="row" spacing={2} sx={{ pt: 0.6 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600, color: theme.palette.grey[700], mt: 2 }}>
                  Stations
                </Typography>
              </Stack>
            }
            hasDivider={false}
            defaultExpended={true}
          >
            <StepperOfStation
              flow={flow}
              stations={stations}
              flowConfigDisplay={flowConfigDisplay}
              setSelectedTab={setSelectedTab}
            />
          </CollapseMore>
        </>
      ) : (
        <>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            <FormControl size="small" fullWidth>
              <InputLabel id="stations-list-label">Stations</InputLabel>
              <Select<string[]>
                multiple
                value={selectedStationId}
                renderValue={(selected) =>
                  selected
                    .map((selectedId) => {
                      const selectedStationsName = availableStations
                        .map((station) => {
                          if (selectedId === station.id) {
                            return station.name;
                          }

                          return undefined;
                        })
                        .filter(isDefined);

                      return selectedStationsName;
                    })
                    .join(', ')
                }
                size="small"
                labelId="stations-list-label"
                label="Stations"
                id="stations-list"
                onChange={handleSelectChange}
              >
                {availableStations.map((station) => (
                  <MenuItem key={station.id} value={station.id}>
                    <Checkbox checked={selectedStationId.indexOf(station.id) > -1} />
                    <ListItemText primary={station.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Tooltip title="Add station to the flow">
              <Box component="span">
                <IconButton
                  aria-label="add station"
                  color="primary"
                  disabled={!selectedStationId}
                  onClick={handleAddStation}
                >
                  <AddCircleIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Stack>
          <StepperOfStation
            flow={flow}
            stations={stations}
            flowConfigDisplay={flowConfigDisplay}
            setSelectedTab={setSelectedTab}
          />
        </>
      )}

      <DisplayFlowRobots flow={flow} flowConfigDisplay={flowConfigDisplay} />
      <Divider variant="middle" sx={{ mt: 2, mb: 0.5 }} />
      <ButtonGroup
        sx={{
          width: '100%',
          marginTop: theme.spacing(1),
        }}
      >
        <Button
          fullWidth
          onClick={() => checkFlow(true)}
          disabled={!selectedFlowId || checkingFlow}
          startIcon={<Checklist />}
          variant="outlined"
        >
          Check flow
        </Button>
        {!checkingFlow && (
          <Tooltip title="Check flow without displaying itineraries (much faster on large projects)">
            <span>
              <Button onClick={() => checkFlow(false)}>
                <Bolt />
              </Button>
            </span>
          </Tooltip>
        )}
        {checkingFlow && (
          <Tooltip title="Abort the computation">
            <span>
              <Button onClick={stopCheckingFlow} disabled={!checkingFlowProgress}>
                <ClearIcon />
              </Button>
            </span>
          </Tooltip>
        )}
      </ButtonGroup>
      {checkingFlow && (
        <LinearProgress
          value={checkingFlow && checkingFlowProgress !== undefined ? checkingFlowProgress * 100 : undefined}
          variant={checkingFlowProgress ? 'determinate' : 'indeterminate'}
          sx={{
            marginTop: '-4px',
          }}
          color={'inherit'}
        />
      )}

      {previousCheckResult && <CheckResultAlert checkResult={previousCheckResult} />}
    </>
  );
}
