import { projectHost, remoteDoc } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { PreferencesService } from 'utils/preferences';
import type { YText } from 'yjs/dist/src/internals';

export const useXmlTruckSubscription = (): void => {
  const xmlTruckText = remoteDoc?.getText('xmlTruckUpdate') as YText;
  const prefMap = remoteDoc?.getMap('pref');

  useLayoutEffect(() => {
    const observerHandle = (event, transaction): void => {
      const isTransactionLocal = transaction.origin === 'local';
      if (isTransactionLocal || !prefMap) return;

      if (!PreferencesService.arePreferencesFullyLoaded()) return;

      const xmls = prefMap.get('xmls');
      PreferencesService.setTrucksDocumentFromYJS({ xmls });

      if (store.getState().dialog.open && store.getState().dialog.type === 'robot-data') {
        SnackbarUtils.info('A user updated a preference', {
          autoHideDuration: 3000,
        });
      }

      document.dispatchEvent(new CustomEvent('update-robot-remote'));

      if (!projectHost) return;
      PreferencesService.saveYJSPreferences(xmlTruckText.toString());
    };

    xmlTruckText.observe(observerHandle);

    return () => {
      xmlTruckText.unobserve(observerHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
