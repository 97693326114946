import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { deleteMissingPositionsInStations } from 'flows/check-positions';
import type { EditorMode } from './model';

export const performanceModeKey = 'settings.performance-mode';
export const render2ModeKey = 'settings.render2-mode';
export const showPerformancesKey = 'settings.show-performances-mode';

export interface EditorSliceState {
  /** current mode for the editor */
  editorMode: EditorMode;
  /** Whether or not we enable the performance mode */
  isPerformanceModeEnabled: boolean;
  /** Whether or not to use render2 instead of render1 as the display engine */
  isRender2Enabled: boolean;
  /** Whether or not we show performances */
  isShowPerformancesEnabled: boolean;
  /** Whether or not we are computing layer lidar gabarits */
  shouldComputeLayerLidarGabarits: boolean;
}

const VITE_FEATURE_FLAG_LIBCIR = import.meta.env.VITE_FEATURE_FLAG_LIBCIR === 'true';

if (localStorage[render2ModeKey] !== undefined && !VITE_FEATURE_FLAG_LIBCIR) {
  delete localStorage[render2ModeKey];
}

const VITE_SHOW_PERFORMANCE = import.meta.env.VITE_SHOW_PERFORMANCE === 'true';

if (localStorage[showPerformancesKey] !== undefined && !VITE_SHOW_PERFORMANCE) {
  delete localStorage[showPerformancesKey];
}

// All modes are disabled by default
const initialState: EditorSliceState = {
  editorMode: 'circuit',
  isPerformanceModeEnabled: localStorage[performanceModeKey] ? localStorage[performanceModeKey] === 'true' : false,
  isRender2Enabled: localStorage[render2ModeKey] ? localStorage[render2ModeKey] === 'true' : false,
  isShowPerformancesEnabled: localStorage[showPerformancesKey] ? localStorage[showPerformancesKey] === 'true' : false,
  shouldComputeLayerLidarGabarits: false,
};

const editorSlicePrivate = createSlice({
  initialState,
  name: 'editor',
  reducers: {
    setEditorMode: (state, action: PayloadAction<{ editorMode: EditorMode }>) => {
      state.editorMode = action.payload.editorMode;

      if (action.payload.editorMode === 'flow') {
        // if shapes have been deleted in circuit edition mode, we need to remove them from the stations
        setTimeout(() => deleteMissingPositionsInStations(), 0);
      }
    },
    setPerformanceMode: (state, action: PayloadAction<{ performanceMode: boolean }>) => {
      state.isPerformanceModeEnabled = action.payload.performanceMode;
      localStorage[performanceModeKey] = action.payload.performanceMode;
    },
    setRender2Mode: (state, action: PayloadAction<{ enableRender2: boolean }>) => {
      state.isRender2Enabled = action.payload.enableRender2;
      localStorage[render2ModeKey] = action.payload.enableRender2;
    },
    setShowPerformances: (state, action: PayloadAction<{ showPerformances: boolean }>) => {
      state.isShowPerformancesEnabled = action.payload.showPerformances;
      localStorage[showPerformancesKey] = action.payload.showPerformances;
    },
    setComputingLayerLidarGabarits: (state, action: PayloadAction<{ computingLayerLidarGabarits: boolean }>) => {
      state.shouldComputeLayerLidarGabarits = action.payload.computingLayerLidarGabarits;
    },
  },
});

export const editorSlice = {
  reducer: editorSlicePrivate.reducer,
  name: editorSlicePrivate.name,
};

export const {
  setEditorMode,
  setPerformanceMode,
  setRender2Mode,
  setShowPerformances,
  setComputingLayerLidarGabarits,
} = editorSlicePrivate.actions;
