import store from 'store';
import { normalizeAngleToMinusPiPi, toRad } from 'utils/helpers';
import { canBeUndefined } from 'utils/ts/is-defined';
import type { PositionItinerary } from './routes';
import { setNextItineraryPosition } from './routes';

/**
 * Selects route points based on their names and sets them as the next itinerary positions.
 *
 * This function searches for points in the circuit state by their names, and if found,
 * sets them as the 'from' and 'to' positions for the next itinerary.
 *
 * @param {string} pointNameFrom - The name of the starting point.
 * @param {string} pointNameTo - The name of the destination point.
 *
 * @returns {boolean} - Returns true if both points are found and set, false otherwise.
 *
 * @example
 * const success = selectRoutePoints('StartPoint', 'EndPoint');
 * if (success) {
 *   console.log('Points selected successfully');
 * } else {
 *   console.log('Failed to select points');
 * }
 */

export function selectRoutePoints(pointNameFrom: string, pointNameTo: string): boolean {
  const pointsSlice = store.getState().circuit.present.points;
  const pointsIds = pointsSlice.ids;
  const points = pointsSlice.entities;

  const pointFromId = pointsIds.find((id) => points[id].properties.name === pointNameFrom);
  const pointToId = pointsIds.find((id) => points[id].properties.name === pointNameTo);

  if (!pointFromId || !pointToId) {
    return false;
  }

  const pointFrom = canBeUndefined(points[pointFromId]);
  const pointTo = canBeUndefined(points[pointToId]);

  if (!pointFrom || !pointTo) {
    return false;
  }

  const from: PositionItinerary = {
    x: pointFrom.geometry.coordinates[0],
    y: pointFrom.geometry.coordinates[1],
    heading: normalizeAngleToMinusPiPi(toRad(pointFrom.properties.orientation)),
  };

  const to: PositionItinerary = {
    x: pointTo.geometry.coordinates[0],
    y: pointTo.geometry.coordinates[1],
    heading: normalizeAngleToMinusPiPi(toRad(pointTo.properties.orientation)),
  };

  store.dispatch(setNextItineraryPosition({ from, to }));

  return true;
}
