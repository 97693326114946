import HelpIcon from '@mui/icons-material/Help';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { Box, Button, ListItem, Menu, MenuItem, MenuList, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import { clearShapesSelectionAction } from 'actions/circuit';
import { CorneredButton } from 'components/utils/cornered-button';
import { copyPredefinedShapesAction } from 'drawings/copy-paste';
import type { GeoJsonCircuit } from 'models/circuit';
import { Tools } from 'models/tools';
import { useState } from 'react';
import { isGeoJsonCircuit } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAppDispatch } from 'store';
import { CIRCUIT_LIBRARY_URL } from 'utils/config';
import { theme } from 'utils/mui-theme';
import defaultPredefinedShapes from '../../predefinedShapes/defaultPredefinedShapes.json';

interface PredefinedShapesMenuProps {
  setDisplayPredefinedShapesToolTip: React.Dispatch<React.SetStateAction<boolean>>;
  editCircuitPerm: boolean | undefined;
  toolClickHandler: (tool: Tools) => void;
  tool: Tools;
  activeTool: Tools | undefined;
  marginBottomValue: number;
}

export function PredefinedShapesMenu(props: PredefinedShapesMenuProps): JSX.Element {
  const { setDisplayPredefinedShapesToolTip, editCircuitPerm, toolClickHandler, tool, activeTool, marginBottomValue } =
    props;
  const [displayMenuPredefinedShapes, setDisplayMenuPredefinedShapes] = useState(false);
  const dispatch = useAppDispatch();

  if (!isGeoJsonCircuit(defaultPredefinedShapes)) {
    // eslint-disable-next-line no-console
    console.error('The imported circuit is not a GeoJsonCircuit');

    return <></>;
  }

  const defaultPredefinedShapesTyped = defaultPredefinedShapes as GeoJsonCircuit;

  const defaultShapes = defaultPredefinedShapesTyped.features;

  return (
    <div>
      <Tooltip title={!editCircuitPerm ? 'You do not have the permission to edit circuits' : ''}>
        <Box component="span">
          <ListItem sx={{ padding: 0, marginBottom: marginBottomValue }} disabled={!editCircuitPerm}>
            <CorneredButton
              id="button-predefined-shapes"
              onMouseEnter={() => {
                setDisplayPredefinedShapesToolTip(true);
              }}
              onMouseLeave={() => setDisplayPredefinedShapesToolTip(false)}
              onClick={() => {
                toolClickHandler(tool);
                setDisplayMenuPredefinedShapes(true);
                dispatch(clearShapesSelectionAction());
              }}
              data-testid="button-predefined-shapes"
              color="inherit"
            >
              <WidgetsIcon color={tool === activeTool ? 'primary' : undefined} />
            </CorneredButton>
          </ListItem>
        </Box>
      </Tooltip>

      <Menu
        anchorEl={document.querySelector(`#button-${Tools.Move}`)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={displayMenuPredefinedShapes && tool === activeTool}
        MenuListProps={{
          sx: { paddingTop: 0, paddingBottom: 0 },
        }}
        onClose={() => {
          setDisplayMenuPredefinedShapes(false);
          toolClickHandler(Tools.Move);
        }}
        slotProps={{
          paper: {
            style: {
              marginLeft: theme.spacing(1.5),
            },
          },
        }}
        keepMounted
      >
        <MenuList>
          <Grid container sx={{ margin: theme.spacing(1) }}>
            <Grid item xs={1}>
              <WidgetsIcon />
            </Grid>
            <Grid item xs={9} sx={{ textAlign: 'center' }}>
              Predefined Shapes
            </Grid>
            <Grid item xs={1}>
              <Tooltip
                title={
                  <Box component="div" padding={1}>
                    <div>Set of good-practice predefined templates.</div>
                    <Button
                      href={CIRCUIT_LIBRARY_URL}
                      target="_blank"
                      color="primary"
                      variant="contained"
                      endIcon={<OpenInNewIcon />}
                      size="small"
                      sx={{ marginTop: 1 }}
                    >
                      Documentation
                    </Button>
                  </Box>
                }
              >
                <HelpIcon />
              </Tooltip>
            </Grid>
          </Grid>
          {defaultShapes.map((defaultShape) => {
            const selectedShapes = defaultShape.features;

            return (
              <MenuItem
                key={defaultShape.id}
                sx={{ margin: 1, fontSize: '0.875rem' }}
                onClick={() => {
                  dispatch(
                    copyPredefinedShapesAction({
                      predefinedShapes: selectedShapes,
                      defaultPredefinedShapes: defaultPredefinedShapesTyped,
                    })
                  );
                  SnackbarUtils.info(
                    `${defaultShape.properties.name} template copied to clipboard and ready to be pasted`
                  );
                  setDisplayMenuPredefinedShapes(false);
                  toolClickHandler(Tools.Move);
                }}
              >
                {defaultShape.properties.name}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
}
