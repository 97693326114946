import { SwapHoriz } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material';
import { Border } from 'components/utils/border';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useCallback } from 'react';
import { useAppSelector } from 'store';
import { isDefined } from 'utils/ts/is-defined';
import type { PointPropertiesFormValue } from '../point-properties/point-properties';

interface TeleporterInputProps {
  formProperties: PointPropertiesFormValue;
  changeFormPropertiesValue: (value: (value: PointPropertiesFormValue) => PointPropertiesFormValue) => void;
  locked: boolean;
}

export function TeleporterInput({
  formProperties,
  changeFormPropertiesValue,
  locked,
}: TeleporterInputProps): JSX.Element {
  const zonesIds = useAppSelector((state) => state.circuit.present.zones.ids);
  const zones = useAppSelector((state) => state.circuit.present.zones.entities);

  const allFloorLevelAvailable = zonesIds
    .map((id) => {
      const zone = zones[id];
      const floorNameIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'FloorName');

      if (floorNameIndex !== -1) {
        return zone.properties.rules[floorNameIndex][1];
      }

      return undefined;
    })
    .filter(isDefined);

  const uniqueAllFloorLevelAvailable = [...new Set(allFloorLevelAvailable)];

  const allElevatorNameAvailable = zonesIds
    .map((id) => {
      const zone = zones[id];
      const elevatorNameIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'ElevatorName');

      if (elevatorNameIndex !== -1) {
        return zone.properties.rules[elevatorNameIndex][1];
      }

      return undefined;
    })
    .filter(isDefined);

  const uniqueAllElevatorNameAvailable = [...new Set(allElevatorNameAvailable)];

  const handleTeleportationChange = useCallback(
    (event) => {
      const newTeleportation = !formProperties.isTeleportation;
      changeFormPropertiesValue((state) => ({ ...state, isTeleportation: newTeleportation }));
    },
    [changeFormPropertiesValue, formProperties.isTeleportation]
  );

  const onFloorLevelChangeHandler = useCallback(
    (e: SelectChangeEvent) => {
      changeFormPropertiesValue((state) => ({ ...state, floorLevel: e.target.value }));
    },
    [changeFormPropertiesValue]
  );

  const handleElevatorNameChange = useCallback(
    (e: SelectChangeEvent) => {
      changeFormPropertiesValue((state) => ({ ...state, elevatorName: e.target.value }));
    },
    [changeFormPropertiesValue]
  );

  return (
    <>
      <ListItemButton disabled={locked} onClick={handleTeleportationChange}>
        <ListItemIcon>
          <SwapHoriz />
        </ListItemIcon>
        <ListItemText
          id="switch-list-label-teleportation"
          primary={
            <>
              Teleportation <HelpIconTooltip title="Where the robot should stop inside the elevators" />
            </>
          }
        />
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            checked={formProperties.isTeleportation}
            inputProps={{ 'aria-labelledby': 'switch-list-label-teleportation' }}
            disabled={locked}
            tabIndex={-1}
          />
        </ListItemSecondaryAction>
      </ListItemButton>
      <Collapse in={formProperties.isTeleportation} timeout="auto" unmountOnExit>
        <Border>
          <Box component={'div'} display={'flex'} flexDirection={'column'}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink={true} error={!formProperties.floorLevel}>
                Floor level{' '}
                <HelpIconTooltip title="The floor level should match the elevator floor level of the zone which contains the point" />
              </InputLabel>
              <Select
                value={formProperties.floorLevel}
                onChange={onFloorLevelChangeHandler}
                disabled={locked || uniqueAllFloorLevelAvailable.length < 1}
                error={!formProperties.floorLevel}
              >
                {uniqueAllFloorLevelAvailable.map((floorLevel) => (
                  <MenuItem key={floorLevel} value={floorLevel}>
                    {floorLevel}
                  </MenuItem>
                ))}
              </Select>
              {uniqueAllFloorLevelAvailable.length < 1 && <FormHelperText>No floor level defined</FormHelperText>}
            </FormControl>
            <FormControl fullWidth variant="standard" sx={{ marginTop: 1 }}>
              <InputLabel shrink={true} error={!formProperties.elevatorName}>
                Elevator name{' '}
                <HelpIconTooltip title="The elevator name should match the elevator name of the zone which contains the point" />
              </InputLabel>
              <Select
                value={formProperties.elevatorName}
                onChange={handleElevatorNameChange}
                disabled={locked || uniqueAllElevatorNameAvailable.length < 1}
                error={!formProperties.elevatorName}
              >
                {uniqueAllElevatorNameAvailable.map((elevatorName) => (
                  <MenuItem key={elevatorName} value={elevatorName}>
                    {elevatorName}
                  </MenuItem>
                ))}
              </Select>
              {uniqueAllElevatorNameAvailable.length < 1 && <FormHelperText>No elevator defined</FormHelperText>}
            </FormControl>
          </Box>
        </Border>
      </Collapse>
    </>
  );
}
