import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import type { StationPositionType } from 'flows/flows';
import { addStation, setSelectedStationId } from 'flows/flows';
import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { StationForm } from './station-form';
import { StationListAccordion } from './stations-list-accordion';

export enum StationConfigDisplay {
  Default,
  Edition,
  Creation,
}

export type StationPositionTypeAndAll = StationPositionType | 'all' | 'conveyors';

export function StationsToolbox(): JSX.Element {
  const stations = useAppSelector((state) => state.flows.stations);
  const selectedStationId = useAppSelector((state) => state.flows.selectedStationId);

  const dispatch = useAppDispatch();

  const [stationConfigDisplay, setStationConfigDisplay] = useState(StationConfigDisplay.Default);

  const selectedStation = useMemo(() => {
    return stations.find((station) => station.id === selectedStationId);
  }, [selectedStationId, stations]);

  const handleCreateStation = useCallback(() => {
    let newStationName = 'New Station';
    let nb = 1;
    // eslint-disable-next-line no-loop-func
    while (stations.find((station) => station.name === newStationName)) {
      newStationName = `New Station ${++nb}`;
    }

    const newStationId = generateShapeId();

    dispatch(
      addStation({
        name: newStationName,
        id: newStationId,
        positions: [],
      })
    );

    dispatch(setSelectedStationId(newStationId));

    setStationConfigDisplay(StationConfigDisplay.Creation);
  }, [dispatch, stations]);

  return (
    <Stack
      sx={{
        textAlign: 'left',
      }}
    >
      {stationConfigDisplay === StationConfigDisplay.Default && (
        <Stack direction="row" spacing={1} sx={{ pb: 2, pr: 1 }}>
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={handleCreateStation}
            endIcon={<Add />}
            sx={{
              marginTop: (theme) => theme.spacing(1),
            }}
          >
            Create station
          </Button>
        </Stack>
      )}

      {stationConfigDisplay !== StationConfigDisplay.Default && selectedStation && (
        <StationForm stationConfigDisplay={stationConfigDisplay} setStationConfigDisplay={setStationConfigDisplay} />
      )}

      {stationConfigDisplay === StationConfigDisplay.Default && (
        <StationListAccordion setStationConfigDisplay={setStationConfigDisplay} />
      )}
    </Stack>
  );
}
