import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, TextField, Tooltip } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { Flow } from 'flows/flows';
import { setFlow } from 'flows/flows';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'store';
import { formatSecondsToHHMMSS } from 'utils/time';

interface MaximumTaskTimeTextfieldProps {
  selectedFlowId: string;
  selectedFlow: Flow;
}
export function MaximumTaskTimeTextfield(props: MaximumTaskTimeTextfieldProps): JSX.Element {
  const { selectedFlowId, selectedFlow } = props;

  const dispatch = useAppDispatch();

  const maximumTaskTimeInputRef = useRef<HTMLInputElement>(null);

  const minMaxTaskTimeValue = 0;

  const handleChangeMaxTime = useCallback(() => {
    if (!selectedFlow) return;
    const input = maximumTaskTimeInputRef.current;
    if (!input) return;

    const maximumTaskTime = input.valueAsNumber;
    let maximumTaskTimeOk = true;
    if (isNaN(maximumTaskTime)) maximumTaskTimeOk = false;
    else if (maximumTaskTime < minMaxTaskTimeValue) maximumTaskTimeOk = false;

    if (!maximumTaskTimeOk) {
      input.value = '';

      dispatch(
        setFlow({
          ...selectedFlow,
          maximumTaskTime: undefined,
        })
      );

      return;
    }

    dispatch(
      setFlow({
        ...selectedFlow,
        maximumTaskTime,
      })
    );
  }, [dispatch, selectedFlow]);

  const [maximumTaskTimeForTooltip, setMaximumTaskTimeForTooltip] = useState<number | undefined>(
    selectedFlow?.maximumTaskTime
  );
  const [inputFocus, setInputFocus] = useState(false);

  useEffect(() => {
    setMaximumTaskTimeForTooltip(selectedFlow?.maximumTaskTime);
  }, [selectedFlow?.maximumTaskTime]);

  const maximumTaskTimeFormatted =
    maximumTaskTimeForTooltip !== undefined ? formatSecondsToHHMMSS(maximumTaskTimeForTooltip) : '';

  return (
    <Tooltip
      title={maximumTaskTimeFormatted}
      arrow
      open={inputFocus && !!maximumTaskTimeForTooltip && !isNaN(maximumTaskTimeForTooltip)}
    >
      <TextField
        key={selectedFlowId}
        type="number"
        label={
          <>
            Maximum Task Time <HelpIconTooltip title="Maximum task time for the flows in seconds (optional)" />
          </>
        }
        variant="outlined"
        fullWidth
        size="small"
        defaultValue={selectedFlow?.maximumTaskTime}
        inputProps={{ min: minMaxTaskTimeValue, step: 1 }}
        InputProps={{
          endAdornment: (
            <>
              <IconButton
                onClick={(e) => {
                  if (maximumTaskTimeInputRef.current) {
                    maximumTaskTimeInputRef.current.value = '';
                    handleChangeMaxTime();
                  }
                }}
                size="small"
              >
                <ClearIcon fontSize="small" />
              </IconButton>
              s
            </>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onFocus={() => setInputFocus(true)}
        onBlur={() => {
          setInputFocus(false);
          handleChangeMaxTime();
        }}
        onChange={(e) => {
          const input = e.target;
          if (!(input instanceof HTMLInputElement)) return;
          setMaximumTaskTimeForTooltip(input.valueAsNumber);
        }}
        inputRef={maximumTaskTimeInputRef}
      />
    </Tooltip>
  );
}
