import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton, LinearProgress } from '@mui/material';
import type { SnackbarKey } from 'notistack';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { SnackbarUtils } from 'services/snackbar.service';
import { isSavingProject, resetSnackbarLoadingKey, setIsSavingProject, snackbarLoadingKey } from './save-project';

let snackbarElementRoot: ReactDOM.Root | undefined;

export function createSavingProjectSnackbar(): SnackbarKey {
  return SnackbarUtils.toast(
    '',
    {
      content: (
        <Box
          component="div"
          className="SnackbarContent-root SnackbarItem-contentRoot SnackbarItem-variantInfo SnackbarItem-lessPadding snackbarSavingProject"
          sx={{
            padding: '0 !important',
            width: '288px',
            transition:
              'background-color 500ms ease-in-out 500ms, transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !important',
          }}
        >
          <Box
            component="div"
            sx={{
              position: 'relative',
              padding: '6px 16px 6px 20px',
            }}
          >
            <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }} color="info" />
            <Box
              component="div"
              sx={{
                paddingTop: '10px',
                paddingBottom: '10px',
                display: 'flex',
                columnGap: '8px',
                alignItems: 'center',
              }}
            >
              <InfoIcon fontSize="small" />
              Saving project
            </Box>
          </Box>
        </Box>
      ),
      variant: 'info',
      persist: true,
    },
    false
  );
}

export function SnackbarSuccess(): JSX.Element {
  const [progress, setProgress] = useState(0);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);

          setTimeout(() => {
            setSaved(true);
          }, 500);

          return 100;
        }

        const diff = Math.random() * 10;

        return Math.min(oldProgress + diff, 100);
      });
    }, 5);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box
      component="div"
      sx={{
        padding: '0 16px 0 20px',
        position: 'relative',
      }}
    >
      <LinearProgress
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          transition: 'opacity 150ms ease-in-out',
          opacity: saved ? 0 : 1,
        }}
        variant="determinate"
        value={progress}
        color={saved ? 'success' : 'info'}
      />
      {saved ? (
        <Box
          component="div"
          sx={{
            paddingTop: '6px',
            paddingBottom: '6px',
            display: 'flex',
            columnGap: '8px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box component="div" sx={{ display: 'flex', columnGap: '8px' }}>
            <CheckCircleIcon fontSize="small" />
            Project saved successfully
          </Box>
          <IconButton
            onClick={() => {
              SnackbarUtils.closeSnackbar(snackbarLoadingKey);
              resetSnackbarLoadingKey();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ) : (
        <Box
          component="div"
          sx={{
            paddingTop: '16px',
            paddingBottom: '16px',
            display: 'flex',
            columnGap: '8px',
            alignItems: 'center',
          }}
        >
          <InfoIcon fontSize="small" />
          Saving project
        </Box>
      )}
    </Box>
  );
}

export function changeLoadingToSuccessSnackbar(): void {
  const snackbarElementArray = document.querySelectorAll('.snackbarSavingProject');
  const snackbarElement = snackbarElementArray[snackbarElementArray.length - 1];
  if (snackbarElement instanceof HTMLElement) {
    if (snackbarElementRoot) snackbarElementRoot.unmount();
    snackbarElementRoot = ReactDOM.createRoot(snackbarElement);

    /* Change snackbar to success state */
    snackbarElement.innerHTML = '';
    snackbarElement.style.backgroundColor = 'rgb(67, 160, 71)';
    snackbarElementRoot.render(<SnackbarSuccess />);

    /* Await success animation to finish */
    setTimeout(() => {
      setIsSavingProject(false);

      /* Auto hide the snackbar after 3 seconds */
      setTimeout(() => {
        if (!snackbarLoadingKey || isSavingProject) return;
        SnackbarUtils.closeSnackbar(snackbarLoadingKey);
        resetSnackbarLoadingKey();
      }, 3000);
    }, 1250);
  }
}
