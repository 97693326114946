import { Avatar, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { RobotsList } from 'components/toolboxes/simulation/robot-list';
import { useRobotsEmulation } from 'components/toolboxes/simulation/use-set-robots';
import { CollapseMore } from 'components/utils/collapse-more';
import type { Flow } from 'flows/flows';
import { useMemo } from 'react';
import { theme } from 'utils/mui-theme';
import { FlowConfigDisplay } from './flow';

interface DisplayFlowRobotsProps {
  flow: Flow;
  flowConfigDisplay: FlowConfigDisplay;
}
export function DisplayFlowRobots(props: DisplayFlowRobotsProps): JSX.Element {
  const { flow, flowConfigDisplay } = props;

  const [robots] = useRobotsEmulation();

  const getRobotsOfFlow = useMemo(() => {
    if (flow.robotsAssigned === 'all') {
      return robots;
    }

    return robots.filter((robot) => flow.robotsAssigned?.includes(robot.serial));
  }, [flow.robotsAssigned, robots]);

  if (flowConfigDisplay !== FlowConfigDisplay.Default) {
    return (
      <>
        <Stack direction="row" spacing={2} sx={{ pt: 0.6 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 600, color: theme.palette.grey[700], mt: 3 }}>
            Robots assignment
          </Typography>
        </Stack>
        <RobotsList flow={flow} useCollapse={false} mode="flow-assignation" />
      </>
    );
  }

  return (
    <CollapseMore
      title={
        <Stack direction="row" spacing={2} sx={{ pt: 0.6 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 600, color: theme.palette.grey[700], mt: 3 }}>
            Robots assigned
          </Typography>
        </Stack>
      }
      hasDivider={false}
    >
      <List dense sx={{ maxHeight: '250px', overflowY: 'auto' }}>
        {getRobotsOfFlow.map((robot, robotIndex) => (
          <ListItem key={robot.serial}>
            <ListItemIcon>
              <Avatar src={`/img/trucks/${robot.picturePath}-icon.png`} sx={{ width: 24, height: 24 }} />
            </ListItemIcon>
            <ListItemText primary={robot.name} secondary={robot.modelName} />
          </ListItem>
        ))}
      </List>
      {getRobotsOfFlow.length === 0 && (
        <Typography variant="subtitle2" sx={{ color: theme.palette.grey[700] }}>
          No robot assigned
        </Typography>
      )}
    </CollapseMore>
  );
}
