import { Table, TableBody, TableHead } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import { StyledTableCell, StyledTableRow } from 'simulation/styled-table';
import { theme } from 'utils/mui-theme';
import type { FlowsStats } from './report';

interface FlowThroughputTableProps {
  elapsedTimeSimulation: number;
  utilizationRateValue: string;
  flowsStats: FlowsStats;
  simplifiedMode: boolean;
}

export function FlowThroughputTable(props: FlowThroughputTableProps): JSX.Element {
  const { elapsedTimeSimulation, utilizationRateValue, flowsStats, simplifiedMode } = props;

  const totalFlowsStats = useMemo(() => {
    const objective = flowsStats.reduce((acc, flow) => acc + flow.objective, 0);
    const objectiveInPalPerHr = flowsStats.reduce((acc, flow) => acc + flow.objective * flow.palletsPerTask, 0);
    const palletsPerTask = flowsStats.reduce((acc, flow) => acc + flow.palletsPerTask, 0);
    const nbTasks = flowsStats.reduce((acc, flow) => acc + flow.nbTasks, 0);
    const nbFinishedTasks = flowsStats.reduce((acc, flow) => acc + flow.nbFinishedTasks, 0);

    const throughput = nbFinishedTasks / (elapsedTimeSimulation / 60 / 60); // tasks per hour
    const throughputWithUtilizationRate = throughput * Number(utilizationRateValue);

    const result = flowsStats.reduce((acc, flow) => {
      const throughputResult = flow.nbFinishedTasks / (elapsedTimeSimulation / 60 / 60);
      const throughputWithUtilizationRateResult = throughputResult * Number(utilizationRateValue);

      return acc + flow.palletsPerTask * throughputWithUtilizationRateResult;
    }, 0);

    const deltaWithUtilizationRate = objective > 0 ? throughputWithUtilizationRate / objective : null;

    return {
      objectiveInPalPerHr,
      palletsPerTask,
      nbTasks,
      nbFinishedTasks,
      throughput,
      throughputWithUtilizationRate,
      deltaWithUtilizationRate,
      result,
    };
  }, [elapsedTimeSimulation, flowsStats, utilizationRateValue]);

  return (
    <Table size="small">
      <TableHead key="flow-throughputs-header">
        <StyledTableRow>
          <StyledTableCell>Name</StyledTableCell>
          <StyledTableCell>Created Tasks</StyledTableCell>
          <StyledTableCell>Finished Tasks</StyledTableCell>
          {!simplifiedMode && (
            <>
              <StyledTableCell>Ratio pal/task</StyledTableCell>
              <StyledTableCell>Task Throughput</StyledTableCell>
            </>
          )}
          <StyledTableCell>Throughput</StyledTableCell>
          <StyledTableCell>Objective</StyledTableCell>
          <StyledTableCell>Δ</StyledTableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {flowsStats.map((flowStat) => {
          const { nbFinishedTasks } = flowStat;

          let throughput = nbFinishedTasks / (elapsedTimeSimulation / 60 / 60); // tasks per hour
          let throughputWithUtilizationRate = throughput * Number(utilizationRateValue);

          const objective = flowStat.objective ?? 0;

          const palletsPerTask = flowStat.palletsPerTask;

          const objectiveInPalPerHr = objective * palletsPerTask;

          const result = palletsPerTask * throughputWithUtilizationRate;

          const deltaWithUtilizationRate = objective > 0 ? throughputWithUtilizationRate / objective : null;

          if (isNaN(throughput)) throughput = 0;
          if (isNaN(throughputWithUtilizationRate)) throughputWithUtilizationRate = 0;

          return (
            <StyledTableRow key={flowStat.id}>
              <StyledTableCell>{flowStat.name}</StyledTableCell>
              <StyledTableCell>{flowStat.nbTasks}</StyledTableCell>
              <StyledTableCell>{flowStat.nbFinishedTasks}</StyledTableCell>
              {!simplifiedMode && (
                <>
                  <StyledTableCell>{palletsPerTask}</StyledTableCell>
                  <StyledTableCell>{throughputWithUtilizationRate.toFixed(2)} tasks/hr</StyledTableCell>
                </>
              )}

              <StyledTableCell>
                <Box component="span" data-testid="total-flows-throughput">
                  {result.toFixed(2)}
                </Box>
                {' pal/hr'}
              </StyledTableCell>
              <StyledTableCell>{objectiveInPalPerHr.toFixed(2)} pal/hr</StyledTableCell>
              <StyledTableCell
                sx={{
                  boxShadow: deltaScoreToCssBoxShadow(deltaWithUtilizationRate),
                }}
              >
                {deltaWithUtilizationRate !== null ? `${(deltaWithUtilizationRate * 100).toFixed(2)}%` : 'N/A'}
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
      <TableHead key="flow-throughputs-footer">
        <StyledTableRow>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell>
            <Box component="span" data-testid="total-tasks-count">
              {totalFlowsStats.nbTasks}
            </Box>
          </StyledTableCell>
          <StyledTableCell>
            <Box component="span" data-testid="total-finished-tasks-count">
              {totalFlowsStats.nbFinishedTasks}
            </Box>
          </StyledTableCell>
          {!simplifiedMode && (
            <>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>
                {(totalFlowsStats.throughputWithUtilizationRate || 0).toFixed(2)} tasks/hr
              </StyledTableCell>
            </>
          )}
          <StyledTableCell>{totalFlowsStats.result.toFixed(2)} pal/hr</StyledTableCell>
          <StyledTableCell>{totalFlowsStats.objectiveInPalPerHr.toFixed(2)} pal/hr</StyledTableCell>
          <StyledTableCell
            sx={{
              boxShadow: deltaScoreToCssBoxShadow(totalFlowsStats.deltaWithUtilizationRate),
            }}
          >
            {totalFlowsStats.deltaWithUtilizationRate !== null
              ? `${(totalFlowsStats.deltaWithUtilizationRate * 100)?.toFixed(2)}%`
              : 'N/A'}
          </StyledTableCell>
        </StyledTableRow>
      </TableHead>
    </Table>
  );
}

/**
 * Function to convert delta score to a meaningful color for the user
 * @param {number | null} delta - The delta score [%]
 * @returns {string | undefined} - The color corresponding to the delta score
 */
function deltaScoreToColor(delta: number | null): string | undefined {
  if (delta === null) {
    return undefined;
  }

  if (delta < 0.9) {
    return theme.palette.error.main;
  }

  if (delta < 1) {
    return theme.palette.warning.main;
  }

  return theme.palette.success.main;
}

/**
 * Function to convert delta score to a CSS box shadow property
 * @param {number | null} delta - The delta score [%]
 * @returns {string | undefined} - The CSS box shadow property corresponding to the delta score
 */
function deltaScoreToCssBoxShadow(delta: number | null): string | undefined {
  const color = deltaScoreToColor(delta);

  if (color === undefined) return undefined;

  return `inset -4px 0px ${color}`;
}
