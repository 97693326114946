import type { MapsActions } from 'actions/maps';
import { MapsActionTypes } from 'actions/maps';
import type { MapImageTiles } from 'models/maps';
import type { MapImageTilesData } from './state';

export function getMapImageTilesInitialState(): MapImageTilesData {
  return {
    mapImageTilesData: undefined,
    mapImageTilesFetchError: undefined,
    mapTilesReleaseVersion: undefined,
    mapTilesReleaseVersionFetchError: undefined,
    fullImgURL: undefined,
    fullImgURLFetchError: undefined,
    error: undefined,
    updated: false,
    updating: false,
  };
}

export function mapImageTilesReducer(state = getMapImageTilesInitialState(), action: MapsActions): MapImageTilesData {
  switch (action.type) {
    case MapsActionTypes.ImportMapImageTiles: {
      const { name, release, tiles, version, tilePixelSize } = action.payload;

      return {
        ...state,
        mapImageTilesData: {
          name: name,
          version: version,
          tiles: tiles,
          release: release,
          tilePixelSize: tilePixelSize,
        },
        updating: true,
      };
    }

    case MapsActionTypes.UpdateMapImageTilesReleaseVersion: {
      const { newRelease } = action.payload;

      if (state.mapImageTilesData !== undefined) {
        return {
          ...state,
          mapImageTilesData: {
            ...state.mapImageTilesData,
            release: newRelease,
          },
          updating: true,
        };
      }

      return state;
    }

    case MapsActionTypes.UpdateMapImageTilesName: {
      const { newName } = action.payload;

      if (state.mapImageTilesData !== undefined) {
        return {
          ...state,
          mapImageTilesData: {
            ...state.mapImageTilesData,
            name: newName,
          },
          updating: true,
        };
      }

      return state;
    }

    case MapsActionTypes.ImportMapImageTilesReleaseVersion: {
      const { releaseVersion } = action.payload;

      return {
        ...state,
        mapTilesReleaseVersion: releaseVersion,
        updating: true,
      };
    }

    case MapsActionTypes.ImportFullImgURL: {
      const { fullImgURL } = action.payload;

      return {
        ...state,
        fullImgURL: fullImgURL,
        updating: true,
      };
    }

    case MapsActionTypes.ImportErrorMessage: {
      const { errorMessage, fetchAction } = action.payload;

      if (fetchAction === 'mapTiles') {
        return {
          ...state,
          mapImageTilesFetchError: errorMessage,
          updating: true,
        };
      }

      if (fetchAction === 'releases') {
        return {
          ...state,
          mapTilesReleaseVersionFetchError: errorMessage,
          updating: true,
        };
      }

      if (fetchAction === 'preview') {
        return {
          ...state,
          fullImgURLFetchError: errorMessage,
          updating: true,
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
}

export const getMapImageTiles = (state: MapImageTilesData): MapImageTiles => state.mapImageTilesData as MapImageTiles;
