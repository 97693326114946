import { Backdrop, CircularProgress } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GoogleTagManager } from 'components/core/google-tag-manager';
import { useAutoRefreshToken } from 'components/core/use-auto-refresh-token';
import { useConsentCookie } from 'components/core/use-consent-cookies';
import { MainLayout } from 'components/layouts/main-layout';
import { useFetchMapTiles, useFetchMapTilesReleaseVersion } from 'fetchMapTiles';
import { ConfirmProvider } from 'material-ui-confirm';
import { CallbackPage, EditorPage, ForbiddenPage, NotFoundPage, NotImplementedPage, UnauthorizedPage } from 'pages';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConfirmUtilConfigurator } from 'services/confirm.service';
import { useAppSelector } from 'store';
import { rotateCircuitAndReload, rotateGeojsonCircuit } from 'utils/circuit/rotate-circuit';
import { translateCircuitAndReload, translateGeojsonCircuit } from 'utils/circuit/translate-whole-circuit';
import { globalCss } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';

const useStyles = makeStyles(() =>
  createStyles({
    root: { textAlign: 'center', overflowY: 'hidden', backgroundColor: '#fafafa' },
    ...globalCss,
    loading: {
      cursor: 'wait',
    },
  })
);

interface AppProps {
  /** Whether the app the version runs locally or on the server */
  appOnline: boolean;
  /** Whether the user is logged in */
  loggedIn: boolean;
  /** The auth0 refreshed token */
  tokenRefreshed: boolean;
}

// warn the user if he tries to close the app while there's unsaved changes
window.addEventListener(
  'beforeunload',
  (event) => {
    if (PreferencesService.arePreferencesFullyLoaded() && sessionStorage.unsavedChanges === 'true') {
      return (event.returnValue = "There's unsaved changes. Are you sure you want to close Road Editor?");
    }
  },
  { capture: true }
);

export function App({ appOnline, loggedIn, tokenRefreshed }: AppProps): JSX.Element {
  const styles = useStyles();
  const [initialLoading, setInitialLoading] = useState(appOnline && loggedIn && !tokenRefreshed);

  const isLoading = useAppSelector((state) => state.core.loading);
  const showBackdrop = useAppSelector((state) => state.core.backdrop);

  useEffect(() => {
    if (initialLoading && tokenRefreshed) {
      setInitialLoading(false);
    }
  }, [initialLoading, tokenRefreshed]);

  useFetchMapTilesReleaseVersion();
  useFetchMapTiles();

  useConsentCookie();

  useAutoRefreshToken();

  return (
    <div className={styles.root} style={{ cursor: isLoading ? 'wait' : undefined, height: '100vh' }}>
      <ConfirmProvider
        defaultOptions={{
          confirmationButtonProps: {
            id: 'confirm-button',
            autoFocus: true,
          },
        }}
      >
        <GoogleTagManager />
        <ConfirmUtilConfigurator />

        <MainLayout>
          <Switch>
            <Route exact path="/" component={() => <Redirect to="/editor" />} />
            <Route exact path="/home" component={() => <Redirect to="/editor" />} />
            <Route exact path="/callback" component={CallbackPage} />
            <Route exact path="/unauthorized" component={UnauthorizedPage} />
            <Route exact path="/forbidden" component={ForbiddenPage} />
            <Route exact path="/not-found" component={NotFoundPage} />
            <Route exact path="/not-implemented" component={NotImplementedPage} />
            <Route path="/editor" component={EditorPage} />
            <Route component={() => <Redirect to="/not-found" />} />
          </Switch>
        </MainLayout>
      </ConfirmProvider>

      {showBackdrop && (
        <Backdrop open={showBackdrop} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.snackbar + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}

/**
 * Debug functions
 */
(window as any).translateGeojsonCircuit = translateGeojsonCircuit;
(window as any).translateCircuitAndReload = translateCircuitAndReload;
(window as any).rotateGeojsonCircuit = rotateGeojsonCircuit;
(window as any).rotateCircuitAndReload = rotateCircuitAndReload;
