import type { Station, StationPositionWithName } from 'flows/flows';
import { getAllSlotsOfStation } from 'flows/get-all-slots-station';
import { positionIdToPositionName } from 'flows/position-id-to-position-name';
import { positionToShape } from 'flows/position-to-shape';
import { ShapeTypes, SlotTypes } from 'models/circuit';
import type { NewTaskDispatcher } from 'models/simulation';
import store from 'store';
import { isCircuitStockZone } from 'utils/circuit/shape-guards';
import { isSlotArray } from 'utils/circuit/utils';
import { eqSet, getRandomItem } from 'utils/set';

interface GenerateTaskDataOptions {
  flowId: string;
  selectedFlowId: string | null;
  steps?: StationPositionWithName[];
  robAuth?: number[];
  priority?: number;
  previousTask?: NewTaskDispatcher;
  /**
   * Optional parameter used to set the due date
   * Due date = current time + maxExpectedTaskDuration
   */
  maxExpectedTaskDuration?: number;
}

export function generateTaskData(options: GenerateTaskDataOptions): NewTaskDispatcher | undefined {
  const { flowId, selectedFlowId, priority, robAuth, steps, previousTask, maxExpectedTaskDuration } = options;

  const flows = store.getState().flows.flows;
  const stations = store.getState().flows.stations;

  const flow = flows.find((f) => f.id === (flowId ?? selectedFlowId));
  if (!flow) {
    // eslint-disable-next-line no-console
    console.error(`Flow not found, id: ${selectedFlowId}`);

    return;
  }

  const stationsIds = flow.stations.map((s) => s.id);

  const stationsInFlow: (Station | Station[])[] = [];

  stationsIds.forEach((stationId) => {
    if (typeof stationId === 'string') {
      const station = stations.find((s) => s.id === stationId);
      if (station) stationsInFlow.push(station);
    } else if (Array.isArray(stationId)) {
      const multipleStations = stations.filter((s) => stationId.includes(s.id));

      stationsInFlow.push(multipleStations);
    }
  });

  if (!stationsInFlow.length && !steps?.length) {
    // eslint-disable-next-line no-console
    console.error(`Station not found, id: ${stationsIds}`);

    return;
  }

  const allSlotsPerStation =
    steps?.map((step) => getAllSlotsOfStation([step])) ??
    stationsInFlow.map((station) =>
      getAllSlotsOfStation('positions' in station ? station : station.flatMap((s) => s.positions))
    );

  const slotsIdsRoad = allSlotsPerStation.reduce((acc, slotId, index) => {
    const item = getRandomItem(slotId);
    const isFirstStep = index === 0;

    if (isFirstStep && previousTask && slotId.has(previousTask.dst.toString())) {
      // for cascade tasks, use the previous task destination as the first step
      acc.push(previousTask.dst.toString());
    } else if (!isFirstStep && eqSet(allSlotsPerStation[index - 1], slotId)) {
      // Use the same slot as the previous station given that the previous station is the same
      acc.push(acc[index - 1]);
    } else {
      acc.push(item);
    }

    return acc;
  }, [] as string[]);

  const startPoint = positionIdToPositionName(slotsIdsRoad[0], 'all') ?? 'unk';
  const destinationPoint = positionIdToPositionName(slotsIdsRoad[slotsIdsRoad.length - 1], 'all') ?? 'unk';

  const simulationTime = store.getState().simulation.simulatedTime;
  const dueDate = maxExpectedTaskDuration ? simulationTime + maxExpectedTaskDuration : undefined;

  const newTask: NewTaskDispatcher = {
    flowName: flow.name,
    startPoint,
    destinationPoint,
    src: parseInt(slotsIdsRoad[0], 10),
    dst: parseInt(slotsIdsRoad[slotsIdsRoad.length - 1], 10),
    robAuth: robAuth ?? undefined,
    priority: priority ?? 1,
    dueDate,
  };
  slotsIdsRoad.forEach((slotId, index) => {
    newTask[`step${index + 1}`] = parseInt(slotId, 10);

    const allStations = stationsInFlow.flat();

    const step =
      steps?.[index] ||
      allStations[index].positions.find((position) => {
        if (position.type === SlotTypes.StockLine) {
          return position.id === slotId;
        } else if (position.type === ShapeTypes.StockZoneShape) {
          const shape = positionToShape({
            position,
          });

          return (
            shape && isCircuitStockZone(shape) && shape.properties.slots.find((stockLine) => stockLine.id === slotId)
          );
        }

        return false;
      });

    if (step && (step.type === SlotTypes.StockLine || step.type === ShapeTypes.StockZoneShape)) {
      /**
       * for stock zones and stock lines, we choose to which slot we go to
       * if the positionInLine is random, we choose a random slot
       */

      let shape = positionToShape({
        position: step,
      });
      if (isCircuitStockZone(shape)) {
        const stockLines = shape.properties.slots.find((slot) => slot.id === slotId);
        if (stockLines) shape = stockLines;
      }

      const stockLine = shape;

      if (!isSlotArray(stockLine)) {
        // eslint-disable-next-line no-console
        console.warn('Stock line is not a slot array', stockLine);

        return;
      }

      if (step.positionInLine === 'random') {
        const stockLineCapacity = stockLine.slots.length;
        const positionInStockLine = Math.floor(Math.random() * stockLineCapacity);
        newTask[`positionInLine${index + 1}`] = positionInStockLine;
      }
    }
  });

  if (newTask.src === undefined || newTask.dst === undefined) {
    // eslint-disable-next-line no-console
    console.error('Error while creating the task, src or dst is undefined', newTask);

    return;
  }

  return newTask;
}
