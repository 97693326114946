import { Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import type { SelectChangeEvent } from '@mui/material';
import {
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Border } from 'components/utils/border';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { CustomStep, CustomStepEvent, ExtraStepNames } from 'flows/custom-steps.model';
import { extraStepNames } from 'flows/custom-steps.model';
import {
  moveEventInEventsListInCustomStep,
  removeEventToCustomStep,
  setCustomStepEventName,
  setCustomStepEventPropertyValue,
  setCustomStepEventType,
  setCustomStepToEvent,
} from 'flows/flows';
import { useCallback } from 'react';
import { useAppDispatch } from 'store';
import { theme } from 'utils/mui-theme';
import { getObjectKeys } from 'utils/object';
import {
  actionTypeToHelpTooltip,
  actionTypeToLabel,
  extraStepNameToLabel,
  propertyToUnit,
  propertyValueRestrictions,
} from './custom-steps-inputs-description';

interface CustomEventListProps {
  customStepId: string;
  stepName: ExtraStepNames;
  events: CustomStep['customEvents']['RPK_START'];
}

export function CustomEventList(props: CustomEventListProps): JSX.Element {
  const { events, stepName, customStepId } = props;

  const dispatch = useAppDispatch();

  const handlePropertyChange = useCallback(
    (e: SelectChangeEvent<string>, index: number, event: CustomStepEvent) => {
      const newEventType = e.target.value as CustomStepEvent['actionType'];

      dispatch(
        setCustomStepEventType({
          customStepId: customStepId,
          stepName: stepName,
          eventIndex: index,
          eventType: newEventType,
        })
      );
    },
    [customStepId, dispatch, stepName]
  );

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
      const newName = e.target.value;

      dispatch(
        setCustomStepEventName({
          customStepId: customStepId,
          stepName: stepName,
          eventIndex: index,
          name: newName,
        })
      );
    },
    [customStepId, dispatch, stepName]
  );

  const handleChangeStepForEvent = useCallback(
    (e: SelectChangeEvent<string>, index: number, event: CustomStepEvent) => {
      const newStepName = e.target.value as ExtraStepNames;

      dispatch(
        setCustomStepToEvent({
          customStepId: customStepId,
          formerStepName: stepName,
          stepName: newStepName,
          eventIndex: index,
        })
      );
    },
    [customStepId, dispatch, stepName]
  );

  const handleChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
      const inputEl = e.target;
      const newValue = parseFloat(inputEl.value);

      const propertyType = events?.[index].actionType;
      if (!propertyType) {
        // eslint-disable-next-line no-console
        console.error('No property type');

        return;
      }

      const restrictions = propertyValueRestrictions[propertyType];
      const min = restrictions?.min;
      const max = restrictions?.max;

      if (min !== undefined && newValue < min) {
        inputEl.value = min.toString();

        return;
      }

      if (max !== undefined && newValue > max) {
        inputEl.value = max.toString();

        return;
      }

      dispatch(
        setCustomStepEventPropertyValue({
          customStepId: customStepId,
          stepName: stepName,
          eventIndex: index,
          value: newValue,
          property: propertyType,
        })
      );
    },
    [customStepId, dispatch, events, stepName]
  );

  const handleDeleteEvent = useCallback(
    (index: number) => {
      dispatch(
        removeEventToCustomStep({
          customStepId: customStepId,
          stepName: stepName,
          eventIndex: index,
        })
      );
    },
    [customStepId, dispatch, stepName]
  );

  const handleMoveEventInList = useCallback(
    (index: number, direction: 'up' | 'down') => {
      dispatch(
        moveEventInEventsListInCustomStep({
          customStepId: customStepId,
          stepName: stepName,
          eventIndex: index,
          direction: direction,
        })
      );
    },
    [customStepId, dispatch, stepName]
  );

  const availableEventTypes = getObjectKeys(actionTypeToLabel);

  if (!events) return <></>;

  return (
    <Box component="span">
      {events.map((event, index) => {
        const propertyValue = event.hasOwnProperty(event.actionType) ? (event[event.actionType] as number) : 0;
        const propertyUnit = propertyToUnit[event.actionType];
        const isLastEvent = index === events.length - 1;

        const eventCanBeMovedUp = index > 0;
        const eventCanBeMovedDown = index < events.length - 1;

        const helpTooltip = actionTypeToHelpTooltip[event.actionType];

        return (
          <>
            <Border>
              <Stack direction="row" spacing={1}>
                <TextField
                  fullWidth
                  label={
                    <Box component={'div'}>
                      Name{' '}
                      <HelpIconTooltip title="Name of the custom event, it will appears in the Task performance Analysis section of the simulation report" />
                    </Box>
                  }
                  variant="outlined"
                  value={event.name}
                  onChange={(e) => {
                    handleNameChange(e, index);
                  }}
                  size="small"
                  margin="dense"
                />

                <Tooltip title="Move up this event">
                  <Box component="span">
                    <IconButton
                      aria-label="move up event"
                      onClick={() => {
                        handleMoveEventInList(index, 'up');
                      }}
                      disabled={!eventCanBeMovedUp}
                    >
                      <ExpandLess fontSize="small" />
                    </IconButton>
                  </Box>
                </Tooltip>
                <Tooltip title="Move down this event">
                  <Box component="span">
                    <IconButton
                      aria-label="move down event"
                      onClick={() => {
                        handleMoveEventInList(index, 'down');
                      }}
                      disabled={!eventCanBeMovedDown}
                    >
                      <ExpandMore fontSize="small" />
                    </IconButton>
                  </Box>
                </Tooltip>
                <Tooltip title="Delete this event">
                  <Box component="span">
                    <IconButton
                      aria-label="delete event"
                      onClick={() => {
                        handleDeleteEvent(index);
                      }}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Stack>

              <FormControl
                fullWidth
                sx={{
                  marginTop: theme.spacing(2),
                }}
              >
                <InputLabel id={`step-name-select-label-${index}`}>
                  Step{' '}
                  <HelpIconTooltip
                    title={
                      <div>
                        Moment from the mission when the event will be triggered. <br />
                        Start: As soon as the robot start the mission. <br /> Evacuation: As soon as the robot starts to
                        leave its current position. <br /> Last Reverse: When the robot is near its destination position
                        and just before doing its last turn going backward (rpkLastReverse). <br /> Approach: When the
                        robot is in front of its destination position (rpkApproach). <br /> Destination: When the robot
                        arrives in the destination position (rpkDest).
                      </div>
                    }
                  />
                </InputLabel>
                <Select
                  labelId={`step-name-select-label-${index}`}
                  id={`step-name-select-${index}`}
                  value={stepName}
                  onChange={(e) => handleChangeStepForEvent(e, index, event)}
                  displayEmpty
                  label={
                    <Box component={'div'}>
                      Step{' '}
                      <HelpIconTooltip
                        title={
                          <div>
                            Moment from the mission when the event will be triggered. <br />
                            Start: As soon as the robot start the mission. <br /> Evacuation: As soon as the robot
                            starts to leave its current position. <br /> Last Reverse: When the robot is near its
                            destination position and just before doing its last turn going backward (rpkLastReverse).{' '}
                            <br /> Approach: When the robot is in front of its destination position (rpkApproach).{' '}
                            <br /> Destination: When the robot arrives in the destination position (rpkDest).
                          </div>
                        }
                      />
                    </Box>
                  }
                  size="small"
                >
                  {extraStepNames.map((extraStepName) => {
                    return (
                      <MenuItem key={extraStepName} value={extraStepName}>
                        {extraStepNameToLabel[extraStepName]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl
                fullWidth
                sx={{
                  marginTop: theme.spacing(2),
                }}
              >
                <InputLabel id={`event-type-select-label-${index}`}>
                  Property{' '}
                  <HelpIconTooltip
                    title={
                      <div>
                        Action to execute when the event is triggered. <br /> Wait Time: Set a duration (in second) to
                        wait before the next step. <br /> Forks Movement: Set a distance (in meter) to lift the forks. A
                        positive value means lifting and a negative value means lowering. <br /> Forks Target: Set a
                        height position (in meter) for the forks. <br /> None: Do nothing.
                      </div>
                    }
                  />
                </InputLabel>
                <Select
                  labelId={`event-type-select-label-${index}`}
                  id={`event-type-select-${index}`}
                  value={event.actionType}
                  onChange={(e) => handlePropertyChange(e, index, event)}
                  label={
                    <Box component={'div'}>
                      Property{' '}
                      <HelpIconTooltip
                        title={
                          <div>
                            Action to execute when the event is triggered. <br /> Wait Time: Set a duration (in second)
                            to wait before the next step. <br /> Forks Movement: Set a distance (in meter) to lift the
                            forks. A positive value means lifting and a negative value means lowering. <br /> Forks
                            Target: Set a height position (in meter) for the forks. <br /> None: Do nothing.
                          </div>
                        }
                      />
                    </Box>
                  }
                  size="small"
                >
                  {availableEventTypes.map((eventType) => (
                    <MenuItem key={eventType} value={eventType}>
                      {actionTypeToLabel[eventType] || eventType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {event.actionType === 'none' ? null : (
                <TextField
                  key={event.actionType}
                  type="number"
                  fullWidth
                  label={
                    <>
                      Value ({actionTypeToLabel[event.actionType]})
                      {helpTooltip ? <HelpIconTooltip title={helpTooltip} /> : undefined}
                    </>
                  }
                  variant="outlined"
                  size="small"
                  margin="dense"
                  defaultValue={propertyValue}
                  onBlur={(e) => handleChangeValue(e, index)}
                  inputProps={{
                    min: propertyValueRestrictions[event.actionType]?.min,
                    max: propertyValueRestrictions[event.actionType]?.max,
                    step: propertyValueRestrictions[event.actionType]?.step,
                  }}
                  InputProps={{
                    endAdornment: propertyUnit ? (
                      <InputAdornment position="end">{propertyUnit}</InputAdornment>
                    ) : undefined,
                  }}
                  sx={{
                    marginTop: theme.spacing(1),
                  }}
                />
              )}
            </Border>

            {!isLastEvent && (
              <Divider
                sx={{
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              />
            )}
          </>
        );
      })}
    </Box>
  );
}
