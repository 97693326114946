import type { EditorDrawing } from 'drawings/editor.drawing';
import { awareness } from 'multiplayer/globals';
import { unsetFollowUser } from 'multiplayer/multiplayer';
import { useCallback, useEffect } from 'react';
import { setCameraPosition } from 'reducers/local/camera-position.reducer';
import store, { useAppSelector } from 'store';
import { Vector3 } from 'three';
import { useUsers } from 'y-presence';
import type { UserPresence } from '../../../multiplayer/types';

interface UseFollowUserViewProps {
  drawing?: EditorDrawing;
}

export const useFollowUserView = ({ drawing }: UseFollowUserViewProps): void => {
  if (!awareness) throw new Error('Missing awareness');

  const followClientId = useAppSelector((state) => state.multiplayer.followClientId);
  const followClientData = useUsers(awareness, (state) => state.get(followClientId ?? 0)) as UserPresence | undefined;

  const translate = followClientData?.t;
  const zoom = followClientData?.z;

  const handleFollowUser = useCallback(() => {
    if (!translate || !zoom) return;

    // Only defined for 2D mode
    if (drawing) {
      if (!zoom) {
        store.dispatch(unsetFollowUser());

        return;
      }

      drawing.goTo(translate[0], translate[1], zoom);
    } else {
      // 3D mode
      if (translate?.[2] === undefined) {
        store.dispatch(unsetFollowUser());

        return;
      }

      store.dispatch(setCameraPosition({ position: new Vector3(+translate[0], +translate[1], +translate[2]) }));
    }

    // When following the user, if he moves, update the position in the awareness map
    awareness?.setLocalStateField('t', translate);
    awareness?.setLocalStateField('z', zoom);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawing, translate?.[1], translate?.[2], zoom]);

  useEffect(() => {
    if (!followClientData) return;

    handleFollowUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFollowUser]);
};
