import type { Theme } from '@mui/material';
import { createTheme } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { balyoBlue, balyoRed } from './colors/colors';
import { trafficTypeToCssFilter } from './ui/traffic-type-to-color';

interface CustomThemeColors {
  status: {
    danger: string;
    error: string;
    information: string;
    success: string;
    unknown: string;
    warning: string;
  };
}

interface CustomeThemeSizes {
  formPage: number;
  tablePage: number;
  mainLayoutHeight: string;
  mainLayoutWidth: string;
}

export interface CustomTheme extends Theme, CustomThemeColors {
  customzIndex: {
    high: number;
    normal: number;
    low: number;
  };
  sizes: CustomeThemeSizes;
}

export const theme = createTheme({
  palette: {
    primary: balyoBlue,
    secondary: balyoRed,
    success: green,
    error: red,
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#455A64',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    common: {
      black: '#000000',
      white: '#ffffff',
    },
  },
  typography: {
    button: {
      textTransform: 'capitalize',
    },
  },
});

const grayscaleLocked = '70%';

export const globalCss = {
  '@global': {
    '[aria-labelledby="rack-analysis-dialog-title"] .MuiAlert-message': {
      width: '100%',
    },
    '[data-zoom-level="l"] .arrow-seg': {
      display: 'none',
    },
    'g [main-shape-outline]': {
      outlineOffset: '5px',
    },
    'g [type=SEGMENT] [main-shape]': {
      outlineOffset: '10px',
    },
    '.arrow-seg:hover, .arrow-seg.dragging': {
      filter: 'invert(100%)',
    },
    '.arrow-measurer:hover, .arrow-measurer.dragging': {
      filter: 'invert(100%)',
    },
    'text.shape-name': {
      fill: 'currentColor',
      stroke: 'none',
      fillOpacity: 1,
      strokeDasharray: '0',
      pointerEvents: 'none',
    },
    'text.stock-line-name': {
      stroke: 'none',
      fillOpacity: 2,
      strokeDasharray: '0',
      pointerEvents: 'none',
    },
    'g[type=ZONE]': {
      fill: 'currentColor',
      fillOpacity: 0.15,
      stroke: 'currentColor',
      strokeOpacity: 0.6,
    },
    'g[type=ZONE].active': {
      filter: 'saturate(300%)',
      cursor: 'pointer',
    },
    'g[type=ZONE].locked': {
      filter: `grayscale(${grayscaleLocked})`,
    },
    'g[type=ZONE].active.locked': {
      filter: `saturate(300%) grayscale(${grayscaleLocked})`,
    },
    'g[type=ZONE] circle': {
      cursor: 'move',
    },

    'g[type=RACK]': {
      fill: 'currentColor',
      fillOpacity: 0.15,
      stroke: 'currentColor',
      strokeOpacity: 0.6,
    },
    '[data-zoom-level="l"] .middle-upright': {
      display: 'none',
    },
    'g[type=RACK].active': {
      filter: 'saturate(300%)',
      cursor: 'pointer',
    },
    'g[type=RACK].locked': {
      filter: `grayscale(${grayscaleLocked})`,
    },
    'g[type=RACK].active.locked': {
      filter: 'saturate(300%) grayscale(50%)',
    },
    'g[type=RACK] circle': {
      cursor: 'move',
    },
    'g[type=RACK] text': {
      pointerEvents: 'none',
    },
    '.left-upright': {
      stroke: theme.palette.secondary.main,
      strokeOpacity: 0.6,
    },
    '.right-upright': {
      stroke: theme.palette.primary.main,
      strokeOpacity: 0.6,
    },
    '.middle-upright': {
      stroke: 'yellow',
      strokeOpacity: 0.6,
    },
    'g[type=RACK].outdated': {
      animation: 'animateStrokeDashOffset 5s linear infinite',
      filter: 'grayscale(50%)',
      strokeDasharray: '6',
    },
    '.rack-error-state': {
      animation: `pulse-shadow-error 1.5s infinite alternate`,
    },
    '.rack-error-state path[main-shape]': {
      strokeDasharray: '6',
      stroke: theme.palette.error.main,
    },
    '.rack-warning-state': {
      animation: `pulse-shadow-warning 1.5s infinite alternate`,
    },
    '[data-zoom-level="xs"] .rack-error-state, [data-zoom-level="xs"] .rack-warning-state': {
      animation: 'unset',
    },
    '.rack-warning-state path[main-shape]': {
      strokeDasharray: '6',
      stroke: theme.palette.warning.main,
    },
    '@keyframes pulse-shadow-error': {
      '0%': { filter: 'drop-shadow(0 0 0 rgba(255, 0, 0, 0))' },
      '100%': { filter: `drop-shadow(0 0 20px ${theme.palette.error.main})` },
    },
    '@keyframes pulse-shadow-warning': {
      '0%': { filter: 'drop-shadow(0 0 0 rgba(255, 255, 0, 0))' },
      '100%': { filter: `drop-shadow(0 0 20px ${theme.palette.warning.main})` },
    },

    'g[type=STOCK]': {
      fill: `currentColor`,
      fillOpacity: 0,
      stroke: 'currentColor',
      strokeOpacity: 0.6,
      strokeDasharray: '6',
    },
    'g[type=STOCK].active': {
      filter: 'saturate(300%)',
      cursor: 'pointer',
    },
    'g[type=STOCK].locked': {
      filter: `grayscale(${grayscaleLocked})`,
    },
    'g[type=STOCK].active.locked': {
      filter: 'saturate(300%) grayscale(50%)',
    },
    'g[type=STOCK] circle': {
      cursor: 'grab',
      strokeDasharray: 'none',
      fillOpacity: 0.15,
    },
    '.slot, .pallet': {
      stroke: 'none',
      fill: 'currentColor',
      fillOpacity: 0.6,
    },
    '[data-zoom-level="l"] .pallet': {
      display: 'none',
    },
    '.slot': {
      fillOpacity: 0.05,
    },
    '.slot[disabled=true]': {
      filter: 'grayscale(100%)',
    },
    'g[type=STOCK].outdated': {
      animation: 'animateStrokeDashOffset 5s linear infinite',
      filter: 'grayscale(50%)',
    },
    '@keyframes animateStrokeDashOffset': {
      to: {
        strokeDashoffset: 100,
      },
    },

    'g[type=SEGMENT]': {
      stroke: 'currentColor',
      fill: 'currentColor',
    },
    'g[type=SEGMENT].active': {
      filter: 'saturate(300%)',
      cursor: 'pointer',
    },

    'g[type=SEGMENT].locked': {
      filter: `grayscale(${grayscaleLocked})`,
    },
    'g[type=SEGMENT].active.locked': {
      filter: 'saturate(300%) grayscale(50%)',
    },
    '.portion-kernel': {
      filter: trafficTypeToCssFilter('kernel'),
    },
    '.portion-deadend': {
      filter: trafficTypeToCssFilter('deadend'),
    },
    '.portion-deadend-entry': {
      filter: trafficTypeToCssFilter('deadend-entry'),
    },
    '.portion-deadend-exit': {
      filter: trafficTypeToCssFilter('deadend-exit'),
    },

    'g[type=SEGMENT].wire-guided [main-shape]': {
      strokeDasharray: '20 2',
    },
    'g[type=SEGMENT].point-dragging-over': {
      strokeDasharray: '4 1',
    },
    '.enable-highlighted-turn-attach g[uuid]:not(.highlighted-turn-attach)': {
      fillOpacity: 0.2,
      strokeOpacity: 0.2,
    },
    'g[type=SEGMENT].highlighted-turn-attach': {
      stroke: 'orange !important',
      fill: 'orange !important',
    },
    '.enable-highlighted-shape-attach g[uuid]:not(.highlighted-shape-attach)': {
      fillOpacity: 0.2,
      strokeOpacity: 0.2,
    },
    'g[type=SEGMENT].highlighted-shape-attach': {
      stroke: 'orange !important',
      fill: 'orange !important',
    },

    'g[type=MEASURER]': {
      stroke: 'currentColor',
      fill: 'currentColor',
    },
    'g[type=MEASURER].active': {
      filter: 'saturate(300%)',
      cursor: 'pointer',
    },
    'g[type=MEASURER].locked': {
      filter: `grayscale(${grayscaleLocked})`,
    },
    'g[type=MEASURER].active.locked': {
      filter: 'saturate(300%) grayscale(50%)',
    },
    'g[type=MEASURER] .highlighted': {
      fill: 'yellow',
    },
    '.length-measurer-bg-txt': {
      fill: 'rgba(255, 255, 255, 0.8)',
    },
    'g[type=MEASURER] .measurer-attached-arrow': {
      fill: 'rgba(255, 0, 0, 1)',
    },
    'g[type=MEASURER] .measurer-attached-arrow-line': {
      stroke: 'rgba(255, 0, 0, 1)',
    },
    'g[type=MEASURER] .guide': {
      strokeWidth: '1px',
      strokeDasharray: 'none',
    },

    'g[type=TURN]': {
      fill: 'none',
      stroke: 'currentColor',
    },
    'g[type=TURN].active': {
      filter: 'saturate(300%)',
      cursor: 'pointer',
    },
    'g[type=TURN].locked': {
      filter: `grayscale(${grayscaleLocked})`,
    },
    'g[type=TURN].active.locked': {
      filter: 'saturate(300%) grayscale(50%)',
    },
    '.stop-before-turn-icon': {
      opacity: 0.5,
      fill: 'red',
      stroke: 'none',
      pointerEvents: 'none',
    },

    'g[type=POINT]': {
      fill: 'currentColor',
    },
    'g[type=POINT].active': {
      filter: 'saturate(300%)',
      cursor: 'pointer',
    },
    'g[type=POINT].locked': {
      filter: `grayscale(${grayscaleLocked})`,
    },
    'g[type=POINT].active.locked': {
      filter: 'saturate(300%) grayscale(50%)',
    },
    'g[type=POINT]:not(.snapped) [main-shape]': {
      filter: 'invert(100%)',
    },

    '.gabarit': {
      pointerEvents: 'none',
      stroke: 'none',
      fill: 'rgba(100, 100, 100, 0.3)',
    },

    'g[type=DEVICE]': {
      fill: 'currentColor',
      // stroke: 'currentColor',
      pointerEvents: 'bounding-box',
    },
    'g[type=DEVICE].active': {
      filter: 'saturate(300%)',
      cursor: 'pointer',
    },
    'g[type=DEVICE].locked': {
      filter: `grayscale(${grayscaleLocked})`,
    },
    'g[type=DEVICE].active.locked': {
      filter: `saturate(300%) grayscale(${grayscaleLocked})`,
    },
    'g[type=DEVICE] text': {
      pointerEvents: 'none',
      display: 'none',
    },
    'g[type=DEVICE].active text, g[type=DEVICE]:hover text': {
      display: 'block',
    },

    'g[type=NOTE]': {
      fill: 'currentColor',
      // stroke: 'currentColor',
      pointerEvents: 'bounding-box',
    },
    'g[type=NOTE].active': {
      filter: 'saturate(300%)',
      cursor: 'pointer',
    },
    'g[type=NOTE].locked': {
      filter: `grayscale(${grayscaleLocked})`,
    },
    'g[type=NOTE].active.locked': {
      filter: `saturate(300%) grayscale(${grayscaleLocked})`,
    },
    'g[type=NOTE] text': {
      maxWidth: '100px',
      fontSize: '20px',
    },

    /** for predrawing lines while dragging in order to create a turn */
    '.predraw': {
      pointerEvents: 'none',
      opacity: 0.25,
    },

    '.ZONE-DRAWN-STYLE': {
      fill: theme.palette.primary.main,
      opacity: '50%',
      stroke: theme.palette.common.black,
    },
    '.POINT-DRAWN-STYLE': {
      fill: theme.palette.primary.main,
    },
    '.SEGMENT-DRAWN-STYLE': {
      stroke: theme.palette.common.black,
      opacity: '50%',
    },

    'g[TYPE=POINT]:hover .interest-point-name': {
      display: 'block',
    },

    '.assignCellTemplate g[type="CELL-RACK-EDITION"]:hover rect': {
      filter: 'contrast(3)',
      cursor: 'pointer',
    },
    'g[type="CELL-RACK-EDITION"].selected-cell rect': {
      filter: 'contrast(0.4)',
    },
    '.assignCellTemplate g[type="CELL-RACK-EDITION"]:hover image': {
      cursor: 'pointer',
    },
    'g[type="CELL-RACK-EDITION"]': {
      cursor: 'cell',
    },

    'g[layer=circuit].display-traffic-mode': {
      filter: 'grayscale(100%)',
    },
    'g[layer=circuit].simulation-mode': {
      filter: 'grayscale(100%)',
      pointerEvents: 'none !important',
    },
    'g[layer=circuit].disabled, g[layer=circuit].disabled *': {
      pointerEvents: 'none !important',
      userSelect: 'none !important',
    },

    'svg#cell-template-view-front, svg#cell-template-view-top': {
      width: '100%',
      overflow: 'visible',
      objectFit: 'contain',
      marginTop: 0,
    },
    'svg#cell-template-view-front.view-geometry, svg#cell-template-view-top.view-geometry': {
      background: theme.palette.common.white,
    },
    'svg#cell-template-view-front.view-perception, svg#cell-template-view-top.view-perception, svg#cell-template-view-top.view-perception label, svg#cell-template-view-front.view-perception label':
      {
        background: theme.palette.grey[300],
      },
    'svg [name="left-upright"], svg [name="right-upright"]': {
      fill: theme.palette.primary.light,
    },
    'svg [name="bottom-beam"], svg [name="beam-bottom"], svg [name="beam-top"], svg [name="beam-left"], svg [name="beam-right"]':
      {
        fill: theme.palette.primary.light,
      },
    'svg [name="left-bottom-upright"], svg [name="left-top-upright], svg [name="right-bottom-upright"], svg [name="right-top-upright"]':
      {
        fill: theme.palette.primary.main,
      },
    'polyline.itinerary': {
      animation: 'animateDash 1s ease-in-out forwards',
    },
    '@keyframes animateDash': {
      to: {
        strokeDashoffset: 0,
      },
    },
  },
};

/**
 * used because of the migration from muiv4 to muiv5
 * see: https://mui.com/guides/migration-v4/#types-property-quot-palette-quot-quot-spacing-quot-does-not-exist-on-type-defaulttheme
 */
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
