import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ReplayTrigger, Trigger } from 'models/simulation';
import { getNextFreeId } from 'utils/circuit/next-free-id';

// see https://github.com/reduxjs/redux-toolkit/issues/1806#issuecomment-1536690651
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { WritableDraft } from '@reduxjs/toolkit/node_modules/immer/dist/internal';

export const defaultIntervalTriggerInterval = 5 * 60; // seconds
export const defaultSizeTriggerBuffer = 10; // tasks

export interface TriggersSliceState {
  /** list of triggers */
  triggers: Trigger[];

  /** id of the selected trigger */
  selectedTriggerId: string | undefined;
}

export const triggersInitialState: TriggersSliceState = {
  triggers: [],
  selectedTriggerId: undefined,
};

export const triggersSlice = createSlice({
  initialState: triggersInitialState,
  name: 'triggers',
  reducers: {
    addTrigger: (state, action: PayloadAction<Trigger>) => {
      state.triggers.push(action.payload);
    },
    addDefaultTrigger: (state, action: PayloadAction<{ flowId: string }>) => {
      let name = 'Trigger';
      let i = 1;
      // eslint-disable-next-line no-loop-func
      while (state.triggers.find((t) => t.name === name)) {
        name = `Trigger ${i++}`;
      }

      const id = getNextFreeId().toString();
      const flowId = action.payload.flowId;

      state.triggers.push({
        id,
        name,
        type: 'interval',
        flowId,
        interval: defaultIntervalTriggerInterval,
        enabled: true,
      });

      state.selectedTriggerId = id;
    },
    selectTrigger: (state, action: PayloadAction<string | undefined>) => {
      state.selectedTriggerId = action.payload;
    },
    setTrigger: (state, action: PayloadAction<WritableDraft<Trigger>>) => {
      const index = state.triggers.findIndex((t) => t.id === action.payload.id);
      state.triggers[index] = action.payload;
    },
    changeEnableStateTrigger: (state, action: PayloadAction<{ id: string; enabled: boolean }>) => {
      const index = state.triggers.findIndex((t) => t.id === action.payload.id);
      state.triggers[index].enabled = action.payload.enabled;
    },
    setEnableStateAllTriggers: (state, action: PayloadAction<{ enabled: boolean; triggerIds?: string[] }>) => {
      const { enabled, triggerIds } = action.payload;

      state.triggers.forEach((trigger) => {
        if (!triggerIds || triggerIds.includes(trigger.id)) {
          trigger.enabled = enabled;
        }
      });
    },
    setTriggers: (state, action: PayloadAction<Trigger[]>) => {
      state.triggers = action.payload;
    },
    setTriggerName: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const index = state.triggers.findIndex((t) => t.id === action.payload.id);
      state.triggers[index].name = action.payload.name;
    },
    removeTrigger: (state, action: PayloadAction<string>) => {
      state.triggers = state.triggers.filter((t) => t.id !== action.payload);
    },
    removeTriggers: (state, action: PayloadAction<string[]>) => {
      const triggerIdsToRemove = new Set(action.payload);

      state.triggers = state.triggers.filter((t) => !triggerIdsToRemove.has(t.id));
    },
    removeTaskFromReplayTrigger: (state, action: PayloadAction<{ triggerId: string; taskIndex: number }>) => {
      const taskIndex = action.payload.taskIndex;
      const trigger = state.triggers.find((t) => t.id === action.payload.triggerId);
      if (trigger && trigger.type === 'replay' && 'tasks' in trigger) {
        trigger.tasks = trigger.tasks.filter((_, index) => index !== taskIndex);
      }
    },
    addTasksToReplayTrigger: (state, action: PayloadAction<{ triggerId: string; tasks: ReplayTrigger['tasks'] }>) => {
      const trigger = state.triggers.find((t) => t.id === action.payload.triggerId);
      if (trigger && trigger.type === 'replay' && 'tasks' in trigger) {
        trigger.tasks.push(...action.payload.tasks);
      }
    },
  },
});

export const {
  addTrigger,
  addDefaultTrigger,
  selectTrigger,
  setTrigger,
  changeEnableStateTrigger,
  setTriggers,
  setTriggerName,
  removeTrigger,
  removeTaskFromReplayTrigger,
  addTasksToReplayTrigger,
  setEnableStateAllTriggers,
} = triggersSlice.actions;
