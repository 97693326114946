import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { FormControl, IconButton, TextField, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import type { Trigger } from 'models/simulation';
import { useCallback, useRef, useState } from 'react';
import { setTriggerName } from 'simulation/triggers';
import { useAppDispatch } from 'store';

interface RenameTriggerProps {
  trigger: Trigger;
  triggers: Trigger[];
  handleEditName: (newValue: boolean) => void;
}
export function RenameTrigger(props: RenameTriggerProps): JSX.Element {
  const { trigger, triggers, handleEditName } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const [error, setError] = useState(false);

  const handleValidateName = useCallback(() => {
    const newName = inputRef.current?.value;

    const existingTrigger = triggers.find((t) => t.name === newName && t.id !== trigger.id);
    if (!newName || existingTrigger) {
      setError(true);

      return;
    }

    dispatch(
      setTriggerName({
        id: trigger.id,
        name: newName,
      })
    );

    if (handleEditName) {
      handleEditName(false);
    }
  }, [dispatch, handleEditName, trigger.id, triggers]);

  const handleRevertName = useCallback(() => {
    if (handleEditName) {
      handleEditName(false);
    }
  }, [handleEditName]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (error) setError(false);

      if (e.key === 'Enter') {
        handleValidateName();
      } else if (e.key === 'Escape') {
        handleRevertName();
      }
    },
    [error, handleRevertName, handleValidateName]
  );

  return (
    <>
      <FormControl fullWidth size="small">
        <TextField
          id="flow-name"
          label="Flow name"
          variant="outlined"
          size="small"
          defaultValue={trigger.name}
          inputRef={inputRef}
          onKeyDown={handleKeyPress}
          error={error}
          helperText={error ? 'This name is incorrect or already used' : undefined}
        />
      </FormControl>

      <Tooltip title="Revert">
        <Box component="span">
          <IconButton aria-label="revert" onClick={handleRevertName}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Validate">
        <Box component="span">
          <IconButton aria-label="validate" onClick={handleValidateName}>
            <CheckIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
}
