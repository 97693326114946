// @ts-nocheck
/*
 * Generated type guards for "circuit.ts".
 * WARNING: Do not manually change this file.
 */
import { CantonAtPoint, CheckTracklessResult, CircuitShape, ComboxVersionType, DeadendsAtPoint, DeviceType, FeatureCollectionWithProperties, GeoJsonCircuit, GetAllCantonsResult, Intersection, LayerFeatureCollection, LibTrackItinerary, MeasurementType, NetworkType, NoteDisplayMode, PatternTypes, PerceptionReference, RackAnomalyIds, RackCellTemplate, ReferenceMethodX, ReferenceMethodY, ShapeTypes } from "./circuit";

export function isShapeType(obj: unknown): obj is ShapeTypes {
    const typedObj = obj as ShapeTypes
    return (
        (typedObj === ShapeTypes.PointShape ||
            typedObj === ShapeTypes.ZoneShape ||
            typedObj === ShapeTypes.SegmentShape ||
            typedObj === ShapeTypes.MeasurerShape ||
            typedObj === ShapeTypes.TurnShape ||
            typedObj === ShapeTypes.StockZoneShape ||
            typedObj === ShapeTypes.RackShape ||
            typedObj === ShapeTypes.DeviceShape ||
            typedObj === ShapeTypes.NoteShape)
    )
}

export function isCircuitShapeSlow(obj: unknown): obj is CircuitShape {
    const typedObj = obj as CircuitShape
    return (
        ((typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
            typedObj["type"] === "Feature" &&
            (typedObj["geometry"] !== null &&
                typeof typedObj["geometry"] === "object" ||
                typeof typedObj["geometry"] === "function") &&
            (typedObj["geometry"]["type"] === "Feature" ||
                typedObj["geometry"]["type"] === "Polygon" ||
                typedObj["geometry"]["type"] === "Point" ||
                typedObj["geometry"]["type"] === "MultiPoint" ||
                typedObj["geometry"]["type"] === "LineString" ||
                typedObj["geometry"]["type"] === "MultiLineString" ||
                typedObj["geometry"]["type"] === "MultiPolygon" ||
                typedObj["geometry"]["type"] === "GeometryCollection" ||
                typedObj["geometry"]["type"] === "FeatureCollection") &&
            (typeof typedObj["geometry"]["bbox"] === "undefined" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" &&
                typeof typedObj["geometry"]["bbox"][4] === "number" &&
                typeof typedObj["geometry"]["bbox"][5] === "number") &&
            typedObj["geometry"]["type"] === "Polygon" &&
            Array.isArray(typedObj["geometry"]["coordinates"]) &&
            typedObj["geometry"]["coordinates"].every((e: any) =>
                Array.isArray(e) &&
                e.every((e: any) =>
                    Array.isArray(e) &&
                    e.every((e: any) =>
                        typeof e === "number"
                    )
                )
            ) &&
            (typeof typedObj["id"] === "undefined" ||
                typeof typedObj["id"] === "string" ||
                typeof typedObj["id"] === "number") &&
            (typedObj["properties"] !== null &&
                typeof typedObj["properties"] === "object" ||
                typeof typedObj["properties"] === "function") &&
            typedObj["properties"]["type"] === ShapeTypes.ZoneShape &&
            typeof typedObj["properties"]["layerId"] === "string" &&
            typeof typedObj["properties"]["name"] === "string" &&
            Array.isArray(typedObj["properties"]["rules"]) &&
            typedObj["properties"]["rules"].every((e: any) =>
                Array.isArray(e) &&
                (e[0] === "LimitSpeed" ||
                    e[0] === "NoBeepInside" ||
                    e[0] === "HornEntering" ||
                    e[0] === "HornLeaving" ||
                    e[0] === "HornInside" ||
                    e[0] === "StopEntering" ||
                    e[0] === "StopLeaving" ||
                    e[0] === "NoParking" ||
                    e[0] === "RoofHeight" ||
                    e[0] === "CurtainInhibitedArea" ||
                    e[0] === "Door" ||
                    e[0] === "BlackHole" ||
                    e[0] === "NoHardSafetyInside" ||
                    e[0] === "BatteryCharger" ||
                    e[0] === "LimitAgvCount" ||
                    e[0] === "SafetyManualAck" ||
                    e[0] === "TrafficByID" ||
                    e[0] === "Broadcast") &&
                (typeof e[1] === "undefined" ||
                    typeof e[1] === "number") &&
                (typeof e[2] === "undefined" ||
                    typeof e[2] === "string")
            ) &&
            (typedObj["properties"]["intersectionType"] === Intersection.PointIntersection ||
                typedObj["properties"]["intersectionType"] === Intersection.GabaritIntersection) &&
            (typeof typedObj["properties"]["comments"] === "undefined" ||
                Array.isArray(typedObj["properties"]["comments"]) &&
                typedObj["properties"]["comments"].every((e: any) =>
                    typeof e === "string"
                )) &&
            (typeof typedObj["properties"]["locked"] === "undefined" ||
                typedObj["properties"]["locked"] === false ||
                typedObj["properties"]["locked"] === true) &&
            typeof typedObj["properties"]["prio"] === "number" &&
            (typeof typedObj["properties"]["door"] === "undefined" ||
                (typedObj["properties"]["door"] !== null &&
                    typeof typedObj["properties"]["door"] === "object" ||
                    typeof typedObj["properties"]["door"] === "function") &&
                typeof typedObj["properties"]["door"]["enabled"] === "boolean" &&
                Array.isArray(typedObj["properties"]["door"]["devices"]) &&
                typedObj["properties"]["door"]["devices"].every((e: any) =>
                    (e !== null &&
                        typeof e === "object" ||
                        typeof e === "function") &&
                    typeof e["deviceId"] === "string" &&
                    typeof e["pinId"] === "number" &&
                    (e["openValue"] === 0 ||
                        e["openValue"] === 1)
                ) &&
                typeof typedObj["properties"]["door"]["dAsk"] === "number" &&
                typeof typedObj["properties"]["door"]["dStop"] === "number") &&
            (typeof typedObj["properties"]["conveyor"] === "undefined" ||
                typeof typedObj["properties"]["conveyor"] === "string") &&
            (typeof typedObj["properties"]["displayHMI"] === "undefined" ||
                typedObj["properties"]["displayHMI"] === false ||
                typedObj["properties"]["displayHMI"] === true) &&
            (typeof typedObj["properties"]["enterEventName"] === "undefined" ||
                typeof typedObj["properties"]["enterEventName"] === "string") &&
            (typeof typedObj["properties"]["exitEventName"] === "undefined" ||
                typeof typedObj["properties"]["exitEventName"] === "string") &&
            (typeof typedObj["properties"]["aisle"] === "undefined" ||
                (typedObj["properties"]["aisle"] !== null &&
                    typeof typedObj["properties"]["aisle"] === "object" ||
                    typeof typedObj["properties"]["aisle"] === "function") &&
                typedObj["properties"]["aisle"]["isAisle"] === true &&
                typeof typedObj["properties"]["aisle"]["rack0"] === "string" &&
                typeof typedObj["properties"]["aisle"]["rack1"] === "string") &&
            (typeof typedObj["bbox"] === "undefined" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" &&
                typeof typedObj["bbox"][4] === "number" &&
                typeof typedObj["bbox"][5] === "number") &&
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            (typeof typedObj["hidden"] === "undefined" ||
                typedObj["hidden"] === false ||
                typedObj["hidden"] === true) ||
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            typedObj["type"] === "Feature" &&
            (typedObj["geometry"] !== null &&
                typeof typedObj["geometry"] === "object" ||
                typeof typedObj["geometry"] === "function") &&
            (typedObj["geometry"]["type"] === "Feature" ||
                typedObj["geometry"]["type"] === "Polygon" ||
                typedObj["geometry"]["type"] === "Point" ||
                typedObj["geometry"]["type"] === "MultiPoint" ||
                typedObj["geometry"]["type"] === "LineString" ||
                typedObj["geometry"]["type"] === "MultiLineString" ||
                typedObj["geometry"]["type"] === "MultiPolygon" ||
                typedObj["geometry"]["type"] === "GeometryCollection" ||
                typedObj["geometry"]["type"] === "FeatureCollection") &&
            (typeof typedObj["geometry"]["bbox"] === "undefined" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" &&
                typeof typedObj["geometry"]["bbox"][4] === "number" &&
                typeof typedObj["geometry"]["bbox"][5] === "number") &&
            typedObj["geometry"]["type"] === "Polygon" &&
            Array.isArray(typedObj["geometry"]["coordinates"]) &&
            typedObj["geometry"]["coordinates"].every((e: any) =>
                Array.isArray(e) &&
                e.every((e: any) =>
                    Array.isArray(e) &&
                    e.every((e: any) =>
                        typeof e === "number"
                    )
                )
            ) &&
            (typeof typedObj["id"] === "undefined" ||
                typeof typedObj["id"] === "string" ||
                typeof typedObj["id"] === "number") &&
            (typedObj["properties"] !== null &&
                typeof typedObj["properties"] === "object" ||
                typeof typedObj["properties"] === "function") &&
            typedObj["properties"]["type"] === ShapeTypes.StockZoneShape &&
            typeof typedObj["properties"]["name"] === "string" &&
            Array.isArray(typedObj["properties"]["slots"]) &&
            typedObj["properties"]["slots"].every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                typeof e["id"] === "string" &&
                typeof e["name"] === "string" &&
                typeof e["userEditedName"] === "boolean" &&
                Array.isArray(e["slots"]) &&
                e["slots"].every((e: any) =>
                    (e !== null &&
                        typeof e === "object" ||
                        typeof e === "function") &&
                    typeof e["id"] === "string" &&
                    typeof e["name"] === "string" &&
                    (e["slotPosition"] !== null &&
                        typeof e["slotPosition"] === "object" ||
                        typeof e["slotPosition"] === "function") &&
                    typeof e["slotPosition"]["x"] === "number" &&
                    typeof e["slotPosition"]["y"] === "number" &&
                    typeof e["slotPosition"]["z"] === "number" &&
                    typeof e["slotPosition"]["cap"] === "number" &&
                    (typeof e["parentId"] === "undefined" ||
                        typeof e["parentId"] === "string") &&
                    (e["tolerancePosition"] !== null &&
                        typeof e["tolerancePosition"] === "object" ||
                        typeof e["tolerancePosition"] === "function") &&
                    typeof e["tolerancePosition"]["x"] === "number" &&
                    typeof e["tolerancePosition"]["y"] === "number" &&
                    typeof e["tolerancePosition"]["z"] === "number" &&
                    typeof e["tolerancePosition"]["cap"] === "number" &&
                    (typeof e["freespaceMethod"] === "undefined" ||
                        typeof e["freespaceMethod"] === "number") &&
                    (typeof e["palletTypes"] === "undefined" ||
                        Array.isArray(e["palletTypes"]) &&
                        e["palletTypes"].every((e: any) =>
                            typeof e === "string"
                        )) &&
                    (e["palletSize"] !== null &&
                        typeof e["palletSize"] === "object" ||
                        typeof e["palletSize"] === "function") &&
                    typeof e["palletSize"]["width"] === "number" &&
                    typeof e["palletSize"]["length"] === "number" &&
                    (typeof e["palletSize"]["height"] === "undefined" ||
                        typeof e["palletSize"]["height"] === "number") &&
                    (e["palletPosition"] !== null &&
                        typeof e["palletPosition"] === "object" ||
                        typeof e["palletPosition"] === "function") &&
                    typeof e["palletPosition"]["offsetX"] === "number" &&
                    typeof e["palletPosition"]["offsetY"] === "number" &&
                    (typeof e["palletPosition"]["offsetZ"] === "undefined" ||
                        typeof e["palletPosition"]["offsetZ"] === "number") &&
                    (e["palletPosition"]["referenceX"] === ReferenceMethodX.frontEdge ||
                        e["palletPosition"]["referenceX"] === ReferenceMethodX.rearEdge ||
                        e["palletPosition"]["referenceX"] === ReferenceMethodX.center) &&
                    (e["palletPosition"]["referenceY"] === ReferenceMethodY.leftEdge ||
                        e["palletPosition"]["referenceY"] === ReferenceMethodY.rightEdge ||
                        e["palletPosition"]["referenceY"] === ReferenceMethodY.center) &&
                    (e["slotSize"] !== null &&
                        typeof e["slotSize"] === "object" ||
                        typeof e["slotSize"] === "function") &&
                    typeof e["slotSize"]["width"] === "number" &&
                    typeof e["slotSize"]["length"] === "number" &&
                    (typeof e["slotSize"]["height"] === "undefined" ||
                        typeof e["slotSize"]["height"] === "number") &&
                    Array.isArray(e["geometry"]) &&
                    e["geometry"].every((e: any) =>
                        Array.isArray(e) &&
                        e.every((e: any) =>
                            typeof e === "number"
                        )
                    ) &&
                    (typeof e["disabled"] === "undefined" ||
                        e["disabled"] === false ||
                        e["disabled"] === true)
                )
            ) &&
            typeof typedObj["properties"]["layerId"] === "string" &&
            (typeof typedObj["properties"]["locked"] === "undefined" ||
                typedObj["properties"]["locked"] === false ||
                typedObj["properties"]["locked"] === true) &&
            typeof typedObj["properties"]["length"] === "number" &&
            typeof typedObj["properties"]["width"] === "number" &&
            (typedObj["properties"]["palletSize"] !== null &&
                typeof typedObj["properties"]["palletSize"] === "object" ||
                typeof typedObj["properties"]["palletSize"] === "function") &&
            typeof typedObj["properties"]["palletSize"]["width"] === "number" &&
            typeof typedObj["properties"]["palletSize"]["length"] === "number" &&
            (typeof typedObj["properties"]["palletSize"]["height"] === "undefined" ||
                typeof typedObj["properties"]["palletSize"]["height"] === "number") &&
            typeof typedObj["properties"]["cap"] === "number" &&
            (typedObj["properties"]["pattern"] === PatternTypes.uniformDistribution ||
                typedObj["properties"]["pattern"] === PatternTypes.repetitivePattern ||
                typedObj["properties"]["pattern"] === PatternTypes.relatedDistribution ||
                typedObj["properties"]["pattern"] === PatternTypes.customDistribution) &&
            typeof typedObj["properties"]["extendedLength"] === "number" &&
            Array.isArray(typedObj["properties"]["palletTypes"]) &&
            typedObj["properties"]["palletTypes"].every((e: any) =>
                typeof e === "string"
            ) &&
            (typedObj["properties"]["slotSize"] !== null &&
                typeof typedObj["properties"]["slotSize"] === "object" ||
                typeof typedObj["properties"]["slotSize"] === "function") &&
            typeof typedObj["properties"]["slotSize"]["width"] === "number" &&
            typeof typedObj["properties"]["slotSize"]["length"] === "number" &&
            (typeof typedObj["properties"]["slotSize"]["height"] === "undefined" ||
                typeof typedObj["properties"]["slotSize"]["height"] === "number") &&
            (typedObj["properties"]["gap"] !== null &&
                typeof typedObj["properties"]["gap"] === "object" ||
                typeof typedObj["properties"]["gap"] === "function") &&
            typeof typedObj["properties"]["gap"]["width"] === "number" &&
            typeof typedObj["properties"]["gap"]["length"] === "number" &&
            (typeof typedObj["properties"]["gap"]["height"] === "undefined" ||
                typeof typedObj["properties"]["gap"]["height"] === "number") &&
            (typeof typedObj["properties"]["customGapSlots"] === "undefined" ||
                Array.isArray(typedObj["properties"]["customGapSlots"]) &&
                typedObj["properties"]["customGapSlots"].every((e: any) =>
                    Array.isArray(e) &&
                    e.every((e: any) =>
                        typeof e === "number"
                    )
                )) &&
            (typeof typedObj["properties"]["customGapLines"] === "undefined" ||
                Array.isArray(typedObj["properties"]["customGapLines"]) &&
                typedObj["properties"]["customGapLines"].every((e: any) =>
                    typeof e === "number"
                )) &&
            (typedObj["properties"]["referencePosX"] === ReferenceMethodX.frontEdge ||
                typedObj["properties"]["referencePosX"] === ReferenceMethodX.rearEdge ||
                typedObj["properties"]["referencePosX"] === ReferenceMethodX.center) &&
            typeof typedObj["properties"]["referenceOffsetX"] === "number" &&
            (typedObj["properties"]["referencePosY"] === ReferenceMethodY.leftEdge ||
                typedObj["properties"]["referencePosY"] === ReferenceMethodY.rightEdge ||
                typedObj["properties"]["referencePosY"] === ReferenceMethodY.center) &&
            typeof typedObj["properties"]["referenceOffsetY"] === "number" &&
            (typeof typedObj["properties"]["displayPallets"] === "undefined" ||
                typedObj["properties"]["displayPallets"] === false ||
                typedObj["properties"]["displayPallets"] === true) &&
            (typeof typedObj["properties"]["palletToDisplay"] === "undefined" ||
                typeof typedObj["properties"]["palletToDisplay"] === "string") &&
            (typeof typedObj["properties"]["nbLinesPerGroup"] === "undefined" ||
                typeof typedObj["properties"]["nbLinesPerGroup"] === "number") &&
            (typeof typedObj["properties"]["nbSlotsPerGroup"] === "undefined" ||
                typeof typedObj["properties"]["nbSlotsPerGroup"] === "number") &&
            (typeof typedObj["properties"]["spaceBetweenLinePatterns"] === "undefined" ||
                typeof typedObj["properties"]["spaceBetweenLinePatterns"] === "number") &&
            (typeof typedObj["properties"]["spaceBetweenSlotPatterns"] === "undefined" ||
                typeof typedObj["properties"]["spaceBetweenSlotPatterns"] === "number") &&
            typeof typedObj["properties"]["enableScan"] === "boolean" &&
            (typedObj["properties"]["fillStrategy"] === 4 ||
                typedObj["properties"]["fillStrategy"] === 6 ||
                typedObj["properties"]["fillStrategy"] === 1 ||
                typedObj["properties"]["fillStrategy"] === 2 ||
                typedObj["properties"]["fillStrategy"] === 3 ||
                typedObj["properties"]["fillStrategy"] === 5) &&
            (typedObj["properties"]["emptyStrategy"] === 4 ||
                typedObj["properties"]["emptyStrategy"] === 6 ||
                typedObj["properties"]["emptyStrategy"] === 1 ||
                typedObj["properties"]["emptyStrategy"] === 2 ||
                typedObj["properties"]["emptyStrategy"] === 3 ||
                typedObj["properties"]["emptyStrategy"] === 5) &&
            (typeof typedObj["properties"]["stockZoneMargin"] === "undefined" ||
                typeof typedObj["properties"]["stockZoneMargin"] === "number") &&
            typeof typedObj["properties"]["prio"] === "number" &&
            (typeof typedObj["bbox"] === "undefined" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" &&
                typeof typedObj["bbox"][4] === "number" &&
                typeof typedObj["bbox"][5] === "number") &&
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            (typeof typedObj["hidden"] === "undefined" ||
                typedObj["hidden"] === false ||
                typedObj["hidden"] === true) &&
            (typeof typedObj["isEditing"] === "undefined" ||
                typedObj["isEditing"] === false ||
                typedObj["isEditing"] === true) ||
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            typedObj["type"] === "Feature" &&
            (typedObj["geometry"] !== null &&
                typeof typedObj["geometry"] === "object" ||
                typeof typedObj["geometry"] === "function") &&
            (typedObj["geometry"]["type"] === "Feature" ||
                typedObj["geometry"]["type"] === "Polygon" ||
                typedObj["geometry"]["type"] === "Point" ||
                typedObj["geometry"]["type"] === "MultiPoint" ||
                typedObj["geometry"]["type"] === "LineString" ||
                typedObj["geometry"]["type"] === "MultiLineString" ||
                typedObj["geometry"]["type"] === "MultiPolygon" ||
                typedObj["geometry"]["type"] === "GeometryCollection" ||
                typedObj["geometry"]["type"] === "FeatureCollection") &&
            (typeof typedObj["geometry"]["bbox"] === "undefined" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" &&
                typeof typedObj["geometry"]["bbox"][4] === "number" &&
                typeof typedObj["geometry"]["bbox"][5] === "number") &&
            typedObj["geometry"]["type"] === "Point" &&
            Array.isArray(typedObj["geometry"]["coordinates"]) &&
            typedObj["geometry"]["coordinates"].every((e: any) =>
                typeof e === "number"
            ) &&
            (typeof typedObj["id"] === "undefined" ||
                typeof typedObj["id"] === "string" ||
                typeof typedObj["id"] === "number") &&
            (typedObj["properties"] !== null &&
                typeof typedObj["properties"] === "object" ||
                typeof typedObj["properties"] === "function") &&
            typedObj["properties"]["type"] === ShapeTypes.PointShape &&
            typeof typedObj["properties"]["name"] === "string" &&
            typeof typedObj["properties"]["layerId"] === "string" &&
            typeof typedObj["properties"]["orientation"] === "number" &&
            typeof typedObj["properties"]["isTaxi"] === "boolean" &&
            typeof typedObj["properties"]["isInit"] === "boolean" &&
            typeof typedObj["properties"]["isBattery"] === "boolean" &&
            (typeof typedObj["properties"]["batteryType"] === "undefined" ||
                typeof typedObj["properties"]["batteryType"] === "string") &&
            typeof typedObj["properties"]["height"] === "number" &&
            (typeof typedObj["properties"]["segment"] === "undefined" ||
                (typedObj["properties"]["segment"] !== null &&
                    typeof typedObj["properties"]["segment"] === "object" ||
                    typeof typedObj["properties"]["segment"] === "function") &&
                typeof typedObj["properties"]["segment"]["id"] === "string" &&
                typeof typedObj["properties"]["segment"]["position"] === "number") &&
            (typeof typedObj["properties"]["locked"] === "undefined" ||
                typedObj["properties"]["locked"] === false ||
                typedObj["properties"]["locked"] === true) &&
            (typeof typedObj["properties"]["minimumInitScore"] === "undefined" ||
                typeof typedObj["properties"]["minimumInitScore"] === "number") &&
            (typeof typedObj["properties"]["gabarit"] === "undefined" ||
                (typedObj["properties"]["gabarit"] !== null &&
                    typeof typedObj["properties"]["gabarit"] === "object" ||
                    typeof typedObj["properties"]["gabarit"] === "function") &&
                typeof typedObj["properties"]["gabarit"]["display"] === "boolean" &&
                typeof typedObj["properties"]["gabarit"]["modelName"] === "string" &&
                typeof typedObj["properties"]["gabarit"]["type"] === "string") &&
            typeof typedObj["properties"]["prio"] === "number" &&
            (typeof typedObj["bbox"] === "undefined" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" &&
                typeof typedObj["bbox"][4] === "number" &&
                typeof typedObj["bbox"][5] === "number") &&
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            (typeof typedObj["hidden"] === "undefined" ||
                typedObj["hidden"] === false ||
                typedObj["hidden"] === true) ||
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            typedObj["type"] === "Feature" &&
            (typedObj["geometry"] !== null &&
                typeof typedObj["geometry"] === "object" ||
                typeof typedObj["geometry"] === "function") &&
            (typedObj["geometry"]["type"] === "Feature" ||
                typedObj["geometry"]["type"] === "Polygon" ||
                typedObj["geometry"]["type"] === "Point" ||
                typedObj["geometry"]["type"] === "MultiPoint" ||
                typedObj["geometry"]["type"] === "LineString" ||
                typedObj["geometry"]["type"] === "MultiLineString" ||
                typedObj["geometry"]["type"] === "MultiPolygon" ||
                typedObj["geometry"]["type"] === "GeometryCollection" ||
                typedObj["geometry"]["type"] === "FeatureCollection") &&
            (typeof typedObj["geometry"]["bbox"] === "undefined" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" &&
                typeof typedObj["geometry"]["bbox"][4] === "number" &&
                typeof typedObj["geometry"]["bbox"][5] === "number") &&
            typedObj["geometry"]["type"] === "LineString" &&
            Array.isArray(typedObj["geometry"]["coordinates"]) &&
            typedObj["geometry"]["coordinates"].every((e: any) =>
                Array.isArray(e) &&
                e.every((e: any) =>
                    typeof e === "number"
                )
            ) &&
            (typeof typedObj["id"] === "undefined" ||
                typeof typedObj["id"] === "string" ||
                typeof typedObj["id"] === "number") &&
            (typedObj["properties"] !== null &&
                typeof typedObj["properties"] === "object" ||
                typeof typedObj["properties"] === "function") &&
            typedObj["properties"]["type"] === ShapeTypes.SegmentShape &&
            typeof typedObj["properties"]["layerId"] === "string" &&
            typeof typedObj["properties"]["name"] === "string" &&
            typeof typedObj["properties"]["twoWay"] === "boolean" &&
            (typeof typedObj["properties"]["comments"] === "undefined" ||
                Array.isArray(typedObj["properties"]["comments"]) &&
                typedObj["properties"]["comments"].every((e: any) =>
                    typeof e === "string"
                )) &&
            (typeof typedObj["properties"]["locked"] === "undefined" ||
                typedObj["properties"]["locked"] === false ||
                typedObj["properties"]["locked"] === true) &&
            Array.isArray(typedObj["properties"]["portions"]) &&
            typedObj["properties"]["portions"].every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                typeof e["id"] === "string" &&
                Array.isArray(e["points"]) &&
                e["points"].every((e: any) =>
                    Array.isArray(e) &&
                    e.every((e: any) =>
                        typeof e === "number"
                    )
                ) &&
                (typeof e["trafficType"] === "undefined" ||
                    e["trafficType"] === "kernel" ||
                    e["trafficType"] === "deadend" ||
                    e["trafficType"] === "deadend-exit" ||
                    e["trafficType"] === "deadend-entry" ||
                    e["trafficType"] === "auto") &&
                (typeof e["inStart"] === "undefined" ||
                    Array.isArray(e["inStart"]) &&
                    e["inStart"].every((e: any) =>
                        typeof e === "string"
                    )) &&
                (typeof e["outStart"] === "undefined" ||
                    Array.isArray(e["outStart"]) &&
                    e["outStart"].every((e: any) =>
                        typeof e === "string"
                    )) &&
                (typeof e["inEnd"] === "undefined" ||
                    Array.isArray(e["inEnd"]) &&
                    e["inEnd"].every((e: any) =>
                        typeof e === "string"
                    )) &&
                (typeof e["outEnd"] === "undefined" ||
                    Array.isArray(e["outEnd"]) &&
                    e["outEnd"].every((e: any) =>
                        typeof e === "string"
                    )) &&
                (typeof e["turnIdStart"] === "undefined" ||
                    typeof e["turnIdStart"] === "string") &&
                (typeof e["turnIdEnd"] === "undefined" ||
                    typeof e["turnIdEnd"] === "string") &&
                (typeof e["stockZoneId"] === "undefined" ||
                    typeof e["stockZoneId"] === "string")
            ) &&
            (typeof typedObj["properties"]["stockLine"] === "undefined" ||
                typeof typedObj["properties"]["stockLine"] === "string") &&
            (typeof typedObj["properties"]["rack"] === "undefined" ||
                typeof typedObj["properties"]["rack"] === "string") &&
            (typeof typedObj["properties"]["rackColumn"] === "undefined" ||
                typeof typedObj["properties"]["rackColumn"] === "string") &&
            (typeof typedObj["properties"]["wireGuided"] === "undefined" ||
                typedObj["properties"]["wireGuided"] === false ||
                typedObj["properties"]["wireGuided"] === true) &&
            (typeof typedObj["properties"]["gabarit"] === "undefined" ||
                (typedObj["properties"]["gabarit"] !== null &&
                    typeof typedObj["properties"]["gabarit"] === "object" ||
                    typeof typedObj["properties"]["gabarit"] === "function") &&
                typeof typedObj["properties"]["gabarit"]["display"] === "boolean" &&
                typeof typedObj["properties"]["gabarit"]["modelName"] === "string" &&
                typeof typedObj["properties"]["gabarit"]["type"] === "string") &&
            typeof typedObj["properties"]["prio"] === "number" &&
            (typeof typedObj["properties"]["aisle"] === "undefined" ||
                (typedObj["properties"]["aisle"] !== null &&
                    typeof typedObj["properties"]["aisle"] === "object" ||
                    typeof typedObj["properties"]["aisle"] === "function") &&
                typeof typedObj["properties"]["aisle"]["aisleId"] === "string" &&
                typeof typedObj["properties"]["aisle"]["offset"] === "number" &&
                (typedObj["properties"]["aisle"]["direction"] === "left" ||
                    typedObj["properties"]["aisle"]["direction"] === "right" ||
                    typedObj["properties"]["aisle"]["direction"] === "both")) &&
            (typeof typedObj["bbox"] === "undefined" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" &&
                typeof typedObj["bbox"][4] === "number" &&
                typeof typedObj["bbox"][5] === "number") &&
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            (typeof typedObj["hidden"] === "undefined" ||
                typedObj["hidden"] === false ||
                typedObj["hidden"] === true) ||
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            typedObj["type"] === "Feature" &&
            (typedObj["geometry"] !== null &&
                typeof typedObj["geometry"] === "object" ||
                typeof typedObj["geometry"] === "function") &&
            (typedObj["geometry"]["type"] === "Feature" ||
                typedObj["geometry"]["type"] === "Polygon" ||
                typedObj["geometry"]["type"] === "Point" ||
                typedObj["geometry"]["type"] === "MultiPoint" ||
                typedObj["geometry"]["type"] === "LineString" ||
                typedObj["geometry"]["type"] === "MultiLineString" ||
                typedObj["geometry"]["type"] === "MultiPolygon" ||
                typedObj["geometry"]["type"] === "GeometryCollection" ||
                typedObj["geometry"]["type"] === "FeatureCollection") &&
            (typeof typedObj["geometry"]["bbox"] === "undefined" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" &&
                typeof typedObj["geometry"]["bbox"][4] === "number" &&
                typeof typedObj["geometry"]["bbox"][5] === "number") &&
            typedObj["geometry"]["type"] === "LineString" &&
            Array.isArray(typedObj["geometry"]["coordinates"]) &&
            typedObj["geometry"]["coordinates"].every((e: any) =>
                Array.isArray(e) &&
                e.every((e: any) =>
                    typeof e === "number"
                )
            ) &&
            (typeof typedObj["id"] === "undefined" ||
                typeof typedObj["id"] === "string" ||
                typeof typedObj["id"] === "number") &&
            (typedObj["properties"] !== null &&
                typeof typedObj["properties"] === "object" ||
                typeof typedObj["properties"] === "function") &&
            typedObj["properties"]["type"] === ShapeTypes.MeasurerShape &&
            typeof typedObj["properties"]["layerId"] === "string" &&
            typeof typedObj["properties"]["name"] === "string" &&
            (typeof typedObj["properties"]["locked"] === "undefined" ||
                typedObj["properties"]["locked"] === false ||
                typedObj["properties"]["locked"] === true) &&
            (typeof typedObj["properties"]["lockedLength"] === "undefined" ||
                typedObj["properties"]["lockedLength"] === false ||
                typedObj["properties"]["lockedLength"] === true) &&
            (typeof typedObj["properties"]["showLength"] === "undefined" ||
                typedObj["properties"]["showLength"] === false ||
                typedObj["properties"]["showLength"] === true) &&
            (typedObj["properties"]["measurementType"] === MeasurementType.MinimumDistance ||
                typedObj["properties"]["measurementType"] === MeasurementType.Center2Center) &&
            (typeof typedObj["properties"]["link0"] === "undefined" ||
                (typedObj["properties"]["link0"] !== null &&
                    typeof typedObj["properties"]["link0"] === "object" ||
                    typeof typedObj["properties"]["link0"] === "function") &&
                typeof typedObj["properties"]["link0"]["id"] === "string" &&
                isShapeType(typedObj["properties"]["link0"]["type"]) as boolean) &&
            (typeof typedObj["properties"]["link1"] === "undefined" ||
                (typedObj["properties"]["link1"] !== null &&
                    typeof typedObj["properties"]["link1"] === "object" ||
                    typeof typedObj["properties"]["link1"] === "function") &&
                typeof typedObj["properties"]["link1"]["id"] === "string" &&
                isShapeType(typedObj["properties"]["link1"]["type"]) as boolean) &&
            typeof typedObj["properties"]["prio"] === "number" &&
            (typeof typedObj["properties"]["guide"] === "undefined" ||
                typedObj["properties"]["guide"] === false ||
                typedObj["properties"]["guide"] === true) &&
            (typeof typedObj["bbox"] === "undefined" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" &&
                typeof typedObj["bbox"][4] === "number" &&
                typeof typedObj["bbox"][5] === "number") &&
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            (typeof typedObj["hidden"] === "undefined" ||
                typedObj["hidden"] === false ||
                typedObj["hidden"] === true) ||
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            typedObj["type"] === "Feature" &&
            (typedObj["geometry"] !== null &&
                typeof typedObj["geometry"] === "object" ||
                typeof typedObj["geometry"] === "function") &&
            (typedObj["geometry"]["type"] === "Feature" ||
                typedObj["geometry"]["type"] === "Polygon" ||
                typedObj["geometry"]["type"] === "Point" ||
                typedObj["geometry"]["type"] === "MultiPoint" ||
                typedObj["geometry"]["type"] === "LineString" ||
                typedObj["geometry"]["type"] === "MultiLineString" ||
                typedObj["geometry"]["type"] === "MultiPolygon" ||
                typedObj["geometry"]["type"] === "GeometryCollection" ||
                typedObj["geometry"]["type"] === "FeatureCollection") &&
            (typeof typedObj["geometry"]["bbox"] === "undefined" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" &&
                typeof typedObj["geometry"]["bbox"][4] === "number" &&
                typeof typedObj["geometry"]["bbox"][5] === "number") &&
            typedObj["geometry"]["type"] === "LineString" &&
            Array.isArray(typedObj["geometry"]["coordinates"]) &&
            typedObj["geometry"]["coordinates"].every((e: any) =>
                Array.isArray(e) &&
                e.every((e: any) =>
                    typeof e === "number"
                )
            ) &&
            (typeof typedObj["id"] === "undefined" ||
                typeof typedObj["id"] === "string" ||
                typeof typedObj["id"] === "number") &&
            (typedObj["properties"] !== null &&
                typeof typedObj["properties"] === "object" ||
                typeof typedObj["properties"] === "function") &&
            typedObj["properties"]["type"] === ShapeTypes.TurnShape &&
            typeof typedObj["properties"]["layerId"] === "string" &&
            (typeof typedObj["properties"]["originId"] === "undefined" ||
                typeof typedObj["properties"]["originId"] === "string") &&
            (typeof typedObj["properties"]["destinationId"] === "undefined" ||
                typeof typedObj["properties"]["destinationId"] === "string") &&
            (typedObj["properties"]["turnType"] === "Normal" ||
                typedObj["properties"]["turnType"] === "StopBeforeTurn" ||
                typedObj["properties"]["turnType"] === "Tesseract") &&
            (typeof typedObj["properties"]["trafficType"] === "undefined" ||
                typedObj["properties"]["trafficType"] === "kernel" ||
                typedObj["properties"]["trafficType"] === "deadend" ||
                typedObj["properties"]["trafficType"] === "deadend-exit" ||
                typedObj["properties"]["trafficType"] === "deadend-entry" ||
                typedObj["properties"]["trafficType"] === "auto") &&
            (typeof typedObj["properties"]["drivenBy"] === "undefined" ||
                typedObj["properties"]["drivenBy"] === "Radius" ||
                typedObj["properties"]["drivenBy"] === "StartPoint" ||
                typedObj["properties"]["drivenBy"] === "StraightLine" ||
                typedObj["properties"]["drivenBy"] === "LineChange") &&
            (typeof typedObj["properties"]["radius"] === "undefined" ||
                typeof typedObj["properties"]["radius"] === "number") &&
            (typeof typedObj["properties"]["positionFactorOrigin"] === "undefined" ||
                typeof typedObj["properties"]["positionFactorOrigin"] === "number") &&
            (typeof typedObj["properties"]["positionFactorDest"] === "undefined" ||
                typeof typedObj["properties"]["positionFactorDest"] === "number") &&
            (typedObj["properties"]["coordinateProperties"] !== null &&
                typeof typedObj["properties"]["coordinateProperties"] === "object" ||
                typeof typedObj["properties"]["coordinateProperties"] === "function") &&
            Array.isArray(typedObj["properties"]["coordinateProperties"]["cap"]) &&
            typedObj["properties"]["coordinateProperties"]["cap"].every((e: any) =>
                typeof e === "number"
            ) &&
            (typeof typedObj["properties"]["maxOvershoot"] === "undefined" ||
                typeof typedObj["properties"]["maxOvershoot"] === "number") &&
            (typeof typedObj["properties"]["name"] === "undefined" ||
                typeof typedObj["properties"]["name"] === "string") &&
            (typeof typedObj["properties"]["gabarit"] === "undefined" ||
                (typedObj["properties"]["gabarit"] !== null &&
                    typeof typedObj["properties"]["gabarit"] === "object" ||
                    typeof typedObj["properties"]["gabarit"] === "function") &&
                typeof typedObj["properties"]["gabarit"]["display"] === "boolean" &&
                typeof typedObj["properties"]["gabarit"]["modelName"] === "string" &&
                typeof typedObj["properties"]["gabarit"]["type"] === "string") &&
            typeof typedObj["properties"]["prio"] === "number" &&
            (typeof typedObj["properties"]["startPointOffset"] === "undefined" ||
                typeof typedObj["properties"]["startPointOffset"] === "number") &&
            (typeof typedObj["bbox"] === "undefined" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" &&
                typeof typedObj["bbox"][4] === "number" &&
                typeof typedObj["bbox"][5] === "number") &&
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            (typeof typedObj["hidden"] === "undefined" ||
                typedObj["hidden"] === false ||
                typedObj["hidden"] === true) ||
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            typedObj["type"] === "Feature" &&
            (typedObj["geometry"] !== null &&
                typeof typedObj["geometry"] === "object" ||
                typeof typedObj["geometry"] === "function") &&
            (typedObj["geometry"]["type"] === "Feature" ||
                typedObj["geometry"]["type"] === "Polygon" ||
                typedObj["geometry"]["type"] === "Point" ||
                typedObj["geometry"]["type"] === "MultiPoint" ||
                typedObj["geometry"]["type"] === "LineString" ||
                typedObj["geometry"]["type"] === "MultiLineString" ||
                typedObj["geometry"]["type"] === "MultiPolygon" ||
                typedObj["geometry"]["type"] === "GeometryCollection" ||
                typedObj["geometry"]["type"] === "FeatureCollection") &&
            (typeof typedObj["geometry"]["bbox"] === "undefined" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" &&
                typeof typedObj["geometry"]["bbox"][4] === "number" &&
                typeof typedObj["geometry"]["bbox"][5] === "number") &&
            typedObj["geometry"]["type"] === "Polygon" &&
            Array.isArray(typedObj["geometry"]["coordinates"]) &&
            typedObj["geometry"]["coordinates"].every((e: any) =>
                Array.isArray(e) &&
                e.every((e: any) =>
                    Array.isArray(e) &&
                    e.every((e: any) =>
                        typeof e === "number"
                    )
                )
            ) &&
            (typeof typedObj["id"] === "undefined" ||
                typeof typedObj["id"] === "string" ||
                typeof typedObj["id"] === "number") &&
            (typedObj["properties"] !== null &&
                typeof typedObj["properties"] === "object" ||
                typeof typedObj["properties"] === "function") &&
            typedObj["properties"]["type"] === ShapeTypes.RackShape &&
            typeof typedObj["properties"]["name"] === "string" &&
            typeof typedObj["properties"]["layerId"] === "string" &&
            (typeof typedObj["properties"]["locked"] === "undefined" ||
                typedObj["properties"]["locked"] === false ||
                typedObj["properties"]["locked"] === true) &&
            typeof typedObj["properties"]["prio"] === "number" &&
            typeof typedObj["properties"]["cap"] === "number" &&
            typeof typedObj["properties"]["depth"] === "number" &&
            Array.isArray(typedObj["properties"]["columns"]) &&
            typedObj["properties"]["columns"].every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                typeof e["id"] === "string" &&
                typeof e["x"] === "number" &&
                Array.isArray(e["cells"]) &&
                e["cells"].every((e: any) =>
                    (e !== null &&
                        typeof e === "object" ||
                        typeof e === "function") &&
                    typeof e["id"] === "string" &&
                    (typeof e["cellTemplate"] === "undefined" ||
                        typeof e["cellTemplate"] === "string") &&
                    typeof e["height"] === "number" &&
                    typeof e["beamThickness"] === "number" &&
                    (typeof e["linkedHeight"] === "undefined" ||
                        e["linkedHeight"] === false ||
                        e["linkedHeight"] === true) &&
                    (typeof e["linkedBeamThickness"] === "undefined" ||
                        e["linkedBeamThickness"] === false ||
                        e["linkedBeamThickness"] === true) &&
                    (typeof e["depth"] === "undefined" ||
                        typeof e["depth"] === "number") &&
                    (typeof e["disabled"] === "undefined" ||
                        e["disabled"] === false ||
                        e["disabled"] === true) &&
                    Array.isArray(e["names"]) &&
                    e["names"].every((e: any) =>
                        Array.isArray(e) &&
                        e.every((e: any) =>
                            (e !== null &&
                                typeof e === "object" ||
                                typeof e === "function") &&
                            typeof e["id"] === "string" &&
                            typeof e["value"] === "string" &&
                            typeof e["user"] === "boolean" &&
                            (typeof e["disabled"] === "undefined" ||
                                e["disabled"] === false ||
                                e["disabled"] === true)
                        )
                    )
                ) &&
                typeof e["nbLevels"] === "number" &&
                typeof e["extendedLength"] === "number" &&
                Array.isArray(e["extendedLengthSegments"]) &&
                e["extendedLengthSegments"].every((e: any) =>
                    Array.isArray(e) &&
                    typeof e[0] === "string" &&
                    typeof e[1] === "number"
                ) &&
                typeof e["width"] === "number" &&
                typeof e["startHeight"] === "number" &&
                (e["linkedProperties"] !== null &&
                    typeof e["linkedProperties"] === "object" ||
                    typeof e["linkedProperties"] === "function") &&
                typeof e["linkedProperties"]["width"] === "boolean" &&
                typeof e["linkedProperties"]["nbLevels"] === "boolean" &&
                typeof e["linkedProperties"]["extendedLength"] === "boolean" &&
                typeof e["linkedProperties"]["startHeight"] === "boolean"
            ) &&
            typeof typedObj["properties"]["defaultCellHeight"] === "number" &&
            typeof typedObj["properties"]["defaultBeamThickness"] === "number" &&
            typeof typedObj["properties"]["defaultColumnWidth"] === "number" &&
            typeof typedObj["properties"]["defaultUprightWidth"] === "number" &&
            typeof typedObj["properties"]["defaultNbLevels"] === "number" &&
            typeof typedObj["properties"]["defaultExtendedLength"] === "number" &&
            (typedObj["properties"]["defaultFootProtection"] !== null &&
                typeof typedObj["properties"]["defaultFootProtection"] === "object" ||
                typeof typedObj["properties"]["defaultFootProtection"] === "function") &&
            typeof typedObj["properties"]["defaultFootProtection"]["enabled"] === "boolean" &&
            typeof typedObj["properties"]["defaultFootProtection"]["width"] === "number" &&
            typeof typedObj["properties"]["defaultFootProtection"]["height"] === "number" &&
            typeof typedObj["properties"]["defaultFootProtection"]["overflow"] === "number" &&
            Array.isArray(typedObj["properties"]["uprights"]) &&
            typedObj["properties"]["uprights"].every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                typeof e["width"] === "number" &&
                (typeof e["height"] === "undefined" ||
                    typeof e["height"] === "number") &&
                typeof e["enabled"] === "boolean" &&
                typeof e["linkedWidth"] === "boolean" &&
                typeof e["linkedFootProtection"] === "boolean" &&
                (typeof e["footProtection"] === "undefined" ||
                    (e["footProtection"] !== null &&
                        typeof e["footProtection"] === "object" ||
                        typeof e["footProtection"] === "function") &&
                    typeof e["footProtection"]["enabled"] === "boolean" &&
                    typeof e["footProtection"]["width"] === "number" &&
                    typeof e["footProtection"]["height"] === "number" &&
                    typeof e["footProtection"]["overflow"] === "number")
            ) &&
            (typeof typedObj["properties"]["anomaly"] === "undefined" ||
                (typedObj["properties"]["anomaly"] !== null &&
                    typeof typedObj["properties"]["anomaly"] === "object" ||
                    typeof typedObj["properties"]["anomaly"] === "function") &&
                (typedObj["properties"]["anomaly"]["state"] === 0 ||
                    typedObj["properties"]["anomaly"]["state"] === 1 ||
                    typedObj["properties"]["anomaly"]["state"] === 2) &&
                Array.isArray(typedObj["properties"]["anomaly"]["issues"]) &&
                typedObj["properties"]["anomaly"]["issues"].every((e: any) =>
                    isRackAnomalyId(e) as boolean
                )) &&
            (typeof typedObj["properties"]["conveyor"] === "undefined" ||
                (typedObj["properties"]["conveyor"] !== null &&
                    typeof typedObj["properties"]["conveyor"] === "object" ||
                    typeof typedObj["properties"]["conveyor"] === "function") &&
                typeof typedObj["properties"]["conveyor"]["zone"] === "string" &&
                (typedObj["properties"]["conveyor"]["access"] !== null &&
                    typeof typedObj["properties"]["conveyor"]["access"] === "object" ||
                    typeof typedObj["properties"]["conveyor"]["access"] === "function") &&
                typeof typedObj["properties"]["conveyor"]["access"]["enabled"] === "boolean" &&
                Array.isArray(typedObj["properties"]["conveyor"]["access"]["devices"]) &&
                typedObj["properties"]["conveyor"]["access"]["devices"].every((e: any) =>
                    (e !== null &&
                        typeof e === "object" ||
                        typeof e === "function") &&
                    typeof e["deviceId"] === "string" &&
                    typeof e["pinId"] === "number" &&
                    (e["openValue"] === 0 ||
                        e["openValue"] === 1)
                ) &&
                typeof typedObj["properties"]["conveyor"]["access"]["dAsk"] === "number" &&
                typeof typedObj["properties"]["conveyor"]["access"]["dStop"] === "number") &&
            (typeof typedObj["bbox"] === "undefined" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" &&
                typeof typedObj["bbox"][4] === "number" &&
                typeof typedObj["bbox"][5] === "number") &&
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            (typeof typedObj["hidden"] === "undefined" ||
                typedObj["hidden"] === false ||
                typedObj["hidden"] === true) ||
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            typedObj["type"] === "Feature" &&
            (typedObj["geometry"] !== null &&
                typeof typedObj["geometry"] === "object" ||
                typeof typedObj["geometry"] === "function") &&
            (typedObj["geometry"]["type"] === "Feature" ||
                typedObj["geometry"]["type"] === "Polygon" ||
                typedObj["geometry"]["type"] === "Point" ||
                typedObj["geometry"]["type"] === "MultiPoint" ||
                typedObj["geometry"]["type"] === "LineString" ||
                typedObj["geometry"]["type"] === "MultiLineString" ||
                typedObj["geometry"]["type"] === "MultiPolygon" ||
                typedObj["geometry"]["type"] === "GeometryCollection" ||
                typedObj["geometry"]["type"] === "FeatureCollection") &&
            (typeof typedObj["geometry"]["bbox"] === "undefined" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" &&
                typeof typedObj["geometry"]["bbox"][4] === "number" &&
                typeof typedObj["geometry"]["bbox"][5] === "number") &&
            typedObj["geometry"]["type"] === "Point" &&
            Array.isArray(typedObj["geometry"]["coordinates"]) &&
            typedObj["geometry"]["coordinates"].every((e: any) =>
                typeof e === "number"
            ) &&
            (typeof typedObj["id"] === "undefined" ||
                typeof typedObj["id"] === "string" ||
                typeof typedObj["id"] === "number") &&
            (typedObj["properties"] !== null &&
                typeof typedObj["properties"] === "object" ||
                typeof typedObj["properties"] === "function") &&
            typedObj["properties"]["type"] === ShapeTypes.DeviceShape &&
            typeof typedObj["properties"]["layerId"] === "string" &&
            (typeof typedObj["properties"]["locked"] === "undefined" ||
                typedObj["properties"]["locked"] === false ||
                typedObj["properties"]["locked"] === true) &&
            isDeviceType(typedObj["properties"]["deviceType"]) as boolean &&
            typeof typedObj["properties"]["IP"] === "string" &&
            typeof typedObj["properties"]["name"] === "string" &&
            typeof typedObj["properties"]["displayName"] === "string" &&
            (typeof typedObj["properties"]["pinsIn"] === "undefined" ||
                Array.isArray(typedObj["properties"]["pinsIn"]) &&
                typedObj["properties"]["pinsIn"].every((e: any) =>
                    (e !== null &&
                        typeof e === "object" ||
                        typeof e === "function") &&
                    typeof e["name"] === "string"
                )) &&
            (typeof typedObj["properties"]["pinsOut"] === "undefined" ||
                Array.isArray(typedObj["properties"]["pinsOut"]) &&
                typedObj["properties"]["pinsOut"].every((e: any) =>
                    (e !== null &&
                        typeof e === "object" ||
                        typeof e === "function") &&
                    typeof e["name"] === "string"
                )) &&
            (typeof typedObj["properties"]["frequency"] === "undefined" ||
                typeof typedObj["properties"]["frequency"] === "number") &&
            (typeof typedObj["properties"]["displayUI"] === "undefined" ||
                typedObj["properties"]["displayUI"] === false ||
                typedObj["properties"]["displayUI"] === true) &&
            (typeof typedObj["properties"]["port"] === "undefined" ||
                typeof typedObj["properties"]["port"] === "number") &&
            (typeof typedObj["properties"]["modbusType"] === "undefined" ||
                typedObj["properties"]["modbusType"] === "Bit" ||
                typedObj["properties"]["modbusType"] === "Register") &&
            (typeof typedObj["properties"]["ioAddresses"] === "undefined" ||
                Array.isArray(typedObj["properties"]["ioAddresses"]) &&
                typedObj["properties"]["ioAddresses"].every((e: any) =>
                    typeof e === "number"
                )) &&
            (typeof typedObj["properties"]["functionCodes"] === "undefined" ||
                Array.isArray(typedObj["properties"]["functionCodes"]) &&
                typedObj["properties"]["functionCodes"].every((e: any) =>
                    typeof e === "number"
                )) &&
            (typeof typedObj["properties"]["keepWritingOutputs"] === "undefined" ||
                typedObj["properties"]["keepWritingOutputs"] === false ||
                typedObj["properties"]["keepWritingOutputs"] === true) &&
            typeof typedObj["properties"]["prio"] === "number" &&
            (typeof typedObj["properties"]["loraID"] === "undefined" ||
                typeof typedObj["properties"]["loraID"] === "string") &&
            (typeof typedObj["properties"]["network"] === "undefined" ||
                typedObj["properties"]["network"] === "Standalone" ||
                typedObj["properties"]["network"] === "Gateway" ||
                typedObj["properties"]["network"] === "Device") &&
            (typeof typedObj["properties"]["comboxVersion"] === "undefined" ||
                typedObj["properties"]["comboxVersion"] === "Standard" ||
                typedObj["properties"]["comboxVersion"] === "Extended" ||
                typedObj["properties"]["comboxVersion"] === "Button") &&
            (typeof typedObj["properties"]["gateway"] === "undefined" ||
                typeof typedObj["properties"]["gateway"] === "string") &&
            (typeof typedObj["bbox"] === "undefined" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" &&
                typeof typedObj["bbox"][4] === "number" &&
                typeof typedObj["bbox"][5] === "number") &&
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            (typeof typedObj["hidden"] === "undefined" ||
                typedObj["hidden"] === false ||
                typedObj["hidden"] === true) ||
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            typedObj["type"] === "Feature" &&
            (typedObj["geometry"] !== null &&
                typeof typedObj["geometry"] === "object" ||
                typeof typedObj["geometry"] === "function") &&
            (typedObj["geometry"]["type"] === "Feature" ||
                typedObj["geometry"]["type"] === "Polygon" ||
                typedObj["geometry"]["type"] === "Point" ||
                typedObj["geometry"]["type"] === "MultiPoint" ||
                typedObj["geometry"]["type"] === "LineString" ||
                typedObj["geometry"]["type"] === "MultiLineString" ||
                typedObj["geometry"]["type"] === "MultiPolygon" ||
                typedObj["geometry"]["type"] === "GeometryCollection" ||
                typedObj["geometry"]["type"] === "FeatureCollection") &&
            (typeof typedObj["geometry"]["bbox"] === "undefined" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" ||
                Array.isArray(typedObj["geometry"]["bbox"]) &&
                typeof typedObj["geometry"]["bbox"][0] === "number" &&
                typeof typedObj["geometry"]["bbox"][1] === "number" &&
                typeof typedObj["geometry"]["bbox"][2] === "number" &&
                typeof typedObj["geometry"]["bbox"][3] === "number" &&
                typeof typedObj["geometry"]["bbox"][4] === "number" &&
                typeof typedObj["geometry"]["bbox"][5] === "number") &&
            typedObj["geometry"]["type"] === "Point" &&
            Array.isArray(typedObj["geometry"]["coordinates"]) &&
            typedObj["geometry"]["coordinates"].every((e: any) =>
                typeof e === "number"
            ) &&
            (typeof typedObj["id"] === "undefined" ||
                typeof typedObj["id"] === "string" ||
                typeof typedObj["id"] === "number") &&
            (typedObj["properties"] !== null &&
                typeof typedObj["properties"] === "object" ||
                typeof typedObj["properties"] === "function") &&
            typedObj["properties"]["type"] === ShapeTypes.NoteShape &&
            (typeof typedObj["properties"]["locked"] === "undefined" ||
                typedObj["properties"]["locked"] === false ||
                typedObj["properties"]["locked"] === true) &&
            isNoteDisplayMode(typedObj["properties"]["displayMode"]) as boolean &&
            typeof typedObj["properties"]["prio"] === "number" &&
            typeof typedObj["properties"]["layerId"] === "string" &&
            typeof typedObj["properties"]["name"] === "string" &&
            typeof typedObj["properties"]["size"] === "number" &&
            (typeof typedObj["bbox"] === "undefined" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" ||
                Array.isArray(typedObj["bbox"]) &&
                typeof typedObj["bbox"][0] === "number" &&
                typeof typedObj["bbox"][1] === "number" &&
                typeof typedObj["bbox"][2] === "number" &&
                typeof typedObj["bbox"][3] === "number" &&
                typeof typedObj["bbox"][4] === "number" &&
                typeof typedObj["bbox"][5] === "number") &&
            (typedObj !== null &&
                typeof typedObj === "object" ||
                typeof typedObj === "function") &&
            (typeof typedObj["hidden"] === "undefined" ||
                typedObj["hidden"] === false ||
                typedObj["hidden"] === true))
    )
}

export function isFeatureCollectionWithProperties(obj: unknown): obj is FeatureCollectionWithProperties {
    const typedObj = obj as FeatureCollectionWithProperties
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typedObj["type"] === "FeatureCollection" &&
        Array.isArray(typedObj["features"]) &&
        typedObj["features"].every((e: any) =>
        (e !== null &&
            typeof e === "object" ||
            typeof e === "function")
        ) &&
        (typeof typedObj["bbox"] === "undefined" ||
            Array.isArray(typedObj["bbox"]) &&
            typeof typedObj["bbox"][0] === "number" &&
            typeof typedObj["bbox"][1] === "number" &&
            typeof typedObj["bbox"][2] === "number" &&
            typeof typedObj["bbox"][3] === "number" ||
            Array.isArray(typedObj["bbox"]) &&
            typeof typedObj["bbox"][0] === "number" &&
            typeof typedObj["bbox"][1] === "number" &&
            typeof typedObj["bbox"][2] === "number" &&
            typeof typedObj["bbox"][3] === "number" &&
            typeof typedObj["bbox"][4] === "number" &&
            typeof typedObj["bbox"][5] === "number") &&
        (typedObj["properties"] === null ||
            (typedObj["properties"] !== null &&
                typeof typedObj["properties"] === "object" ||
                typeof typedObj["properties"] === "function") &&
            Object.entries<any>(typedObj["properties"])
                .every(([key, _value]) => (typeof key === "string")))
    )
}

export function isGeoJsonCircuitNotExtended(obj: unknown): obj is GeoJsonCircuit {
    const typedObj = obj as GeoJsonCircuit
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typedObj["type"] === "FeatureCollection" &&
        (typedObj["properties"] !== null &&
            typeof typedObj["properties"] === "object" ||
            typeof typedObj["properties"] === "function") &&
        Array.isArray(typedObj["features"]) &&
        typedObj["features"].every((e: any) =>
            isLayerFeatureCollection(e) as boolean
        )
    )
}

export function isLayerFeatureCollection(obj: unknown): obj is LayerFeatureCollection {
    const typedObj = obj as LayerFeatureCollection
    return (
        isFeatureCollectionWithProperties(typedObj) as boolean &&
        typeof typedObj["id"] === "string" &&
        Array.isArray(typedObj["features"]) &&
        typedObj["features"].every((e: any) =>
            isCircuitShapeSlow(e) as boolean
        ) &&
        (typedObj["properties"] !== null &&
            typeof typedObj["properties"] === "object" ||
            typeof typedObj["properties"] === "function") &&
        (typedObj["properties"]["shapesCount"] !== null &&
            typeof typedObj["properties"]["shapesCount"] === "object" ||
            typeof typedObj["properties"]["shapesCount"] === "function") &&
        typeof typedObj["properties"]["shapesCount"]["POINT"] === "number" &&
        typeof typedObj["properties"]["shapesCount"]["ZONE"] === "number" &&
        typeof typedObj["properties"]["shapesCount"]["SEGMENT"] === "number" &&
        typeof typedObj["properties"]["shapesCount"]["MEASURER"] === "number" &&
        typeof typedObj["properties"]["shapesCount"]["TURN"] === "number" &&
        typeof typedObj["properties"]["shapesCount"]["STOCK"] === "number" &&
        typeof typedObj["properties"]["shapesCount"]["RACK"] === "number" &&
        typeof typedObj["properties"]["shapesCount"]["DEVICE"] === "number" &&
        typeof typedObj["properties"]["shapesCount"]["NOTE"] === "number"
    )
}

export function isRackAnomalyId(obj: unknown): obj is RackAnomalyIds {
    const typedObj = obj as RackAnomalyIds
    return (
        (typedObj === RackAnomalyIds.cellNotAssigned ||
            typedObj === RackAnomalyIds.cellNotEnoughSpace ||
            typedObj === RackAnomalyIds.cellNotEnoughHeight ||
            typedObj === RackAnomalyIds.cellNotEnoughMarginLoadUpright ||
            typedObj === RackAnomalyIds.beamThicknessNotEnough ||
            typedObj === RackAnomalyIds.palletOverflowNotEnough ||
            typedObj === RackAnomalyIds.palletOverflowTooMuch ||
            typedObj === RackAnomalyIds.uprightWidthNotEnough)
    )
}

export function isPerceptionReference(obj: unknown): obj is PerceptionReference {
    const typedObj = obj as PerceptionReference
    return (
        (typedObj === "left" ||
            typedObj === "none" ||
            typedObj === "right" ||
            typedObj === "symmetrical")
    )
}

export function isCellTemplate(obj: unknown): obj is RackCellTemplate {
    const typedObj = obj as RackCellTemplate
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typeof typedObj["id"] === "string" &&
        typeof typedObj["name"] === "string" &&
        typeof typedObj["width"] === "number" &&
        typeof typedObj["height"] === "number" &&
        typeof typedObj["maximumLoadHeight"] === "number" &&
        typeof typedObj["approachDistance"] === "number" &&
        Array.isArray(typedObj["palletTypes"]) &&
        typedObj["palletTypes"].every((e: any) =>
            typeof e === "string"
        ) &&
        typeof typedObj["palletOverflow"] === "number" &&
        (typeof typedObj["loadLength"] === "undefined" ||
            typeof typedObj["loadLength"] === "number") &&
        typeof typedObj["leftObstacle"] === "number" &&
        typeof typedObj["rightObstacle"] === "number" &&
        Array.isArray(typedObj["loads"]) &&
        typedObj["loads"].every((e: any) =>
            (e !== null &&
                typeof e === "object" ||
                typeof e === "function") &&
            typeof e["name"] === "string" &&
            typeof e["N"] === "number" &&
            typeof e["a1"] === "number" &&
            (typeof e["a2"] === "undefined" ||
                typeof e["a2"] === "number") &&
            typeof e["W"] === "number" &&
            Array.isArray(e["references"]) &&
            e["references"].every((e: any) =>
                isPerceptionReference(e) as boolean
            ) &&
            (typeof e["center"] === "undefined" ||
                e["center"] === false ||
                e["center"] === true) &&
            (typeof e["palletOverflow"] === "undefined" ||
                typeof e["palletOverflow"] === "number") &&
            (typeof e["length"] === "undefined" ||
                typeof e["length"] === "number")
        ) &&
        typeof typedObj["selectedLoad"] === "number" &&
        typeof typedObj["color"] === "string" &&
        (typedObj["perception"] !== null &&
            typeof typedObj["perception"] === "object" ||
            typeof typedObj["perception"] === "function") &&
        typeof typedObj["perception"]["startHeight"] === "number" &&
        typeof typedObj["perception"]["startDepth"] === "number" &&
        typeof typedObj["perception"]["minimumHeightVerification"] === "number" &&
        typeof typedObj["perception"]["minimumVerticalClearanceForDrop"] === "number" &&
        typeof typedObj["perception"]["freeSpaceMinObjectSize"] === "number" &&
        typeof typedObj["perception"]["minimumLateralClearanceForDrop"] === "number" &&
        typeof typedObj["perception"]["minimumDepthClearanceForDrop"] === "number" &&
        typeof typedObj["perception"]["beamFaceXTolerance"] === "number" &&
        (typeof typedObj["perception"]["beamUpMarginObstacle"] === "undefined" ||
            typeof typedObj["perception"]["beamUpMarginObstacle"] === "number") &&
        typeof typedObj["perception"]["uprightYTolerance"] === "number" &&
        typeof typedObj["perception"]["beamHeightZTolerance"] === "number" &&
        typeof typedObj["perception"]["overhangTolerance"] === "number" &&
        Array.isArray(typedObj["perception"]["initialSolution"]) &&
        typeof typedObj["perception"]["initialSolution"][0] === "number" &&
        typeof typedObj["perception"]["initialSolution"][1] === "number" &&
        typeof typedObj["perception"]["initialSolution"][2] === "number" &&
        (typedObj["perception"]["rejectPercentage"] !== null &&
            typeof typedObj["perception"]["rejectPercentage"] === "object" ||
            typeof typedObj["perception"]["rejectPercentage"] === "function") &&
        typeof typedObj["perception"]["rejectPercentage"]["model"] === "number" &&
        typeof typedObj["perception"]["rejectPercentage"]["feet"] === "number" &&
        typeof typedObj["perception"]["rejectPercentage"]["pockets"] === "number" &&
        typeof typedObj["perception"]["minObstacleMultiplier"] === "number" &&
        typeof typedObj["perception"]["cameraProjectionBeforeInspectionPlane"] === "boolean" &&
        typeof typedObj["perception"]["horizontalProjectionOnInspectionPlane"] === "boolean" &&
        typeof typedObj["perception"]["horizontalProjection"] === "boolean" &&
        typeof typedObj["perception"]["flyingObjectDetection"] === "boolean" &&
        typeof typedObj["perception"]["activateCrossShapedMethod"] === "boolean" &&
        (typedObj["perception"]["relPickActivateCorr"] !== null &&
            typeof typedObj["perception"]["relPickActivateCorr"] === "object" ||
            typeof typedObj["perception"]["relPickActivateCorr"] === "function") &&
        (typedObj["perception"]["relPickActivateCorr"]["depth"] === 0 ||
            typedObj["perception"]["relPickActivateCorr"]["depth"] === 1) &&
        (typedObj["perception"]["relPickActivateCorr"]["lift"] === 0 ||
            typedObj["perception"]["relPickActivateCorr"]["lift"] === 1) &&
        (typedObj["perception"]["relPickActivateCorr"]["shift"] === 0 ||
            typedObj["perception"]["relPickActivateCorr"]["shift"] === 1) &&
        (typedObj["perception"]["relDropActivateCorr"] !== null &&
            typeof typedObj["perception"]["relDropActivateCorr"] === "object" ||
            typeof typedObj["perception"]["relDropActivateCorr"] === "function") &&
        (typedObj["perception"]["relDropActivateCorr"]["depth"] === 0 ||
            typedObj["perception"]["relDropActivateCorr"]["depth"] === 1 ||
            typedObj["perception"]["relDropActivateCorr"]["depth"] === 2) &&
        (typedObj["perception"]["relDropActivateCorr"]["lift"] === 0 ||
            typedObj["perception"]["relDropActivateCorr"]["lift"] === 1 ||
            typedObj["perception"]["relDropActivateCorr"]["lift"] === 2) &&
        (typedObj["perception"]["relDropActivateCorr"]["shift"] === 0 ||
            typedObj["perception"]["relDropActivateCorr"]["shift"] === 1 ||
            typedObj["perception"]["relDropActivateCorr"]["shift"] === 2) &&
        typeof typedObj["perception"]["rangefinderDetection"] === "boolean" &&
        typeof typedObj["perception"]["enablePerceptionPick"] === "boolean" &&
        typeof typedObj["perception"]["enablePerceptionDrop"] === "boolean" &&
        (typeof typedObj["beamDistanceToRackFront"] === "undefined" ||
            typeof typedObj["beamDistanceToRackFront"] === "number") &&
        (typeof typedObj["forConveyor"] === "undefined" ||
            typedObj["forConveyor"] === false ||
            typedObj["forConveyor"] === true)
    )
}

export function isDeviceType(obj: unknown): obj is DeviceType {
    const typedObj = obj as DeviceType
    return (
        (typedObj === "3BTCombox" ||
            typedObj === "IOECombox" ||
            typedObj === "mobileDockingStation" ||
            typedObj === "smartCharger" ||
            typedObj === "modbusDevice" ||
            typedObj === "comboxGen2")
    )
}

export function isNetworkType(obj: unknown): obj is NetworkType {
    const typedObj = obj as NetworkType
    return (
        (typedObj === "Standalone" ||
            typedObj === "Gateway" ||
            typedObj === "Device")
    )
}

export function isComboxVersionType(obj: unknown): obj is ComboxVersionType {
    const typedObj = obj as ComboxVersionType
    return (
        (typedObj === "Standard" ||
            typedObj === "Extended" ||
            typedObj === "Button")
    )
}

export function isNoteDisplayMode(obj: unknown): obj is NoteDisplayMode {
    const typedObj = obj as NoteDisplayMode
    return (
        (typedObj === "icon" || typedObj === "text" || typedObj === "image")
    )
}

export function isLibTrackItinerary(obj: unknown): obj is LibTrackItinerary {
    const typedObj = obj as LibTrackItinerary
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        (typeof typedObj["distance"] === "undefined" ||
            typeof typedObj["distance"] === "number") &&
        (typeof typedObj["cost"] === "undefined" ||
            typeof typedObj["cost"] === "number") &&
        (typeof typedObj["duration"] === "undefined" ||
            typeof typedObj["duration"] === "number") &&
        (typeof typedObj["listOfPosition"] === "undefined" ||
            Array.isArray(typedObj["listOfPosition"]) &&
            typedObj["listOfPosition"].every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                typeof e["x"] === "number" &&
                typeof e["y"] === "number" &&
                typeof e["heading"] === "number"
            )) &&
        (typeof typedObj["listOfPortionID"] === "undefined" ||
            Array.isArray(typedObj["listOfPortionID"]) &&
            typedObj["listOfPortionID"].every((e: any) =>
                typeof e === "number"
            )) &&
        typeof typedObj["errorID"] === "number" &&
        typeof typedObj["error"] === "string"
    )
}

export function isCheckTracklessResult(obj: unknown): obj is CheckTracklessResult {
    const typedObj = obj as CheckTracklessResult
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typeof typedObj["errorID"] === "number" &&
        (typeof typedObj["error"] === "undefined" ||
            typeof typedObj["error"] === "string") &&
        (typeof typedObj["distanceToOtherRack"] === "undefined" ||
            typeof typedObj["distanceToOtherRack"] === "number") &&
        (typeof typedObj["tracklessTrajectories"] === "undefined" ||
            (typedObj["tracklessTrajectories"] !== null &&
                typeof typedObj["tracklessTrajectories"] === "object" ||
                typeof typedObj["tracklessTrajectories"] === "function") &&
            Object.entries<any>(typedObj["tracklessTrajectories"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    typeof value["distance"] === "number" &&
                    Array.isArray(value["listOfPortionID"]) &&
                    value["listOfPortionID"].every((e: any) =>
                        typeof e === "number"
                    ) &&
                    Array.isArray(value["listOfPosition"]) &&
                    value["listOfPosition"].every((e: any) =>
                        (e !== null &&
                            typeof e === "object" ||
                            typeof e === "function") &&
                        typeof e["x"] === "number" &&
                        typeof e["y"] === "number" &&
                        typeof e["heading"] === "number"
                    ) ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    typeof value["error"] === "string") &&
                    typeof key === "string")))
    )
}

export function isGetAllCantonsResult(obj: unknown): obj is GetAllCantonsResult {
    const typedObj = obj as GetAllCantonsResult
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        (typeof typedObj["noStopCanton"] === "undefined" ||
            (typedObj["noStopCanton"] !== null &&
                typeof typedObj["noStopCanton"] === "object" ||
                typeof typedObj["noStopCanton"] === "function") &&
            Object.entries<any>(typedObj["noStopCanton"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number" &&
                    (typeof value["robotIndex"] === "undefined" ||
                        typeof value["robotIndex"] === "number")) &&
                    typeof key === "string"))) &&
        (typeof typedObj["noStopZoneCanton"] === "undefined" ||
            (typedObj["noStopZoneCanton"] !== null &&
                typeof typedObj["noStopZoneCanton"] === "object" ||
                typeof typedObj["noStopZoneCanton"] === "function") &&
            Object.entries<any>(typedObj["noStopZoneCanton"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number" &&
                    (typeof value["robotIndex"] === "undefined" ||
                        typeof value["robotIndex"] === "number")) &&
                    typeof key === "string")))
    )
}

export function isCantonAtPoint(obj: unknown): obj is CantonAtPoint {
    const typedObj = obj as CantonAtPoint
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        (typeof typedObj["currentCanton"] === "undefined" ||
            (typedObj["currentCanton"] !== null &&
                typeof typedObj["currentCanton"] === "object" ||
                typeof typedObj["currentCanton"] === "function") &&
            Object.entries<any>(typedObj["currentCanton"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number" &&
                    (typeof value["robotIndex"] === "undefined" ||
                        typeof value["robotIndex"] === "number")) &&
                    typeof key === "string"))) &&
        (typeof typedObj["linkedByCollision"] === "undefined" ||
            (typedObj["linkedByCollision"] !== null &&
                typeof typedObj["linkedByCollision"] === "object" ||
                typeof typedObj["linkedByCollision"] === "function") &&
            Object.entries<any>(typedObj["linkedByCollision"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number" &&
                    (typeof value["robotIndex"] === "undefined" ||
                        typeof value["robotIndex"] === "number")) &&
                    typeof key === "string"))) &&
        (typeof typedObj["linkedByDeadend"] === "undefined" ||
            (typedObj["linkedByDeadend"] !== null &&
                typeof typedObj["linkedByDeadend"] === "object" ||
                typeof typedObj["linkedByDeadend"] === "function") &&
            Object.entries<any>(typedObj["linkedByDeadend"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number" &&
                    (typeof value["robotIndex"] === "undefined" ||
                        typeof value["robotIndex"] === "number")) &&
                    typeof key === "string"))) &&
        (typeof typedObj["linkedByZone"] === "undefined" ||
            (typedObj["linkedByZone"] !== null &&
                typeof typedObj["linkedByZone"] === "object" ||
                typeof typedObj["linkedByZone"] === "function") &&
            Object.entries<any>(typedObj["linkedByZone"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number" &&
                    (typeof value["robotIndex"] === "undefined" ||
                        typeof value["robotIndex"] === "number")) &&
                    typeof key === "string"))) &&
        (typeof typedObj["manualCantons"] === "undefined" ||
            (typedObj["manualCantons"] !== null &&
                typeof typedObj["manualCantons"] === "object" ||
                typeof typedObj["manualCantons"] === "function") &&
            Object.entries<any>(typedObj["manualCantons"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number" &&
                    (typeof value["robotIndex"] === "undefined" ||
                        typeof value["robotIndex"] === "number")) &&
                    typeof key === "string"))) &&
        (typeof typedObj["pattern"] === "undefined" ||
            Array.isArray(typedObj["pattern"]) &&
            typedObj["pattern"].every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                typeof e["x"] === "number" &&
                typeof e["y"] === "number"
            ))
    )
}

export function isDeadendsAtPoint(obj: unknown): obj is DeadendsAtPoint {
    const typedObj = obj as DeadendsAtPoint
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        (typeof typedObj["manoeuver"] === "undefined" ||
            (typedObj["manoeuver"] !== null &&
                typeof typedObj["manoeuver"] === "object" ||
                typeof typedObj["manoeuver"] === "function") &&
            Object.entries<any>(typedObj["manoeuver"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number" &&
                    (typeof value["robotIndex"] === "undefined" ||
                        typeof value["robotIndex"] === "number")) &&
                    typeof key === "string"))) &&
        (typeof typedObj["kernelNoStop"] === "undefined" ||
            (typedObj["kernelNoStop"] !== null &&
                typeof typedObj["kernelNoStop"] === "object" ||
                typeof typedObj["kernelNoStop"] === "function") &&
            Object.entries<any>(typedObj["kernelNoStop"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number" &&
                    (typeof value["robotIndex"] === "undefined" ||
                        typeof value["robotIndex"] === "number")) &&
                    typeof key === "string"))) &&
        (typeof typedObj["neutral"] === "undefined" ||
            (typedObj["neutral"] !== null &&
                typeof typedObj["neutral"] === "object" ||
                typeof typedObj["neutral"] === "function") &&
            Object.entries<any>(typedObj["neutral"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number" &&
                    (typeof value["robotIndex"] === "undefined" ||
                        typeof value["robotIndex"] === "number")) &&
                    typeof key === "string"))) &&
        (typeof typedObj["blocked"] === "undefined" ||
            (typedObj["blocked"] !== null &&
                typeof typedObj["blocked"] === "object" ||
                typeof typedObj["blocked"] === "function") &&
            Object.entries<any>(typedObj["blocked"])
                .every(([key, value]) => ((typeof value === "undefined" ||
                    (value !== null &&
                        typeof value === "object" ||
                        typeof value === "function") &&
                    (value["start"] !== null &&
                        typeof value["start"] === "object" ||
                        typeof value["start"] === "function") &&
                    typeof value["start"]["x"] === "number" &&
                    typeof value["start"]["y"] === "number" &&
                    (value["end"] !== null &&
                        typeof value["end"] === "object" ||
                        typeof value["end"] === "function") &&
                    typeof value["end"]["x"] === "number" &&
                    typeof value["end"]["y"] === "number" &&
                    (typeof value["robotIndex"] === "undefined" ||
                        typeof value["robotIndex"] === "number")) &&
                    typeof key === "string")))
    )
}
