import { Sensors } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import WarningIcon from '@mui/icons-material/Warning';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from '@mui/material';
import {
  addLayerAction,
  copyAllShapesAction,
  enableAllGabaritsAction,
  removeAllGabaritsAction,
  transferToAnotherLayerAction,
  updateGabaritStateAction,
  updateLayerAction,
} from 'actions/circuit';
import { closeDialogAction } from 'actions/dialog';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useConfirm } from 'material-ui-confirm';
import { type GabaritProperties } from 'models/circuit';
import { LidarPosition } from 'models/maps';
import { useCallback, useMemo, useRef, useState } from 'react';
import type { LayerData } from 'reducers/circuit/state';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { getGabarit } from 'utils/circuit/get-gabarit';
import { getShapes } from 'utils/circuit/get-shapes-in-layer';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { balyoGradient } from 'utils/colors/colors';
import { PreferencesService } from 'utils/preferences';
import { searchAvailableGabarits } from '../properties/components/gabarit-select';

interface GabaritSelectProps {
  gabarit?: GabaritProperties;
  layer: LayerData;
  setIsMoreOptionsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export function GabaritSelectLayer({ gabarit, layer, setIsMoreOptionsOpen }: GabaritSelectProps): JSX.Element {
  const modelsName = useMemo(() => PreferencesService.getModelNames(), []);

  const computingLayerLidarGabarits = useAppSelector((state) => state.editor.shouldComputeLayerLidarGabarits);
  const gabaritFilter = useAppSelector((state) => state.local.filters.gabarit);

  if (!gabarit) {
    gabarit = getGabarit(gabarit, modelsName);
  }

  const dispatch = useAppDispatch();
  const availableGabarits = useMemo(() => searchAvailableGabarits(gabarit, modelsName), [gabarit, modelsName]);

  const handleChangeDefaultPatternValue = useCallback(
    (event: SelectChangeEvent, layerId: string): void => {
      dispatch(
        updateLayerAction({
          layerId,
          defaultPattern: event.target.value,
        })
      );
    },
    [dispatch]
  );

  const handleChangeDefaultModelValue = useCallback(
    (event: SelectChangeEvent, layerId: string): void => {
      dispatch(
        updateLayerAction({
          layerId,
          defaultModel: event.target.value,
        })
      );
    },
    [dispatch]
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = !!anchorEl;

  const handleClickOpenMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();

      setAnchorEl(e.currentTarget);
      setIsMoreOptionsOpen(true);
    },
    [setIsMoreOptionsOpen]
  );
  const handleClose = useCallback((): void => {
    dispatch(closeDialogAction());
    setIsMoreOptionsOpen(false);
  }, [dispatch, setIsMoreOptionsOpen]);

  const handleClickGenerateGabarits = useCallback(() => {
    if (!computingLayerLidarGabarits) {
      const drawing = CircuitService.getDrawingReference(); // Create a feature collection from the individual shape geometries

      const backgroundLidar = store.getState().maps.lidar['background-lidar'];
      if (!backgroundLidar) return;

      const mapOpacity = store.getState().local.filters.mapOpacity * 100;

      drawing.drawLidar(backgroundLidar, LidarPosition.Background, mapOpacity, false, layer.id);

      handleClose();
    }
  }, [computingLayerLidarGabarits, handleClose, layer.id]);

  const disabledComputeGabarits = computingLayerLidarGabarits || !layer.defaultPattern || !layer.defaultModel;

  const displayGabarits = layer.displayGabarits !== undefined ? layer.displayGabarits : true;

  const updateDisplayGabarit = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, layer: LayerData, displayGabarits: boolean) => {
      const newDisplayGabarits = !displayGabarits;
      dispatch(
        updateGabaritStateAction({
          layerId: layer.id,
          newDisplayGabarit: newDisplayGabarits,
        })
      );
    },
    [dispatch]
  );

  const confirm = useConfirm();

  const handleEnableAllGabarits = useCallback(
    async (layerId: string, defaultModel: string | undefined, defaultPattern: string | undefined) => {
      try {
        await confirm({ title: `Are you sure you want to enable all gabarits in this layer?`, allowClose: false });
      } catch (e) {
        return;
      }

      if (defaultModel && defaultPattern) {
        dispatch(
          enableAllGabaritsAction({
            layerId: layerId,
            defaultModel: defaultModel,
            defaultPattern: defaultPattern,
          })
        );
        handleClose();
      }
    },
    [confirm, dispatch, handleClose]
  );

  const handleDeleteAllGabarits = useCallback(
    async (layerId: string) => {
      try {
        await confirm({ title: `Are you sure you want to remove all gabarits in this layer?`, allowClose: false });
      } catch (e) {
        return;
      }

      dispatch(removeAllGabaritsAction({ layerId: layerId }));
      handleClose();
    },
    [dispatch, handleClose, confirm]
  );

  const layers = useAppSelector((state) => state.circuit.present.layers.layers);
  const layersArr = useMemo(() => {
    return Object.values(layers);
  }, [layers]);

  const shapesInTheLayer = useMemo(() => getShapes(layer.id), [layer.id]);

  const transferToAnotherLayerListItemRef = useRef<HTMLButtonElement | null>(null);
  const [openMenuTransferAnotherLayer, setOpenMenuTransferAnotherLayer] = useState(false);

  const handleCloseMenuTransferAnotherLayer = useCallback(() => {
    setOpenMenuTransferAnotherLayer(false);
  }, []);

  const handleClickTransferAnotherLayer = useCallback(() => {
    setOpenMenuTransferAnotherLayer(true);
  }, []);

  const handleTransferToAnotherLayer = useCallback(
    async (layerId: string, layerName: string) => {
      try {
        await confirm({
          title: `All the shapes will be transferred into the layer ${layerName}?`,
          allowClose: false,
        });
      } catch (e) {
        return;
      }

      handleCloseMenuTransferAnotherLayer();

      dispatch(
        transferToAnotherLayerAction({
          layerId,
          shapes: shapesInTheLayer,
        })
      );
      SnackbarUtils.success(`The shapes have been tranferred to ${layerName}`);

      setAnchorEl(null);
      setIsMoreOptionsOpen(false);
    },
    [confirm, dispatch, handleCloseMenuTransferAnotherLayer, setIsMoreOptionsOpen, shapesInTheLayer]
  );

  const handleDuplicateLayer = useCallback(
    (layerName: string, layerId: string): void => {
      const duplicateName = `${layerName} (copy)`;
      const id = generateShapeId();

      const shapesToCopy = CircuitService.getShapes(layerId);

      dispatch(
        addLayerAction({
          name: duplicateName,
          visibility: true,
          isDraft: false,
          color: balyoGradient[Math.floor(Math.random() * balyoGradient.length)],
          userAction: true,
          id: id,
        })
      );

      dispatch(
        copyAllShapesAction({
          toLayerId: id,
          shapes: shapesToCopy,
        })
      );

      SnackbarUtils.success(`${layerName} has been duplicated as ${duplicateName}`);

      setAnchorEl(null);
      setIsMoreOptionsOpen(false);
    },
    [dispatch, setIsMoreOptionsOpen]
  );

  return (
    <>
      <IconButton onClick={handleClickOpenMenu} size="large">
        <MoreVertIcon />
      </IconButton>
      {open && (
        <>
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
              setIsMoreOptionsOpen(false);
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <MenuItem
              sx={{ justifyContent: 'flex-end', cursor: 'default', ':hover': { backgroundColor: 'white' } }}
              disableTouchRipple
            >
              <HelpIconTooltip title="On each layer, you can add multiple types of robots. The select inputs are just for visualization, to facilitate which robot model and pattern you want to display easily"></HelpIconTooltip>
            </MenuItem>
            <MenuItem sx={{ ':hover': { backgroundColor: 'white' } }} disableTouchRipple>
              <FormControl fullWidth>
                <InputLabel>Default model</InputLabel>
                <Select
                  label="Default model"
                  value={layer.defaultModel ?? ''}
                  onChange={(event: SelectChangeEvent) => handleChangeDefaultModelValue(event, layer.id)}
                  fullWidth
                >
                  {modelsName.map((modelName) => (
                    <MenuItem value={modelName} key={modelName}>
                      {modelName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MenuItem>
            <MenuItem sx={{ ':hover': { backgroundColor: 'white' } }} disableTouchRipple>
              <FormControl fullWidth>
                <InputLabel>Default pattern</InputLabel>
                <Select
                  label="Default pattern"
                  value={layer.defaultPattern ?? ''}
                  onChange={(event) => {
                    handleChangeDefaultPatternValue(event, layer.id);
                  }}
                  fullWidth
                >
                  {availableGabarits.map((gabarit) => (
                    <MenuItem key={gabarit.prefName} value={gabarit.prefName}>
                      {gabarit.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MenuItem>
            <MenuItem
              sx={{
                cursor: 'default',
                ':hover': { backgroundColor: 'white' },
              }}
              disableTouchRipple
            >
              <Tooltip
                title={
                  !gabaritFilter
                    ? 'Gabarits are hidden, you must display them by turning on the gabarits filter on the display filters menu to enable all gabarits'
                    : ''
                }
              >
                <span style={{ width: '100%' }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleEnableAllGabarits(layer.id, layer.defaultModel, layer.defaultPattern);
                    }}
                    disabled={!gabaritFilter ? true : !layer.defaultModel || !layer.defaultPattern}
                    fullWidth
                  >
                    Enable all gabarits
                  </Button>
                </span>
              </Tooltip>
            </MenuItem>

            <Divider sx={{ margin: '10px' }}></Divider>

            <MenuItem
              sx={{
                cursor: 'default',
                ':hover': { backgroundColor: 'white' },
                display: 'flex',
                justifyContent: 'space-between',
              }}
              disableTouchRipple
            >
              Display all gabarits for this layer:
              <Box component={'div'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {!gabaritFilter && <GabaritFilterWarning />}
                <Switch
                  checked={displayGabarits}
                  onChange={(e) => updateDisplayGabarit(e, layer, displayGabarits)}
                  disabled={!gabaritFilter}
                ></Switch>
              </Box>
            </MenuItem>
            <MenuItem
              sx={{
                cursor: 'default',
                ':hover': { backgroundColor: 'white' },
              }}
              disableTouchRipple
            >
              <Button
                variant="contained"
                onClick={() => {
                  handleDeleteAllGabarits(layer.id);
                }}
                color={'error'}
                fullWidth
              >
                Delete all gabarits
              </Button>
            </MenuItem>
            <MenuItem
              sx={{
                cursor: 'default',
                ':hover': { backgroundColor: 'white' },
              }}
              disableTouchRipple
            >
              <Button
                variant="contained"
                onClick={handleClickGenerateGabarits}
                disabled={disabledComputeGabarits}
                startIcon={<Sensors />}
              >
                Compute collision with lidar navigation map
              </Button>
            </MenuItem>

            <Divider sx={{ margin: '10px' }}></Divider>

            <MenuItem
              sx={{
                cursor: 'default',
                ':hover': { backgroundColor: 'white' },
              }}
              disableTouchRipple
            >
              <Button
                variant="contained"
                startIcon={<MoveUpIcon />}
                endIcon={<ArrowRightIcon />}
                onClick={handleClickTransferAnotherLayer}
                disabled={!shapesInTheLayer || !shapesInTheLayer.length}
                ref={transferToAnotherLayerListItemRef}
                fullWidth
              >
                Transfer all shapes to another layer
              </Button>
            </MenuItem>
            <MenuItem
              sx={{
                cursor: 'default',
                ':hover': { backgroundColor: 'white' },
              }}
              disableTouchRipple
            >
              <Button
                variant="contained"
                onClick={() => {
                  handleDuplicateLayer(layer.name, layer.id);
                }}
                fullWidth
              >
                Duplicate the layer
              </Button>
            </MenuItem>
          </Menu>
          {!!(transferToAnotherLayerListItemRef.current && openMenuTransferAnotherLayer) && (
            <Menu
              anchorEl={transferToAnotherLayerListItemRef.current}
              open={openMenuTransferAnotherLayer}
              onClose={handleCloseMenuTransferAnotherLayer}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                maxHeight: '50vh',
              }}
            >
              {layersArr.map((layerT) => {
                if (layerT.name !== layer.name) {
                  return (
                    <MenuItem
                      key={layer.id}
                      onClick={() => {
                        handleTransferToAnotherLayer(layerT.id, layerT.name);
                      }}
                      dense={true}
                      sx={{
                        boxShadow: `inset 3px 0px 0px 0px ${layerT.color}`,
                      }}
                    >
                      {layerT.name}
                    </MenuItem>
                  );
                }

                return null;
              })}
            </Menu>
          )}
        </>
      )}
    </>
  );
}

export function GabaritFilterWarning(): JSX.Element {
  return (
    <Tooltip
      title={'Gabarits are hidden, you can display them by turning on the gabarits filter on the display filters menu'}
    >
      <WarningIcon color={'warning'} />
    </Tooltip>
  );
}
