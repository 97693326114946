import type { YArray } from 'yjs/dist/src/internals';
import { awareness, localDoc, MAX_EDITING_USERS, remoteDoc } from '../../multiplayer/globals';

/**
 * Generates a shape id
 * @returns the shape id
 */
export function generateShapeId(): string {
  if (!window.nextFreeId) window.nextFreeId = 1;

  try {
    if (remoteDoc) {
      if (!awareness) throw new Error('Missing awareness');
      const localState = awareness.getLocalState();
      let sessionId = localState?.i as string;

      const otherState = Array.from(awareness.getStates().entries());

      const sessionIdUsedArray: Array<[number, string]> = [];
      const isConflictSessionId = otherState.find(([clientId, state]) => {
        if (clientId === awareness?.clientID) return false;

        sessionIdUsedArray.push([clientId, state.sessionId]);

        return state.sessionId === sessionId;
      });
      if (!!isConflictSessionId) {
        const sessionIdOrder = !!(awareness.clientID > isConflictSessionId[0]);

        if (sessionIdOrder) {
          for (let i = 1; i < MAX_EDITING_USERS; i++) {
            const uniqueSessionId = i.toString();
            if (!sessionIdUsedArray.some(([, usedSessionId]) => usedSessionId === uniqueSessionId)) {
              awareness.setLocalStateField('i', uniqueSessionId);
              sessionId = uniqueSessionId;
              break;
            }
          }
        }
      }

      const localNextFreeIdArray: YArray<number> = localDoc.getArray('nextFreeId');
      const nextFreeId = localNextFreeIdArray.toArray()[+sessionId - 1];
      const zeroSeparation = '00000000'.substring(0, 9 - nextFreeId.toString().length);

      localDoc?.transact(() => {
        localNextFreeIdArray.delete(+sessionId - 1, 1);
        localNextFreeIdArray.insert(+sessionId - 1, [nextFreeId + 1]);
      });

      window.nextFreeIdArray = localNextFreeIdArray?.toArray();

      return `${sessionId}${zeroSeparation}${nextFreeId}`;
    }
  } catch (error) {}

  const nextFreeId = window.nextFreeId++;
  window.nextFreeIdArray[0] = nextFreeId;

  return nextFreeId.toString();
}

/**
 * Get the next free id
 * @returns the next free id for the next shape to be created
 */
export function getNextFreeId(): number {
  if (!window.nextFreeId) window.nextFreeId = 1;
  if (!window.nextFreeIdArray?.[0]) window.nextFreeIdArray = [1];

  const nextFreeId = window.nextFreeId++;
  window.nextFreeIdArray[0] = nextFreeId;

  return nextFreeId;
}
