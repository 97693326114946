import { getCameraFovAngle } from 'components/properties/rack-properties/utils-extended-length';
import { getDefaultRackCellTemplate } from './circuit/default-circuit-shapes';
import { toRad } from './helpers';
import { cpxStrToPosition } from './parse-cpx';
import { PreferencesService } from './preferences';

/** Number of digits after the comma that has meaning for an extended length */
export const nbDigitsExtendedLength = 2;

interface ComputeAutoExtendedLengthParams {
  safetyPointMargin?: number;
  perceptionEnabled?: boolean;
  palletOverflow?: number;

  palletWidth?: number;
  yCameraOffset?: number;
  uprightYTolerance?: number;
  distancePalletToUpright?: number;
  cameraFovAngle?: number;
  backrestAbcissa?: number;

  /**
   * Robot serials to consider for the computation.
   * If not set, all robots are considered.
   */
  truckSerials?: string[];
  /**
   * Robot model names to consider for the computation.
   * If not set, all robots are considered.
   */
  modelNames?: string[];
}

/**
 * This function automatically compute the appropriate extended length for a given rack and for all the trucks passed as parameters.
 * @see Link to the Redmine ticket - https://redmine.balyo.com/issues/43578
 * @param params the parameters
 * @returns the computed extended length
 */
export function computeAutoExtendedLength(params: ComputeAutoExtendedLengthParams): number {
  const defaultCellTemplate = getDefaultRackCellTemplate();

  const {
    safetyPointMargin = defaultCellTemplate.approachDistance,
    perceptionEnabled = true,
    palletOverflow = defaultCellTemplate.palletOverflow,
  } = params;

  if (!PreferencesService.arePreferencesFullyLoaded()) {
    throw new Error('Preferences are not fully loaded');
  }

  let trucks = PreferencesService.getTrucks();
  if (params.modelNames) {
    trucks = trucks.filter((truck) => params.modelNames?.includes(truck.modelName));
  }

  if (params.truckSerials) {
    trucks = trucks.filter((truck) => params.truckSerials?.includes(truck.serial));
  }

  const extendedLengths: number[] = [];

  for (let i = 0; i < trucks.length; i++) {
    const truck = trucks[i];

    const serial = truck.serial;

    const turretXPositionForksUpPref = PreferencesService.getPreferenceValue(
      'trajectoryDriver/robotKinematicsModel/turretXPositionForksUp',
      serial
    );
    const turretXPositionForksDownPref = PreferencesService.getPreferenceValue(
      'trajectoryDriver/robotKinematicsModel/turretXPositionForksDown',
      serial
    );
    const maxTurretAnglePref = PreferencesService.getPreferenceValue(
      'trajectoryDriver/robotKinematicsModel/maxTurretAngle',
      serial
    );
    const minTurretAnglePref = PreferencesService.getPreferenceValue(
      'trajectoryDriver/robotKinematicsModel/minTurretAngle',
      serial
    );
    let wheelOffset_yPref: string | string[] = '0';
    try {
      wheelOffset_yPref = PreferencesService.getPreferenceValue(
        'trajectoryDriver/robotKinematicsModel/wheelOffset_y',
        serial
      );
    } catch (e) {
      // it looks like some robots don't have this preference set (KGen2 for example)
    }

    if (
      Array.isArray(turretXPositionForksUpPref) ||
      Array.isArray(turretXPositionForksDownPref) ||
      Array.isArray(maxTurretAnglePref) ||
      Array.isArray(minTurretAnglePref) ||
      Array.isArray(wheelOffset_yPref)
    ) {
      throw new Error('A preference is an array and should not be');
    }

    // const turretXPositionForksDown = parseFloat(turretXPositionForksDownPref);
    const turretXPositionForksUp = parseFloat(turretXPositionForksUpPref);
    const maxTurretAngle = parseFloat(maxTurretAnglePref);
    const minTurretAngle = parseFloat(minTurretAnglePref);
    const whellOffset_y = parseFloat(wheelOffset_yPref);

    const navigationMargin = 0.075; // m
    const palletWidthMargin = 0.05; //m

    // 1.	Minimal Turning Radius: (Can be done only once per model, not linked to rack)

    const minTurnRadiusLeft = turretXPositionForksUp * Math.tan(toRad(90) - toRad(maxTurretAngle)) + whellOffset_y;
    const minTurnRadiusRight = turretXPositionForksUp * Math.tan(toRad(90) + toRad(minTurretAngle)) - whellOffset_y;

    const minTurnRadius = Math.max(minTurnRadiusLeft, minTurnRadiusRight);

    // 2. Rotational Constraint: (Can be done only once per model, not linked to rack)

    const shape0Pref = PreferencesService.getPreferenceValue('safetyGen2/forward/shape0', serial);
    if (!Array.isArray(shape0Pref)) throw new Error('shape0 is not an array');

    const shape0 = shape0Pref.map((cpx) => cpxStrToPosition(cpx, false));

    const shape0Filtered = shape0.filter((point) => point[0] < 0 && point[1] > 0);

    const ptsRadius = shape0Filtered.map((point) => Math.sqrt(point[0] ** 2 + (point[1] - minTurnRadius) ** 2));

    const rotationalConstraint = Math.max(...ptsRadius) + navigationMargin;

    // 3.	Rear Clearance after turn: (Can precalculated once per model and corrected per rack)
    // This value needs to be calculated for “every point” on the safety shape that complies with the primary filter

    const shape0_XcoordsVector = shape0Filtered.map((point) => point[0]);

    const rearClearanceConstraint = -Math.min(...shape0_XcoordsVector) + safetyPointMargin;

    // 4. 4.	Perception Constraint: (Needs to be calculated per rack)
    // IF perception constraint option is NOT active, this value shall ne set to 0 (zero)
    // IF perception constraint option is active, calculate as indicated below
    let perceptionConstraint = 0.0;
    if (perceptionEnabled) {
      let yCameraOffset = params.yCameraOffset;
      if (yCameraOffset === undefined) {
        try {
          const prefValue = PreferencesService.getPreferenceValue('perception/forksCamera/yTranslation', serial);
          if (Array.isArray(prefValue)) throw new Error('yCameraOffset is an array');
          yCameraOffset = parseFloat(prefValue);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('Could not get yCameraOffset from preferences', e);
        }
      }

      let backrestAbcissa = params.backrestAbcissa;
      if (backrestAbcissa === undefined) {
        try {
          const prefValue = PreferencesService.getPreferenceValue('general/backrestAbscissa', serial);
          if (Array.isArray(prefValue)) throw new Error('backrestAbcissa is an array');
          backrestAbcissa = parseFloat(prefValue);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('Could not get backrestAbcissa from preferences', e);
        }
      }

      const cameraFovAngle = params.cameraFovAngle ?? getCameraFovAngle(serial);

      // eslint-disable-next-line no-console
      if (params.palletWidth === undefined) console.log('palletWidth is undefined');
      // eslint-disable-next-line no-console
      if (yCameraOffset === undefined) console.log('yCameraOffset is undefined');
      // eslint-disable-next-line no-console
      if (params.uprightYTolerance === undefined) console.log('uprightYTolerance is undefined');
      // eslint-disable-next-line no-console
      if (params.distancePalletToUpright === undefined) console.log('distancePalletToUpright is undefined');
      // eslint-disable-next-line no-console
      if (cameraFovAngle === undefined) console.log('cameraFovAngle is undefined');
      // eslint-disable-next-line no-console
      if (backrestAbcissa === undefined) console.log('backrestAbcissa is undefined');

      if (
        params.palletWidth === undefined ||
        yCameraOffset === undefined ||
        params.uprightYTolerance === undefined ||
        params.distancePalletToUpright === undefined ||
        cameraFovAngle === undefined ||
        backrestAbcissa === undefined
      ) {
        perceptionConstraint = 0;
      } else {
        perceptionConstraint =
          ((params.palletWidth + palletWidthMargin) / 2 +
            Math.abs(yCameraOffset) +
            params.uprightYTolerance +
            params.distancePalletToUpright) /
            Math.tan(toRad(cameraFovAngle / 2)) -
          backrestAbcissa;
      }
    }

    // 5.	H2_AUTO: (Needs to be calculated per rack) -> same as AST tool, to be used as validation point

    const H2_AUTO = Math.max(rotationalConstraint, rearClearanceConstraint, perceptionConstraint);

    const extendedLength = H2_AUTO + Math.max(0, palletOverflow);

    extendedLengths.push(extendedLength);
  }

  const maxExtendedLength = Math.max(...extendedLengths);

  return maxExtendedLength;
}
