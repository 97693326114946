import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { addFlow, setFlow, setSelectedFlowId } from 'flows/flows';
import { useCallback, useMemo, useState } from 'react';
import store, { useAppDispatch, useAppSelector } from 'store';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { FlowForm } from './flow-form';
import { FlowListAccordion } from './flow-list-accordion';

export enum FlowConfigDisplay {
  Default,
  Edition,
  Creation,
}

export const maximumFlowNameLength = 30;

export interface FlowsContentProps {
  setSelectedTab: (tabToSelect: string) => void;
}
export function FlowsContent(props: FlowsContentProps): JSX.Element {
  const { setSelectedTab } = props;

  const flows = useAppSelector((state) => state.flows.flows);
  const selectedFlowId = useAppSelector((state) => state.flows.selectedFlowId);

  const dispatch = useAppDispatch();

  const [flowConfigDisplay, setFlowConfigDisplay] = useState(FlowConfigDisplay.Default);

  //Add the default palletsPerTask value for the already created flows
  flows.forEach((flow) => {
    if (!flow.palletsPerTask) {
      store.dispatch(setFlow({ ...flow, palletsPerTask: 1 }));
    }
  });

  const selectedFlow = useMemo(() => {
    return flows.find((flow) => flow.id === selectedFlowId);
  }, [flows, selectedFlowId]);

  const handleCreateFlow = useCallback(() => {
    let newFlowName = 'New Flow';
    let nb = 1;
    // eslint-disable-next-line no-loop-func
    while (flows.find((flow) => flow.name === newFlowName)) {
      newFlowName = `New Flow ${nb++}`;
    }

    const newFlowId = generateShapeId();
    dispatch(
      addFlow({
        id: newFlowId,
        name: newFlowName,
        stations: [],
        robotsAssigned: 'all',
        palletsPerTask: 1,
      })
    );

    dispatch(setSelectedFlowId(newFlowId));

    setFlowConfigDisplay(FlowConfigDisplay.Creation);
  }, [dispatch, flows]);

  return (
    <Box component="div">
      {flowConfigDisplay === FlowConfigDisplay.Default && (
        <Stack direction="row" spacing={1} sx={{ pb: 2, pr: 1 }}>
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={handleCreateFlow}
            endIcon={<AddCircleIcon />}
            sx={{
              marginTop: (theme) => theme.spacing(1),
            }}
          >
            Create flow
          </Button>
        </Stack>
      )}

      {flowConfigDisplay !== FlowConfigDisplay.Default && selectedFlow && (
        <FlowForm
          flows={flows}
          flowConfigDisplay={flowConfigDisplay}
          setFlowConfigDisplay={setFlowConfigDisplay}
          flow={selectedFlow}
          setSelectedTab={setSelectedTab}
        />
      )}

      {flowConfigDisplay === FlowConfigDisplay.Default && (
        <FlowListAccordion
          flowConfigDisplay={flowConfigDisplay}
          setFlowConfigDisplay={setFlowConfigDisplay}
          setSelectedTab={setSelectedTab}
        />
      )}
    </Box>
  );
}
