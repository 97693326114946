import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExploreIcon from '@mui/icons-material/Explore';
import { Box, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { saveCircuitToHistoryAction, segmentMovedAction } from 'actions/circuit';
import { saveRackAction } from 'actions/racks';
import { findShapeOrientation } from 'drawings/helpers';
import { cloneDeep } from 'lodash';
import type { CircuitShape } from 'models/circuit';
import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch } from 'store';
import {
  isCircuitMeasurer,
  isCircuitPoint,
  isCircuitRack,
  isCircuitSegment,
  isCircuitStockZone,
} from 'utils/circuit/shape-guards';
import type { UpdateStockZoneParams } from 'utils/circuit/stockzones';
import { updateStockZone } from 'utils/circuit/stockzones';
import { toRad } from 'utils/helpers';
import { isNumberArr, isNumberArrArr, isNumberArrArrArr } from 'utils/is-number-arr';
import { saveMeasurerAction } from '../../../actions/measurers';

interface MovementArrowButtonsProps {
  handleMoveShape: (params?: { distanceX?: number; distanceY?: number }) => void;
  disabled: boolean;
  shape: CircuitShape;
}

export const MovementArrowButtons: React.FC<MovementArrowButtonsProps> = ({ handleMoveShape, disabled, shape }) => {
  const [distance, setDistance] = useState<number>(10); // mm
  const [useShapeOrientation, setUseShapeOrientation] = useState<boolean>(false);

  const shapeInputAngle = useMemo(() => {
    // If we do not use shape orientation, we want to move the shapes as if the angle of the shape was 0 deg
    if (!useShapeOrientation) {
      return 0;
    }

    const coords = shape.geometry.coordinates;

    let angle = 0;
    if (isCircuitRack(shape)) {
      // it's a rack, the cap is embedded in the properties
      angle = shape.properties.cap;
    } else if (isCircuitStockZone(shape)) {
      // it's a stockzone, the cap is embedded in the properties
      angle = shape.properties.cap;
    } else if (isCircuitPoint(shape)) {
      // the shape is a pointn the orientation is embedded in the properties
      angle = shape.properties.orientation;
    } else if (isNumberArrArr(coords)) {
      // the shape is a linestring (segments, measurer, etc.)
      angle = findShapeOrientation(coords[0], coords[coords.length - 1]);
    } else {
      // eslint-disable-next-line no-console
      console.error(`MovementArrowButtons: Case not handled in the angle computation`);
    }

    const normalizeAngle = (angle + 360) % 360;

    return normalizeAngle;
  }, [shape, useShapeOrientation]);

  const handleChangeButtonsRotations = useCallback(
    (orientation: number, distance: number) => {
      // angle in degrees
      const angleInDegrees = shapeInputAngle + orientation;
      // Calculating the angle in radian
      const angleInRadians = toRad(angleInDegrees);
      const distanceX = (distance / 10) * Math.cos(angleInRadians);
      const distanceY = (distance / 10) * Math.sin(angleInRadians);

      handleMoveShape({
        distanceX,
        distanceY,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shapeInputAngle, handleMoveShape, shape]
  );

  const handleDistanceChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let newValue = parseFloat(event.target.value);

    if (isNaN(newValue)) {
      newValue = 0;
    }

    setDistance(newValue);
  };

  const toggleArrowType = (): void => {
    setUseShapeOrientation((prev) => !prev);
  };

  return (
    <Box component="div" display="flex" flexDirection="column">
      <TextField
        type="number"
        label="Slide distance"
        value={distance.toString()}
        onChange={handleDistanceChange}
        disabled={disabled}
        inputProps={{ min: 0 }}
        InputProps={{
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        }}
        variant="standard"
      />
      <Box component="div" display="flex" flexDirection="row" justifyContent="center">
        {/* Angle in the segment is - angle in the css transform  */}
        <IconButton disabled={disabled} color="primary" onClick={() => handleChangeButtonsRotations(0, distance)}>
          <ArrowForwardIcon sx={{ transform: `rotate(${-shapeInputAngle}deg)` }} />
        </IconButton>
        <IconButton disabled={disabled} color="primary" onClick={() => handleChangeButtonsRotations(-90, distance)}>
          <ArrowForwardIcon sx={{ transform: `rotate(${-(shapeInputAngle - 90)}deg)` }} />
        </IconButton>
        <IconButton disabled={disabled} color="primary" onClick={() => handleChangeButtonsRotations(180, distance)}>
          <ArrowForwardIcon sx={{ transform: `rotate(${-(shapeInputAngle + 180)}deg)` }} />
        </IconButton>
        <IconButton disabled={disabled} color="primary" onClick={() => handleChangeButtonsRotations(90, distance)}>
          <ArrowForwardIcon sx={{ transform: `rotate(${-(shapeInputAngle + 90)}deg)` }} />
        </IconButton>

        <Tooltip
          title={useShapeOrientation ? 'Move the element along the axis' : 'Move the element along its orientation'}
        >
          <IconButton
            onClick={toggleArrowType}
            color="primary"
            sx={{
              marginLeft: (theme) => theme.spacing(1),
            }}
          >
            <ExploreIcon
              sx={{
                transition: 'all 1s ease-in-out',
                transform: useShapeOrientation ? 'rotate(1600deg)' : 'rotate(135deg)',
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

type ShapeMovedActionType =
  | typeof saveMeasurerAction
  | typeof segmentMovedAction
  | typeof saveRackAction
  | typeof updateStockZone;

interface MovementArrowButtonsGroupProps {
  shape: CircuitShape;
  shapeId: string;
  locked?: boolean;
  shapeMovedAction: ShapeMovedActionType;
  children?: JSX.Element[];
}

export const MovementArrowButtonsGroup: React.FC<MovementArrowButtonsGroupProps> = ({
  shape,
  shapeId,
  locked = false,
  shapeMovedAction,
  children,
}) => {
  const dispatch = useAppDispatch();

  const handleMoveShape = useCallback(
    (params?: { distanceX?: number; distanceY?: number }): void => {
      if (!shape) return;

      const distanceX = params?.distanceX ?? 0;
      const distanceY = params?.distanceY ?? 0;

      const newCoordinates = cloneDeep(shape.geometry.coordinates);
      let coordsToOffset: number[][];
      if (isNumberArrArr(newCoordinates)) {
        // newCoordinates = linestring
        coordsToOffset = newCoordinates;
      } else if (isNumberArr(newCoordinates)) {
        // newCoordinates = point
        coordsToOffset = [newCoordinates];
      } else {
        // newCoordinates = polygon
        coordsToOffset = newCoordinates[0];
      }

      coordsToOffset.forEach((coord) => {
        coord[0] += distanceX;
        coord[1] += distanceY;
      });

      if (shapeMovedAction === saveMeasurerAction && isNumberArrArr(newCoordinates) && isCircuitMeasurer(shape)) {
        dispatch(saveCircuitToHistoryAction());
        const action = saveMeasurerAction({
          id: shapeId,
          geometry: { ...shape.geometry, coordinates: newCoordinates },
        });
        dispatch(action);
      } else if (shapeMovedAction === segmentMovedAction && isNumberArrArr(newCoordinates) && isCircuitSegment(shape)) {
        const action = segmentMovedAction({
          idSegment: shapeId,
          coordinates: newCoordinates,
        });
        dispatch(saveCircuitToHistoryAction());
        dispatch(action);
      } else if (shapeMovedAction === saveRackAction && isNumberArrArrArr(newCoordinates) && isCircuitRack(shape)) {
        const action = saveRackAction({
          id: shapeId,
          geometry: {
            ...shape.geometry,
            coordinates: newCoordinates,
          },
        });
        dispatch(saveCircuitToHistoryAction());
        dispatch(action);
      } else if (
        shapeMovedAction === updateStockZone &&
        isNumberArrArrArr(newCoordinates) &&
        isCircuitStockZone(shape)
      ) {
        const params: UpdateStockZoneParams = {
          newCoordinates,
          doNotDispatchAndReturn: true,
          forceUpdateCap: true,
        };
        dispatch(saveCircuitToHistoryAction());
        const action = updateStockZone(shape, params);
        if (action) {
          dispatch(action);
        }
      }
    },
    [dispatch, shape, shapeId, shapeMovedAction]
  );

  return (
    <>
      <MovementArrowButtons handleMoveShape={handleMoveShape} disabled={locked} shape={shape} />
      {children}
    </>
  );
};
