import { Box, Button, CardActions } from '@mui/material';
import { Stack } from '@mui/system';
import { openDialogAction } from 'actions';
import { Border } from 'components/utils/border';
import { DialogTypes } from 'models';
import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { RenameTrigger } from './rename-trigger';
import { TriggerConfiguration } from './trigger-configuration';
import { TriggersSelect } from './triggers-select';

export function TriggersToolbox(): JSX.Element {
  const dispatch = useAppDispatch();

  const selectedTriggerId = useAppSelector((state) => state.triggers.selectedTriggerId);
  const triggers = useAppSelector((state) => state.triggers.triggers);

  const selectedTrigger = useMemo(() => {
    return triggers.find((trigger) => trigger.id === selectedTriggerId);
  }, [selectedTriggerId, triggers]);

  const [editName, setEditName] = useState(false);

  const handleEditName = useCallback((newValue: boolean) => {
    setEditName(newValue);
  }, []);

  const handleOpenUpdateIntervalThroughput = useCallback(() => {
    dispatch(openDialogAction({ type: DialogTypes.UpdateIntervalThroughput, payload: {} }));
  }, [dispatch]);

  return (
    <Box component="div">
      <Stack direction={'row'} spacing={1}>
        {!editName ? (
          <TriggersSelect handleEditName={handleEditName} />
        ) : (
          selectedTrigger && (
            <RenameTrigger handleEditName={handleEditName} trigger={selectedTrigger} triggers={triggers} />
          )
        )}
      </Stack>

      <Border>
        <TriggerConfiguration />
      </Border>
      <CardActions sx={{ float: 'right', pt: 2, pb: 2 }}>
        <Button
          variant="contained"
          // endIcon={<Send />}
          onClick={handleOpenUpdateIntervalThroughput}
          // disabled={submittingCollab}
        >
          Update Multiple Interval Throughput
        </Button>
      </CardActions>
    </Box>
  );
}
