import type { CircuitImageData } from 'models/images';
import { remoteDoc } from 'multiplayer/globals';
import { useLayoutEffect } from 'react';
import { loadCircuitImageFromYJS, removeCircuitImageFromYJS } from 'reducers/images/images';
import store from 'store';
import type { YMap } from 'yjs/dist/src/internals';

export const useCircuitImagesSubscription = (): void => {
  const circuitImageMap = remoteDoc?.getMap('circuitImages') as YMap<{
    data: CircuitImageData;
    uInt8Array: Uint8Array;
  }>;

  useLayoutEffect(() => {
    const circuitImageObserverHandle = (event, transaction): void => {
      const isTransactionLocal = transaction.origin === 'local';
      if (isTransactionLocal) return;

      const circuitImages = store.getState().images.circuitImages;
      if (circuitImages?.length) {
        circuitImages.forEach((circuitImage) => {
          if (circuitImageMap.has(circuitImage.id)) return;

          store.dispatch(removeCircuitImageFromYJS(circuitImage));
        });
      }

      if (!circuitImageMap.size) return;

      circuitImageMap.forEach((remoteCircuitImage, key) => {
        const circuitImagePropagated = circuitImages?.find((circuitImage) => circuitImage.id === key);

        if (circuitImagePropagated && circuitImagePropagated.url) return;

        store.dispatch(loadCircuitImageFromYJS({ id: key }));
      });
    };

    circuitImageMap.observe(circuitImageObserverHandle);

    return () => {
      circuitImageMap.unobserve(circuitImageObserverHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
