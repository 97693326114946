import type { CircuitImageData } from 'models/images';
import { addCircuitImage } from 'reducers/images/images';
import store from 'store';
import { generateShapeId } from 'utils/circuit/next-free-id';

export function getCircuitImageById(imageId: string): CircuitImageData | undefined {
  const circuitImages = store.getState().images.circuitImages;

  return circuitImages.find((image) => image.id === imageId);
}

export function generateCircuitImageData(file: File): string {
  const id = generateShapeId();
  const name = renameFileWithId(file.name, id);
  const imageUrl = window.URL.createObjectURL(file);

  const image = new Image();
  image.src = imageUrl;
  image.onload = () => {
    const circuitImage: CircuitImageData = {
      id: id,
      name: name,
      url: imageUrl,
      width: image.width,
      height: image.height,
    };
    store.dispatch(addCircuitImage(circuitImage));
  };

  return id;
}

function renameFileWithId(filename: string, id: string): string {
  const dotIndex = filename.lastIndexOf('.');

  if (dotIndex === -1) {
    // If file name don't have extension
    return `${filename}_${id}`;
  }

  const name = filename.slice(0, dotIndex);
  const extension = filename.slice(dotIndex);

  return `${name}_${id}${extension}`;
}
