import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, ButtonGroup, Divider, Grid, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ColorPicker } from 'components/utils/color-picker';
import { GetComponent } from 'components/utils/get-component';
import type { CellLoad } from 'models/circuit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { CellTemplate, LoadedCellTemplate } from 'reducers/circuit/state';
import { useAppSelector } from 'store';
import { getDefaultBeamUpMarginObstacle, getDefaultLoad, getEuroPallet } from 'utils/circuit/default-circuit-shapes';
import { theme } from 'utils/mui-theme';
import { CellTemplateLoadForm } from './CellTemplateLoadForm';
import type {
  FocusInputs,
  GeometryCellTemplateData,
  PerceptionCellTemplateData,
  PickOrDrop,
  ViewCellTemplate,
} from './rack-edition-celltemplate';
import { CellTemplateParametersGeometry, CellTemplateParametersPerception } from './rack-edition-celltemplate';

interface RackEditionCellTemplateParametersProps {
  /** cell template to edit the parameters */
  cellTemplate: CellTemplate;
  /** function to call to edit the cell template parameters */
  updateCellTemplate: (newCellTemplate: CellTemplate) => void;

  /** which kind of parameters should we edit currently? */
  view?: ViewCellTemplate;
  /** change the kind iof parameters we want to edit */
  setView?: (newView: ViewCellTemplate) => void;
  /** which input is currently focused (undefined if none are focused) */
  focusedInputs?: FocusInputs;
  /** change which input is currently focused */
  onChangeFocusedInputs?: (newState: FocusInputs) => void;
  /** width taken by the largest load [m] */
  widthTakenByLargestLoads?: number;
  /** fonction to call to change the pickOrDropMode */
  setPickOrDropMode: React.Dispatch<React.SetStateAction<PickOrDrop>>;
}
export function RackEditionCellTemplateParameters({
  cellTemplate,
  updateCellTemplate: updateCellTemplateAction,
  view = 'geometry',
  setView = () => {
    // eslint-disable-next-line no-console
    console.warn('setView function not defined');
  },
  focusedInputs,
  onChangeFocusedInputs,
  widthTakenByLargestLoads,
  setPickOrDropMode,
}: RackEditionCellTemplateParametersProps): JSX.Element {
  const [name, setName] = useState(cellTemplate.name);
  const [color, setColor] = useState(cellTemplate.color);

  const [loads, setLoads] = useState(cellTemplate.loads);

  useEffect(() => {
    setLoads(cellTemplate.loads);
  }, [cellTemplate.loads]);
  useEffect(() => {
    setName(cellTemplate.name);
  }, [cellTemplate.name]);
  useEffect(() => {
    setColor(cellTemplate.color);
  }, [cellTemplate.color]);

  const geometryDataRef = useRef<undefined | GeometryCellTemplateData>(undefined);
  const perceptionDataRef = useRef<undefined | PerceptionCellTemplateData>(undefined);

  const updateCellTemplate = useCallback(() => {
    let newCellTemplate: LoadedCellTemplate | undefined;
    if (geometryDataRef.current) {
      const {
        width,
        height,
        approachDistance,
        leftObstacle,
        rightObstacle,
        palletOverflow,
        beamDistanceToRackFront,
        loadLength,
        maximumLoadHeight,
      } = geometryDataRef.current;

      newCellTemplate = {
        ...cellTemplate,
        ...newCellTemplate,
        name,
        width: parseFloat(width) / 1000,
        height: parseFloat(height) / 1000,
        maximumLoadHeight: parseFloat(maximumLoadHeight) / 1000,
        approachDistance: parseFloat(approachDistance) / 1000,
        color,
        loads,
        leftObstacle: parseFloat(leftObstacle) / 1000,
        rightObstacle: parseFloat(rightObstacle) / 1000,
        palletOverflow: parseFloat(palletOverflow) / 1000,
        loadLength: loadLength ? parseFloat(loadLength) / 1000 : getEuroPallet().palletLength ?? 1.2,
        beamDistanceToRackFront: parseFloat(beamDistanceToRackFront) / 1000,
      };
    }

    if (perceptionDataRef.current) {
      const perceptionValues = perceptionDataRef.current;
      const marginObstacleValue = perceptionValues.beamUpMarginObstacle
        ? parseFloat(perceptionValues.beamUpMarginObstacle) / 1000
        : getDefaultBeamUpMarginObstacle();

      newCellTemplate = {
        ...cellTemplate,
        ...newCellTemplate,
        perception: {
          ...cellTemplate.perception,
          startDepth: parseFloat(perceptionValues.startDepth) / 1000,
          startHeight: parseFloat(perceptionValues.startHeight) / 1000,
          minimumHeightVerification: parseFloat(perceptionValues.minimumHeightVerification) / 1000,
          beamUpMarginObstacle: marginObstacleValue,
          minimumVerticalClearanceForDrop: parseFloat(perceptionValues.minimumVerticalClearanceForDrop) / 1000,
          freeSpaceMinObjectSize: parseFloat(perceptionValues.freeSpaceMinObjectSize) / 1000,
          minimumLateralClearanceForDrop: parseFloat(perceptionValues.minimumLateralClearanceForDrop) / 1000,
          minimumDepthClearanceForDrop: parseFloat(perceptionValues.minimumDepthClearanceForDrop) / 1000,
          beamFaceXTolerance: parseFloat(perceptionValues.beamFaceXTolerance) / 1000,
          uprightYTolerance: parseFloat(perceptionValues.uprightYTolerance) / 1000,
          beamHeightZTolerance: parseFloat(perceptionValues.beamHeightZTolerance) / 1000,
          overhangTolerance: parseFloat(perceptionValues.overhangTolerance) / 1000,
          initialSolution: perceptionValues.initialSolution.map((v) => parseFloat(v) / 1000) as [
            number,
            number,
            number,
          ],
          rejectPercentage: {
            model: parseFloat(perceptionValues.rejectPercentage.model) / 100,
            feet: parseFloat(perceptionValues.rejectPercentage.feet) / 100,
            pockets: parseFloat(perceptionValues.rejectPercentage.pockets) / 100,
          },
          minObstacleMultiplier: parseFloat(perceptionValues.minObstacleMultiplier) / 1,
          cameraProjectionBeforeInspectionPlane: perceptionValues.cameraProjectionBeforeInspectionPlane,
          horizontalProjectionOnInspectionPlane: perceptionValues.horizontalProjectionOnInspectionPlane,
          horizontalProjection: perceptionValues.horizontalProjection,
          flyingObjectDetection: perceptionValues.flyingObjectDetection,
          activateCrossShapedMethod: perceptionValues.activateCrossShapedMethod,
          relPickActivateCorr: perceptionValues.relPickActivateCorr,
          relDropActivateCorr: perceptionValues.relDropActivateCorr,
          rangefinderDetection: perceptionValues.rangefinderDetection,
          enablePerceptionPick: perceptionValues.enablePerceptionPick,
          enablePerceptionDrop: perceptionValues.enablePerceptionDrop,
        },
      };
    }

    if (newCellTemplate) updateCellTemplateAction(newCellTemplate);
  }, [cellTemplate, color, loads, name, updateCellTemplateAction]);

  const handleBlurNumberField = useCallback(
    (
      value: string,
      setValue: React.Dispatch<React.SetStateAction<string>>,
      fallbackValue: number,
      options?: {
        min?: number;
        max?: number;
        unitFactor?: number;
        toFixedValue?: number;
      }
    ) => {
      const newValue = parseFloat(value) / (options?.unitFactor ?? 1000);

      if (isNaN(newValue)) {
        setValue((fallbackValue * (options?.unitFactor ?? 1000)).toFixed(options?.toFixedValue ?? 0));
      } else if (options && options.min !== undefined && newValue < options.min) {
        setValue((options.min * (options.unitFactor ?? 1000)).toFixed(options?.toFixedValue ?? 0));
      } else if (options && options.max !== undefined && newValue > options.max) {
        setValue((options.max * (options.unitFactor ?? 1000)).toFixed(options?.toFixedValue ?? 0));
      }

      if (onChangeFocusedInputs) onChangeFocusedInputs(undefined);

      setTimeout(() => updateCellTemplate(), 10);
    },
    [onChangeFocusedInputs, updateCellTemplate]
  );
  const handleKeyPressNumberField = useCallback(
    (
      e: React.KeyboardEvent<HTMLDivElement>,
      value: string,
      setValue: React.Dispatch<React.SetStateAction<string>>,
      fallbackValue: number,
      options?: {
        min?: number;
        max?: number;
        /** by default * 1000 because mm */
        unitFactor?: number;
        /** 0 by default */
        toFixedValue?: number;
      }
    ) => {
      if (e.key === 'Enter' && !isNaN(parseFloat(value))) {
        setValue(parseFloat(value).toFixed(options?.toFixedValue ?? 0));
        handleBlurNumberField(value, setValue, fallbackValue, options);
      } else if (e.key === 'Escape') {
        e.stopPropagation();
        setValue((fallbackValue * (options?.unitFactor ?? 1000)).toFixed(options?.toFixedValue ?? 0));
        setTimeout(() => (e.target as HTMLInputElement).blur(), 0);
      }
    },
    [handleBlurNumberField]
  );

  const onSelectLoad = useCallback(
    (loadIndex: number) => {
      updateCellTemplateAction({
        ...cellTemplate,
        selectedLoad: loadIndex,
      });
    },
    [cellTemplate, updateCellTemplateAction]
  );
  const onDeleteLoad = useCallback(
    (loadIndex: number) => {
      const newLoads = [...loads];
      newLoads.splice(loadIndex, 1);
      setLoads(newLoads);

      if (cellTemplate.selectedLoad >= newLoads.length) {
        onSelectLoad(newLoads.length - 1);
      }

      updateCellTemplateAction({
        ...cellTemplate,
        loads: newLoads,
      });
    },
    [cellTemplate, loads, onSelectLoad, updateCellTemplateAction]
  );
  const onUpdateLoad = useCallback(
    (loadIndex: number, newLoad: CellLoad) => {
      const newLoads = [...loads];
      newLoads[loadIndex] = newLoad;
      setLoads(newLoads);

      updateCellTemplateAction({
        ...cellTemplate,
        loads: newLoads,
      });
    },
    [cellTemplate, loads, updateCellTemplateAction]
  );

  const onUpdateColor = useCallback(
    (newColor: string) => {
      setColor(newColor);

      updateCellTemplateAction({
        ...cellTemplate,
        color: newColor,
      });
    },
    [cellTemplate, updateCellTemplateAction]
  );

  const onAddLoad = useCallback(() => {
    const newLoads = [
      ...loads,
      getDefaultLoad(
        true,
        loads.map((l) => l.name)
      ),
    ];
    setLoads(newLoads);

    updateCellTemplateAction({
      ...cellTemplate,
      loads: newLoads,
    });
  }, [cellTemplate, loads, updateCellTemplateAction]);

  const standardMode = useAppSelector((state) => state.local.standardMode);

  return (
    <>
      <TextField
        label="Cell Name"
        value={name}
        fullWidth
        sx={{ marginTop: theme.spacing(1) }}
        margin="none"
        size="small"
        onChange={(e) => setName(e.target.value)}
        InputProps={{
          endAdornment: <ColorPicker variant="color-bubble" defaultColor={color} onChangeFinished={onUpdateColor} />,
        }}
        onBlur={() => {
          if (!name) setName(cellTemplate.name);
          else updateCellTemplate();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !name) {
            if (!name) setName(cellTemplate.name);
            else updateCellTemplate();
          } else if (e.key === 'Escape') {
            e.stopPropagation();
            setName(cellTemplate.name);
            setTimeout((e.target as HTMLInputElement).blur, 0);
          }
        }}
      />
      <Divider variant="middle" sx={{ paddingTop: 2 }}>
        Pallets
      </Divider>
      <Grid container columnSpacing={0.5} alignItems="center" justifyContent="center" rowSpacing={0}>
        {/** legends */}
        <Grid item xs={1} sx={{ textAlign: 'center' }}></Grid>
        <Grid item xs={2} sx={{ textAlign: 'center' }}>
          <Tooltip title="Name of the load" disableInteractive={true} leaveDelay={10}>
            <div>Name</div>
          </Tooltip>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: 'center', cursor: 'help' }}>
          <Tooltip title="Number of loads in the cell" disableInteractive={true} leaveDelay={10}>
            <div style={{ width: '100%' }}>N</div>
          </Tooltip>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'center', cursor: 'help' }}>
          <Tooltip
            title="Minimum distance between a load and an upright [mm]"
            disableInteractive={true}
            leaveDelay={10}
          >
            <div>
              a<span style={{ verticalAlign: 'sub', fontSize: '0.8em' }}>1</span>
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'center', cursor: 'help' }}>
          <Tooltip title="Width of a load [mm]" disableInteractive={true} leaveDelay={10}>
            <div>W</div>
          </Tooltip>
        </Grid>
        <Grid item xs={1}></Grid>

        {/** the fields */}
        {loads.map((load, index) => (
          <CellTemplateLoadForm
            key={index}
            load={load}
            onDelete={loads.length > 1 ? onDeleteLoad : undefined}
            index={index}
            selected={index === cellTemplate.selectedLoad}
            onSelect={onSelectLoad}
            onUpdateLoad={onUpdateLoad}
            cellTemplate={cellTemplate}
            focusedInputs={focusedInputs}
            onChangeFocusedInputs={onChangeFocusedInputs}
            loads={loads}
          />
        ))}
      </Grid>

      <Divider variant="middle">
        <Tooltip title="Add a new pallet">
          <IconButton aria-label="add a new pallet" onClick={onAddLoad}>
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      </Divider>

      <ButtonGroup variant="contained" fullWidth sx={{ marginTop: theme.spacing(2) }} disableElevation>
        <Button
          color={view === 'geometry' ? 'primary' : 'inherit'}
          sx={{ textTransform: 'none' }}
          onClick={() => setView('geometry')}
        >
          Geometry
        </Button>
        {
          <GetComponent
            component={standardMode ? Tooltip : React.Fragment}
            title={standardMode ? 'Some perception feature are not available in standard mode.' : undefined}
          >
            <Button
              color={view === 'perception' ? 'primary' : 'inherit'}
              sx={{ textTransform: 'none' }}
              onClick={() => setView('perception')}
            >
              Perception
            </Button>
          </GetComponent>
        }
      </ButtonGroup>
      {view === 'geometry' && (
        <CellTemplateParametersGeometry
          cellTemplate={cellTemplate}
          handleBlurNumberField={handleBlurNumberField}
          handleKeyPressNumberField={handleKeyPressNumberField}
          geometryDataRef={geometryDataRef}
          focusedInputs={focusedInputs}
          onChangeFocusedInputs={onChangeFocusedInputs}
          widthTakenByLargestLoads={widthTakenByLargestLoads}
          requestCellTemplateUpdate={() => setTimeout(updateCellTemplate, 10)}
        />
      )}
      {view === 'perception' && (
        <CellTemplateParametersPerception
          cellTemplate={cellTemplate}
          handleBlurNumberField={handleBlurNumberField}
          handleKeyPressNumberField={handleKeyPressNumberField}
          perceptionDataRef={perceptionDataRef}
          updateCellTemplate={updateCellTemplate}
          setPickOrDropMode={setPickOrDropMode}
        />
      )}
    </>
  );
}
