import { syncYJSLocalToRemote } from 'components/presence/utils/syncYjsDoc';
import type { CircuitImageData } from 'models/images';
import { localDoc } from 'multiplayer/globals';
import { addCircuitImage, removeCircuitImage } from 'reducers/images/images';
import type { AppState } from 'reducers/state';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { combineEpics, ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { ignoreElements, map, withLatestFrom } from 'rxjs/operators';
import { convertBlobUrlToUInt8Array } from 'utils/preferences';

export const addCircuitImageEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<null> => {
  return action$.pipe(
    ofType(addCircuitImage.type),
    withLatestFrom(state$),
    map(async ([action, state]) => {
      const circuitImage: CircuitImageData = action.payload as CircuitImageData;

      if (
        !state.multiplayer.multiplayer ||
        !state.images.circuitImages ||
        !state.images.circuitImages.some((img: { name: any }) => img.name === circuitImage.name)
      )
        return;

      const localCircuitImagesMap = localDoc.getMap('circuitImages');
      const { url, id } = circuitImage;
      const circuitImageUInt8Array = await convertBlobUrlToUInt8Array(url);

      localCircuitImagesMap.set(id, { data: circuitImage, uInt8Array: circuitImageUInt8Array });

      syncYJSLocalToRemote();
    }),
    ignoreElements()
  );
};

export const removeCircuitImageEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<AppState>
): Observable<null> => {
  return action$.pipe(
    ofType(removeCircuitImage.type),
    withLatestFrom(state$),
    map(([action, state]) => {
      const circuitImageId: string = action.payload.imageId as string;

      if (!state.multiplayer.multiplayer) return;

      const localCircuitImagesMap = localDoc.getMap('circuitImages');
      localCircuitImagesMap.delete(circuitImageId);

      syncYJSLocalToRemote();
    }),
    ignoreElements()
  );
};

// Combine the epics
export const combineImagesEpics = combineEpics(addCircuitImageEpic, removeCircuitImageEpic);
