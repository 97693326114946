/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AuthActionTypes } from 'actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { selectTokenStatus, selectUserLoggedIn } from 'reducers/auth';
import type { Middleware } from 'redux';
import type { Action } from 'utils/redux/shared';

export function delayNavigationMiddleware(): Middleware {
  let delayed: Action | null = null;

  return (store) => (next) => (action) => {
    if (!action) return;

    const { type } = action;

    switch (type) {
      case LOCATION_CHANGE: {
        const state = store.getState();
        const loggedIn = selectUserLoggedIn(state);
        const tokenRefreshed = selectTokenStatus(state).refreshed;

        if (loggedIn && !tokenRefreshed) {
          delayed = action;
        } else {
          next(action);
        }

        break;
      }

      case AuthActionTypes.RefreshTokenSuccess: {
        if (delayed) {
          next(action);
          store.dispatch(delayed);
          delayed = null;
        }

        break;
      }

      default: {
        next(action);
      }
    }
  };
}
